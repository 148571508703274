import { BigNumber, constants, ethers } from 'ethers';
import { EthereumService } from './EthereumService';
import { MessagingService } from './MessagingService';
import { Utils } from './Utils';
import { ILottoService, PrizeSplit } from './Interfaces';
import { IGameConfig } from '../models/GameConfig';

export class ChickenFeedServiceV1 implements ILottoService {
  walletAddress?: string;

  tokenContract?: ethers.Contract | any;
  gameContract?: ethers.Contract | any;

  currentDrawIndex = 0;
  currentDrawDeadlinePassed = false;
  currentDrawTickets = 0;
  currentDrawTicketsPercent = 0;
  totalDrawTickets = 0;
  currentDrawCountdownSecs = 0;
  currentDrawCountdownTime = { days: '  ', hours: '  ', mins: '  ', secs: '  ', isComplete: false };
  currentPrizePool = 0;
  prizeSplit = new PrizeSplit();

  ticketPrice: string | 0 = 0;
  isSpendApproved: boolean = false;
  unclaimedPrizes: BigNumber[] = [];
  unclaimedPrizeTotal: number = 0;
  numberOfTickets: number = 0;
  isWinning = false;

  actionDisplayName = 'Feed The Chicken';

  constructor(
    public config: IGameConfig,
    private ethereumService: EthereumService,
    private messagingService: MessagingService
  ) {
    this.walletAddress = this.ethereumService!.walletData.walletAddress;
    this.tokenContract = new ethers.Contract(
      this.config.tokenContractAddress,
      this.config.tokenContractAbi,
      ethereumService.signer
    );
    this.gameContract = new ethers.Contract(
      this.config.gameContractAddress,
      this.config.gameContractAbi,
      ethereumService.signer
    );

    this.setupEventHandlers();
  }

  async update(): Promise<any> {
    const currentDraw = await this.gameContract.getCurrentDraw();
    this.currentDrawIndex = currentDraw.toNumber();

    var work = [
      this.tokenContract.allowance(this.walletAddress, this.config.gameContractAddress),
      this.gameContract.ticketPrice(),
      this.gameContract.draws(currentDraw),
      this.gameContract.getUnclaimedWinnerByAddress(this.walletAddress),
      this.gameContract.numberOfTickets(),
      this.gameContract.getNumberOfTicketPerAddressByDraw(currentDraw, this.walletAddress)
    ];

    await Promise.all(work).then(async response => {
      this.isSpendApproved = response[0]._hex !== constants.Zero._hex;
      this.ticketPrice = Utils.formatUnits(response[1], 18);
      const networkBlockTime = this.ethereumService.networkManager!.network!.blocktime;
      const deadlineBlockNumber = +Utils.formatUnits(response[2][4], 0);
      this.currentPrizePool = +Utils.formatUnits(response[2][2], 18);
      this.currentDrawCountdownSecs =
        networkBlockTime * (deadlineBlockNumber - this.ethereumService.walletData.stdBlockNumber);
      this.updateTimeRemaining();
      this.currentDrawDeadlinePassed = deadlineBlockNumber < this.ethereumService.walletData.stdBlockNumber;
      this.totalDrawTickets = response[2][6];
      this.unclaimedPrizes = response[3];
      this.numberOfTickets = response[4];

      this.actionDisplayName = `Feed The Chicken (${this.numberOfTickets * +this.ticketPrice} CORN)`;

      this.isWinning = response[2][0] === this.walletAddress;
      this.currentDrawTickets = response[6] * this.numberOfTickets;

      var prizeSplit = await this.gameContract.splitPrizePool(response[2][2]);
      this.prizeSplit.burnAmount = +Utils.formatUnits(prizeSplit.burnAmount, 18);
      this.prizeSplit.closersFee = +Utils.formatUnits(prizeSplit.closersFee, 18);
      this.prizeSplit.nextDrawAmount = +Utils.formatUnits(prizeSplit.nextDrawAmount, 18);
      this.prizeSplit.winnersPrize = +Utils.formatUnits(prizeSplit.winnersPrize, 18);
    });

    if (this.unclaimedPrizes.length > 0) {
      work = this.unclaimedPrizes.map(udi => {
        return this.gameContract.draws(udi);
      });

      this.unclaimedPrizeTotal = await Promise.all(work).then(response => {
        return response.reduce((p, c) => {
          return p + +Utils.formatUnits(c[5], 18);
        }, 0);
      });
    }
  }

  updateTimeRemaining() {
    var secondsRemaining = this.currentDrawCountdownSecs < 0 ? 0 : Math.trunc(this.currentDrawCountdownSecs);

    this.currentDrawCountdownTime = {
      days: secondsRemaining < 0 ? '00' : ('0' + Math.floor(secondsRemaining / (60 * 60 * 24))).slice(-2),
      hours: secondsRemaining < 0 ? '00' : ('0' + Math.floor((secondsRemaining / (60 * 60)) % 24)).slice(-2),
      mins: secondsRemaining < 0 ? '00' : ('0' + Math.floor((secondsRemaining / 60) % 60)).slice(-2),
      secs: secondsRemaining < 0 ? '00' : ('0' + (secondsRemaining % 60)).slice(-2),
      isComplete: secondsRemaining < 0
    };
  }

  approve() {
    var amount = Utils.parseUnits('1000000000');

    this.tokenContract.approve(this.config.gameContractAddress, amount).then(
      (approveResponse: any) =>
        this.messagingService.events.next({
          message: `Approval of ${this.config.gameType} is Pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error Approving ${this.config.token} : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  buyTicket() {
    this.gameContract.buyTicket().then(
      (stakeResponse: any) =>
        this.messagingService.events.next({
          message: `Feed the Chicken Pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error Feeding the Chicken: ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  closeDraw() {
    this.gameContract.closeDraw().then(
      (withdrawResponse: any) =>
        this.messagingService.events.next({
          message: `${this.config.gameType} Start Pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error Starting a new ${this.config.gameType} Game : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  claimPrize() {
    const prizeIndex = this.unclaimedPrizes[0];
    this.gameContract.claimWinningPrize(prizeIndex).then(
      (claimResponse: any) =>
        this.messagingService.events.next({
          message: `${this.config.gameType} Prize Claim Is Pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error Claiming ${this.config.gameType} Prize : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  removeEventHandlers() {
    this.tokenContract?.removeAllListeners();
    this.gameContract?.removeAllListeners();
  }

  setupEventHandlers() {
    this.removeEventHandlers();

    var update = (message: string) =>
      this.update().then(() => {
        this.messagingService.events.next({ message: message });
      });

    // Commented out because approvals emitted on ticket buy

    // if (this.tokenContract) {
    //   this.tokenContract.on(
    //     {
    //       address: this.ethereumService.walletData.walletAddress,
    //       topics: [ethers.utils.id('Approval(address,address,uint256)')]
    //     },
    //     (owner: any, spender: any, amount: any) => {
    //       if (owner === this.ethereumService.walletData.walletAddress && spender === this.config.gameContractAddress) {
    //         update(`Approved ${this.config.gameType}`);
    //       }
    //     }
    //   );
    // }

    if (this.gameContract) {
      this.gameContract.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: ['0x3ae04c94f33d87777270e24b5f3a2f1d9a407718706c7c060da6941141f73abe']
        },
        (updatedBy: any, pricePaid: any, totalTickets: any) => {
          if (updatedBy === this.ethereumService.walletData.walletAddress) {
            update(`You Fed the Chicken`);
          }
        }
      );

      this.gameContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: ['0x95681e512bc0fe659e195e06c283eada494316f3d801213e48e7101af92bf770']
        },
        (updatedBy: any, amountClaimed: any) => {
          if (updatedBy === this.ethereumService.walletData.walletAddress) {
            update(`${this.config.gameType} Prize Claimed`);
          }
        }
      );

      this.gameContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: ['0x64f8e38e0c84e256216022f39cbb3545f496f09e605a5803e30de6f83040df72']
        },
        (closedBy: any, burnedAmount: any, closerFee: any, nextDrawAmount: any) => {
          if (closedBy === this.ethereumService.walletData.walletAddress) {
            update(`New ${this.config.gameType} Started`);
          }
        }
      );

      this.ethereumService.provider!.on('block', currentBlock => {
        this.update().then(() => {});
      });
    }
  }
}
