import { Token } from '@uniswap/sdk';
import { BigNumber, ethers } from 'ethers';
import { IContractConfig } from '../models/AssetConfig';
import { EthereumService } from './EthereumService';
import { MessagingService } from './MessagingService';
import { INonFungibleAssetService } from './Interfaces';
import { getItemId, IItemDetails, toItemDetails } from '../models/ItemDetails';
import { Mutex } from 'async-mutex';

export class ItemService implements INonFungibleAssetService {
  private mutex = new Mutex();
  walletAddress?: string;
  config: IContractConfig;
  itemsContract?: ethers.Contract;
  itemSetsContract?: ethers.Contract;
  allItems: IItemDetails[] = [];
  itemMap: Map<number, IItemDetails> = new Map<number, IItemDetails>();
  count: number = 0;
  gasPrice?: string | number;
  token: Token;
  tokenUri?: string;

  // TODO interface needs refactoring, some of this doesn't make sense

  actionText: string = 'Find';
  actionUrl: string = '/quests';

  constructor(private messagingService: MessagingService, private ethereumService: EthereumService) {
    this.walletAddress = this.ethereumService!.walletData.walletAddress;

    this.config = this.ethereumService.networkManager!.network!.itemConfig!;

    this.itemsContract = new ethers.Contract(
      this.config.contractAddress,
      this.config.contractConstants!.contractAbi,
      ethereumService.signer
    );

    // get itemset contract
    var itemSetsConfig = this.ethereumService.networkManager!.network!.itemSetsConfig!;
    this.itemSetsContract = new ethers.Contract(
      itemSetsConfig.contractAddress,
      itemSetsConfig.contractConstants!.contractAbi,
      ethereumService.signer
    );

    this.token = new Token(ethereumService.networkManager!.network!.chainId, this.config.contractAddress, 0);

    this.setupEventHandlers();
  }

  async update(): Promise<any> {
    await this.mutex.runExclusive(async () => {
      await this.loadStatics();
      await this.loadOwned();
    });
  }

  private async loadStatics() {
    if (this.allItems.length > 0) {
      return;
    }

    var itemsJson = localStorage.getItem('items');
    var itemsLoadedContract = localStorage.getItem('itemsLoadedContract2');
    var itemsLoadedMs = localStorage.getItem('itemsLoaded') ?? Date.now();
    var contractAddressKey = this.config.contractAddress;

    // TODO make this less hardcoded
    if (!itemsJson || itemsLoadedContract !== contractAddressKey || Date.now() - +itemsLoadedMs > 86400000) {
      var [getItemSet10, getItemSet11, getItemSet12, getItemSet20, getItemSet21, getItems] = await Promise.all([
        this.itemSetsContract!.getItemSet(10),
        this.itemSetsContract!.getItemSet(11),
        this.itemSetsContract!.getItemSet(12),
        this.itemSetsContract!.getItemSet(20),
        this.itemSetsContract!.getItemSet(21),
        this.itemsContract!.getItems()
      ]);

      var scavengeItems = getItemSet10.map((i: any) => BigNumber.from(i.itemID).toNumber());
      var mineItems = getItemSet11.map((i: any) => BigNumber.from(i.itemID).toNumber());
      var stillItems = getItemSet12.map((i: any) => BigNumber.from(i.itemID).toNumber());
      var quarryItems = getItemSet11.map((i: any) => BigNumber.from(i.itemID).toNumber());
      var forestItems = getItemSet11.map((i: any) => BigNumber.from(i.itemID).toNumber());
      var healthItems = getItemSet21.map((i: any) => {
        return { itemId: BigNumber.from(i.itemID).toNumber(), statRestoreAmount: BigNumber.from(i.value1).toNumber() };
      });
      var moraleItems = getItemSet20.map((i: any) => {
        return { itemId: BigNumber.from(i.itemID).toNumber(), statRestoreAmount: BigNumber.from(i.value1).toNumber() };
      });

      var itemArray = [...getItems];
      itemArray.shift();

      await Promise.all(
        itemArray.map(async (item: Array<any>) => {
          var itemId = getItemId(item);
          var questUseMap = new Map<number, boolean>();
          questUseMap.set(0, scavengeItems.includes(itemId));
          questUseMap.set(3, scavengeItems.includes(itemId));
          questUseMap.set(1, mineItems.includes(itemId));
          questUseMap.set(6, mineItems.includes(itemId));
          questUseMap.set(2, stillItems.includes(itemId));
          questUseMap.set(7, stillItems.includes(itemId));
          questUseMap.set(4, quarryItems.includes(itemId));
          questUseMap.set(8, quarryItems.includes(itemId));
          questUseMap.set(5, forestItems.includes(itemId));
          questUseMap.set(9, forestItems.includes(itemId));

          var healthItem = healthItems.find((i: any) => i.itemId === itemId);
          var moraleItem = moraleItems.find((i: any) => i.itemId === itemId);
          var statRestoreAmount = healthItem?.statRestoreAmount ?? moraleItem?.statRestoreAmount ?? 0;
          var itemDetails = await toItemDetails(item, questUseMap, healthItem, moraleItem, statRestoreAmount);

          if (!this.allItems.find(i => i.id === itemDetails.id)) {
            this.allItems.push(itemDetails);
          }
        })
      );

      localStorage.setItem('items', JSON.stringify(this.allItems));
      localStorage.setItem('itemsLoadedContract', contractAddressKey);
      localStorage.setItem('itemsLoaded', Date.now().toString());
    } else {
      this.allItems = JSON.parse(itemsJson);
    }

    this.allItems.forEach((item: IItemDetails) => {
      this.itemMap.set(item.id, item);
    });
  }

  private async loadOwned() {
    var allItemIds = this.allItems.map((item: IItemDetails) => +item.id).sort((a, b) => a - b);

    const details = await this.itemsContract!.balanceOfBatch(
      allItemIds.map(_ => this.walletAddress),
      allItemIds
    );

    var itemBalances: number[] = details.map((bn: BigNumber) => bn.toNumber());

    this.allItems.forEach((i: IItemDetails) => {
      i.balance = itemBalances[i.id - 1];
      this.itemMap.set(i.id, i);
    });
  }

  async getActiveItems(characterId: number): Promise<IItemDetails[]> {
    var activeItems = await this.itemsContract!.getActiveItemsByTokenID(characterId);
    return activeItems.map((i: any) => this.itemMap.get(BigNumber.from(i).toNumber()));
  }

  removeEventHandlers() {
    this.itemsContract?.removeAllListeners();
  }

  setupEventHandlers() {
    this.removeEventHandlers();

    var update = (message: string) => {
      this.messagingService.events.next({ message: message });
      this.loadOwned();
    };

    if (this.itemsContract) {
      this.itemsContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('TransferSingle(address,address,address,uint256,uint256)')]
        },
        (operator, from, to, id, value) => {
          if (to === this.ethereumService.walletData.walletAddress) {
            var itemDetails = this.itemMap.get(BigNumber.from(id).toNumber());
            update(`Item has been added to your barn: ${value} ${itemDetails?.name}`);
          }
        }
      );
    }
  }
}
