import { ethers } from 'ethers';
import { Utils } from './Utils';
import { EthereumService } from './EthereumService';
import { Fetcher, Pair, Route } from '@uniswap/sdk';
import { AssetType } from '../models/AssetConfig';
import { TokenId } from '../models/Enums';
import { PricesServiceBase } from './IPricesService';

export class UniswapPricesService extends PricesServiceBase {
  public ethersProvider?: ethers.providers.BaseProvider;

  constructor(private ethereumService: EthereumService) {
    super();
  }

  async update(): Promise<any> {
    if (
      !this.ethereumService.provider ||
      !this.ethereumService.provider.network ||
      !this.ethereumService.networkManager!.network
    ) {
      return;
    }

    let network = this.ethereumService.networkManager!.network;

    this.ethereumService!.provider.getGasPrice().then(price => {
      this.prices.gas = Utils.formatUnits(price, 'gwei');
    });

    if (network.loadPrices) {
      var basePairPromise = Fetcher.fetchPairData(
        network.tokens!.WETH!,
        network.tokens!.USDC!,
        // @ts-ignore
        this.ethereumService.networkManager!.ethersProvider
      );
      var landPairPromise = Fetcher.fetchPairData(
        network.tokens!.LAND!,
        network.tokens!.WETH!,
        // @ts-ignore
        this.ethereumService.networkManager!.ethersProvider
      );

      var cropPairPromises: Promise<Pair>[] = [];

      network.assets.forEach((asset, assetId) => {
        if (asset.assetConstants!.assetType === AssetType.Crop) {
          cropPairPromises.push(
            Fetcher.fetchPairData(
              network.tokens![assetId]!,
              network.tokens!.WETH!,
              // @ts-ignore
              this.ethereumService.networkManager!.ethersProvider
            )
          );
        }
      });

      Promise.all([basePairPromise, landPairPromise, ...cropPairPromises]).then(
        response => {
          const wethUsdcPair = response[0];
          const landWethPair = response[1];
          const landRoute = new Route([wethUsdcPair, landWethPair], network.tokens!.USDC!);

          this.prices.tokenUsd.set(TokenId.LAND, landRoute.midPrice.invert().toSignificant(4));

          for (let i = 2; i < response.length; i++) {
            const iWethPair = response[i];
            const iRoute = new Route([wethUsdcPair, iWethPair], network.tokens!.USDC!);
            this.prices.tokenUsd.set(
              TokenId[iWethPair.token0.name! as keyof typeof TokenId],
              iRoute.midPrice.invert().toSignificant(4)
            );
          }
        },
        error => {
          this.handleLoadPricesError(error);
        }
      );
    }
  }

  handleLoadPricesError(error: any) {
    if (error && error.reason) {
      console.error('Error loading prices: ' + error.reason);
    } else {
      console.error(error);
    }
  }
}
