import React, { Component } from 'react';
import { getStatPercent, ICharacterDetails, ICharacterStat, isStatFull } from '../../models/CharacterDetails';
import { ICharacterAction } from '../../models/ICharacterAction';
import { CharacterImage } from './CharacterImage';

export interface ICharacterProps {
  character?: ICharacterDetails;
  actionsLv1: ICharacterAction[];
  actionsLv2: ICharacterAction[];
  actionsLv3: ICharacterAction[];
  onCardClick?: any;
  isSample: boolean;
  isLoading: boolean;
  health?: ICharacterStat;
  morale?: ICharacterStat;
}

export class Character extends Component<ICharacterProps> {
  constructor(props: any) {
    super(props);
    this.click = this.click.bind(this);
  }

  click() {
    this.props.onCardClick();
  }

  renderImagePart(partType: string, partName: string) {
    var src = `./assets/mercs/${partType}/${partName}.png`;
    return (
      <div style={{ height: 0 }}>
        <img className="nft" src={src} alt={partType} />
      </div>
    );
  }

  render() {
    return (
      <div
        className={`stage mr-3 mb-5 is-flex is-flex-direction-column is-align-items-center noselect character-type-${this.props.character!.type.toLowerCase()}`}>
        <div
          onClick={this.click}
          className={`card p-3 ${this.rarityClass()} ${this.props.isLoading ? 'is-loading' : ''}`}
          style={{
            backgroundImage: `linear-gradient(rgba(10, 10, 10, 0.6), rgba(10, 10, 10, 1)),url("${
              this.props.character!.image
            }")`
          }}>
          <div className="card-image">
            <CharacterImage character={this.props.character} isSample={this.props.isSample}></CharacterImage>
          </div>
          <div className="card-content p-0 py-2">
            <div className="content">
              <div className="is-flex is-justify-content-space-between is-align-items-center">
                <span>
                  <p className="character-title is-4 mb-0 mt-1">{this.props.character!.name}</p>
                  <p className="is-size-6 mb-0">{this.props.character!.shortDescription}</p>
                </span>

                <span className="pt-1">
                  {typeof this.props.character!.xp !== 'undefined' && (
                    <React.Fragment>
                      <span
                        className="is-flex is-justify-content-end is-align-items-end
                     character-xp ">
                        <span className="fa-fw mr-1">
                          <i className="fas fa-shield-alt fa-inverse"></i>
                        </span>
                        <span className="info mr-1" style={{ width: '16px' }}>
                          XP
                        </span>
                        <span style={{ width: '15px' }}>{this.props.character!.xp}</span>
                      </span>
                      <span className="is-flex is-justify-content-end is-align-items-end character-level">
                        <span className="fa-fw mr-1">
                          <i className="fas fa-star-half-alt fa-inverse"></i>
                        </span>
                        <span className="info mr-1" style={{ width: '32px' }}>
                          LEVEL
                        </span>
                        <span style={{ width: '15px' }}>{this.props.character!.level}</span>
                      </span>
                    </React.Fragment>
                  )}
                  <span className="is-flex is-justify-content-end is-align-items-end character-boost-remaining">
                    <span className="fa-fw mr-1">
                      <i className="fas fa-bolt fa-inverse"></i>
                    </span>
                    <span className="info mr-1" style={{ width: '43px' }}>
                      BOOSTS
                    </span>
                    <span style={{ width: '15px' }}>{this.props.character!.statBoostsAvailable}</span>
                  </span>
                </span>
              </div>
              <div className="attributes is-flex is-flex-direction-row is-align-items-self-end is-flex-wrap-wrap">
                {this.renderAttributes()}
              </div>
              <div className="stats is-flex is-flex-direction-column is-align-items-end has-text-weight-bold mt-2">
                {this.props.character!.stats.map(s => {
                  return (
                    <div key={s.name} className="is-flex is-justify-content-space-between">
                      <p className="is-size-7 mb-0">{s.name}</p>
                      <p className={`${this.statClass(s.value)} is-size-7 mb-0`}>{s.value > 0 ? s.value : '??'}</p>
                    </div>
                  );
                })}
                {typeof this.props.health !== 'undefined' && (
                  <div className="bar-stat-container">{this.renderBarStat(this.props.health!)}</div>
                )}
                {typeof this.props.morale !== 'undefined' && (
                  <div className="bar-stat-container">{this.renderBarStat(this.props.morale!)}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            this.props.actionsLv3.length > 0
              ? 'lv3-stage-action stage-action'
              : this.props.actionsLv2.length > 0
              ? 'lv2-stage-action stage-action'
              : 'lv1-stage-action stage-action'
          }`}>
          <div className="is-flex is-flex-direction-column" style={{ zIndex: 100 }}>
            <div className="is-flex is-flex-direction-row" style={{ zIndex: 100 }}>
              {this.props.actionsLv1!.map((a: ICharacterAction) => {
                return (
                  <button
                    key={a.name}
                    className={`${a.onHoverMessage ? 'tooltip' : ''} button is-transparent-action activate-button`}
                    onClick={() => a.onClick()}
                    disabled={!a.enabled}>
                    {!a.isApproved && <span>Approve {a.name}</span>}
                    {!!a.isApproved && <span>{a.name}</span>}
                    <span className="tooltip-text tooltip-text-up">{a.onHoverMessage}</span>
                  </button>
                );
              })}
            </div>
            <div className="is-flex is-flex-direction-row">
              {this.props.actionsLv2.length > 0 && (
                <React.Fragment>
                  {this.props.actionsLv2.map((a: ICharacterAction) => {
                    return (
                      <button
                        key={a.name}
                        className={`${a.onHoverMessage ? 'tooltip' : ''} button is-transparent-action activate-button`}
                        onClick={() => a.onClick()}
                        disabled={!a.enabled}>
                        <span>{a.name}</span>
                        <span className={`${this.props.actionsLv3.length > 0} tooltip-text-middle-drop tooltip-text`}>
                          {a.onHoverMessage}
                        </span>
                      </button>
                    );
                  })}
                </React.Fragment>
              )}
            </div>
            <div className="is-flex is-flex-direction-row">
              {this.props.actionsLv3.length > 0 && (
                <React.Fragment>
                  {this.props.actionsLv3.map((a: ICharacterAction) => {
                    return (
                      <button
                        key={a.name}
                        className={`${a.onHoverMessage ? 'tooltip' : ''} button is-transparent-action activate-button`}
                        onClick={() => a.onClick()}
                        disabled={!a.enabled}>
                        <span>{a.name}</span>
                        <span className="tooltip-text">{a.onHoverMessage}</span>
                      </button>
                    );
                  })}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  rarityClass(): string {
    if (!this.props.character!.revealed) {
      return 'rarity unknown';
    } else {
      if (this.props.character!.attributes.filter(a => a.badge_type === 'Special' && a.value === 'Unique').length > 0)
        return 'rarity unique';
      var specialAttributes = this.props.character!.attributes.filter(
        a => ['Special', 'Image', 'Stat'].indexOf(a.badge_type) >= 0
      ).length;
      if (specialAttributes > 3) return 'rarity legendary';
      if (specialAttributes > 2) return 'rarity epic';
      if (specialAttributes > 1) return 'rarity rare';
      if (specialAttributes > 0) return 'rarity uncommon';
    }
    return '';
  }

  statClass(value: number): string {
    if (value > 95) return 'super-stat';
    if (value > 79) return 'good-stat';
    return '';
  }

  renderAttributes() {
    return (
      <React.Fragment>
        {this.props
          .character!.attributes.filter(a => ['Unknown', 'Special', 'Image', 'Stat'].indexOf(a.badge_type) >= 0)
          .map(a => {
            return (
              <span key={a.id} className={`tag is-primary ${a.badge_type}`}>
                {a.value}
              </span>
            );
          })}
      </React.Fragment>
    );
  }

  renderBarStat(stat: ICharacterStat) {
    return (
      <React.Fragment>
        {stat && (
          <React.Fragment>
            <div
              className={`
              ${stat.remaining < 5 ? 'bar-stat-background-low' : ''} 
              ${stat.remaining < stat.max / 2 ? 'bar-stat-background-med' : ''} 
              ${
                stat.remaining === stat.max ? 'bar-stat-background-full' : ''
              } bar-stat-background is-flex is-justify-content-space-between`}
              style={{ width: `${getStatPercent(stat)}%` }}></div>
            <div className="bar-stat is-flex is-justify-content-space-between">
              <p className="is-size-7 mb-0">{stat.name}</p>
              <p
                className="is-size-7 mb-0"
                style={{
                  float: 'right'
                }}>
                {!isStatFull(stat) && (
                  <span>
                    <span style={{ color: 'var(--grey5)' }}>{stat.remaining}</span> /{' '}
                  </span>
                )}
                {stat.max}
              </p>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
