import React, { Component } from 'react';
import { CharacterStat, ICharacterDetails } from '../../../models/CharacterDetails';
import { MercenaryService } from '../../../services/MercenaryService';
import produce from 'immer';
import './AllocateStats.scss';

export interface IAllocateStatsProps {
  mercenaryService: MercenaryService;
  character: ICharacterDetails;
  close: any;
}

export interface IAllocateStatsState {
  statBoosts: StatBoost[];
  current: StatBoost;
  totalBoosts: number;
}

interface StatBoost {
  boostingStat: CharacterStat;
  unBoostedAmount: number;
  boostAmount: number;
}

export class AllocateStats extends Component<IAllocateStatsProps, IAllocateStatsState> {
  constructor(props: any) {
    super(props);

    var statBoosts = [
      {
        boostingStat: CharacterStat.Stamina,
        unBoostedAmount: this.props.character.stats.find(s => s.name === CharacterStat[CharacterStat.Stamina])!.value,
        boostAmount: 0
      },
      {
        boostingStat: CharacterStat.Strength,
        unBoostedAmount: this.props.character.stats.find(s => s.name === CharacterStat[CharacterStat.Strength])!.value,
        boostAmount: 0
      },
      {
        boostingStat: CharacterStat.Speed,
        unBoostedAmount: this.props.character.stats.find(s => s.name === CharacterStat[CharacterStat.Speed])!.value,
        boostAmount: 0
      },
      {
        boostingStat: CharacterStat.Courage,
        unBoostedAmount: this.props.character.stats.find(s => s.name === CharacterStat[CharacterStat.Courage])!.value,
        boostAmount: 0
      },
      {
        boostingStat: CharacterStat.Intelligence,
        unBoostedAmount: this.props.character.stats.find(s => s.name === CharacterStat[CharacterStat.Intelligence])!
          .value,
        boostAmount: 0
      }
    ];
    this.state = {
      current: statBoosts[0],
      totalBoosts: 0,
      statBoosts: statBoosts
    };

    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleBoostingChange = this.handleBoostingChange.bind(this);
    this.boost = this.boost.bind(this);
    this.close = this.close.bind(this);
  }

  handleAmountChange(amount: number) {
    this.setState(
      produce((draft: IAllocateStatsState) => {
        draft.totalBoosts += amount;
        draft.statBoosts.find(s => s.boostingStat === this.state.current.boostingStat)!.boostAmount += amount;
        draft.current = draft.statBoosts.find(s => s.boostingStat === this.state.current.boostingStat)!;
      })
    );
  }

  handleBoostingChange(stat: CharacterStat) {
    this.setState({ current: this.state.statBoosts.find(s => s.boostingStat === stat)! });
  }

  getBoostsRemaining(): number {
    return this.props.character.statBoostsAvailable - this.state.totalBoosts;
  }

  async boost(): Promise<void> {
    await this.props.mercenaryService.boostStat(
      this.props.character,
      this.state.statBoosts.filter(s => s.boostAmount > 0).map(s => s.boostAmount),
      this.state.statBoosts.filter(s => s.boostAmount > 0).map(s => s.boostingStat)
    );

    this.props.close();
  }

  close() {
    this.props.close();
  }

  render() {
    return (
      <div className="is-active modal">
        <div className="modal-background" onClick={this.close}></div>
        <div id="allocate-xp" className="modal-content">
          <div
            className="card"
            style={{
              backgroundImage: `linear-gradient(rgba(10, 10, 10, 0.6), rgba(10, 10, 10, 1)),url("${
                this.props.character!.image
              }")`
            }}>
            <button className="modal-close is-medium" aria-label="close" onClick={this.close}></button>
            <div className="is-flex is-justify-content-center allocate-stats-title pb-3">
              <span>Boost Stats</span>
            </div>
            <div className="is-flex is-justify-content-center allocate-stats-subtitle">{this.props.character.name}</div>
            <div className="is-flex is-justify-content-center mb-3 allocate-stats-remaining">
              <span className={`mr-1 ${this.getBoostsRemaining() === 0 ? 'boosts-empty' : ''}`}>
                {this.getBoostsRemaining()}
              </span>
              boosts remaining
            </div>
            <ul>
              {this.renderStatListItem(CharacterStat.Stamina)}
              {this.renderStatListItem(CharacterStat.Strength)}
              {this.renderStatListItem(CharacterStat.Speed)}
              {this.renderStatListItem(CharacterStat.Courage)}
              {this.renderStatListItem(CharacterStat.Intelligence)}
            </ul>

            <div className="is-flex is-justify-content-center mt-4">
              <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center allocate-stats-stats">
                <ul className="action-control">
                  <li className="action-form">
                    <div className="field has-addons">
                      <div className="control">
                        <button
                          className="button minus-button is-action"
                          disabled={this.state.current.boostAmount <= 0}
                          onClick={() => this.handleAmountChange(-1)}>
                          <span className="icon">
                            <i className="fas fa-minus"></i>
                          </span>
                        </button>
                      </div>
                      <div className="control">
                        <input
                          className="input is-action"
                          type="text"
                          disabled={true}
                          value={this.state.current.boostAmount}
                        />
                      </div>
                      <div className="control">
                        <button
                          className="button plus-button is-action"
                          disabled={
                            this.getBoostsRemaining() <= 0 ||
                            this.state.current.unBoostedAmount + this.state.current.boostAmount >= 99
                          }
                          onClick={() => this.handleAmountChange(+1)}>
                          <span className="icon">
                            <i className="fas fa-plus"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  </li>
                  <li>
                    <button
                      className="button boost-button is-action is-fullwidth"
                      onClick={() => this.boost()}
                      disabled={this.state.totalBoosts <= 0}>
                      Allocate Stats Boosts
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderStatListItem(stat: CharacterStat) {
    var s = this.state.statBoosts.find(s => s.boostingStat === stat)!;
    return (
      <CharacterStatListItem
        boostingStat={this.state.current.boostingStat}
        boostAmount={s.boostAmount}
        currentStat={stat}
        unBoostedAmount={s.unBoostedAmount}
        handleBoostingChange={this.handleBoostingChange}></CharacterStatListItem>
    );
  }
}

interface ICharacterStatListItemProps {
  boostingStat: CharacterStat;
  boostAmount: number;
  currentStat: CharacterStat;
  unBoostedAmount: number;
  handleBoostingChange: any;
}

class CharacterStatListItem extends Component<ICharacterStatListItemProps> {
  render() {
    return (
      <li className="is-flex is-justify-content-space-between character-stat-list-item px-3">
        <button
          className={`button ${
            this.props.boostingStat === this.props.currentStat ? 'is-ghost-action' : 'is-inactive-action'
          }`}
          style={{ width: '100%' }}
          onClick={() => this.props.handleBoostingChange(this.props.currentStat)}>
          <span className="is-flex is-justify-content-space-between" style={{ width: '100%' }}>
            <span>{CharacterStat[this.props.currentStat]}</span>
            <span>
              {this.props.boostAmount > 0 && (
                <span className="boosted-from">
                  {this.props.unBoostedAmount} <i className="fas fa-arrow-right pr-1"></i>
                </span>
              )}
              <span className={this.props.boostAmount > 0 ? 'boosted' : ''}>
                {this.props.unBoostedAmount + this.props.boostAmount}
              </span>
            </span>
          </span>
        </button>
      </li>
    );
  }
}
