import React, { Component } from 'react';
// @ts-ignore
import { Link } from 'react-router-dom';
import { Subscription } from 'rxjs';
import { AssetType, IAssetConfig } from '../../models/AssetConfig';
import { EthereumService } from '../../services/EthereumService';
import { MessagingService } from '../../services/MessagingService';
import { Utils } from '../../services/Utils';
import { ClaimableFungibleAssetService } from '../../services/ClaimableFungibleAssetService';
import { FungibleAssetService } from '../../services/FungibleAssetService';
import { ILiquidityAssetService } from '../../services/Interfaces';
import { StakingAssetService } from '../../services/StakingAssetService';
import { StakingAssetServiceV2 } from '../../services/StakingAssetServiceV2';
import { LiquidityAssetType } from '../../models/Enums';
import { PoolType } from '../../models/LiquidityConfig';
import CollapsibleCard from '../Common/CollapsibleCard';
import '../Content.scss';
import '../Tables.scss';
import './Bank.scss';
import { Mutex } from 'async-mutex';

enum AssetsToDisplay {
  All,
  Wallet
}

interface IBankProps {
  messagingService: MessagingService;
  ethereumService: EthereumService;
}

interface IBankState {
  isConnected: boolean;
  isLoaded: boolean;
  investAsset?: string;
  assets: FungibleAssetService[];
  claimableAssets: ClaimableFungibleAssetService[];
  liquidityAssets: ILiquidityAssetService[];
  showDetail: boolean;
  assetsDisplayed: AssetsToDisplay;
  refreshedAtBlock: number;
}

export class Bank extends Component<IBankProps, IBankState> {
  subscription: Subscription;
  walletAddress?: string;
  chainId?: number;
  displayAssetTypes = [AssetType.Land, AssetType.Crop];
  equipTargetAssetsTypes = [AssetType.Crop];
  equipTarget?: IAssetConfig;
  mutex = new Mutex();

  constructor(props: IBankProps) {
    super(props);

    var toDisplay = localStorage.getItem('bankAssetsToDisplay');

    this.state = {
      isConnected: true,
      isLoaded: false,
      investAsset: '',
      assets: [],
      claimableAssets: [],
      liquidityAssets: [],
      showDetail: false,
      assetsDisplayed: toDisplay === 'Wallet' ? AssetsToDisplay.Wallet : AssetsToDisplay.All,
      refreshedAtBlock: 0
    };

    this.subscription = new Subscription();

    this.toggleAssetsDisplayed = this.toggleAssetsDisplayed.bind(this);
  }

  componentDidMount() {
    var eventSubscription = this.props.messagingService.events.subscribe(event => {
      this.refresh();
    });
    this.subscription.add(eventSubscription);
    this.refresh();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  toggleAssetsDisplayed() {
    this.setState({
      assetsDisplayed:
        this.state.assetsDisplayed === AssetsToDisplay.Wallet ? AssetsToDisplay.All : AssetsToDisplay.Wallet
    });

    localStorage.setItem(
      'bankAssetsToDisplay',
      AssetsToDisplay[
        this.state.assetsDisplayed === AssetsToDisplay.Wallet ? AssetsToDisplay.All : AssetsToDisplay.Wallet
      ]
    );
  }

  async refresh(): Promise<any> {
    await this.mutex.runExclusive(async () => {
      await this.refreshImpl();
    });
  }

  private async refreshImpl(): Promise<any> {
    if (
      this.props.ethereumService.walletData.walletAddress &&
      this.props.ethereumService.networkManager?.network?.chainId &&
      this.props.ethereumService.walletData.blockNumber > this.state.refreshedAtBlock
    ) {
      if (
        this.props.ethereumService.walletData.walletAddress !== this.walletAddress ||
        this.props.ethereumService.networkManager!.network?.chainId !== this.chainId
      ) {
        this.walletAddress = this.props.ethereumService.walletData.walletAddress;
        this.chainId = this.props.ethereumService.networkManager!.network!.chainId;
        await this.init();
      } else {
        await this.update();
      }
    }
  }

  async init() {
    if (
      this.props.ethereumService.networkManager?.network &&
      this.props.ethereumService.networkManager?.network?.assets.size > 0
    ) {
      await this.loadAssets();
    }
  }

  async loadAssets(): Promise<any> {
    await this.loadStandardAssets();
    await this.loadLiquidityAssets();
    await this.loadClaimableAssets();
    this.setState({
      isLoaded: true,
      isConnected: this.props.ethereumService.walletData.isConnected,
      refreshedAtBlock: this.props.ethereumService.walletData.blockNumber
    });
  }

  private async loadStandardAssets() {
    let displayAssets = [...this.props.ethereumService.networkManager!.network!.assets.values()].filter(config =>
      this.displayAssetTypes.includes(config.assetConstants!.assetType!)
    );

    var assetUpdatePromises = displayAssets.map(ac => {
      var asset = new FungibleAssetService(ac, this.props.ethereumService);
      return asset.update();
    });

    this.setState({
      assets: await Promise.all(assetUpdatePromises),
      isLoaded: true,
      isConnected: this.props.ethereumService.walletData.isConnected
    });
  }

  private async loadLiquidityAssets() {
    let liquidityAssets = [...this.props.ethereumService.networkManager!.network!.liquidityConfigs.values()];

    var liquidityAssetUpdatePromises = liquidityAssets
      .filter(lc => !lc.expired)
      .map(lc => {
        var asset: ILiquidityAssetService;
        switch (lc.liquidityType) {
          case LiquidityAssetType.Staking:
            asset = new StakingAssetService(lc, this.props.ethereumService, this.props.messagingService);
            break;
          case LiquidityAssetType.StakingV2:
            asset = new StakingAssetServiceV2(lc, this.props.ethereumService, this.props.messagingService);
            break;
          default:
            throw new Error('Unknown LiquidityAssetType');
        }

        return asset.update();
      });

    this.setState({
      liquidityAssets: await Promise.all(liquidityAssetUpdatePromises),
      isLoaded: true,
      isConnected: this.props.ethereumService.walletData.isConnected
    });
  }

  private async loadClaimableAssets() {
    let claimableAssets = [...this.props.ethereumService.networkManager!.network!.claims.values()].filter(config =>
      this.displayAssetTypes.includes(config.assetConstants!.assetType!)
    );

    var claimableAssetUpdatePromises = claimableAssets.map(cc => {
      var asset = new ClaimableFungibleAssetService(cc, this.props.messagingService, this.props.ethereumService);
      return asset.update();
    });

    this.setState({
      claimableAssets: (await Promise.all(claimableAssetUpdatePromises)).filter(c => c.balance > 0),
      isLoaded: true,
      isConnected: this.props.ethereumService.walletData.isConnected
    });
  }

  update() {
    this.state.assets.forEach(asset => {
      asset.update();
    });
    this.setState({ assets: this.state.assets });

    this.state.liquidityAssets.forEach(asset => {
      asset.update();
    });
    this.setState({ liquidityAssets: this.state.liquidityAssets });

    this.state.claimableAssets.forEach(asset => {
      asset.update();
    });
    this.setState({ claimableAssets: this.state.claimableAssets });
  }

  render() {
    return (
      <section id="content" className="bank is-vcentered">
        <div className="container">
          {!this.state.isConnected && (
            <span
              className="column meme is-half has-text-centered mt-4"
              style={{ cursor: 'pointer' }}
              onClick={() => this.props.ethereumService.connectWallet()}>
              <h3 className="sub-title py-3">Connect Wallet</h3>
            </span>
          )}
          {this.state.isConnected && !this.state.isLoaded && (
            <span className="column meme is-half has-text-centered mt-4">
              <h3 className="sub-title py-3">Loading...</h3>
            </span>
          )}
          {this.state.isConnected && this.state.isLoaded && (
            <React.Fragment>
              <div className="tile is-ancestor mx-3">
                <div className="tile is-vertical is-12">
                  <div className="field pl-1">
                    <div>
                      <input
                        id="assetsDisplayed"
                        type="checkbox"
                        name="assetsDisplayed"
                        className={`${
                          this.state.assetsDisplayed === AssetsToDisplay.Wallet ? 'is-active' : ''
                        }  switch is-rounded is-small`}
                        onClick={() => this.toggleAssetsDisplayed()}
                        defaultChecked={this.state.assetsDisplayed === AssetsToDisplay.Wallet}
                      />
                      <label htmlFor="assetsDisplayed">
                        <span className="has-light-text noselect" style={{ marginTop: '-1px' }}>
                          Hide zero balances
                        </span>
                      </label>
                    </div>
                  </div>

                  {this.state.assetsDisplayed === AssetsToDisplay.All && (
                    <React.Fragment>
                      {this.state.assets.length > 0 && (
                        <CollapsibleCard title="Tokens" id="bank-token-card" key={'Tokens' + this.state.assets.length}>
                          {this.renderTokens()}
                        </CollapsibleCard>
                      )}
                      {this.state.liquidityAssets.length > 0 && (
                        <CollapsibleCard
                          title="Liquidity"
                          id="bank-liquidity-card"
                          key={'Liquidity' + this.state.liquidityAssets.length}>
                          {this.renderLiquidity()}
                        </CollapsibleCard>
                      )}
                      {this.state.claimableAssets.length > 0 && (
                        <CollapsibleCard
                          title="Claims"
                          id="bank-claims-card"
                          key={'Claims' + this.state.claimableAssets.length}>
                          {this.renderClaims()}
                        </CollapsibleCard>
                      )}
                    </React.Fragment>
                  )}

                  {this.state.assetsDisplayed === AssetsToDisplay.Wallet &&
                    this.state.assets.filter(a => +a.balance > 0).length === 0 &&
                    this.state.liquidityAssets.filter(a => +a.lptBalance > 0).length === 0 &&
                    this.state.claimableAssets.filter(a => +a.balance > 0).length === 0 && (
                      <span className="column meme is-half has-text-centered">
                        <h3 className="sub-title py-3">The vaults are empty</h3>
                      </span>
                    )}

                  {this.state.assetsDisplayed === AssetsToDisplay.Wallet && (
                    <React.Fragment>
                      {this.state.assets.filter(a => +a.balance > 0).length > 0 && (
                        <CollapsibleCard title="Tokens" id="bank-token-card" key={'Tokens' + this.state.assets.length}>
                          {this.renderTokens()}
                        </CollapsibleCard>
                      )}
                      {this.state.liquidityAssets.filter(a => +a.lptBalance > 0).length > 0 && (
                        <CollapsibleCard
                          title="Liquidity"
                          id="bank-liquidity-card"
                          key={'Liquidity' + this.state.liquidityAssets.length}>
                          {this.renderLiquidity()}
                        </CollapsibleCard>
                      )}
                      {this.state.claimableAssets.filter(a => +a.balance > 0).length > 0 && (
                        <CollapsibleCard
                          title="Claims"
                          id="bank-claims-card"
                          key={'Claims' + this.state.claimableAssets.length}>
                          {this.renderClaims()}
                        </CollapsibleCard>
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </section>
    );
  }

  renderTokens() {
    return (
      <div>
        <div className="table-control">
          <table className="table table-grouped small-text is-fullwidth">
            <thead>
              <tr>
                <th className="image-header"></th>
                <th></th>
                <th>Available</th>
                <th>Value</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.state.assets
                .filter(a => this.state.assetsDisplayed === AssetsToDisplay.All || +a.balance > 0)
                .map(a => (
                  <FungibleAssetRow
                    key={a.config.contractAddress}
                    ethereumService={this.props.ethereumService}
                    asset={a}></FungibleAssetRow>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderLiquidity() {
    return (
      <div>
        <div className="table-control">
          <table className="table table-grouped small-text is-fullwidth">
            <thead>
              <tr>
                <th className="image-header"></th>
                <th></th>
                <th>Available</th>
                <th>Value</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.state.liquidityAssets
                .filter(a => this.state.assetsDisplayed === AssetsToDisplay.All || +a.lptBalance > 0)
                .map(a => (
                  <LiquidityAssetRow
                    key={a.config.liquidityContractAddress + a.config.poolId}
                    ethereumService={this.props.ethereumService}
                    asset={a}></LiquidityAssetRow>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderClaims() {
    return (
      <div>
        <div className="table-control">
          <table className="table table-grouped small-text is-fullwidth">
            <thead>
              <tr>
                <th className="image-header"></th>
                <th></th>
                <th>Available</th>
                <th>Value</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.state.claimableAssets
                .filter(a => this.state.assetsDisplayed === AssetsToDisplay.All || +a.balance > 0)
                .map(a => (
                  <ClaimableFungibleAssetRow
                    key={a.config.contractAddress}
                    ethereumService={this.props.ethereumService}
                    asset={a}></ClaimableFungibleAssetRow>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

interface IFungibleAssetRowProps {
  asset?: FungibleAssetService;
  ethereumService?: EthereumService;
}

class FungibleAssetRow extends Component<IFungibleAssetRowProps> {
  render() {
    return (
      <tr>
        <td>
          <div className="double_cell">
            <div className="tLogo">
              <div className="tLogo_group">
                <img
                  alt={this.props.asset!.config.assetConstants!.symbol}
                  className="token"
                  src={this.props.asset!.config.assetConstants!.icon}
                />
              </div>
            </div>
          </div>
        </td>
        <td>
          <div className="cell">
            <div className="cell_PrimaryTitleText">
              {this.props.asset!.config.assetConstants!.symbol}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  this.props.ethereumService!.networkManager!.network?.blockExplorerUrls[0] +
                  '/address/' +
                  this.props.asset!.config.contractAddress
                }>
                <span className="icon-text">
                  <span className="icon">
                    <i className="fas fa-external-link-alt has-link-text" style={{ fontSize: 'small' }}></i>
                  </span>
                </span>
              </a>
            </div>
            <div className="cell_SecondaryText">
              <button
                className="button is-ghost p-0"
                onClick={() =>
                  this.props.ethereumService!.networkManager!.addToken(this.props.asset!.config.assetConstants!.symbol)
                }>
                Add Token
              </button>
            </div>
          </div>
        </td>
        <td>
          <div className="cell">
            <div className="cell_TitleText">Available</div>
            <div className="cell_PrimaryText">
              {Utils.toFixed(this.props.asset!.balance, this.props.asset!.config.assetConstants!.displayDecimals)}
            </div>
          </div>
        </td>
        <td>
          <div className="cell">
            <div className="cell_TitleText">Value</div>
            <div className="cell_PrimaryText">
              {this.props.ethereumService!.pricesService!.renderPrice(
                this.props.asset!.tokenId,
                this.props.asset!.balance
              )}
            </div>
          </div>
        </td>
        <td>
          <div className="cell_Action">
            <a
              className="button is-action"
              target="_blank"
              rel="noopener noreferrer"
              href={
                this.props.ethereumService!.networkManager!.network!.dexBaseUrl! +
                this.props.asset!.config.contractAddress
              }>
              Buy
            </a>
          </div>
        </td>
      </tr>
    );
  }
}

interface ILiquidityAssetRowProps {
  asset?: ILiquidityAssetService;
  ethereumService?: EthereumService;
}

class LiquidityAssetRow extends Component<ILiquidityAssetRowProps> {
  render() {
    return (
      <tr>
        <td className="image-column">
          <div className="double_cell">
            <div className="tLogo">
              <div className="tLogo_group">
                <img
                  alt={this.props.asset!.config.token1}
                  className="token"
                  src={this.props.asset!.config.token1Icon}
                />
                {this.props.asset!.config.poolType === PoolType.Pair && (
                  <img
                    alt={this.props.asset!.config.token2!}
                    className="token"
                    src={this.props.asset!.config.token2Icon!}
                  />
                )}
              </div>
              {this.props.asset!.config.poolType === PoolType.Pair && (
                <img alt={this.props.asset!.config.pool} className="tExtra" src={this.props.asset!.config.poolIcon} />
              )}
            </div>
          </div>
        </td>
        <td>
          <div className="cell">
            <div className="cell_PrimaryTitleText">
              {this.props.asset!.config.token1}
              {this.props.asset!.config.poolType === PoolType.Pair && (
                <React.Fragment> / {this.props.asset!.config.token2}</React.Fragment>
              )}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  this.props.ethereumService?.networkManager?.network?.blockExplorerUrls[0] +
                  '/address/' +
                  this.props.asset!.config.poolContractAddress
                }>
                <span className="icon-text">
                  <span className="icon">
                    <i className="fas fa-external-link-alt has-link-text" style={{ fontSize: 'small' }}></i>
                  </span>
                </span>
              </a>
            </div>
            {this.props.asset!.config.poolType === PoolType.Pair && (
              <div className="cell_SecondaryText">{this.props.asset!.config.pool}</div>
            )}
          </div>
        </td>
        <td>
          <div className="cell">
            <div className="cell_TitleText">Available</div>
            <div className="cell_PrimaryText">{Utils.toFixed(this.props.asset!.lptBalance, 8)}</div>
          </div>
        </td>
        <td>
          <div className="cell">
            <div className="cell_TitleText">Value</div>
            <div className="cell_PrimaryText"></div>
          </div>
        </td>
        <td>
          <div className="cell_Action">
            {this.props.asset!.config.poolType === PoolType.Pair && (
              <React.Fragment>
                {+this.props.asset!.lptBalance > 0 && (
                  <Link className="button is-action" to="/pools">
                    Stake
                  </Link>
                )}
                {+this.props.asset!.lptBalance <= 0 && (
                  <a
                    className="button is-action"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.props.asset!.config.poolUrl}>
                    Add
                  </a>
                )}
              </React.Fragment>
            )}
            {this.props.asset!.config.poolType === PoolType.Single && (
              <Link className="button is-action" to="/pools">
                Stake
              </Link>
            )}
          </div>
        </td>
      </tr>
    );
  }
}

interface IClaimableFungibleAssetRowProps {
  asset?: ClaimableFungibleAssetService;
  ethereumService?: EthereumService;
}

class ClaimableFungibleAssetRow extends Component<IClaimableFungibleAssetRowProps> {
  claim = () => {
    this.props.asset?.sendClaim();
  };

  render() {
    return (
      <tr>
        <td>
          <div className="double_cell">
            <div className="tLogo">
              <div className="tLogo_group">
                <img
                  alt={this.props.asset!.config.assetConstants!.symbol}
                  className="token"
                  src={this.props.asset!.config.assetConstants!.icon}
                />
              </div>
            </div>
          </div>
        </td>
        <td>
          <div className="cell">
            <div className="cell_PrimaryTitleText">{this.props.asset!.config.assetConstants!.symbol}</div>
          </div>
        </td>
        <td>
          <div className="cell">
            <div className="cell_TitleText">Available</div>
            <div className="cell_PrimaryText">
              {Utils.toFixed(this.props.asset!.balance, this.props.asset!.config.assetConstants!.displayDecimals)}
            </div>
          </div>
        </td>
        <td>
          <div className="cell">
            <div className="cell_TitleText">Value</div>
            <div className="cell_PrimaryText">
              {this.props.ethereumService!.pricesService!.renderPrice(
                this.props.asset!.tokenId,
                this.props.asset!.balance
              )}
            </div>
          </div>
        </td>
        <td>
          <div className="cell_Action">
            <button className="button is-action" onClick={this.claim}>
              Claim
            </button>
          </div>
        </td>
      </tr>
    );
  }
}
