import React, { Component } from 'react';
import produce from 'immer';
// @ts-ignore
import { NavLink } from 'react-router-dom';
import { Subscription } from 'rxjs';
import { EthereumService } from '../../services/EthereumService';
import { MessagingService } from '../../services/MessagingService';
import { IQuestService, QuestServiceFactory } from '../../services/QuestService';
import { ItemService } from '../../services/ItemService';
import { Utils } from '../../services/Utils';
import { Mutex } from 'async-mutex';
import { BeginQuest } from './Modals/BeginQuest';
import { RestoreStat } from './Modals/RestoreStat';
import { CustomizeMercenary, CustomizeMercenaryType } from './Modals/CustomizeMercenary';
import { MercenaryService } from '../../services/MercenaryService';
import { AllocateStats } from './Modals/AllocateStats';

import '../Content.scss';
import '../Tables.scss';
import './Quests.scss';

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/scss';
import 'swiper/css/bundle';
import 'swiper/scss/navigation';
import { InView } from 'react-intersection-observer';
import ExplorerWrapper from './ExplorerWrapper';
import { ExplorerState } from './ExplorerState';

interface IQuestsProps {
  messagingService: MessagingService;
  ethereumService: EthereumService;
}

enum ActiveActivity {
  None,
  MintMercenary,
  ClaimMercenary,
  BeginQuest,
  RestoreHealth,
  RestoreMorale,
  AllocateStatBoosts,
  UpdateAppearance
}

interface IQuestsState {
  isConnected: boolean;
  questsSupported: boolean;
  mercernariesSupported: boolean;
  isLoaded: boolean;
  explorers: ExplorerState[];
  hasMercenaryClaim: boolean;
  activeActivity: ActiveActivity;
  activeCharacter: IQuestCharacter | null;
  refreshedAtBlock: number;
}

export interface IQuestCharacter {
  questService: IQuestService;
  mercenaryService: MercenaryService;
  itemService: ItemService;
  explorerState: ExplorerState;
}

export class Quests extends Component<IQuestsProps, IQuestsState> {
  subscription: Subscription;
  itemService: ItemService | undefined;
  questService: IQuestService | undefined;
  questPaymentSymbol?: string;
  mutex = new Mutex();
  mercenaryService?: MercenaryService;
  navigationPrevRef: any;
  navigationNextRef: any;

  constructor(props: IQuestsProps) {
    super(props);

    this.state = {
      isConnected: true,
      questsSupported: false,
      mercernariesSupported: false,
      isLoaded: false,
      explorers: [],
      hasMercenaryClaim: false,
      activeActivity: ActiveActivity.None,
      activeCharacter: null,
      refreshedAtBlock: 0
    };

    this.navigationPrevRef = React.createRef();
    this.navigationNextRef = React.createRef();

    SwiperCore.use([Navigation]);
    this.subscription = new Subscription();

    this.aproveQuestPayment = this.aproveQuestPayment.bind(this);
    this.resetActivity = this.resetActivity.bind(this);
    this.mintMercenary = this.mintMercenary.bind(this);
    this.claimMercenary = this.claimMercenary.bind(this);
    this.beginQuest = this.beginQuest.bind(this);
    this.restoreHealth = this.restoreHealth.bind(this);
    this.restoreMorale = this.restoreMorale.bind(this);
    this.allocateStatBoosts = this.allocateStatBoosts.bind(this);
    this.updateAppearance = this.updateAppearance.bind(this);
    this.setInView = this.setInView.bind(this);
  }

  componentDidMount() {
    var eventSubscription = this.props.messagingService.events.subscribe(event => {
      this.refresh();
    });
    this.subscription.add(eventSubscription);
    this.refresh();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  getPaymentName(): string {
    if (this.questPaymentSymbol) return this.questPaymentSymbol!;

    if (!this.questService) return '';

    this.questPaymentSymbol = this.questService.getPaymentSymbol();

    return this.questPaymentSymbol!;
  }

  aproveQuestPayment() {
    if (!this.questService) return '';

    this.questService!.approvePayment();
  }

  resetActivity() {
    this.setState({
      activeActivity: ActiveActivity.None,
      activeCharacter: null
    });
  }

  mintMercenary() {
    this.setState({
      activeActivity: ActiveActivity.MintMercenary,
      activeCharacter: null
    });
  }

  claimMercenary() {
    this.setState({
      activeActivity: ActiveActivity.ClaimMercenary,
      activeCharacter: null
    });
  }

  beginQuest(questCharacter: IQuestCharacter) {
    this.setState({
      activeActivity: ActiveActivity.BeginQuest,
      activeCharacter: questCharacter
    });
  }

  restoreHealth(questCharacter: IQuestCharacter) {
    this.setState({
      activeActivity: ActiveActivity.RestoreHealth,
      activeCharacter: questCharacter
    });
  }

  restoreMorale(questCharacter: IQuestCharacter) {
    this.setState({
      activeActivity: ActiveActivity.RestoreMorale,
      activeCharacter: questCharacter
    });
  }

  allocateStatBoosts(questCharacter: IQuestCharacter) {
    this.setState({
      activeActivity: ActiveActivity.AllocateStatBoosts,
      activeCharacter: questCharacter
    });
  }

  updateAppearance(questCharacter: IQuestCharacter) {
    this.setState({
      activeActivity: ActiveActivity.UpdateAppearance,
      activeCharacter: questCharacter
    });
  }

  async setInView(explorer: ExplorerState, inView: boolean) {
    this.mercenaryService?.setInView(explorer.characterWrapper, inView);
    if (inView && !explorer.isCharacterUpdated) {
      await explorer.updateState();
      this.setState(
        produce((draft: IQuestsState) => {
          const index = draft.explorers.findIndex(e => e.characterWrapper.id === explorer.characterWrapper.id);
          if (index !== -1) draft.explorers[index] = explorer;
        })
      );
    }
  }

  async refresh(): Promise<any> {
    await this.mutex.runExclusive(async () => {
      await this.refreshImpl();
    });
  }

  private async refreshImpl(): Promise<any> {
    if (
      this.props.ethereumService.walletData.walletAddress &&
      this.props.ethereumService.networkManager?.network?.chainId &&
      this.props.ethereumService.walletData.blockNumber > this.state.refreshedAtBlock
    ) {
      if (
        !this.mercenaryService &&
        this.props.ethereumService.networkManager.network.characterConfig &&
        this.props.ethereumService.networkManager.network.characterConfig!.mercenaryConfig.contractAddress !== ''
      ) {
        this.mercenaryService = new MercenaryService(
          this.props.ethereumService.networkManager.network.characterConfig!,
          this.props.messagingService,
          this.props.ethereumService
        );
      }

      var networkState = {
        isConnected: this.props.ethereumService.walletData.isConnected,
        questsSupported: this.props.ethereumService.networkManager.network.questConfigs?.length > 0,
        mercernariesSupported: this.mercenaryService !== undefined,
        refreshedAtBlock: this.props.ethereumService.walletData.blockNumber
      };

      if (this.mercenaryService) {
        await this.loadItemService();
        await this.loadQuestService();
        var explorerState = await this.loadExplorers();
        this.setState({ ...networkState, ...explorerState, isLoaded: true });
      } else {
        this.setState({ ...networkState, isLoaded: true });
      }
    }
  }

  private async loadItemService(): Promise<any> {
    if (!this.props.ethereumService.networkManager!.network!.itemConfig) {
      return;
    }

    if (!this.itemService) {
      this.itemService = new ItemService(this.props.messagingService, this.props.ethereumService);
    }

    await this.itemService.update();
  }

  private async loadQuestService(): Promise<any> {
    if (!this.questService) {
      var questServices = QuestServiceFactory.createQuests(this.props.messagingService, this.props.ethereumService);
      if (questServices.length > 0) {
        this.questService = questServices[0];
      }
    }

    if (this.questService) {
      await this.questService!.update();
    }
  }

  private async loadExplorers(): Promise<any> {
    if (!this.questService) return;

    await this.mercenaryService!.update();

    var ownedCharacters = this.mercenaryService!.ownedCharacters.map(c => {
      return (
        this.state.explorers.find(e => e.characterWrapper.id === c.id) ??
        new ExplorerState(c, this.questService!, this.mercenaryService!, this.itemService!, this.props.ethereumService)
      );
    });

    var updatePromises = ownedCharacters
      .filter(e => e.characterWrapper.inView && this.props.ethereumService.walletData.blockNumber > e.refreshedAtBlock)
      .map(async e => await e.updateState());
    await Promise.all(updatePromises);

    return {
      explorers: ownedCharacters,
      hasMercenaryClaim: this.mercenaryService!.hasClaim()
    };
  }

  render() {
    return (
      <section id="content" className="quests is-vcentered">
        <div>
          {!this.state.isConnected && (
            <span
              className="column meme is-half has-text-centered mt-4"
              style={{ cursor: 'pointer' }}
              onClick={() => this.props.ethereumService.connectWallet()}>
              <h3 className="sub-title py-3">Connect Wallet</h3>
            </span>
          )}
          {this.state.isConnected && (
            <React.Fragment>
              {this.state.isLoaded && !this.state.questsSupported && !this.state.mercernariesSupported && (
                <span className="column meme is-half has-text-centered my-4 pb-5">
                  <h3 className="sub-title py-3 mb-3"> Coming Soon!</h3>
                </span>
              )}
              {!this.state.isLoaded && (
                <span className="column meme is-half has-text-centered mt-4">
                  <h3 className="sub-title py-3">Loading...</h3>
                </span>
              )}
              {(this.state.questsSupported || this.state.mercernariesSupported) && (
                <React.Fragment>
                  {this.state.isLoaded && (
                    <React.Fragment>
                      {this.state.explorers.length > 0 && this.renderQuestCharacters()}
                      {this.state.explorers.length === 0 && (
                        <React.Fragment>
                          {this.state.hasMercenaryClaim && (
                            <section className="my-6">
                              <div className="container is-flex is-flex-direction-column">
                                <div className="columns is-centered">
                                  <span className="column meme is-bordered is-half has-text-centered">
                                    <h3 className="sub-title is-upper pt-3">Claim Mercenary</h3>
                                    <div className="is-flex mt-5 mb-4">
                                      <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center character-mint buy-buttons">
                                        <ul className="action-control">
                                          <li>
                                            <button
                                              className="button boost-button is-action is-fullwidth"
                                              onClick={this.claimMercenary}>
                                              Claim
                                            </button>
                                          </li>
                                        </ul>

                                        <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center mt-5">
                                          <p>
                                            <span className="mr-1">Cost Free (+ gas)</span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </section>
                          )}
                          <section className="my-6">
                            <div className="container is-flex is-flex-direction-column">
                              <div className="columns is-centered">
                                <span className="column meme is-bordered is-half has-text-centered">
                                  <h3 className="sub-title is-upper pt-3">Recruit a Mercenary</h3>
                                  <div className="is-flex my-5">
                                    <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center character-mint buy-buttons">
                                      <ul className="action-control">
                                        <li>
                                          <button
                                            className="button boost-button is-action is-fullwidth"
                                            onClick={this.mintMercenary}>
                                            Recruit
                                          </button>
                                        </li>
                                      </ul>

                                      <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center mt-5">
                                        <p>
                                          <span className="mr-1">
                                            Cost {Utils.formatUnits(this.mercenaryService?.mintPrice)}
                                          </span>
                                          ETH (+ gas)
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </section>
                        </React.Fragment>
                      )}
                      {this.state.questsSupported && this.state.explorers.length === 0 && (
                        <span className="column meme is-half has-text-centered mt-4 mb-5">
                          <h3 className="is-body py-3">Citizen can be trained as Mercenaries</h3>
                          <NavLink
                            className="button is-link is-action my-3"
                            to="/citizens"
                            activeStyle={{ color: 'var(--orange2)' }}>
                            Train a Citizen
                          </NavLink>
                        </span>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>

        {this.mercenaryService && this.state.activeActivity === ActiveActivity.MintMercenary && (
          <CustomizeMercenary
            ethereumService={this.props.ethereumService}
            messagingService={this.props.messagingService}
            mercenaryService={this.mercenaryService!}
            customizeType={CustomizeMercenaryType.Mint}
            close={() => this.resetActivity()}></CustomizeMercenary>
        )}

        {this.mercenaryService && this.state.activeActivity === ActiveActivity.ClaimMercenary && (
          <CustomizeMercenary
            ethereumService={this.props.ethereumService}
            messagingService={this.props.messagingService}
            mercenaryService={this.mercenaryService!}
            customizeType={CustomizeMercenaryType.Claim}
            close={() => this.resetActivity()}></CustomizeMercenary>
        )}

        {this.mercenaryService && this.state.activeActivity === ActiveActivity.UpdateAppearance && (
          <CustomizeMercenary
            ethereumService={this.props.ethereumService}
            messagingService={this.props.messagingService}
            mercenaryService={this.mercenaryService!}
            character={this.state.activeCharacter!.explorerState.character!}
            customizeType={CustomizeMercenaryType.updateAppearance}
            close={() => this.resetActivity()}></CustomizeMercenary>
        )}

        {this.state.activeActivity === ActiveActivity.BeginQuest && (
          <BeginQuest
            ethereumService={this.props.ethereumService}
            questService={this.state.activeCharacter!.questService!}
            itemService={this.state.activeCharacter!.itemService!}
            character={this.state.activeCharacter!.explorerState.character!}
            close={() => this.resetActivity()}></BeginQuest>
        )}

        {this.state.activeActivity === ActiveActivity.RestoreHealth && (
          <RestoreStat
            questService={this.state.activeCharacter!.questService!}
            itemService={this.state.activeCharacter!.itemService!}
            character={this.state.activeCharacter!.explorerState.character!}
            stat={this.state.activeCharacter!.explorerState.health!}
            restoreFunction={(character, item, amount) =>
              this.state.activeCharacter!!.mercenaryService!.restoreHealth(character, item, amount)
            }
            close={() => this.resetActivity()}></RestoreStat>
        )}

        {this.state.activeActivity === ActiveActivity.RestoreMorale && (
          <RestoreStat
            questService={this.state.activeCharacter!.questService!}
            itemService={this.state.activeCharacter!.itemService!}
            character={this.state.activeCharacter!.explorerState.character!}
            stat={this.state.activeCharacter!.explorerState.morale!}
            restoreFunction={(character, item, amount) =>
              this.state.activeCharacter!.mercenaryService!.restoreMorale(character, item, amount)
            }
            close={() => this.resetActivity()}></RestoreStat>
        )}

        {this.state.activeActivity === ActiveActivity.AllocateStatBoosts && (
          <AllocateStats
            mercenaryService={this.mercenaryService!}
            character={this.state.activeCharacter!.explorerState.character!}
            close={() => this.resetActivity()}></AllocateStats>
        )}
      </section>
    );
  }

  renderQuestCharacters() {
    return (
      <div className="characters">
        <Swiper className="px-1" navigation spaceBetween={10} slidesPerView="auto" centerInsufficientSlides>
          {this.state.explorers.map((explorer, index) => (
            <SwiperSlide key={'' + index + explorer.character.id}>
              <InView
                onChange={inView => {
                  this.setInView(explorer, inView);
                }}>
                <ExplorerWrapper
                  explorer={explorer}
                  itemService={this.itemService}
                  questService={this.questService}
                  mercenaryService={this.mercenaryService}
                  messagingService={this.props.messagingService}
                  ethereumService={this.props.ethereumService}
                  refreshedAtBlock={this.state.refreshedAtBlock}
                  onBeginQuest={this.beginQuest}
                  onRestoreHealth={this.restoreHealth}
                  onRestoreMorale={this.restoreMorale}
                  onallocateStatBoosts={this.allocateStatBoosts}
                  onupdateAppearance={this.updateAppearance}></ExplorerWrapper>
              </InView>
            </SwiperSlide>
          ))}

          {!this.mercenaryService?.hasClaim() && (
            <SwiperSlide key="mint">
              <button className="button is-transparent-action mint-mercenary-button mr-3" onClick={this.mintMercenary}>
                <div
                  className="is-flex is-flex-direction-column is-align-items-center is-justify-content-space-around"
                  style={{ height: '100%' }}>
                  <div className="flex-1"></div>
                  <div className="mt-6 pt-6">
                    <div>
                      <span className="icon">
                        <i className="fas fa-plus"></i>
                      </span>
                    </div>
                    <span className="side-action-text">Recruit Mercenary</span>
                  </div>
                  <div className="flex-1"></div>
                  <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                    <p>
                      <span className="mr-1 has-light-text">
                        Cost {Utils.formatUnits(this.mercenaryService?.mintPrice)}
                      </span>
                      <span className="has-light-text">ETH (+ gas)</span>
                    </p>
                  </div>
                </div>
              </button>
            </SwiperSlide>
          )}

          {this.mercenaryService?.hasClaim() && (
            <SwiperSlide key="claim">
              <button
                className="button is-transparent-action claim-mercenary-button mr-3"
                onClick={this.claimMercenary}>
                <div
                  className="is-flex is-flex-direction-column is-align-items-center is-justify-content-space-around"
                  style={{ height: '100%' }}>
                  <div className="flex-1"></div>
                  <div className="mt-6 pt-6">
                    <div>
                      <span className="icon">
                        <i className="fas fa-box"></i>
                      </span>
                    </div>
                    <span className="side-action-text">Claim Mercenary</span>
                  </div>
                  <div className="flex-1"></div>
                  <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                    <p>
                      <span className="has-light-text">Cost Free (+ gas)</span>
                    </p>
                  </div>
                </div>
              </button>
            </SwiperSlide>
          )}
        </Swiper>
      </div>
    );
  }
}
