import { BigNumber, ethers } from 'ethers';
import { EthereumService } from './EthereumService';
import { MessagingService } from './MessagingService';
import { Utils } from './Utils';
import { ISuperdrawService, PrizeSplit } from './Interfaces';
import { IGameConfig } from '../models/GameConfig';

export class SuperdrawServiceV1 implements ISuperdrawService {
  walletAddress?: string;

  tokenContract?: ethers.Contract | any;
  gameContract?: ethers.Contract | any;

  prizePoolTarget = 0;
  currentDrawIndex = 0;
  currentPrizePool = 0;
  currentDrawTickets = 0;
  currentDrawTicketsPercent = 0;
  prizeSplit = new PrizeSplit();

  unclaimedPrizes: BigNumber[] = [];
  unclaimedPrizeTotal: number = 0;

  constructor(
    public config: IGameConfig,
    private ethereumService: EthereumService,
    private messagingService: MessagingService
  ) {
    this.walletAddress = this.ethereumService!.walletData.walletAddress;
    this.tokenContract = new ethers.Contract(
      this.config.tokenContractAddress,
      this.config.tokenContractAbi,
      ethereumService.signer
    );
    this.gameContract = new ethers.Contract(
      this.config.gameContractAddress,
      this.config.gameContractAbi,
      ethereumService.signer
    );
  }

  async update(): Promise<any> {
    const currentDraw = await this.gameContract.getCurrentDraw();
    this.currentDrawIndex = currentDraw.toNumber();

    var work = [
      this.tokenContract.allowance(this.walletAddress, this.config.gameContractAddress),
      this.gameContract.draws(currentDraw),
      this.gameContract.getUnclaimedWinnerByAddress(this.walletAddress),
      this.gameContract.getNumberOfTicketPerAddressByDraw(currentDraw, this.walletAddress)
    ];

    await Promise.all(work).then(async response => {
      this.currentPrizePool = +Utils.formatUnits(response[1][2], 18);
      this.prizePoolTarget = +Utils.formatUnits(response[1][4], 18);
      this.unclaimedPrizes = response[2];
      this.currentDrawTickets = response[3];
      this.currentDrawTicketsPercent = (this.currentDrawTickets / response[1][6]) * 100;

      var prizeSplit = await this.gameContract.splitPrizePool(response[1][2]);
      this.prizeSplit.burnAmount = +Utils.formatUnits(prizeSplit.burnAmount, 18);
      this.prizeSplit.closersFee = +Utils.formatUnits(prizeSplit.closersFee, 18);
      this.prizeSplit.nextDrawAmount = +Utils.formatUnits(prizeSplit.nextDrawAmount, 18);
      this.prizeSplit.winnersPrize = +Utils.formatUnits(prizeSplit.winnersPrize, 18);
    });

    if (this.unclaimedPrizes.length > 0) {
      work = this.unclaimedPrizes.map(udi => {
        return this.gameContract.draws(udi);
      });

      this.unclaimedPrizeTotal = await Promise.all(work).then(response => {
        return response.reduce((p, c) => {
          return p + +Utils.formatUnits(c[5], 18);
        }, 0);
      });
    }
  }

  closeDraw() {
    this.gameContract.closeDraw().then(
      (withdrawResponse: any) =>
        this.messagingService.events.next({
          message: `${this.config.gameType} Winner Draw Pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error Drawing ${this.config.gameType} Winner : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  claimPrize() {
    const prizeIndex = this.unclaimedPrizes[0];
    this.gameContract.claimWinningPrize(prizeIndex).then(
      (claimResponse: any) =>
        this.messagingService.events.next({
          message: `${this.config.gameType} Prize Claim Is Pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error Claiming ${this.config.gameType} Prize : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  removeEventHandlers() {
    this.tokenContract?.removeAllListeners();
    this.gameContract?.removeAllListeners();
  }

  setupEventHandlers() {
    this.removeEventHandlers();

    var update = (message: string) =>
      this.update().then(() => {
        this.messagingService.events.next({ message: message });
      });

    if (this.gameContract) {
      this.gameContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: ['0x95681e512bc0fe659e195e06c283eada494316f3d801213e48e7101af92bf770']
        },
        (updatedBy: any, amountClaimed: any) => {
          if (updatedBy === this.ethereumService.walletData.walletAddress) {
            update(`${this.config.gameType} Prize Claimed`);
          }
        }
      );

      this.gameContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: ['0x64f8e38e0c84e256216022f39cbb3545f496f09e605a5803e30de6f83040df72']
        },
        (closedBy: any, burnedAmount: any, closerFee: any, nextDrawAmount: any) => {
          if (closedBy === this.ethereumService.walletData.walletAddress) {
            update(`${this.config.gameType} Winner Drawn`);
          }
        }
      );

      this.ethereumService.provider!.on('block', currentBlock => {
        this.update().then(() => {});
      });
    }
  }
}
