import React, { Component } from 'react';
import { Subscription } from 'rxjs';
import { EthereumService } from '../services/EthereumService';
import { MessagingService } from '../services/MessagingService';
import './Footer.scss';

interface ILogItem {
  message: string;
  typeClass: string;
  typeId: number;
}

interface IFooterProps {
  messagingService: MessagingService;
  ethereumService: EthereumService;
}

interface IFooterState {
  isMaximised: boolean;
  isPinned: boolean;
  logItems: ILogItem[];
  hasNewItem: boolean;
}

export class Footer extends Component<IFooterProps, IFooterState> {
  subscription: Subscription;

  constructor(props: IFooterProps) {
    super(props);

    this.subscription = new Subscription();
    this.state = {
      isMaximised: false,
      isPinned: false,
      logItems: [],
      hasNewItem: false
    };

    this.togglePin = this.togglePin.bind(this);
    this.toggleMinMax = this.toggleMinMax.bind(this);
  }

  toggleMinMax() {
    if (this.state.isPinned) return;
    this.setState({ isMaximised: !this.state.isMaximised });
  }

  togglePin() {
    this.setState({ isPinned: !this.state.isPinned, isMaximised: !this.state.isPinned });
  }

  componentDidMount() {
    this.subscription.add(
      this.props.messagingService.events.subscribe((event: any) => {
        this.addLogItem(event, 0);
      })
    );
    this.subscription.add(
      this.props.messagingService.networkEvents.subscribe((event: any) => {
        this.addLogItem(event, 1);
      })
    );
    this.subscription.add(
      this.props.messagingService.errors.subscribe((event: any) => {
        this.addLogItem(event, 2);
      })
    );
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  private addLogItem(event: any, typeId: number) {
    const typeClass =
      typeId === 0
        ? event.pending
          ? 'logitem-pending'
          : 'logitem-success'
        : typeId === 1
        ? 'logitem-debug'
        : 'logitem-error';
    if (event.message) {
      this.setState({
        logItems: [...this.state.logItems, { message: event.message, typeClass: typeClass, typeId: typeId }],
        hasNewItem: true
      });
    }

    setTimeout(() => {
      this.setState({ hasNewItem: false });
    }, 5000);
  }

  lastItem(): ILogItem {
    return this.state.logItems[this.state.logItems.length - 1];
  }

  render() {
    if (this.state.logItems?.length > 0) {
      return (
        <React.Fragment>
          <footer
            id="footer"
            className={`${this.state.isMaximised ? 'is-maximised' : 'is-minimised'} ${
              this.state.hasNewItem ? 'has-new-item' : ''
            } footer`}>
            {this.state.isMaximised && (
              <span className="icon collapse-expand expanded" onClick={this.togglePin}>
                <i className={`${this.state.isPinned ? 'is-pinned' : ''} fas fa-thumbtack`}></i>
              </span>
            )}
            {!this.state.isMaximised && (
              <span
                className={`${this.lastItem()?.typeClass} icon collapse-expand collapsed`}
                onClick={this.toggleMinMax}>
                <i className="fas fa-terminal"></i>
              </span>
            )}
            {this.state.isMaximised && (
              <React.Fragment>
                <div>
                  {this.state.logItems.map((logItem, index) => (
                    <p className={logItem.typeClass} key={index}>
                      {'>'} {logItem.message}
                    </p>
                  ))}
                </div>
                <div id="anchor"></div>
              </React.Fragment>
            )}

            {!this.state.isMaximised && (
              <p className={this.lastItem().typeClass}>
                {'>'} {this.lastItem().message}
              </p>
            )}
          </footer>
        </React.Fragment>
      );
    }
  }
}
