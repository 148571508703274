import React, { Component } from 'react';
import { Subscription } from 'rxjs';
import { Utils } from '../../services/Utils';
import { ILiquidityAssetService } from '../../services/Interfaces';
import LiquidityPoolRow from './LiquidityPoolRow';
import CollapsibleCard from '../Common/CollapsibleCard';

interface ILiquidityPoolProps {
  liquidityAssetServices: ILiquidityAssetService[];
}

enum Action {
  None = 1,
  Approve,
  Add,
  Remove
}

enum AssetsToDisplay {
  All,
  Wallet
}

interface ILiquidityPoolState {
  liquidityAssetService?: ILiquidityAssetService | null;
  amount: string | number;
  balance: string | number;
  action: Action;
  area: string;
  showDetail: boolean;
  assetsDisplayed: AssetsToDisplay;
}

class LiquidityPool extends Component<ILiquidityPoolProps, ILiquidityPoolState> {
  subscription: Subscription;
  walletAddress?: string;
  chainId?: number;
  groupedLiquidityAssetServices: {}[];

  constructor(props: any) {
    super(props);

    var groups = props.liquidityAssetServices.reduce((r: any, a: any) => {
      r[a.config.area] = [...(r[a.config.area] || []), a];
      return r;
    }, {});

    this.groupedLiquidityAssetServices = [];
    for (var area in groups) {
      this.groupedLiquidityAssetServices.push({
        area: area,
        group: groups[area]
      });
    }

    var toDisplay = localStorage.getItem('poolAssetsToDisplay');

    this.state = {
      liquidityAssetService: null,
      amount: 0,
      balance: 0,
      action: Action.None,
      area: '',
      showDetail: false,
      assetsDisplayed: toDisplay === 'Wallet' ? AssetsToDisplay.Wallet : AssetsToDisplay.All
    };

    this.addLiquidity = this.addLiquidity.bind(this);
    this.removeLiquidity = this.removeLiquidity.bind(this);
    this.claimReward = this.claimReward.bind(this);
    this.clearAction = this.clearAction.bind(this);
    this.setAmount = this.setAmount.bind(this);
    this.setPercentAmount = this.setPercentAmount.bind(this);
    this.approveSpend = this.approveSpend.bind(this);
    this.confirmAdd = this.confirmAdd.bind(this);
    this.confirmRemove = this.confirmRemove.bind(this);
    this.confirmExit = this.confirmExit.bind(this);

    this.subscription = new Subscription();
  }

  toggleAssetsDisplayed() {
    this.setState({
      assetsDisplayed:
        this.state.assetsDisplayed === AssetsToDisplay.Wallet ? AssetsToDisplay.All : AssetsToDisplay.Wallet
    });

    localStorage.setItem(
      'poolAssetsToDisplay',
      AssetsToDisplay[
        this.state.assetsDisplayed === AssetsToDisplay.Wallet ? AssetsToDisplay.All : AssetsToDisplay.Wallet
      ]
    );
  }

  addLiquidity(liquidityAssetService: ILiquidityAssetService) {
    this.setState({
      action: Action.Add,
      area: liquidityAssetService.config.area,
      liquidityAssetService: liquidityAssetService,
      amount: 0,
      balance: liquidityAssetService.lptBalance
    });
  }

  removeLiquidity(liquidityAssetService: ILiquidityAssetService) {
    this.setState({
      action: Action.Remove,
      area: liquidityAssetService.config.area,
      liquidityAssetService: liquidityAssetService,
      amount: 0,
      balance: liquidityAssetService.liquidityBalance
    });
  }

  claimReward(liquidityAssetService: ILiquidityAssetService) {
    liquidityAssetService!.claim();
  }

  clearAction() {
    this.setState({ action: Action.None, area: '', liquidityAssetService: null, amount: 0 });
  }

  setAmount(e: any) {
    this.setState({ amount: e.target.value });
  }

  setPercentAmount(percentage: number) {
    var addAmount = Utils.formatUnits(Utils.parseUnits(this.state.balance).mul(percentage).div(100));
    this.setState({ amount: addAmount });
  }

  approveSpend(liquidityAssetService: ILiquidityAssetService) {
    liquidityAssetService!.approve();
    this.clearAction();
  }

  confirmAdd() {
    this.state.liquidityAssetService!.add(this.state.amount);
    this.clearAction();
  }

  confirmRemove() {
    this.state.liquidityAssetService!.remove(this.state.amount);
    this.clearAction();
  }

  confirmExit() {
    this.state.liquidityAssetService!.exit(this.state.amount);
    this.clearAction();
  }

  render() {
    return (
      <div className="tile is-ancestor mx-3">
        <div className="tile is-vertical is-12">
          <div className="field pl-1">
            <input
              id="assetsDisplayed"
              type="checkbox"
              name="assetsDisplayed"
              className={`${
                this.state.assetsDisplayed === AssetsToDisplay.Wallet ? 'is-active' : ''
              }  switch is-rounded is-small`}
              onClick={() => this.toggleAssetsDisplayed()}
              defaultChecked={this.state.assetsDisplayed === AssetsToDisplay.Wallet}
            />
            <label htmlFor="assetsDisplayed">
              <span className="has-light-text noselect" style={{ marginTop: '-1px' }}>
                Hide zero balances
              </span>
            </label>
          </div>

          {this.state.assetsDisplayed === AssetsToDisplay.All &&
            this.groupedLiquidityAssetServices.map((ag: any) => (
              <React.Fragment key={ag.area}>
                {ag.group.length > 0 && (
                  <CollapsibleCard
                    title={ag.area}
                    id="liquidity-token-card"
                    key={'liquidity-token-card' + ag.group.length}>
                    {(!this.state.liquidityAssetService || this.state.area !== ag.area) && (
                      <React.Fragment>{this.renderPools(ag.area, ag.group)}</React.Fragment>
                    )}
                  </CollapsibleCard>
                )}
              </React.Fragment>
            ))}

          {this.state.assetsDisplayed === AssetsToDisplay.Wallet &&
            this.groupedLiquidityAssetServices
              .map((a: any) => {
                return a.group
                  .filter(
                    (b: ILiquidityAssetService) => +b.lptBalance > 0 || +b.liquidityBalance > 0 || +b.currentReward > 0
                  )
                  .map((b: ILiquidityAssetService) => {
                    return { l: b.lptBalance, b: b.liquidityBalance, c: b.currentReward };
                  });
              })
              .flat().length === 0 && (
              <span className="column meme is-half has-text-centered">
                <h3 className="sub-title py-3">You have no balances in the liquidity pools</h3>
              </span>
            )}

          {this.state.assetsDisplayed === AssetsToDisplay.Wallet &&
            this.groupedLiquidityAssetServices.map((ag: any) => (
              <React.Fragment key={ag.area}>
                {ag.group.filter(
                  (las: ILiquidityAssetService) =>
                    +las.lptBalance > 0 || +las.liquidityBalance > 0 || +las.currentReward > 0
                ).length > 0 && (
                  <CollapsibleCard
                    title={ag.area}
                    id="liquidity-token-card"
                    key={'liquidity-token-card' + ag.group.length}>
                    {(!this.state.liquidityAssetService || this.state.area !== ag.area) && (
                      <React.Fragment>{this.renderPools(ag.area, ag.group)}</React.Fragment>
                    )}
                  </CollapsibleCard>
                )}
              </React.Fragment>
            ))}
        </div>
        {this.renderActions()}
      </div>
    );
  }

  renderActions() {
    return (
      <React.Fragment>
        {this.state.liquidityAssetService && this.state.action === Action.Add && (
          <div className="is-active modal">
            <div className="modal-background" onClick={() => this.clearAction()}></div>
            <div className="modal-content liquidity-action">
              <article className="card tile is-child box">
                <div className="content">{this.renderAddLiquidity()}</div>
              </article>
              <button className="modal-close is-medium" aria-label="close" onClick={() => this.clearAction()}></button>
            </div>
          </div>
        )}
        {this.state.liquidityAssetService && this.state.action === Action.Remove && (
          <div className="is-active modal">
            <div className="modal-background" onClick={() => this.clearAction()}></div>
            <div className="modal-content liquidity-action">
              <article className="card tile is-child box">
                <div className="content">{this.renderRemoveLiquidity()}</div>
              </article>
              <button className="modal-close is-medium" aria-label="close" onClick={() => this.clearAction()}></button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderPools(name: string, liquidityServices: []) {
    return (
      <div className="table-control">
        <table className="table table-grouped small-text is-fullwidth">
          <thead>
            <tr>
              <th className="image-header"></th>
              <th></th>
              <th>APY</th>
              <th>Available</th>
              <th>Staked</th>
              <th>Rewards</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {liquidityServices
              .filter(
                (las: ILiquidityAssetService) =>
                  this.state.assetsDisplayed === AssetsToDisplay.All ||
                  +las.lptBalance > 0 ||
                  +las.liquidityBalance > 0 ||
                  +las.currentReward > 0
              )
              .map((las: ILiquidityAssetService) => (
                <LiquidityPoolRow
                  key={las.config.liquidityContractAddress + las.config.poolId}
                  onApproveClick={this.approveSpend}
                  onAddClick={this.addLiquidity}
                  onRemoveClick={this.removeLiquidity}
                  onClaimClick={this.claimReward}
                  liquidityAssetService={las}></LiquidityPoolRow>
              ))}
          </tbody>
        </table>
      </div>
    );
  }

  renderAddLiquidity() {
    return (
      <div className="mb-3">
        {+this.state.liquidityAssetService!.lptBalance <= 0 && (
          <ul className="action-control mt-5 is-vertical">
            <li className="is-center  is-fullwidth m-0">
              <h2 className="info">Nothing to {this.state.liquidityAssetService!.actionDisplayName}</h2>
            </li>

            <li>
              <button className="button" onClick={this.clearAction}>
                OK{' '}
              </button>
            </li>
          </ul>
        )}

        {+this.state.liquidityAssetService!.lptBalance > 0 && (
          <React.Fragment>
            <div className="info-control">
              <h2 className="info is-center">{this.state.liquidityAssetService!.actionDisplayName} :</h2>
              <h2 className="value is-center">
                <span className="pre">
                  {Utils.toFixed(this.state.liquidityAssetService!.lptBalance, 8)}{' '}
                  {this.state.liquidityAssetService!.config.lpToken}
                </span>
              </h2>
            </div>

            {this.renderAddAmount()}

            <ul className="action-control mt-5">
              <li>
                <button className="button is-cancel" onClick={this.clearAction}>
                  Cancel
                </button>
              </li>

              <li>
                <button className="button is-action" disabled={+this.state.amount <= 0} onClick={this.confirmAdd}>
                  {this.state.liquidityAssetService!.actionDisplayName}
                </button>
              </li>
            </ul>
          </React.Fragment>
        )}
      </div>
    );
  }

  renderRemoveLiquidity() {
    return (
      <div className="mb-3">
        {+this.state.liquidityAssetService!.liquidityBalance <= 0 && (
          <ul className="action-control mt-5 is-vertical">
            <li className="is-center  is-fullwidth m-0">
              <h2 className="info">Nothing to {this.state.liquidityAssetService!.undoActionDisplayName}</h2>
            </li>

            <li>
              <button className="button is-action" onClick={this.clearAction}>
                OK{' '}
              </button>
            </li>
          </ul>
        )}

        {+this.state.liquidityAssetService!.liquidityBalance > 0 && (
          <React.Fragment>
            <div className="info-control">
              <h2 className="info is-center">{this.state.liquidityAssetService!.undoActionDisplayName} :</h2>
              <h2 className="value is-center">
                <span className="pre">
                  {Utils.toFixed(this.state.liquidityAssetService!.liquidityBalance, 8)}{' '}
                  {this.state.liquidityAssetService!.config.lpToken}
                </span>
              </h2>
            </div>

            {this.renderRemoveAmount()}

            <ul className="action-control mt-5">
              <li>
                <button className="button is-cancel" onClick={this.clearAction}>
                  Cancel
                </button>
              </li>

              <li>
                <button className="button is-action" disabled={+this.state.amount <= 0} onClick={this.confirmRemove}>
                  {this.state.liquidityAssetService!.undoActionDisplayName}
                </button>
              </li>

              {+this.state.liquidityAssetService!.currentReward > 0 && (
                <li>
                  <button className="button is-action" disabled={+this.state.amount <= 0} onClick={this.confirmExit}>
                    {this.state.liquidityAssetService!.undoActionDisplayName} and Claim Reward
                  </button>
                </li>
              )}
            </ul>
          </React.Fragment>
        )}
      </div>
    );
  }

  renderApproveAmount() {
    return (
      <ul className="action-control mt-5 no-gap">
        <li className="action-form">
          <div className="field has-addons">
            {this.renderPercentButtons()}

            <div className="control input-control">
              <input
                className="input is-action"
                type="text"
                placeholder="BPT Amount"
                value={this.state.amount}
                onChange={this.setAmount}
                disabled={+this.state.liquidityAssetService!.lptBalance <= 0}
              />
            </div>
          </div>
        </li>
      </ul>
    );
  }

  renderAddAmount() {
    return (
      <ul className="action-control mt-5 is-vertical no-gap">
        <li className="is-center  is-fullwidth m-0">{this.renderPercentButtons()}</li>

        <li className="is-center is-fullwidth m-0">
          <div className="control input-control">
            <input
              className="input is-action"
              type="text"
              placeholder="BPT Amount"
              value={this.state.amount}
              onChange={this.setAmount}
              disabled={+this.state.liquidityAssetService!.approvedLptBalance <= 0}
            />
          </div>
        </li>
      </ul>
    );
  }

  renderRemoveAmount() {
    return (
      <ul className="action-control mt-5 is-vertical no-gap">
        <li className="is-center  is-fullwidth m-0">{this.renderPercentButtons()}</li>

        <li className="is-center is-fullwidth m-0">
          <div className="control input-control">
            <input
              className="input is-action"
              type="text"
              placeholder="BPT Amount"
              value={this.state.amount}
              onChange={this.setAmount}
              disabled={+this.state.liquidityAssetService!.liquidityBalance <= 0}
            />
          </div>
        </li>
      </ul>
    );
  }

  renderPercentButtons() {
    return (
      <div className="buttons has-addons">
        <button className="button is-white is-small" onClick={() => this.setPercentAmount(25)}>
          25 %
        </button>

        <button className="button is-gray is-small" onClick={() => this.setPercentAmount(50)}>
          50 %
        </button>

        <button className="button is-darkgray is-small" onClick={() => this.setPercentAmount(75)}>
          75 %
        </button>

        <button className="button is-darkergray is-small" onClick={() => this.setPercentAmount(100)}>
          100 %
        </button>
      </div>
    );
  }
}

export default LiquidityPool;
