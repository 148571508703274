import { BigNumber } from 'ethers';
import { IAssetConfig } from '../models/AssetConfig';
import { ILiquidityConfig } from '../models/LiquidityConfig';
import { IGameConfig } from '../models/GameConfig';
import { ICharacterWrapper } from '../models/CharacterDetails';

export interface IFungibleAssetService {
  update(): Promise<IFungibleAssetService>;
}

export interface INonFungibleAssetService {
  config: IAssetConfig;
  update(): Promise<INonFungibleAssetService>;
  actionText: string;
  actionUrl: string;
}

export interface ICharacterService extends INonFungibleAssetService {
  ownedCharacters: ICharacterWrapper[];
  setInView(characterStatics: ICharacterWrapper, inView: boolean): any;
}

export interface ILiquidityAssetService {
  config: ILiquidityConfig;

  lptBalance: string | 0;
  unapprovedLptBalance: string | 0;
  approvedLptBalance: string | 0;
  liquidityBalance: string | 0;

  hasReward: boolean;
  currentReward: string | 0;
  upcomingReward: string | 0;
  isInstantReward: boolean;

  totalLiquidity: string | 0;
  apy: number | 0;

  actionDisplayName: string;
  undoActionDisplayName: string;

  update(): Promise<ILiquidityAssetService>;
  approve(): void;
  add(amount: any): void;
  remove(amount: any): void;
  exit(amount: any): void;
  claim(): void;
}

export class PrizeSplit {
  burnAmount: number = 0;
  closersFee: number = 0;
  nextDrawAmount: number = 0;
  winnersPrize: number = 0;
}
export interface IPrizeSplit {
  burnAmount: number;
  closersFee: number;
  nextDrawAmount: number;
  winnersPrize: number;
}
export interface IGameService {
  config: IGameConfig;
  currentDrawIndex: number;
  currentPrizePool: number;
  currentDrawTickets: number;
  currentDrawTicketsPercent: number;
  prizeSplit: IPrizeSplit;

  unclaimedPrizes: BigNumber[];
  unclaimedPrizeTotal: number;

  update(): Promise<any>;
  closeDraw(): void;
  claimPrize(): void;
}

export interface ILottoService extends IGameService {
  isWinning: boolean; // TODO move to different interface
  isSpendApproved: boolean;

  currentDrawDeadlinePassed: boolean;
  totalDrawTickets: number;
  currentDrawCountdownSecs: number;
  currentDrawCountdownTime: ITime;

  ticketPrice: string | 0;
  actionDisplayName: string;

  approve(): void;
  buyTicket(amount: any): void;
}

export interface ISuperdrawService extends IGameService {
  prizePoolTarget: number;
}

export interface ITime {
  days: string;
  hours: string;
  mins: string;
  secs: string;
  isComplete: boolean;
}
