import { Mutex } from 'async-mutex';
import { BigNumber, ethers } from 'ethers';
import { EthereumService } from './EthereumService';
import { ItemService } from './ItemService';
import { MessagingService } from './MessagingService';
import { IContractConfig } from '../models/AssetConfig';
import { Utils } from './Utils';

export enum IngredientStatus {
  NoMatch = 0,
  WrongAmounts = 1,
  MissingIngredient = 2,
  Match = 3
}
export interface IRecipeStatus {
  recipe: IRecipe | null;
  status: IngredientStatus;
}
export interface IRecipeIngredient {
  itemId: number;
  amount: number;
}
export interface IRecipe {
  id: number;
  name: string;
  itemId: number;
  amount: number;
  price: BigNumber;
  paymentAddress: string;
  active: boolean;
  incredients: IRecipeIngredient[];
}

export class CraftingService {
  mutex = new Mutex();
  walletAddress?: string;
  craftingContract?: ethers.Contract;
  cornContract?: ethers.Contract;
  recipes: IRecipe[] = [];

  constructor(
    public config: IContractConfig,
    private itemService: ItemService,
    private messagingService: MessagingService,
    private ethereumService: EthereumService
  ) {
    this.walletAddress = this.ethereumService!.walletData.walletAddress;

    this.craftingContract = new ethers.Contract(
      this.config.contractAddress,
      this.config.contractConstants.contractAbi,
      ethereumService.signer
    );

    // TODO: support other payment tokens
    this.cornContract = this.ethereumService.networkManager!.network!.contracts.CORN;

    this.loadRecipes();

    this.setupEventHandlers();
  }

  private async loadRecipes() {
    var recipes: IRecipe[] = [];
    var recipesJson = localStorage.getItem('recipes');
    var recipesLoadedContract = localStorage.getItem('recipesLoadedContract');
    var recipesLoadedMs = localStorage.getItem('recipesLoaded') ?? Date.now();

    if (
      !recipesJson ||
      recipesLoadedContract !== this.config.contractAddress ||
      Date.now() - +recipesLoadedMs > 60 * 60 * 1000 * 24
    ) {
      (await this.craftingContract!.getRecipes()).forEach((r: any) => {
        recipes.push({
          id: r.recipeID,
          name: r.name,
          itemId: BigNumber.from(r.itemID).toNumber(),
          amount: BigNumber.from(r.amount).toNumber(),
          price: r.price,
          paymentAddress: r.paymentAddress,
          active: r.active,
          incredients: r.recipeItems.map((item: any, i: number) => {
            return {
              itemId: BigNumber.from(item).toNumber(),
              amount: BigNumber.from(r.recipeAmounts[i]).toNumber()
            };
          })
        });
      });

      localStorage.setItem('recipes', JSON.stringify(recipes));
      localStorage.setItem('recipesLoadedContract', this.config.contractAddress);
      localStorage.setItem('recipesLoaded', Date.now().toString());
    } else {
      recipes = JSON.parse(recipesJson);
    }

    this.recipes = recipes;
  }

  public getRecipe(incredients: IRecipeIngredient[]): IRecipeStatus {
    var matches: IRecipeStatus[] = [];

    this.recipes
      .filter(r => r.active && r.incredients.length === incredients.length)
      .forEach(recipe => {
        var status = IngredientStatus.Match;

        var result = recipe.incredients.every(recipeIngredient => {
          var incredient = incredients.find(i => i.itemId === recipeIngredient.itemId);
          if (!incredient) {
            status = IngredientStatus.NoMatch;
            return false;
          } else if (incredient.amount < recipeIngredient.amount) {
            status = IngredientStatus.WrongAmounts;
          }

          return true;
        });

        if (result) {
          matches.push({
            recipe: recipe,
            status: status
          });
        }
      });

    return matches.length > 0
      ? matches[0]
      : {
          recipe: null,
          status: IngredientStatus.NoMatch
        };
  }

  public getRecipeWIP(recipeAttempt: IRecipeIngredient[]): IRecipeStatus {
    const availableRecipes = this.recipes.filter(
      r =>
        r.active &&
        r.incredients.length >= recipeAttempt.length &&
        recipeAttempt.every(i => r.incredients.some(recipeIngredient => recipeIngredient.itemId === i.itemId))
    );

    let bestRecipeStatus: IRecipeStatus = {
      recipe: null,
      status: IngredientStatus.NoMatch
    };

    availableRecipes.forEach(recipe => {
      let recipeStatus: IRecipeStatus = {
        recipe: recipe,
        status: IngredientStatus.Match
      };
      let allIngredientsMatch = recipe.incredients.every(recipeIngredient =>
        recipeAttempt.some(attemptIngredient => recipeIngredient.itemId === attemptIngredient.itemId)
      );
      if (!allIngredientsMatch) {
        recipeStatus.status = IngredientStatus.MissingIngredient;
      } else {
        let wrongAmounts = recipe.incredients.some(recipeIngredient => {
          let matchingAttemptIngredient = recipeAttempt.find(
            attemptIngredient => attemptIngredient.itemId === recipeIngredient.itemId
          );
          return matchingAttemptIngredient && matchingAttemptIngredient.amount < recipeIngredient.amount;
        });
        if (wrongAmounts) {
          recipeStatus.status = IngredientStatus.WrongAmounts;
        }
      }

      if (recipeStatus.status > bestRecipeStatus.status) {
        bestRecipeStatus = recipeStatus;
      }
    });

    return bestRecipeStatus;
  }

  async isPaymentApproved(recipe: IRecipe): Promise<any> {
    if (!recipe?.paymentAddress) return true;

    if (recipe.paymentAddress !== this.cornContract?.address) throw new Error('Not yet supported');

    var allowance = await this.cornContract!.allowance(this.walletAddress, this.config.contractAddress);
    return allowance.gte(recipe.price);
  }

  // TODO: support for allowance
  async getPaymentBalance(recipe: IRecipe): Promise<any> {
    if (!recipe?.paymentAddress) return 0;

    if (recipe.paymentAddress !== this.cornContract?.address) return BigNumber.from(0);
    return await this.cornContract!.balanceOf(this.walletAddress);
  }

  getPaymentSymbol(recipe: IRecipe): string {
    if (!recipe?.paymentAddress) return '';

    if (recipe.paymentAddress === this.cornContract?.address) return 'CORN';
    return 'NOT YET SUPPORTED';
  }

  async isCraftingApproved(): Promise<boolean> {
    return await this.itemService.itemsContract!.isApprovedForAll(
      this.ethereumService.walletData.walletAddress,
      this.craftingContract!.address
    );
  }

  async approveCrafting(): Promise<any> {
    await this.itemService.itemsContract!.setApprovalForAll(this.craftingContract!.address, true).then(
      () => this.messagingService.events.next({ message: 'Crafting item use approval is pending', pending: true }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error approving crafting item use : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  async approvePayment(recipe: IRecipe): Promise<any> {
    if (!recipe.paymentAddress) return;

    if (recipe.paymentAddress !== this.cornContract?.address) throw new Error('Not yet supported');

    var amount = Utils.parseUnits('1000000000');

    await this.cornContract!.approve(this.config.contractAddress, amount).then(
      () =>
        this.messagingService.events.next({
          message: `Approval of crafting payment is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error approving crafting payment: ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  async craftItems(recipe: IRecipe, amountToCraft: number): Promise<any> {
    var itemIds = recipe.incredients.map(i => i.itemId);
    var itemAmounts = recipe.incredients.map(i => i.amount);

    try {
      const gasEstimated = await this.craftingContract!.estimateGas.craftItems(
        recipe.id,
        [...itemIds],
        [...itemAmounts],
        amountToCraft
      );

      await this.craftingContract!.craftItems(recipe.id, [...itemIds], [...itemAmounts], amountToCraft, {
        gasLimit: Math.ceil(gasEstimated.toNumber() * 2)
      });

      this.messagingService.events.next({
        message: `Crafting is pending`,
        pending: true
      });
    } catch (error) {
      this.messagingService.errors.next({
        message: `Error during crafting : ${Utils.getContractErrorMessage(error)}`,
        error: error
      });
    }
  }

  removeEventHandlers() {
    this.craftingContract?.removeAllListeners();
  }

  setupEventHandlers() {
    this.removeEventHandlers();

    var update = (message: string) => {
      this.messagingService.events.next({ message: message });
    };

    if (this.craftingContract) {
      this.craftingContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('RecipeCrafted(address,uint256,uint256,uint256)')]
        },
        (account, recipeId, itemId, amount) => {
          if (account === this.ethereumService.walletData.walletAddress) {
            var itemDetails = this.itemService!.itemMap.get(BigNumber.from(itemId).toNumber());
            update(`You have successfully crafted ${amount} ${itemDetails?.name}`);
          }
        }
      );
    }
  }
}
