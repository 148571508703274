import { IAssetConfig } from '../AssetConfig';
import { JobType, LiquidityAssetType, PoolId, QuestType, TokenId } from '../Enums';
import { AssetId, INetworkConfig } from '../NetworkConfig';
import { Constants } from '../Constants';
import { PoolType } from '../LiquidityConfig';
import { GameType } from '../GameConfig';

// Icons
import arbitrum_icon from '../../assets/arbitrum-icon.png';
import ethIcon from '../../assets/ETH-icon.png';
import SushiswapIcon from '../../assets/SUSHISWAP-icon.png';

// Contracts
import UniswapPoolV2 from '../../contracts/liquidity/Uniswap-Pool-V2.json';
import StakingPoolV2 from '../../contracts/liquidity/StakingPoolV2.json';
import cropV2 from '../../contracts/Crops/CornV2.json';
import LottoV1 from '../../contracts/minigames/LottoV1.json';
import ChickenFeedV1 from '../../contracts/minigames/ChickenFeedV1.json';
import SuperdrawV1 from '../../contracts/minigames/SuperdrawV1.json';
import packsV1 from '../../contracts/items/packsV1.json';

// Claims
import welcomePacksClaims from '../../contracts/claims/ethereum/arbOne/claimWelcomePacks.json';
import mercClaims from '../../contracts/claims/ethereum/arbOne/claimMercs.json';

const ArbOneCornContractAddress = '0xFcc0351f3a1ff72409Df66a7589c1F9efBf53386';
const ArbOneCornEthPoolContractAddress = '0x968f56dcbb7d870ddfbc7ea053ce27ea50d1c0f0';
const ArbOneArbProxyContractAddress = '0x912CE59144191C1204E64559FE8253a0e49E6548';
const ArbOneCitizenContractAddress = '0xe16321aae3a6333033d96fa02785813ea2412334';
const ArbOneMercenariesContractAddress = '0xd09556ECE9783f9AABba43e1df6A04a8218e7ca1';
const ArbOneMintMercenariesContractAddress = '0xD228e7E6A6fb61BB4a8B6516c5Ec9C586bB9313a';
const ArbOneBoxContractAddress = '0xa0Dd926Fb5840f6A1B1C5315306894508144faF3';
const ArbOneItemsContractAddress = '0xe493F951Bf1F0F40dEa6487f006680E3D542d84b';
const ArbOneItemSetsContractAddress = '0xa8Bfe6EA7D6cf2dDD744d838dC12765DF2f4ECc7';
const ArbOnePacksContractAddress = '0x18E854810f50f385B09F01d018241A49024993bf';
const ArbOneQuestsContractAddress = '0x6dDA95F4989885B61D5420C8D2A1E94dD1aAd054';
const ArbOneStatManagerContractAddress = '0x3434b988AbCEf0d125Bd20d3A9909BDab65b1f2f';
const ArbOneCraftingContractAddress = '0x7b4166dB835AC0276AC9846802Ba808f56af0d28';

const GoerliLandContractAddress = '0x112b2060E01000194d13aF3B15874fF5599cD0f6';
const GoerliCornContractAddress = '0x7F7b5fe0099A15d7A5082f721D182cd09605A5Dd';
const GoerliCitizenContractAddress = '0x5CF5b194BA2AA9da8fA4923ed444AE5414dAa5c9';
const GoerliMercenariesContractAddress = '0x78aff6a8881AaFbf7eEa5400e104722c3a201f53';
const GoerliMintMercenariesContractAddress = '0xdCF2Dc1ee8D0C6a94BAd46Ed8D7029Cc6037C909';
const GoerliBoxContractAddress = '0x25747099B0eeFaefb77023dedBeBF064Ecd94448';
const GoerliItemsContractAddress = '0x7BAD6628618E422633978eBF7958C20EED351e28';
const GoerliItemSetsContractAddress = '0x476C8c28AA7224D422561BA99F8613391341eA6e';
const GoerliPacksContractAddress = '0x600390E9d6C8988d56b165FCEd5DDf2A58fF8987';
const GoerliQuestsContractAddress = '0x7aebD1C9241eD572e9451d10a245837baC33Fa7c';
const GoerliStatManagerContractAddress = '0xf85641441508b6E8052897295837134dE7565405';
const GoerliCraftingContractAddress = '0xe569092a32932fa28705db5c790d8423387c0f83';
// const GoerliGoldContractAddress = '0x960c308c180eda31ded9d7060Cea190A4DF40949';
// const GoerliMintGoldContractAddress = '0xEe90bf363DD375412cE67B72526F43397DfBcFF8';

// TODOs
// MintGold >> function mintGold(address recipient, uint256 amount) burns item 39, gold ingot .. (could merge this into crafting)
// Crafting >> function craftItem(uint256 recipeID, uint256[] memory itemIDs, uint256[] memory amounts, address recipient)
// Show amount of health/morale restored when using items to restore health/morale
// Add useful item info to item description

// Improve event messaging
// Improve gas estimation/contract call, cosolidate into single try catch with same error handling

export class ArbitrumNetworks {
  static readonly ONE: INetworkConfig = {
    name: 'arbitrum',
    chainId: 42161,
    blocktime: 12,
    icon: arbitrum_icon,
    loadPrices: true,
    displayChainName: 'Arbitrum',
    fullDisplayChainName: 'Arbitrum One',
    nativeCurrencyName: 'Ether',
    nativeCurrencySymbol: 'AETH',
    rpcUrls: [
      'https://arb1.arbitrum.io/rpc',
      'https://arbitrum-mainnet.infura.io/v3/22cd52c0e08642349a11e938e78b3e19',
      'https://arb-mainnet.g.alchemy.com/v2/oM9hcVu1u2JbcEVATMU_aO5UJaibsgrU',
      'https://rpc.ankr.com/arbitrum',
      'https://arbitrum.public-rpc.com'
    ],
    blockExplorerUrls: ['https://arbiscan.io'],
    dexBaseUrl: 'https://arbitrum.sushi.com/swap?&outputCurrency=',
    wethContractAddres: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    usdcContractAddress: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    assets: new Map<AssetId, IAssetConfig>([
      [
        TokenId.LAND,
        {
          contractAddress: '0x3CD1833Ce959E087D0eF0Cb45ed06BffE60F23Ba', // https://arbiscan.io/address/0x3CD1833Ce959E087D0eF0Cb45ed06BffE60F23Ba
          assetConstants: Constants.LAND
        }
      ],
      [
        TokenId.CORN,
        {
          contractAddress: ArbOneCornContractAddress,
          assetConstants: Constants.CORNv2
        }
      ]
    ]),
    boxConfig: {
      contractAddress: ArbOneBoxContractAddress,
      contractConstants: Constants.BOX,
      deployedBlockNumber: 0
    },
    itemConfig: {
      contractAddress: ArbOneItemsContractAddress,
      contractConstants: Constants.ITEM,
      deployedBlockNumber: 0
    },
    itemSetsConfig: {
      contractAddress: ArbOneItemSetsContractAddress,
      contractConstants: Constants.ITEMSETS,
      deployedBlockNumber: 0
    },
    craftingConfig: {
      contractAddress: ArbOneCraftingContractAddress,
      contractConstants: Constants.CRAFTING,
      deployedBlockNumber: 0
    },
    characterConfig: {
      citizenConfig: {
        contractAddress: ArbOneCitizenContractAddress,
        contractConstants: Constants.CITIZENSV1,
        deployedBlockNumber: 0
      },
      mercenaryConfig: {
        contractAddress: ArbOneMercenariesContractAddress,
        contractConstants: Constants.MERCENARIESV1,
        deployedBlockNumber: 0
      },
      mintMercenaryConfig: {
        contractAddress: ArbOneMintMercenariesContractAddress,
        contractConstants: Constants.MINTMERCENARIESV1,
        deployedBlockNumber: 0,
        claimMerkle: mercClaims
      },
      statManagerConfig: {
        contractAddress: ArbOneStatManagerContractAddress,
        contractConstants: Constants.STATMANAGERV1,
        deployedBlockNumber: 0
      },
      boostPayConfig: {
        contractAddress: ArbOneArbProxyContractAddress,
        assetConstants: Constants.ARB
      },
      employmentConfigs: [
        {
          jobType: JobType.Farmer,
          contractAddress: ArbOneCornContractAddress,
          contractContants: Constants.CORNv2,
          jobHelp: Constants.FARMER_EMPLOY_HELP
        },
        {
          jobType: JobType.Explorer,
          contractAddress: ArbOneMercenariesContractAddress,
          contractContants: Constants.MERCENARIESV1,
          jobHelp: Constants.EXPLORER_QUEST_EMPLOY_HELP
        }
      ]
    },
    questConfigs: [
      {
        questPayConfig: {
          contractAddress: ArbOneCornContractAddress,
          assetConstants: Constants.CORNv2
        },
        questType: QuestType.ScavangeHunt,
        difficultyQuestIndexes: [3, 0],
        contractAddress: ArbOneQuestsContractAddress,
        contractConstants: Constants.QUESTSV1,
        deployedBlockNumber: 0
      },
      {
        questPayConfig: {
          contractAddress: ArbOneCornContractAddress,
          assetConstants: Constants.CORNv2
        },
        questType: QuestType.Still,
        difficultyQuestIndexes: [7, 2],
        contractAddress: ArbOneQuestsContractAddress,
        contractConstants: Constants.QUESTSV1,
        deployedBlockNumber: 0
      },
      {
        questPayConfig: {
          contractAddress: ArbOneCornContractAddress,
          assetConstants: Constants.CORNv2
        },
        questType: QuestType.Mine,
        difficultyQuestIndexes: [6, 1],
        contractAddress: ArbOneQuestsContractAddress,
        contractConstants: Constants.QUESTSV1,
        deployedBlockNumber: 0
      },
      {
        questPayConfig: {
          contractAddress: ArbOneCornContractAddress,
          assetConstants: Constants.CORNv2
        },
        questType: QuestType.Quarry,
        difficultyQuestIndexes: [8, 4],
        contractAddress: ArbOneQuestsContractAddress,
        contractConstants: Constants.QUESTSV1,
        deployedBlockNumber: 0
      },
      {
        questPayConfig: {
          contractAddress: ArbOneCornContractAddress,
          assetConstants: Constants.CORNv2
        },
        questType: QuestType.DarkForest,
        difficultyQuestIndexes: [9, 5],
        contractAddress: ArbOneQuestsContractAddress,
        contractConstants: Constants.QUESTSV1,
        deployedBlockNumber: 0
      }
    ],
    contracts: {},
    tokens: {},
    liquidityConfigs: [
      {
        area: 'Land Bank',
        token1: TokenId.ETH,
        token1Icon: ethIcon,
        token2: TokenId.LAND,
        token2Icon: Constants.LAND.icon,
        pool: PoolId.Sushiswap,
        poolType: PoolType.Pair,
        poolReserveIndex: 1,
        poolIcon: SushiswapIcon,
        poolId: 0, // Pool ID for LAND-ETH
        lpToken: TokenId.SLP,
        poolUrl: 'https://arbitrum.sushi.com/add/0x3CD1833Ce959E087D0eF0Cb45ed06BffE60F23Ba/ETH', // Add LAND-ETH liquidity
        poolContractAddress: '0x629aa19df31882fac22d7f845ee8053c137a1741', // LAND-ETH SLP https://arbiscan.io/address/0x629aa19df31882fac22d7f845ee8053c137a1741
        poolContractAbi: UniswapPoolV2.abi,
        liquidityContractAddress: '0x8e8fE0aCDd67E1291a504E7290ecDCB7578EDa69', // StakingPools contract https://arbiscan.io/address/0x8e8fE0aCDd67E1291a504E7290ecDCB7578EDa69
        liquidityContractAbi: StakingPoolV2.abi,
        liquidityType: LiquidityAssetType.StakingV2,
        rewardToken: TokenId.LAND,
        rewardTokenIcon: Constants.LAND.icon,
        expired: false
      },
      {
        area: 'Farmers Market',
        token1: TokenId.ETH,
        token1Icon: ethIcon,
        token2: TokenId.CORN,
        token2Icon: Constants.CORNv2.icon,
        pool: PoolId.Sushiswap,
        poolType: PoolType.Pair,
        poolReserveIndex: 0,
        poolIcon: SushiswapIcon,
        poolId: 1, // Pool ID for CORN-ETH
        lpToken: TokenId.SLP,
        poolUrl: 'https://arbitrum.sushi.com/add/0xFcc0351f3a1ff72409Df66a7589c1F9efBf53386/ETH', // Add CORN-ETH liquidity
        poolContractAddress: ArbOneCornEthPoolContractAddress,
        poolContractAbi: UniswapPoolV2.abi,
        liquidityContractAddress: '0x8e8fE0aCDd67E1291a504E7290ecDCB7578EDa69', // StakingPools contract https://arbiscan.io/address/0x8e8fE0aCDd67E1291a504E7290ecDCB7578EDa69
        liquidityContractAbi: StakingPoolV2.abi,
        liquidityType: LiquidityAssetType.StakingV2,
        rewardToken: TokenId.LAND,
        rewardTokenIcon: Constants.LAND.icon,
        expired: false
      },
      {
        area: 'Farmers Market',
        token1: TokenId.LAND,
        token1Icon: Constants.LAND.icon,
        token2: TokenId.CORN,
        token2Icon: Constants.CORNv2.icon,
        pool: PoolId.Sushiswap,
        poolType: PoolType.Pair,
        poolReserveIndex: 0,
        poolIcon: SushiswapIcon,
        poolId: 3, // Pool ID for CORN-LAND
        lpToken: TokenId.SLP, // https://arbitrum.sushi.com/swap?&outputCurrency=0xFcc0351f3a1ff72409Df66a7589c1F9efBf53386&inputCurrency=0x3CD1833Ce959E087D0eF0Cb45ed06BffE60F23Ba
        poolUrl:
          'https://arbitrum.sushi.com/add/0x3CD1833Ce959E087D0eF0Cb45ed06BffE60F23Ba/0xFcc0351f3a1ff72409Df66a7589c1F9efBf53386', // Add LAND-CORN liquidity
        poolContractAddress: '0x5e4da2a2e528b6dc2c0d12e3bd2328c0e11764cd', // CORN-LAND SLP https://arbiscan.io/address/0x5e4da2a2e528b6dc2c0d12e3bd2328c0e11764cd
        poolContractAbi: UniswapPoolV2.abi,
        liquidityContractAddress: '0x8e8fE0aCDd67E1291a504E7290ecDCB7578EDa69', // StakingPools contract https://arbiscan.io/address/0x8e8fE0aCDd67E1291a504E7290ecDCB7578EDa69
        liquidityContractAbi: StakingPoolV2.abi,
        liquidityType: LiquidityAssetType.StakingV2,
        rewardToken: TokenId.LAND,
        rewardTokenIcon: Constants.LAND.icon,
        expired: false
      },
      {
        area: 'Grain Silo',
        token1: TokenId.CORN,
        token1Icon: Constants.CORNv2.icon,
        token2: null,
        token2Icon: null,
        pool: PoolId.Farmland,
        poolType: PoolType.Single,
        poolReserveIndex: null,
        poolIcon: null,
        poolId: 2, // Pool ID for CORN
        lpToken: TokenId.CORN,
        poolUrl: `https://arbitrum.sushi.com/swap?&outputCurrency=${ArbOneCornContractAddress}`,
        poolContractAddress: ArbOneCornContractAddress,
        poolContractAbi: cropV2.abi,
        liquidityContractAddress: '0x8e8fE0aCDd67E1291a504E7290ecDCB7578EDa69', // StakingPools contract https://arbiscan.io/address/0x8e8fE0aCDd67E1291a504E7290ecDCB7578EDa69
        liquidityContractAbi: StakingPoolV2.abi,
        liquidityType: LiquidityAssetType.StakingV2,
        rewardToken: TokenId.LAND,
        rewardTokenIcon: Constants.LAND.icon,
        expired: false
      }
    ],
    gameConfigs: [
      {
        gameIcon: null,
        gameType: GameType.HarvestLotto,
        token: TokenId.CORN,
        tokenIcon: Constants.CORNv2.icon,
        tokenUrl: `https://arbitrum.sushi.com/swap?&outputCurrency=${ArbOneCornContractAddress}`,
        tokenContractAddress: ArbOneCornContractAddress,
        tokenContractAbi: cropV2.abi,
        gameContractAddress: '0x05A164bF2A2172770B8da2175b469fe537578307', //  https://.arbiscan.io/address/0x05A164bF2A2172770B8da2175b469fe537578307#readContract
        gameContractAbi: LottoV1.abi,
        rewardToken: TokenId.CORN,
        rewardTokenIcon: Constants.CORNv2.icon,
        expired: false
      },
      {
        gameIcon: null,
        gameType: GameType.ChickenFeed,
        token: TokenId.CORN,
        tokenIcon: Constants.CORNv2.icon,
        tokenUrl: `https://arbitrum.sushi.com/swap?&outputCurrency=${ArbOneCornContractAddress}`,
        tokenContractAddress: ArbOneCornContractAddress,
        tokenContractAbi: cropV2.abi,
        gameContractAddress: '0x872cb42AEce6495173A9e25FDe69CD0F49106C58', //  https://.arbiscan.io/address/0x872cb42AEce6495173A9e25FDe69CD0F49106C58#readContract
        gameContractAbi: ChickenFeedV1.abi,
        rewardToken: TokenId.CORN,
        rewardTokenIcon: Constants.CORNv2.icon,
        expired: false
      },
      {
        gameIcon: null,
        gameType: GameType.SuperDraw,
        token: TokenId.CORN,
        tokenIcon: Constants.CORNv2.icon,
        tokenUrl: `https://arbitrum.sushi.com/swap?&outputCurrency=${ArbOneCornContractAddress}`,
        tokenContractAddress: ArbOneCornContractAddress,
        tokenContractAbi: cropV2.abi,
        gameContractAddress: '0x95184a384BD13c17704640dD9dd5af71B9f48cF4', //  https://.arbiscan.io/address/0x95184a384BD13c17704640dD9dd5af71B9f48cF4#readContract
        gameContractAbi: SuperdrawV1.abi,
        rewardToken: TokenId.CORN,
        rewardTokenIcon: Constants.CORNv2.icon,
        expired: false
      }
    ],
    claims: [
      // {
      //   name: 'Land-Drop',
      //   contractAbi: distributorContract.abi,
      //   claimMerkle: claimLandArbOne,
      //   contractAddress: '0x5512F1b5e0Ab885D38CB190C97725375cE2Fa402', // https://arbiscan.io/address/0x5512F1b5e0Ab885D38CB190C97725375cE2Fa402
      //   assetConstants: Assets.LAND
      // }
    ],
    rewardConfig: {
      name: 'Reward-Pack',
      contractAbi: packsV1.abi,
      claimMerkle: welcomePacksClaims,
      contractAddress: ArbOnePacksContractAddress,
      deployedBlockNumber: 0,
      mintPayConfig: {
        contractAddress: ArbOneCornContractAddress,
        assetConstants: Constants.CORNv2
      }
    },
    isL2: true,
    isTestnet: false,
    hasCollectibles: true,
    providerConfig: {
      infuraKey: '22cd52c0e08642349a11e938e78b3e19',
      etherscanKey: 'GPNPEWCTND2YACQGJU9J95DB628NQTMHVI',
      alchemyKey: 'oM9hcVu1u2JbcEVATMU_aO5UJaibsgrU'
    },
    graphAPIExchangeEndpoint: 'https://api.thegraph.com/subgraphs/name/sushiswap/arbitrum-exchange'
  };

  static readonly GOERLI: INetworkConfig = {
    name: 'arbitrum-goerli',
    chainId: 421613,
    blocktime: 12,
    icon: arbitrum_icon,
    loadPrices: false,
    displayChainName: 'Arbitrum Goerli',
    fullDisplayChainName: 'Arbitrum Testnet Goerli',
    nativeCurrencyName: 'Arbitrum Goerli Ether',
    nativeCurrencySymbol: 'AGETH',
    rpcUrls: [
      'https://goerli-rollup.arbitrum.io/rpc',
      'https://arb-goerli.g.alchemy.com/v2/KulQqCmS3WFJD0a87zg1j7tvUEfBUBbF',
      'https://rpc.ankr.com/arbitrum'
    ],
    blockExplorerUrls: ['https://testnet.arbiscan.io/'],
    dexBaseUrl: 'https://app.uniswap.org/#/swap?outputCurrency=',
    wethContractAddres: '',
    usdcContractAddress: '',
    assets: new Map<AssetId, IAssetConfig>([
      [
        TokenId.LAND,
        {
          contractAddress: GoerliLandContractAddress,
          assetConstants: Constants.LAND
        }
      ],
      [
        TokenId.CORN,
        {
          contractAddress: GoerliCornContractAddress,
          assetConstants: Constants.CORNv2
        }
      ]
    ]),
    boxConfig: {
      contractAddress: GoerliBoxContractAddress,
      contractConstants: Constants.BOX,
      deployedBlockNumber: 0
    },
    itemConfig: {
      contractAddress: GoerliItemsContractAddress,
      contractConstants: Constants.ITEM,
      deployedBlockNumber: 0
    },
    itemSetsConfig: {
      contractAddress: GoerliItemSetsContractAddress,
      contractConstants: Constants.ITEMSETS,
      deployedBlockNumber: 0
    },
    craftingConfig: {
      contractAddress: GoerliCraftingContractAddress,
      contractConstants: Constants.CRAFTING,
      deployedBlockNumber: 0
    },
    characterConfig: {
      citizenConfig: {
        contractAddress: GoerliCitizenContractAddress,
        contractConstants: Constants.CITIZENSV1,
        deployedBlockNumber: 0
      },
      mercenaryConfig: {
        contractAddress: GoerliMercenariesContractAddress,
        contractConstants: Constants.MERCENARIESV1,
        deployedBlockNumber: 0
      },
      mintMercenaryConfig: {
        contractAddress: GoerliMintMercenariesContractAddress,
        contractConstants: Constants.MINTMERCENARIESV1,
        deployedBlockNumber: 0,
        claimMerkle: mercClaims
      },
      statManagerConfig: {
        contractAddress: GoerliStatManagerContractAddress,
        contractConstants: Constants.STATMANAGERV1,
        deployedBlockNumber: 0
      },
      boostPayConfig: {
        contractAddress: GoerliCornContractAddress,
        assetConstants: Constants.CORNv2
      },
      employmentConfigs: [
        {
          jobType: JobType.Farmer,
          contractAddress: GoerliCornContractAddress,
          contractContants: Constants.CORNv2,
          jobHelp: Constants.FARMER_EMPLOY_HELP
        },
        {
          jobType: JobType.Explorer,
          contractAddress: GoerliMercenariesContractAddress,
          contractContants: Constants.MERCENARIESV1,
          jobHelp: Constants.EXPLORER_QUEST_EMPLOY_HELP
        }
      ]
    },
    questConfigs: [
      {
        questPayConfig: {
          contractAddress: GoerliCornContractAddress,
          assetConstants: Constants.CORNv2
        },
        questType: QuestType.ScavangeHunt,
        difficultyQuestIndexes: [3, 0],
        contractAddress: GoerliQuestsContractAddress,
        contractConstants: Constants.QUESTSV1,
        deployedBlockNumber: 0
      },
      {
        questPayConfig: {
          contractAddress: GoerliCornContractAddress,
          assetConstants: Constants.CORNv2
        },
        questType: QuestType.Mine,
        difficultyQuestIndexes: [6, 1],
        contractAddress: GoerliQuestsContractAddress,
        contractConstants: Constants.QUESTSV1,
        deployedBlockNumber: 0
      },
      {
        questPayConfig: {
          contractAddress: GoerliCornContractAddress,
          assetConstants: Constants.CORNv2
        },
        questType: QuestType.Still,
        difficultyQuestIndexes: [7, 2],
        contractAddress: GoerliQuestsContractAddress,
        contractConstants: Constants.QUESTSV1,
        deployedBlockNumber: 0
      },
      {
        questPayConfig: {
          contractAddress: GoerliCornContractAddress,
          assetConstants: Constants.CORNv2
        },
        questType: QuestType.Quarry,
        difficultyQuestIndexes: [8, 4],
        contractAddress: GoerliQuestsContractAddress,
        contractConstants: Constants.QUESTSV1,
        deployedBlockNumber: 0
      },
      {
        questPayConfig: {
          contractAddress: GoerliCornContractAddress,
          assetConstants: Constants.CORNv2
        },
        questType: QuestType.DarkForest,
        difficultyQuestIndexes: [9, 5],
        contractAddress: GoerliQuestsContractAddress,
        contractConstants: Constants.QUESTSV1,
        deployedBlockNumber: 0
      }
    ],
    contracts: {},
    tokens: {},
    liquidityConfigs: [],
    gameConfigs: [],
    claims: [],
    rewardConfig: {
      name: 'Reward-Pack',
      contractAbi: packsV1.abi,
      claimMerkle: welcomePacksClaims,
      contractAddress: GoerliPacksContractAddress,
      deployedBlockNumber: 0,
      mintPayConfig: {
        contractAddress: GoerliCornContractAddress,
        assetConstants: Constants.CORNv2
      }
    },
    isL2: true,
    isTestnet: true,
    hasCollectibles: true,
    providerConfig: {
      infuraKey: '',
      etherscanKey: '',
      alchemyKey: 'KulQqCmS3WFJD0a87zg1j7tvUEfBUBbF'
    },
    graphAPIExchangeEndpoint: null
  };
}
