import { BigNumber, ethers } from 'ethers';
import { ILiquidityConfig, PoolType } from '../models/LiquidityConfig';
import { EthereumService } from './EthereumService';
import { MessagingService } from './MessagingService';
import { Utils } from './Utils';
import { ILiquidityAssetService } from './Interfaces';
import { TokenId } from '../models/Enums';

export class StakingAssetServiceV2 implements ILiquidityAssetService {
  walletAddress?: string;

  poolContract?: ethers.Contract | any;
  stakingContract?: ethers.Contract | any;

  lptBalance: string | 0 = 0;
  approvedLptBalance: string | 0 = 0;
  unapprovedLptBalance: string | 0 = 0;
  liquidityBalance: string | 0 = 0;

  hasReward = true;
  currentReward: string | 0 = 0;
  upcomingReward: string | 0 = 0;
  isInstantReward = true;

  totalLiquidity: string | 0 = 0;
  tokenSupply: string | 0 = 0;
  apy: number | 0 = 0;

  actionDisplayName = 'Stake';
  undoActionDisplayName = 'Unstake';

  constructor(
    public config: ILiquidityConfig,
    private ethereumService: EthereumService,
    private messagingService: MessagingService
  ) {
    if (config.poolId === null) {
      throw new Error('Liquidity StakingV2 requires poolId');
    }

    this.walletAddress = this.ethereumService!.walletData.walletAddress;
    this.poolContract = new ethers.Contract(
      this.config.poolContractAddress,
      this.config.poolContractAbi,
      ethereumService.signer
    );
    this.stakingContract = new ethers.Contract(
      this.config.liquidityContractAddress,
      this.config.liquidityContractAbi,
      ethereumService.signer
    );

    this.setupEventHandlers();
  }

  update(): Promise<ILiquidityAssetService> {
    var work = [
      this.poolContract.balanceOf(this.walletAddress),
      this.poolContract.allowance(this.walletAddress, this.config.liquidityContractAddress),
      this.stakingContract.getStakeTotalDeposited(this.walletAddress, this.config.poolId),
      this.stakingContract.getStakeTotalUnclaimed(this.walletAddress, this.config.poolId),
      this.stakingContract.getPoolTotalDeposited(this.config.poolId),
      this.stakingContract.getPoolRewardRate(this.config.poolId),
      this.poolContract.totalSupply()
    ];

    if (this.config.poolType === PoolType.Pair) {
      work.push(this.poolContract.getReserves());
    }

    return Promise.all(work).then(response => {
      this.lptBalance = Utils.formatUnits(response[0], 18);
      this.approvedLptBalance = Utils.formatUnits(response[1], 18);
      this.unapprovedLptBalance = Utils.formatUnits(response[0].sub(response[1]), 18);
      this.liquidityBalance = Utils.formatUnits(response[2], 18);
      this.currentReward = Utils.formatUnits(response[3], 18);
      this.upcomingReward = Utils.formatUnits(response[3], 18);
      this.totalLiquidity = Utils.formatUnits(response[4], 18);
      this.tokenSupply = Utils.formatUnits(response[6], 18);

      const landPerBlock = Utils.formatUnits(response[5], 18);
      const landPerDay = (+landPerBlock * (24 * 60 * 60)) / 13.5;
      const stakedTokens = +this.totalLiquidity;
      const LPTokens = +this.tokenSupply;
      const landPerDayPerToken = landPerDay / stakedTokens;
      const wethPrice = this.ethereumService.pricesService!.prices.tokenUsd.get(TokenId.WETH);
      const landPrice = this.ethereumService.pricesService!.prices.tokenUsd.get(TokenId.LAND);
      const dailyReturn = landPerDayPerToken * landPrice;

      // This is for the single sided staking - CORN
      var liquidity = +this.totalLiquidity;
      var costPerToken = this.ethereumService.pricesService!.prices.tokenUsd.get(TokenId.CORN); // liquidity / stakedTokens;

      // This is for LP staking
      if (this.config.poolType === PoolType.Pair) {
        if (this.config.token1 === TokenId.ETH) {
          liquidity = +Utils.formatUnits(response[7][this.config.poolReserveIndex!], 18) * wethPrice * 2;
        } else {
          liquidity = +Utils.formatUnits(response[7][this.config.poolReserveIndex!], 18) * landPrice * 2;
        }
        costPerToken = liquidity / LPTokens;
      }

      this.apy = ((dailyReturn * 365) / costPerToken) * 100;

      return this;
    });
  }

  approve() {
    var amount = Utils.parseUnits('1000000000');

    this.poolContract.approve(this.config.liquidityContractAddress, amount).then(
      (approveResponse: any) =>
        this.messagingService.events.next({
          message: `Approval of ${this.config.lpToken} is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error approving ${this.config.lpToken} : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  add(amount: any) {
    var sanitizedAmount = Utils.sanitizeParseUnits(amount);

    this.stakingContract.deposit(BigNumber.from(this.config.poolId), sanitizedAmount).then(
      (stakeResponse: any) =>
        this.messagingService.events.next({
          message: `Staking of ${this.config.lpToken} is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error staking ${this.config.lpToken} : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  remove(amount: any) {
    var sanitizedAmount = Utils.sanitizeParseUnits(amount);

    this.stakingContract.withdraw(BigNumber.from(this.config.poolId), sanitizedAmount).then(
      (withdrawResponse: any) =>
        this.messagingService.events.next({
          message: `Unstaking of ${this.config.lpToken} is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error unstaking ${this.config.lpToken} : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  claim() {
    this.stakingContract.claim(BigNumber.from(this.config.poolId)).then(
      (claimResponse: any) =>
        this.messagingService.events.next({
          message: `Claim of ${this.currentReward} staking reward is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error claiming staking reward : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  exit() {
    this.stakingContract.exit(BigNumber.from(this.config.poolId)).then(
      (exitResponse: any) =>
        this.messagingService.events.next({
          message: `Unstaking of ${this.config.lpToken} and reward claim is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error unstaking ${this.config.lpToken} : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  removeEventHandlers() {
    this.poolContract?.removeAllListeners();
    this.stakingContract?.removeAllListeners();
  }

  setupEventHandlers() {
    this.removeEventHandlers();

    var update = (message: string) =>
      this.update().then(() => {
        this.messagingService.events.next({ message: message });
      });

    if (this.poolContract) {
      this.poolContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('Approval(address,address,uint256)')]
        },
        (owner: any, spender: any, amount: any) => {
          if (owner === this.ethereumService.walletData.walletAddress) {
            update(`Approved ${this.config.lpToken}`);
          }
        }
      );
    }

    if (this.stakingContract) {
      this.stakingContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('TokensDeposited(address,uint256,uint256)')]
        },
        (user: any, poolId: any, amount: any) => {
          if (user === this.ethereumService.walletData.walletAddress && poolId.eq(BigNumber.from(this.config.poolId))) {
            update(`${Utils.formatUnits(amount, 18)} ${this.config.lpToken} staked`);
          }
        }
      );

      this.stakingContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('TokensWithdrawn(address,uint256,uint256)')]
        },
        (user: any, poolId: any, amount: any) => {
          if (user === this.ethereumService.walletData.walletAddress && poolId.eq(BigNumber.from(this.config.poolId))) {
            update(`${Utils.formatUnits(amount, 18)} ${this.config.lpToken} unstaked`);
          }
        }
      );

      this.stakingContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('TokensClaimed(address,uint256,uint256)')]
        },
        (user: any, poolId: any, amount: any) => {
          if (user === this.ethereumService.walletData.walletAddress && poolId.eq(BigNumber.from(this.config.poolId))) {
            update(`${Utils.formatUnits(amount, 18)} ${this.config.lpToken} reward collected`);
          }
        }
      );

      this.ethereumService.provider!.on('block', currentBlock => {
        this.update().then(() => {});
      });
    }
  }
}
