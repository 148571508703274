import { Mutex } from 'async-mutex';
import { BigNumber, ethers } from 'ethers';
import { EthereumService } from './EthereumService';

export class ActiveQuestEventCacheService {
  private mutex = new Mutex();
  private readonly lastActiveQuestsKey: string;
  private readonly blockKey: string;

  public lastActiveQuestsMap: Map<string, number> = new Map<string, number>();

  constructor(private questContract: ethers.Contract, protected ethereumService: EthereumService) {
    const prefix = `${ethereumService.networkManager!.network!.chainId}.${questContract.address}.${
      ethereumService.walletData.walletAddress
    }`;
    this.lastActiveQuestsKey = `${prefix}.LastActiveQuests`;
    this.blockKey = `${prefix}.ActiveQuestsBlock`;
  }

  async update() {
    await this.mutex.runExclusive(async () => {
      await this.refreshEvents();
    });
  }

  private async refreshEvents() {
    var cachedLastActiveQuestMap = localStorage.getItem(this.lastActiveQuestsKey);
    var lastBlockCached = localStorage.getItem(this.blockKey) ?? 0;

    var blockNumber = await this.ethereumService.provider!.getBlockNumber();

    if (cachedLastActiveQuestMap) {
      this.lastActiveQuestsMap = new Map(Object.entries(JSON.parse(cachedLastActiveQuestMap)));
    }

    if (!cachedLastActiveQuestMap || blockNumber > +lastBlockCached) {
      var latestEvents = await this.loadLatestEvents(+lastBlockCached, blockNumber);

      if (latestEvents.length > 0) {
        latestEvents.forEach((i: any) => {
          this.lastActiveQuestsMap.set(
            BigNumber.from(i.args.tokenID).toString(),
            BigNumber.from(i.args.quest).toNumber()
          );
        });

        localStorage.setItem(this.lastActiveQuestsKey, JSON.stringify(Object.fromEntries(this.lastActiveQuestsMap)));
      }
    }

    localStorage.setItem(this.blockKey, blockNumber.toString());
  }

  private async loadLatestEvents(fromBlock: number, toBlock: number): Promise<any[]> {
    var events: any[] = [];
    try {
      events = await this.questContract!.queryFilter(
        this.questContract!.filters.QuestStarted(this.ethereumService.walletData.walletAddress),
        fromBlock,
        toBlock
      );
    } catch (e) {
      console.error(e);
    }

    return events;
  }
}
