import React from 'react';
import { TokenId } from '../models/Enums';
import { Utils } from './Utils';

export interface IPrices {
  gas: string | number;
  tokenUsd: Map<TokenId, any>;
}

export interface IPriceService {
  prices: IPrices;
  update(): Promise<any>;
  renderPrice(assetId: TokenId, amount: any): any;
}

export class PricesServiceBase implements IPriceService {
  prices: IPrices = {
    gas: 0,
    tokenUsd: new Map<TokenId, string | number>([])
  };

  update(): Promise<any> {
    return new Promise(() => []);
  }

  renderPrice(assetId: TokenId, amount: number): any {
    return (
      <React.Fragment>
        {amount > 0 && !this.prices.tokenUsd.get(assetId) && (
          <span className="post">
            <button className="button is-white is-loading"></button>
          </span>
        )}
        {amount > 0 && this.prices.tokenUsd.get(assetId) && (
          <span className="post"> ${Utils.toFixed(this.prices.tokenUsd.get(assetId) * amount, 2)}</span>
        )}
      </React.Fragment>
    );
  }
}

export class DefaultPricesService extends PricesServiceBase {
  async update(): Promise<any> {
    this.prices.tokenUsd.set(TokenId.LAND, 1);
    this.prices.tokenUsd.set(TokenId.CORN, 1);
  }
}
