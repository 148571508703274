import { BigNumber } from '@ethersproject/bignumber';
import { Component } from 'react';
import { CharacterStat, ICharacterDetails } from '../../models/CharacterDetails';
import { Utils } from '../../services/Utils';
import { CitizenService } from '../../services/CitizenService';

export interface ICitizenBoostProps {
  characterService: CitizenService;
  nft: ICharacterDetails;
  close: any;
}

export interface ICitizenBoostState {
  boostingStat: CharacterStat;
  currentAmount: number;
  boostAmount: number;
}

export class CitizenBoost extends Component<ICitizenBoostProps, ICitizenBoostState> {
  constructor(props: any) {
    super(props);

    this.state = {
      boostingStat: CharacterStat.Stamina,
      currentAmount: 0,
      boostAmount: 0
    };

    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.getCurrentAmount = this.getCurrentAmount.bind(this);
    this.handleBoostingChange = this.handleBoostingChange.bind(this);
    this.boost = this.boost.bind(this);
    this.getBoostCost = this.getBoostCost.bind(this);
    this.close = this.close.bind(this);
  }

  handleAmountChange(amount: number) {
    this.setState({ boostAmount: this.state.boostAmount + amount });
  }

  getCurrentAmount(stat: CharacterStat): number {
    return this.props.nft.stats.find(s => s.name === CharacterStat[stat])!.value;
  }

  handleBoostingChange(stat: CharacterStat) {
    this.setState({
      boostingStat: stat,
      currentAmount: this.getCurrentAmount(stat),
      boostAmount: 0
    });
  }

  boost(nft: ICharacterDetails): void {
    if (this.state.boostingStat && this.state.boostAmount) {
      this.props.characterService.boost(this.props.nft, this.state.boostingStat, this.state.boostAmount);
      this.close();
    }
  }

  getBoostCost(): string {
    return Utils.formatUnits(this.props.characterService.boostPrice?.mul(BigNumber.from(this.state.boostAmount)));
  }

  close() {
    this.props.close();
  }

  render() {
    return (
      <div className="is-active modal">
        <div className="modal-background" onClick={this.close}></div>
        <div className="modal-content character-boost">
          <div className="card">
            <button className="modal-close is-medium" aria-label="close" onClick={this.close}></button>

            <div className="is-flex is-justify-content-center character-boost-title">{this.props.nft.name}</div>
            <div className="is-flex is-justify-content-center mb-3">
              <span className={`mr-1 ${this.state.boostAmount > 0 ? 'boosting' : ''}`}>
                {this.props.nft.statBoostsAvailable - this.state.boostAmount}
              </span>
              boosts remaining
            </div>
            <ul>
              {this.renderStatListItem(CharacterStat.Stamina)}
              {this.renderStatListItem(CharacterStat.Strength)}
              {this.renderStatListItem(CharacterStat.Speed)}
              {this.renderStatListItem(CharacterStat.Courage)}
              {this.renderStatListItem(CharacterStat.Intelligence)}
            </ul>

            <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center mt-5">
              <p>
                <span className="mr-1">Cost {this.getBoostCost()}</span>
                {this.props.characterService.characterConfig.boostPayConfig!.assetConstants!.symbol} (+ gas)
              </p>
              <p className="mr-1 is-size-7">
                <span className="mr-1">{Utils.toFixed(this.props.characterService.boostPaymentBalance!, 2)}</span>
                {this.props.characterService.characterConfig.boostPayConfig!.assetConstants!.symbol} in wallet
              </p>
            </div>

            <div className="is-flex is-justify-content-center mt-4">
              <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center character-boost-stats">
                <ul className="action-control">
                  <li className="action-form">
                    <div className="field has-addons">
                      <div className="control">
                        <button
                          className="button minus-button is-action"
                          disabled={this.state.boostAmount <= 0}
                          onClick={() => this.handleAmountChange(-1)}>
                          <span className="icon">
                            <i className="fas fa-minus"></i>
                          </span>
                        </button>
                      </div>
                      <div className="control">
                        <input className="input is-action" type="text" disabled={true} value={this.state.boostAmount} />
                      </div>
                      <div className="control">
                        <button
                          className="button plus-button is-action"
                          disabled={
                            this.state.boostAmount >= this.props.nft.statBoostsAvailable ||
                            this.state.currentAmount + this.state.boostAmount >= 99
                          }
                          onClick={() => this.handleAmountChange(+1)}>
                          <span className="icon">
                            <i className="fas fa-plus"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  </li>
                  <li>
                    <button
                      className="button boost-button is-action is-fullwidth"
                      onClick={() => this.boost(this.props.nft)}
                      disabled={this.state.boostAmount <= 0}>
                      Boost
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderStatListItem(stat: CharacterStat) {
    return (
      <CharacterStatListItem
        boostingStat={this.state.boostingStat}
        boostAmount={this.state.boostAmount}
        currentStat={stat}
        currentValue={this.getCurrentAmount(stat)}
        handleBoostingChange={this.handleBoostingChange}></CharacterStatListItem>
    );
  }
}

interface ICharacterStatListItemProps {
  boostingStat: CharacterStat;
  boostAmount: number;
  currentStat: CharacterStat;
  currentValue: number;
  handleBoostingChange: any;
}

class CharacterStatListItem extends Component<ICharacterStatListItemProps> {
  render() {
    return (
      <li className="is-flex is-justify-content-space-between character-stat-list-item">
        <button
          className={`button ${
            this.props.boostingStat === this.props.currentStat ? 'is-ghost-action' : 'is-inactive-action'
          }`}
          style={{ width: '100%' }}
          onClick={() => this.props.handleBoostingChange(this.props.currentStat)}>
          <span className="is-flex is-justify-content-space-between" style={{ width: '100%' }}>
            <span>{CharacterStat[this.props.currentStat]}</span>
            {this.props.boostingStat === this.props.currentStat && (
              <span className={this.props.boostAmount > 0 ? 'boosted' : ''}>
                {this.props.currentValue + this.props.boostAmount}
              </span>
            )}
            {this.props.boostingStat !== this.props.currentStat && <span>{this.props.currentValue}</span>}
          </span>
        </button>
      </li>
    );
  }
}
