import { useDraggable } from '@dnd-kit/core';
import { CSSProperties } from 'react';

export default function Draggable(props: any) {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: props.id,
    data: props.data
  });

  const style: CSSProperties = isDragging
    ? {
        position: 'absolute',
        transform: `translate3d(${transform?.x}px, ${transform?.y}px, 0)`,
        cursor: 'grabbing'
      }
    : {
        cursor: 'grab'
      };

  return (
    <span style={{ position: 'relative', zIndex: '1' }}>
      <span ref={setNodeRef} style={style} {...listeners} {...attributes}>
        {props.children}
      </span>
      {isDragging && <span style={{ display: 'none !important' }}>{props.children}</span>}
    </span>
  );
}
