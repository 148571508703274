import { ethers } from 'ethers';
import { hexlify, toUtf8Bytes, concat } from 'ethers/lib/utils';

export const HashZero = '0x0000000000000000000000000000000000000000000000000000000000000000';

export class Utils {
  static getContractErrorMessage(error: any) {
    if (error.error) {
      error = error.error;
    }
    var msg = error.reason ?? error.data?.message ?? error.message;
    msg = msg.replace('execution reverted: ', '');
    if (msg === 'user rejected transaction') msg = 'Transaction rejected';
    return msg;
  }

  static isEthAmountValid(value: string) {
    try {
      ethers.FixedNumber.fromString(value, 'fixed128x18');
      return true;
    } catch (e) {}
    return false;
  }

  static formatUnits(value: any, units?: any): string {
    return value ? ethers.utils.formatUnits(value, units !== null ? units : 'ether') : '0';
  }

  static parseUnits(value: any, units?: any): ethers.BigNumber {
    return value ? ethers.utils.parseUnits(value, units !== null ? units : 'ether') : ethers.BigNumber.from(0);
  }

  static sanitizeParseUnits(value: any, units?: any): ethers.BigNumber {
    var sanitizedValue = Utils.sanitizeUnits(value, units);
    return ethers.utils.parseUnits(sanitizedValue, units !== null ? units : 'ether');
  }

  static sanitizeUnits(value: any, units?: any): string {
    if (!value || isNaN(value)) {
      value = '0';
    } else if (typeof value === 'number') {
      value = ethers.utils.formatUnits(value, units !== null ? units : 'ether');
    }
    return value;
  }

  static toTitleCase(text: string) {
    return text
      .toLowerCase()
      .split(' ')
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }

  static toRarityDisplay(rarity: number) {
    switch (rarity) {
      case 1:
        return 'uncommon';
      case 2:
        return 'rare';
      case 3:
        return 'epic';
      case 4:
        return 'legendary';
      case 5:
        return 'unique';
      default:
        return 'common';
    }
  }

  static toFixed(amount: any, decimals: any): string {
    return !amount || isNaN(amount)
      ? Number.parseFloat('0').toFixed(decimals)
      : Number.parseFloat(amount).toFixed(decimals);
  }

  static getKeyValue =
    <T extends object, U extends keyof T>(obj: T) =>
    (key: U) =>
      obj[key];

  static fileExists(image_url: string) {
    var http = new XMLHttpRequest();

    http.open('HEAD', image_url, false);
    http.send();

    return http.status !== 404;
  }

  static numberToShortDisplay(amount: number) {
    amount = +amount.toString().replace(/[^0-9.]/g, '');
    if (amount < 1000) {
      return amount;
    }
    let si = [
      { v: 1e3, s: 'K' },
      { v: 1e6, s: 'M' },
      { v: 1e9, s: 'B' }
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (amount >= si[index].v) {
        break;
      }
    }
    return (amount / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
  }

  static formatBytes32String(text: string): string {
    // Get the bytes
    const bytes = toUtf8Bytes(text);

    // Check we have room for null-termination
    if (bytes.length > 31) {
      throw new Error('bytes32 string must be less than 32 bytes');
    }

    // Zero-pad (implicitly null-terminates)
    return hexlify(concat([bytes, HashZero]).slice(0, 16));
  }

  static async forEachAsync<T>(items: T[], fn: PromiseFunction<T>) {
    await items.reduce(async (promise: Promise<any>, item: T) => {
      return await fn(item);
    }, Promise.resolve());
  }
}

type PromiseFunction<T> = (item: T) => Promise<any>;
