import { useDroppable } from '@dnd-kit/core';

export default function Droppable(props: any) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id
  });

  const style = {
    color: isOver ? 'green' : undefined
  };

  return (
    <div ref={setNodeRef} style={style}>
      {props.children}
    </div>
  );
}
