import { LiquidityAssetType } from './Enums';

export enum PoolType {
  Single = 'Single',
  Pair = 'Pair'
}

export interface ILiquidityConfig {
  area: string;
  pool: string;
  poolType: PoolType;
  poolReserveIndex: 0 | 1 | null;
  poolIcon: any | null;
  poolId: number | null;
  lpToken: string;
  token1: string;
  token1Icon: any;
  token2: string | null;
  token2Icon: any | null;
  poolUrl: string;
  poolContractAddress: string;
  poolContractAbi: any;
  liquidityContractAddress: string;
  liquidityContractAbi: any;
  liquidityType: LiquidityAssetType;
  rewardToken: string;
  rewardTokenIcon: any;
  expired: boolean;
}
