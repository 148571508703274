import React, { Component } from 'react';
// @ts-ignore
import { NavLink } from 'react-router-dom';
import { Utils } from '../../services/Utils';
import Flip from '../Common/Flip';
import { EthereumService } from '../../services/EthereumService';
import { TokenId } from '../../models/Enums';
import { ICropService } from './Services/CropServiceBase';
import { createCropService } from './Services/CropServiceFactory';
import { Subscription } from 'rxjs';
import { ContractsData, IContractsData } from '../../models/ContractsData';
import { AssetType } from '../../models/AssetConfig';

import { ICharacterDetails, newSampleCharacterDetails } from '../../models/CharacterDetails';
import { MessagingService } from '../../services/MessagingService';
import { CitizenService } from '../../services/CitizenService';

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/css/bundle';
import 'swiper/scss/navigation';

import { Character } from '../Characters/Character';
import '../Content.scss';
import './Farm.scss';
import './Farmers.scss';
import '../Characters/Characters.scss';
import '../Characters/CharacterAnim.scss';

import jc from '../../assets/avatars/unknown.png';

interface IFarmProps {
  messagingService: MessagingService;
  ethereumservice: EthereumService;
}

interface IFarmState {
  allocateLand: any;
  releaseLand: any;
  compostCropAmount: any;
  isReleasing: boolean;
  isAllocating: boolean;
  isComposting: boolean;
  contractData: IContractsData;
  isGlobalActiveStats: boolean;
  farmers?: null | ICharacterDetails[];
  isDummy: boolean;
  farmerBoostMax: number;
  zoomedFamer?: null | ICharacterDetails;
}

class Farm extends Component<IFarmProps, IFarmState> {
  cropServices?: ICropService[];
  cropService?: ICropService;
  subscription: Subscription;
  carousel?: any;
  farmerNftService?: CitizenService;

  unallocatedAmountBody: React.RefObject<HTMLDivElement>;
  allocatedAmountBody: React.RefObject<HTMLDivElement>;
  growingAmountBody: React.RefObject<HTMLDivElement>;
  harvestedAmountBody: React.RefObject<HTMLDivElement>;

  constructor(props: IFarmProps) {
    super(props);

    this.unallocatedAmountBody = React.createRef();
    this.allocatedAmountBody = React.createRef();
    this.growingAmountBody = React.createRef();
    this.harvestedAmountBody = React.createRef();

    this.state = {
      allocateLand: 0,
      releaseLand: 0,
      compostCropAmount: 0,
      isReleasing: false,
      isAllocating: false,
      isComposting: false,
      contractData: new ContractsData(),
      isGlobalActiveStats: false,
      farmers: null,
      isDummy: false,
      farmerBoostMax: 0,
      zoomedFamer: null
    };

    this.subscription = new Subscription();

    this.buyLand = this.buyLand.bind(this);
    this.buyCrop = this.buyCrop.bind(this);
    this.approveContract = this.approveContract.bind(this);
    this.handleAllocateLand = this.handleAllocateLand.bind(this);
    this.maxAllocateLand = this.maxAllocateLand.bind(this);
    this.allocateLand = this.allocateLand.bind(this);
    this.cancelAllocate = this.cancelAllocate.bind(this);
    this.confirmAllocate = this.confirmAllocate.bind(this);
    this.releaseLand = this.releaseLand.bind(this);
    this.cancelRelease = this.cancelRelease.bind(this);
    this.confirmRelease = this.confirmRelease.bind(this);
    this.harvestCrop = this.harvestCrop.bind(this);
    this.handleCompostCropAmount = this.handleCompostCropAmount.bind(this);
    this.maxCompostCropAmount = this.maxCompostCropAmount.bind(this);
    this.compostCrop = this.compostCrop.bind(this);
    this.cancelCompost = this.cancelCompost.bind(this);
    this.confirmCompost = this.confirmCompost.bind(this);
    this.preparingAllocate = this.preparingAllocate.bind(this);
    this.preparingRelease = this.preparingRelease.bind(this);
    this.preparingHarvest = this.preparingHarvest.bind(this);
    this.preparingCompost = this.preparingCompost.bind(this);
    this.toggleActiveStats = this.toggleActiveStats.bind(this);
    this.getMaxFarmBoost = this.getMaxFarmBoost.bind(this);
    this.setZoomedFarmer = this.setZoomedFarmer.bind(this);

    SwiperCore.use([Navigation]);
  }

  componentDidMount() {
    this.subscription.add(
      this.props.messagingService.networkEvents.subscribe((event: any) => {
        this.init();
      })
    );

    this.subscription.add(
      this.props.messagingService.events.subscribe((event: any) => {
        this.refresh();
      })
    );

    this.refresh();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  init() {
    if (
      this.props.ethereumservice.networkManager!.network &&
      this.props.ethereumservice.networkManager!.network!.assets.size > 0
    ) {
      this.cropServices = [];

      this.props.ethereumservice.networkManager!.network!.assets.forEach((asset, assetId) => {
        if (asset.assetConstants!.assetType === AssetType.Crop) {
          var cropService = createCropService(this.props.messagingService, this.props.ethereumservice, assetId, asset);
          cropService.update();
          this.cropServices!.push(cropService);
        }
      });

      if (this.cropServices.length > 0) {
        this.cropService = this.cropServices[0];
        this.cropService!.update();
        this.loadFarmerService();
        this.setState({ contractData: new ContractsData() });
        this.refresh();
      } else {
        if (this.props.ethereumservice.networkManager!.network.chainId === 42161) {
        } else {
          this.props.messagingService.errors.next({
            message: `Error: unable to load crop contract`,
            error: 'Unable to load crop contract'
          });
        }
      }
    }
  }

  async refresh() {
    if (!this.props.ethereumservice.walletData.isConnected) {
      return;
    }

    if (!this.cropService) {
      this.init();
    } else {
      this.cropService!.loadBalances().then(() => {
        this.setState({ contractData: this.cropService!.contracts });
      });

      if (this.farmerNftService) {
        var farmers = await this.cropService!.getCollectibles(this.farmerNftService!.citizenContract!);
        if (farmers.length > 0) {
          this.setState({ farmers: farmers, isDummy: false });
        }
      }

      if (!this.state.farmers || this.state.farmers!.length === 0) {
        this.setState({ farmers: [newSampleCharacterDetails('Jeremy Clarkson', jc, [], 0)], isDummy: true });
      }
    }
  }

  async loadFarmerService() {
    if (this.cropService != null && this.props.ethereumservice.networkManager!.network!.characterConfig != null) {
      this.farmerNftService = new CitizenService(
        this.props.ethereumservice.networkManager!.network!.characterConfig!,
        this.props.messagingService,
        this.props.ethereumservice
      );
    }
  }

  buyLand() {
    window.open(
      this.props.ethereumservice.networkManager!.network!.dexBaseUrl! +
        this.props.ethereumservice.networkManager!.network!.assets.get(TokenId.LAND)!.contractAddress,
      '_blank'
    );
  }

  buyCrop() {
    window.open(
      this.props.ethereumservice.networkManager!.network!.dexBaseUrl! +
        this.props.ethereumservice.networkManager!.network!.assets.get(this.cropService!.assetId)!.contractAddress,
      '_blank'
    );
  }

  approveContract() {
    this.cropService!.approveContract();
  }

  // Allocate Land
  allocateLand() {
    this.setState({ isAllocating: true });
    this.preparingAllocate(false);
  }

  cancelAllocate() {
    this.setState({ isAllocating: false });
  }

  handleAllocateLand(e: any) {
    this.setState({ allocateLand: e.target.value });
  }

  maxAllocateLand() {
    this.setState({ allocateLand: this.cropService!.contracts.landBalance });
  }

  confirmAllocate() {
    this.cropService!.allocateLand(this.state.allocateLand).then(() => {
      this.setState({ allocateLand: 0, isAllocating: false });
    });
  }

  // Release Land
  releaseLand() {
    this.setState({ isReleasing: true });
    this.preparingRelease(false);
  }

  cancelRelease() {
    this.setState({ isReleasing: false });
  }

  confirmRelease() {
    this.cropService!.releaseLand();
    this.setState({ isReleasing: false });
  }

  // Harvest
  harvestCrop() {
    this.cropService!.harvest();
  }

  // Compost
  compostCrop() {
    this.setState({ isComposting: true });
    this.preparingCompost(false);
  }

  cancelCompost() {
    this.setState({ isComposting: false });
  }

  handleCompostCropAmount(e: any) {
    this.setState({ compostCropAmount: e.target.value });
  }

  maxCompostCropAmount() {
    this.setState({ compostCropAmount: this.cropService!.contracts.cropBalance });
  }

  confirmCompost() {
    this.cropService!.compost(this.state.compostCropAmount).then(() => {
      this.setState({ compostCropAmount: 0, isComposting: false });
    });
  }

  // UI state
  preparingAllocate(isPreparing: boolean) {
    if (isPreparing) this.unallocatedAmountBody.current?.classList.add('is-active');
    else this.unallocatedAmountBody.current?.classList.remove('is-active');
  }

  preparingRelease(isPreparing: boolean) {
    if (isPreparing) this.allocatedAmountBody.current?.classList.add('is-active');
    else this.allocatedAmountBody.current?.classList.remove('is-active');
  }

  preparingHarvest(isPreparing: boolean) {
    if (isPreparing) this.growingAmountBody.current?.classList.add('is-active');
    else this.growingAmountBody.current?.classList.remove('is-active');
  }

  preparingCompost(isPreparing: boolean) {
    if (isPreparing) this.harvestedAmountBody.current?.classList.add('is-active');
    else this.harvestedAmountBody.current?.classList.remove('is-active');
  }

  toggleActiveStats() {
    this.setState({ isGlobalActiveStats: !this.state.isGlobalActiveStats });
  }

  getMaxFarmBoost(): number {
    const maxFarmerBoost =
      !this.state.farmers || !this.state.farmers.length || this.state.isDummy
        ? 0
        : this.state.farmers!.reduce(
            (p: number, f: ICharacterDetails) => p + f.stats.find(s => s.name === 'Stamina')!.value / 100 + 1,
            0
          );
    var maxMaturityBoost = +this.cropService?.contracts.maxMaturityBoost ?? 3;
    const farmBoost = maxMaturityBoost + maxFarmerBoost;
    return Math.min(10, farmBoost);
  }

  setZoomedFarmer(farmer: null | ICharacterDetails) {
    this.setState({ zoomedFamer: farmer });
  }

  removeFarmer(character: ICharacterDetails) {
    this.cropService!.releaseCollectible(character);
    this.setState({ zoomedFamer: null });
  }

  render() {
    return (
      <section id="farm" className="is-vcentered">
        {this.renderZoomedFarmer()}
        <div className="container">
          {!this.cropService && (
            <span
              className="column meme is-half has-text-centered mt-4"
              style={{ cursor: 'pointer' }}
              onClick={() => this.props.ethereumservice.connectWallet()}>
              <h3 className="sub-title py-3">Connect Wallet</h3>
            </span>
          )}
          {this.cropService && (
            <div className="tile is-ancestor mx-3">
              <div className="tile is-vertical is-12">
                <div className="tile">
                  <div className="tile is-6 is-parent">
                    <div className="is-flex is-flex-direction-column is-fullwidth">
                      <article className="card tile is-child box">
                        <div className="content">{this.renderManageFarm()}</div>
                      </article>
                    </div>
                  </div>
                  <div className="tile is-6 is-parent">
                    <div className="is-flex is-flex-direction-column is-fullwidth">
                      <article className="card tile is-child box">
                        <div className="content">{this.renderTendCrops()}</div>
                      </article>
                    </div>
                  </div>
                </div>
                <div className="tile">
                  <div className="tile is-6 is-parent">
                    <div className="is-flex is-flex-direction-column is-fullwidth">
                      <article className="card tile is-child box has-background-light">{this.renderFarmers()}</article>
                    </div>
                  </div>
                  <div className="tile is-6 is-parent">
                    <div className="is-flex is-flex-direction-column is-fullwidth">
                      <article className="card tile is-child box has-background-light">
                        {!this.state.isGlobalActiveStats && <div className="content">{this.renderFarmStats()}</div>}
                        {this.state.isGlobalActiveStats && <div className="content">{this.renderGlobalStats()}</div>}
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }

  renderManageFarm() {
    return (
      <div>
        <div className="info-control card-title">
          <h2 className="info">FARM</h2>
        </div>

        <div className="info-control unallocated-amount" ref={this.unallocatedAmountBody}>
          {/* UNALLOCATED */}
          <h2 className="info">Unallocated :</h2>
          <h2 className="value">
            <span className="pre">{Utils.toFixed(this.cropService!.contracts.landBalance, 2)} LAND</span>
            {this.props.ethereumservice.pricesService!.renderPrice(
              TokenId.LAND,
              this.cropService!.contracts.landBalance
            )}
          </h2>
        </div>

        <div className="info-control allocated-amount" ref={this.allocatedAmountBody}>
          {/* FARMLAND */}
          <h2 className="info">Farmland :</h2>
          <h2 className="value">
            <span className="pre">{Utils.toFixed(this.cropService!.contracts.landInFarm, 2)} LAND</span>
            {this.props.ethereumservice.pricesService!.renderPrice(
              TokenId.LAND,
              this.cropService!.contracts.landInFarm
            )}
          </h2>
        </div>

        {!this.cropService!.contracts.isAuthorized && (
          <ul className="action-control mt-5">
            <li>
              <button className="button is-action" onClick={() => this.approveContract()}>
                Grant planning permission
              </button>
            </li>
          </ul>
        )}

        {this.cropService!.contracts.isAuthorized && !this.state.isAllocating && !this.state.isReleasing && (
          <ul className="action-control mt-5">
            <li>
              <button
                className="button is-action "
                onClick={this.allocateLand}
                onMouseEnter={() => this.preparingAllocate(true)}
                onMouseLeave={() => this.preparingAllocate(false)}
                disabled={!this.cropService!.contracts.isAuthorized}>
                Allocate Land{' '}
              </button>
            </li>

            <li>
              <button
                className="button is-action "
                onClick={this.releaseLand}
                onMouseEnter={() => this.preparingRelease(true)}
                onMouseLeave={() => this.preparingRelease(false)}
                disabled={!this.cropService!.contracts.isAuthorized}>
                Release Land{' '}
              </button>
            </li>
          </ul>
        )}

        {this.state.isAllocating && (
          <React.Fragment>
            {this.cropService!.contracts.landInFarm > 0 && (
              <ul className="action-control mt-3">
                <li className="has-text-danger has-text-weight-bold">
                  Warning: Allocating more land will cause the permanent loss of any growing corn. Click cancel to
                  harvest corn first.
                </li>
              </ul>
            )}
            <ul className="action-control mt-2">
              <li className="action-form">
                <div className="field has-addons">
                  <div className="control">
                    <button
                      className="button is-action"
                      onClick={this.maxAllocateLand}
                      disabled={!this.cropService!.contracts.isAuthorized}>
                      Max{' '}
                    </button>
                  </div>

                  <div className="control input-control">
                    <input
                      className="input is-action"
                      type="text"
                      placeholder="LAND Amount"
                      value={this.state.allocateLand}
                      onChange={this.handleAllocateLand}
                      disabled={!this.cropService!.contracts.isAuthorized}
                    />
                  </div>
                </div>
              </li>
            </ul>
            <ul className="action-control mt-2">
              <li>
                <button className="button is-cancel is-fullwidth" onClick={this.cancelAllocate}>
                  Cancel{' '}
                </button>
              </li>

              <li>
                <button
                  className="button is-action"
                  onClick={this.confirmAllocate}
                  disabled={!this.cropService!.contracts.isAuthorized}>
                  Allocate Land{' '}
                </button>
              </li>
            </ul>
          </React.Fragment>
        )}

        {this.state.isReleasing && (
          <React.Fragment>
            <ul className="action-control mt-3">
              <li className="has-text-danger has-text-weight-bold">
                Warning: Releasing land will cause the permanent loss of any growing corn and reset the farm maturity
                boost. Click cancel to harvest corn first.
              </li>
            </ul>
            <ul className="action-control mt-2">
              <li>
                <button className="button is-cancel" onClick={this.cancelRelease}>
                  Cancel{' '}
                </button>
              </li>

              <li>
                <button className="button is-action" onClick={this.confirmRelease}>
                  Confirm{' '}
                </button>
              </li>
            </ul>
          </React.Fragment>
        )}
      </div>
    );
  }

  renderTendCrops() {
    return (
      <div>
        <div className="info-control card-title">
          <h2 className="info">CROPS</h2>
        </div>

        <div className="info-control growing-amount" ref={this.growingAmountBody}>
          <h2 className="info">{this.cropService!.assetName} growing :</h2>
          <h2 className="value">
            <span className="pre">
              {Utils.toFixed(this.cropService!.contracts.cropToHarvest, 8)} {this.cropService!.assetId}
            </span>
            {this.props.ethereumservice.pricesService!.renderPrice(
              this.cropService!.assetId,
              this.cropService!.contracts.cropToHarvest
            )}
          </h2>
        </div>

        <div className="info-control harvested-amount" ref={this.harvestedAmountBody}>
          {/* AVAILABLE */}
          <h2 className="info">Available {this.cropService!.assetName} :</h2>
          <h2 className="value">
            <span className="pre">
              {Utils.toFixed(this.cropService!.contracts.cropBalance, 8)} {this.cropService!.assetId}
            </span>
            {this.props.ethereumservice.pricesService!.renderPrice(
              this.cropService!.assetId,
              this.cropService!.contracts.cropBalance
            )}
          </h2>
        </div>

        {!this.state.isComposting && (
          <ul className="action-control mt-5">
            <li>
              <button
                className="button is-action"
                onClick={this.harvestCrop}
                onMouseEnter={() => this.preparingHarvest(true)}
                onMouseLeave={() => this.preparingHarvest(false)}
                disabled={!this.cropService!.contracts.isAuthorized}>
                Harvest {this.cropService!.assetName}{' '}
              </button>
            </li>
            <li>
              <button
                className="button is-action"
                onClick={this.compostCrop}
                onMouseEnter={() => this.preparingCompost(true)}
                onMouseLeave={() => this.preparingCompost(false)}
                disabled={!this.cropService!.contracts.isAuthorized}>
                Compost {this.cropService!.assetName}{' '}
              </button>
            </li>
          </ul>
        )}

        {this.state.isComposting && (
          <React.Fragment>
            <ul className="action-control mt-5">
              <li className="action-form">
                <div className="field has-addons">
                  <div className="control">
                    <button
                      className="button is-action"
                      onClick={this.maxCompostCropAmount}
                      disabled={!this.cropService!.contracts.isAuthorized}>
                      Max{' '}
                    </button>
                  </div>
                  <div className="control input-control">
                    <input
                      className="input is-action"
                      type="text"
                      placeholder={this.cropService!.assetName}
                      value={this.state.compostCropAmount}
                      onChange={this.handleCompostCropAmount}
                      disabled={!this.cropService!.contracts.isAuthorized}
                    />
                  </div>
                </div>
              </li>
            </ul>
            <ul className="action-control mt-2">
              <li>
                <button className="button is-cancel" onClick={this.cancelCompost}>
                  Cancel{' '}
                </button>
              </li>

              <li>
                <button
                  className="button is-action"
                  onClick={this.confirmCompost}
                  disabled={!this.cropService!.contracts.isAuthorized}>
                  Compost {this.cropService!.assetName}
                </button>
              </li>
            </ul>
          </React.Fragment>
        )}
      </div>
    );
  }

  renderFarmers() {
    return (
      <React.Fragment>
        <div>
          <div className="info-control card-title">
            <h2 className="info">FARMERS</h2>
          </div>
          {this.state.farmers && this.state.farmers.length > 0 && (
            <div className={`${this.state.isDummy ? 'dummy' : ''} farmers`}>
              <React.Fragment>
                <Swiper navigation spaceBetween={10} slidesPerView="auto" centerInsufficientSlides>
                  {this.state.farmers!.map(c => (
                    <SwiperSlide key={c.name + c.id}>
                      <Character
                        actionsLv1={[
                          {
                            name: 'View',
                            isApproved: true,
                            onClick: () => this.setZoomedFarmer(c),
                            onHoverMessage: null,
                            enabled: true
                          }
                        ]}
                        actionsLv2={[]}
                        actionsLv3={[]}
                        onCardClick={this.state.isDummy ? () => {} : () => this.setZoomedFarmer(c)}
                        character={c}
                        isLoading={false}
                        isSample={false}></Character>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </React.Fragment>
            </div>
          )}
          {this.state.isDummy && (
            <span className="employ-farmer column meme is-half" style={{ position: 'absolute', margin: 'auto' }}>
              <h3 className="is-upper">
                <NavLink className="has-action-text" to="/citizens" activeStyle={{ color: 'var(--orange2)' }}>
                  Employ Farmers for an Extra Boost
                </NavLink>
              </h3>
            </span>
          )}
        </div>
      </React.Fragment>
    );
  }

  renderZoomedFarmer() {
    return (
      <React.Fragment>
        {this.state.zoomedFamer && (
          <div className="is-active modal">
            <div className="modal-background" onClick={() => this.setZoomedFarmer(null)}></div>
            <div className="modal-content characters zoomed-farmer">
              <button
                className="modal-close is-medium"
                aria-label="close"
                onClick={() => this.setZoomedFarmer(null)}></button>
              <Character
                actionsLv1={[
                  {
                    name: 'Release',
                    isApproved: true,
                    onClick: () => this.removeFarmer(this.state.zoomedFamer!),
                    onHoverMessage: `Release ${
                      this.state.zoomedFamer!.name
                    } from employment as a Farmer, they will return to the Characters page`,
                    enabled: true
                  }
                ]}
                actionsLv2={[]}
                actionsLv3={[]}
                onCardClick={() => {}}
                character={this.state.zoomedFamer!}
                isLoading={false}
                isSample={false}></Character>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  tickerClass() {
    if (+this.cropService!.contracts.growingCycleCountDown.days > 0) {
      return 'days';
    }
    if (+this.cropService!.contracts.growingCycleCountDown.hours > 0) {
      return 'hours';
    }
    if (+this.cropService!.contracts.growingCycleCountDown.minutes > 0) {
      return 'mins';
    }
  }

  renderFarmStats() {
    return (
      <div>
        <div className="is-flex is-justify-content-space-between info-control card-title">
          <h2 className="info">YOUR STATISTICS</h2>
          <h2 className="info info-tab" onClick={this.toggleActiveStats}>
            GLOBAL STATISTICS
          </h2>
        </div>

        <div className="info-control is-justify-content-space-between pt-3">
          {this.cropService!.contracts.growingCycleCountDown &&
            typeof this.cropService!.contracts.growingCycleCountDown === 'string' && (
              <React.Fragment>
                <h2 className="info">Growing season : </h2>
                <div className="countdown">
                  <div className="ticker mins">
                    <div className="digits">
                      <Flip value="COMPLETE" />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          {this.cropService!.contracts.growingCycleCountDown &&
            typeof this.cropService!.contracts.growingCycleCountDown !== 'string' && (
              <React.Fragment>
                <h2 className="info">Growing season countdown: </h2>
                <div className="countdown">
                  <div className={'ticker ' + this.tickerClass()}>
                    <div className="digits">
                      <Flip value={this.cropService!.contracts.growingCycleCountDown.days} />
                    </div>
                    <div>
                      <div className="units">days</div>
                    </div>
                  </div>
                  <div className={'ticker ' + this.tickerClass()}>
                    <div className="digits">
                      <Flip value={this.cropService!.contracts.growingCycleCountDown.hours} />
                    </div>
                    <div>
                      <div className="units">hours</div>
                    </div>
                  </div>
                  <div className={'ticker ' + this.tickerClass()}>
                    <div className="digits">
                      <Flip value={this.cropService!.contracts.growingCycleCountDown.minutes} />
                    </div>
                    <div>
                      <div className="units">mins</div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
        </div>

        <div className="info-control">
          <h2 className="info">
            Farm boost
            <span className="icon-text tooltip pl-1 pr-2">
              <span className="tooltip-text">
                Maturity boost (max x3), with boost from Farmers (max x10). Potential with currently employed farmers x
                {Utils.toFixed(this.getMaxFarmBoost(), 2).replace(/[.,]00$/, '')}
              </span>
              <span className="icon has-tooltip-text is-small">
                <i className="fas fa-question-circle fa-xs"></i>
              </span>
            </span>
            :
          </h2>
          <h2 className="value ">x {Utils.toFixed(this.cropService!.contracts.farmMaturityBoost, 4)}</h2>
          <progress
            className="progress is-info"
            value={this.cropService!.contracts.farmMaturityBoost - 1}
            max={9}></progress>
        </div>

        <div className="info-control">
          <h2 className="info">Composting boost : </h2>
          <h2 className="value">x {Utils.toFixed(this.cropService!.contracts.farmCompostBoost, 4)}</h2>
          <progress
            className="progress is-info"
            value={this.cropService!.contracts.farmCompostBoost - 1}
            max={this.cropService!.contracts.maxCompostBoost - 1}></progress>
        </div>

        <div className="info-control">
          <h2 className="info">{this.cropService!.assetName} composted : </h2>
          <h2 className="value">
            <span className="pre">{Utils.toFixed(this.cropService!.contracts.cropComposted, 8)}</span>
            {typeof this.cropService!.contracts.burnRequiredTo10x == 'number' &&
              typeof this.cropService!.contracts.maxTo10x == 'number' && (
                <span className="post">({Utils.toFixed(this.cropService!.contracts.maxTo10x, 2)} for x 10)</span>
              )}
          </h2>
          {typeof this.cropService!.contracts.burnRequiredTo10x == 'number' && (
            <progress
              className="progress is-info mb-2"
              value={this.cropService!.contracts.cropComposted}
              max={this.cropService!.contracts.maxTo10x}></progress>
          )}
          {typeof this.cropService!.contracts.burnRequiredTo10x != 'number' && (
            <progress className="progress is-error mb-2" value={1} max={1}></progress>
          )}
        </div>
      </div>
    );
  }

  renderGlobalStats() {
    return (
      <div>
        <div className="is-flex is-justify-content-space-between info-control card-title">
          <h2 className="info info-tab" onClick={this.toggleActiveStats}>
            YOUR STATISTICS
          </h2>
          <h2 className="info">GLOBAL STATISTICS</h2>
        </div>

        <div className="info-control pt-3">
          <h2 className="info">Total Farms : </h2>
          <h2 className="value">{Utils.toFixed(this.cropService!.contracts.globalNumberOfFarms, 0)}</h2>
        </div>
        <div className="info-control">
          <h2 className="info">Total Land in Farms : </h2>
          <h2 className="value">{Utils.toFixed(this.cropService!.contracts.globalLandInFarm, 2)} LAND</h2>
        </div>
        <div className="info-control">
          <h2 className="info">Average Size of Farms : </h2>
          <h2 className="value">{Utils.toFixed(this.cropService!.contracts.globalAverageLandInFarms, 0)} LAND</h2>
        </div>
        <div className="info-control">
          <h2 className="info">Total {this.cropService!.assetName} composted : </h2>
          <h2 className="value">
            {Utils.toFixed(this.cropService!.contracts.globalComposted, 2)} {this.cropService!.assetId}
          </h2>
        </div>
        <div className="info-control">
          <h2 className="info">Average Amount of {this.cropService!.assetName} composted : </h2>
          <h2 className="value">
            {Utils.toFixed(this.cropService!.contracts.globalAverageComposted, 2)} {this.cropService!.assetId}
          </h2>
        </div>
      </div>
    );
  }
}

export default Farm;
