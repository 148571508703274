import { Component } from 'react';
import { ICharacterDetails } from '../../models/CharacterDetails';
import { renderImagePart } from './ImagePart';
import { Unrevealed } from './Unrevealed';
import { Stage, Layer } from 'react-konva';

export interface ICharacterImageProps {
  character?: ICharacterDetails;
  isSample: boolean;
}

export class CharacterImage extends Component<ICharacterImageProps> {
  render() {
    return (
      <figure className="image is-3by3">
        {!this.props.character?.revealed && <Unrevealed></Unrevealed>}
        {this.props.character?.revealed && this.props.character!.image && !this.props.character!.imageParts && (
          <img className="nft" src={this.props.character!.image} alt={this.props.character.name} />
        )}
        {this.props.character?.revealed && this.props.character!.imageParts && (
          <Stage width={250} height={250}>
            <Layer>
              {renderImagePart('background', this.props.character!.imageParts.background, 250, 250)}
              {renderImagePart('base', this.props.character!.imageParts.base, 250, 250)}
              {renderImagePart('hair', this.props.character!.imageParts.hair, 250, 250)}
              {renderImagePart('eyes', this.props.character!.imageParts.eyes, 250, 250)}
              {renderImagePart('clothing', this.props.character!.imageParts.clothing, 250, 250)}
              {renderImagePart('mouth', this.props.character!.imageParts.mouth, 250, 250)}
              {renderImagePart('feature', this.props.character!.imageParts.feature, 250, 250)}
            </Layer>
          </Stage>
        )}
        {this.props.isSample && <h3 className="nft-sample">Sample</h3>}
      </figure>
    );
  }
}
