import { Utils } from '../../../services/Utils';
import { CropServiceBase } from './CropServiceBase';

export class CropServiceV1 extends CropServiceBase {
  loadBalancesImpl(): Promise<any> {
    var cropContract = this.ethereumService.networkManager!.network!.contracts[this.assetId]!;

    return this.ethereumService.signer!.getAddress().then(address =>
      Promise.all([
        //0 wallet address
        address,
        //1 ETH balance
        this.ethereumService.provider!.getBalance(address),
        //2 getAddressDetails [0] blockNumber [1] cropBalance [2] amountToHarvest, [3] MaturityBoost, [4] CompostBoost (CROP V2) [5] EquipmentBoost
        cropContract.getAddressDetails(address),
        //3 struct Farm [0]Amount of LAND [1] CROP composted [2] Farm built, [3] Last harvested [4] Delegated Harvester (CROP v2) [5] amountLP [6] equipmentID [7] equipmentName [8] equipmentmaxBoostLevel [9] equipmentblocksToMaxBoost [10] equipmentAddedBlockNumber;
        cropContract.farms(address),
        //4 getAddressTokenDetails [0] Block [1] isCropOperator [2] landBalance(LP) [3] myRatio (MyComposted / LandInFarm) [4] globalRatio [5] globalAverageRatio
        cropContract.getAddressTokenDetails(address),
        //5 [0]maxCompostBoost, [1]maxMaturityBoost, [2]maxGrowthCycle, [3]maturityBoostExtension
        cropContract.getConstantDetails(),
        //6 [0]globalTotalFarms, [1]globalRatio (globalComposted / globalLandInFarm), [2]globalAverageRatio, (globalComposted / globalLandInFarm) / GlobalTotalFarms [3]globalAllocatedAmount, [4]globalCompostedAmount
        cropContract.getGlobalDetails()
        //7 getUnderlyingTokenDetails - [0] lpTotalSupply, [1] token0Balance,[2] token1Balance,[3] underlyingToken0Balance, [4] underlyingToken1Balance
        // cropContract.getUnderlyingTokenDetails(wLAND_Amount)
      ]).then(
        response => {
          this.contracts.cropBalance = Utils.formatUnits(response[2] ? response[2][1] : null);
          this.contracts.cropToHarvest = Utils.formatUnits(response[2] ? response[2][2] : null);
          this.contracts.farmMaturityBoost = Utils.formatUnits(response[2] ? response[2][3] : null, 4);
          this.contracts.farmCompostBoost = Utils.formatUnits(response[2] ? response[2][4] : null, 4);
          this.contracts.landInFarm = Utils.formatUnits(response[3] ? response[3][0] : null);
          this.contracts.cropComposted = Utils.formatUnits(response[3] ? response[3][1] : null);
          this.contracts.farmBuiltBlockNumber = parseInt(response[3] ? response[3][2] : null);
          this.contracts.lastHarvestBlockNumber = parseInt(response[3] ? response[3][3] : null);
          this.contracts.isAuthorized = response[4] ? response[4][1] : null;
          this.contracts.landBalance = Utils.formatUnits(response[4] ? response[4][2] : null);
          this.contracts.maxGrowthCycle = parseInt(response[5] ? response[5][2] : null);
          this.contracts.globalRatio = Utils.formatUnits(response[4] ? response[4][5] : null, 4);
          this.contracts.globalNumberOfFarms = Utils.formatUnits(response[6] ? response[6][0] : null, 0);
          this.contracts.globalLandInFarm = Utils.formatUnits(response[6] ? response[6][3] : null);
          this.contracts.globalComposted = Utils.formatUnits(response[6] ? response[6][4] : null);

          // Only calculate this when we have at least one farm globally to avoid division by zero errors
          if (this.contracts.globalNumberOfFarms !== 0) {
            this.contracts.globalAverageLandInFarms =
              this.contracts.globalLandInFarm / this.contracts.globalNumberOfFarms;
            this.contracts.globalAverageComposted = this.contracts.globalComposted / this.contracts.globalNumberOfFarms;
          }

          // TODO: Just initialise the variable to avoid error ... Sparta to find a proper fix
          this.contracts.growingCycleCountDown = '';

          // Only calculate this stuff when there is a farm
          if (this.contracts.landInFarm > 0) {
            // Calculates the number of blocks until the end of the growing cycle:
            this.contracts.endOfGrowingCycle = this.contracts.lastHarvestBlockNumber + this.contracts.maxGrowthCycle;
            // Calculates the approx. number of seconds until the end of the growing cycle
            const networkBlockTime = this.ethereumService.networkManager!.network!.blocktime;
            this.contracts.growingCycleCountDownSecs =
              networkBlockTime * (this.contracts.endOfGrowingCycle - this.ethereumService.walletData.stdBlockNumber);
            // Decides how to present the countdown in hours or complete message
            if (this.contracts.growingCycleCountDownSecs > 0) {
              this.contracts.growingCycleCountDown = this.calculateCountdown(this.contracts.growingCycleCountDownSecs);
            } else {
              this.contracts.growingCycleCountDown = 'COMPLETE';
            }

            // x 10 composting boost calc. x10 = 9 * global composted average
            if (this.contracts.farmCompostBoost < 10) {
              var averageMaxTo10x = (9 * this.contracts.globalComposted) / this.contracts.globalNumberOfFarms;
              var myFarmMultiple = this.contracts.landInFarm / this.contracts.globalAverageLandInFarms;
              this.contracts.maxTo10x = averageMaxTo10x * myFarmMultiple;
              this.contracts.burnRequiredTo10x = this.contracts.maxTo10x - this.contracts.cropComposted;
            } else {
              this.contracts.burnRequiredTo10x = 'OVER COMPOSTED';
            }
          }
        },
        error => {
          this.handleLoadBalancesError(error);
        }
      )
    );
  }
}
