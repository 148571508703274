import { Character } from './Character';
import { ICharacterWrapper, newLoadingCharacter } from '../../models/CharacterDetails';
import { ICharacterService } from '../../services/Interfaces';
import { ICharacterAction } from '../../models/ICharacterAction';

type Props = {
  characterService: ICharacterService;
  character: ICharacterWrapper;
  actionsLv1: ICharacterAction[];
  actionsLv2: ICharacterAction[];
  actionsLv3: ICharacterAction[];
  onCardClick?: any;
  isSample: boolean;
};

const CharacterWrapper = (props: Props) => {
  return (
    <>
      {/* <div className="has-light-text">Id: {props.character.id}</div>
      <div className="has-light-text">
        DetailsRefreshedAtBlock: {props.character.detailsRefreshedAtBlock.toString()}
      </div>
      <div className="has-light-text">{props.character.details !== null ? 'Loaded' : 'Not'}</div> */}
      <Character
        actionsLv1={props.actionsLv1}
        actionsLv2={props.actionsLv2}
        actionsLv3={props.actionsLv3}
        onCardClick={props.onCardClick}
        character={props.character.details ?? newLoadingCharacter(props.character.id)}
        isLoading={props.character.details === null}
        isSample={props.isSample}
      />
    </>
  );
};

export default CharacterWrapper;
