import { Utils } from '../../../services/Utils';
import { EthereumService } from '../../../services/EthereumService';
import { AssetId } from '../../../models/NetworkConfig';
import { BigNumber, ethers } from 'ethers';
import { TokenId } from '../../../models/Enums';
import { ContractsData } from '../../../models/ContractsData';
import { IAssetConfig } from '../../../models/AssetConfig';
import { ICharacterDetails } from '../../../models/CharacterDetails';
import { MessagingService } from '../../../services/MessagingService';
import { Mutex } from 'async-mutex';

export interface ICropService {
  assetName: string;
  asset: any;
  assetId: AssetId;
  contracts: ContractsData;

  update(): void;
  loadBalances(): Promise<any>;
  allocateLand(amount: any): Promise<any>;
  harvest(): void;
  compost(amount: any): Promise<any>;
  releaseLand(): void;
  approveContract(): void;

  getCollectibles(collectibleContract: ethers.Contract): Promise<ICharacterDetails[]>;
  releaseCollectible(collectible: ICharacterDetails): Promise<any>;
}

export class CropServiceBase implements ICropService {
  landContract?: ethers.Contract;
  cropContract?: ethers.Contract;

  contracts = new ContractsData();

  assetName: string;

  mutex = new Mutex();

  loadBalancesErrors = {
    count: 0,
    lastError: null,
    blockNumber: 0
  };

  constructor(
    protected messagingService: MessagingService,
    protected ethereumService: EthereumService,
    public assetId: AssetId,
    public asset: IAssetConfig
  ) {
    this.update();
    this.assetName = assetId.replace(/\w\S*/g, function (txt: any) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  update(): void {
    this.landContract = this.ethereumService.networkManager!.network?.contracts[TokenId.LAND];
    this.cropContract = this.ethereumService.networkManager!.network?.contracts[this.assetId];
    this.contracts = new ContractsData();

    this.setupEventHandlers();
  }

  async loadBalances(): Promise<any> {
    if (!this.ethereumService.provider?.network) {
      this.messagingService.errors.next({
        message: 'Error loading network',
        error: 'Error loading network info from provider'
      });
      return;
    }

    if (!this.ethereumService.networkManager?.network) {
      this.messagingService.errors.next({
        message: 'Error loading farm, unsupported network: ' + this.ethereumService.provider!.network.name,
        error: 'Unsupported network: ' + this.ethereumService.provider!.network.name
      });
      return;
    }

    this.mutex.runExclusive(async () => {
      await this.loadBalancesImpl();
    });
  }

  async loadBalancesImpl(): Promise<any> {
    return new Promise(() => null);
  }

  protected handleLoadBalancesError(error: any) {
    if (!this.ethereumService.walletData.stdBlockNumber) {
      this.messagingService.errors.next({
        message: `Error loading farm details : ${Utils.getContractErrorMessage(error)}`,
        error: error
      });
    }

    if (this.loadBalancesErrors.blockNumber === this.ethereumService.walletData.stdBlockNumber) {
      // "ignore same block as previous error"
      return;
    }

    var err = error && error.message ? error.message : 'unknown';

    if (
      this.loadBalancesErrors.blockNumber + 1 === this.ethereumService.walletData.stdBlockNumber &&
      this.loadBalancesErrors.lastError === err
    ) {
      if (this.loadBalancesErrors.count > 3) {
        // >3 continuous error - report
        this.loadBalancesErrors = {
          count: 1,
          lastError: err,
          blockNumber: this.ethereumService.walletData.stdBlockNumber
        };
        this.messagingService.errors.next({
          message: 'Error loading latest farm details, information may be stale',
          error: error
        });
      } else {
        // <=3 continuous error - capture
        this.loadBalancesErrors.count++;
        this.loadBalancesErrors.lastError = err;
        this.loadBalancesErrors.blockNumber = this.ethereumService.walletData.stdBlockNumber;
      }
    } else {
      // non-continuous error - reset
      this.loadBalancesErrors = {
        count: 1,
        lastError: err,
        blockNumber: this.ethereumService.walletData.stdBlockNumber
      };
    }
  }

  protected calculateCountdown(totalSeconds: number) {
    const minutes = ('0' + Math.floor((totalSeconds / 60) % 60)).slice(-2);
    const hours = ('0' + Math.floor((totalSeconds / (60 * 60)) % 24)).slice(-2);
    const days = ('0' + Math.floor(totalSeconds / (60 * 60 * 24))).slice(-2);

    return {
      days,
      hours,
      minutes
    };
  }

  private removeEventHandlers() {
    this.cropContract?.removeAllListeners();
  }

  protected setupEventHandlers() {
    this.removeEventHandlers();

    var update = (message: string) => {
      this.messagingService.events.next({ message: message });
      this.loadBalances();
    };

    if (this.ethereumService.networkManager!.network && this.cropContract) {
      this.cropContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('Allocated(address,uint256,address,uint256,uint256)')]
        },
        (walletAddress, currentBlock, delegatedAddress, plantedAmount, burnedAmountIncrease) => {
          if (walletAddress === this.ethereumService.walletData.walletAddress) {
            update('Your LAND is allocated to farm');
          }
        }
      );

      this.cropContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('Released(address,uint256,uint256)')]
        },
        (walletAddress, landInFarm, burnedAmountDecrease) => {
          if (walletAddress === this.ethereumService.walletData.walletAddress) {
            update('Your LAND has been released from a farm');
          }
        }
      );

      this.cropContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('Harvested(address,uint256,address,address,uint256,uint256)')]
        },
        (walletAddress, blockNumber, delegatedAddress, preBlockNumber, cornToHarvest) => {
          if (walletAddress === this.ethereumService.walletData.walletAddress) {
            update('Your CORN has been harvested');
          }
        }
      );

      this.cropContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('Composted(address,address,uint256)')]
        },
        (walletAddress, delegatedAddress, fertilizedAmount) => {
          if (walletAddress === this.ethereumService.walletData.walletAddress) {
            update('Your CORN has been composted & used to fertilize your farm');
          }
        }
      );
    }
  }

  approveContract(): void {
    this.loadBalances().then(() => {
      this.landContract!.authorizeOperator(this.cropContract!.address, {
        from: this.ethereumService.walletData.walletAddress
      }).then(
        () => this.messagingService.events.next({ message: 'Planning permission is pending', pending: true }),
        (error: any) =>
          this.messagingService.errors.next({
            message: `Error providing planning permission : ${Utils.getContractErrorMessage(error)}`,
            error: error
          })
      );
    });
  }

  allocateLand(amount: any): Promise<any> {
    if (!Utils.isEthAmountValid(amount)) {
      this.messagingService.errors.next({
        message: 'Error allocating LAND - Please enter a valid amount',
        error: 'Invalid value entered to allocate'
      });
      return new Promise((resolve, reject) => {});
    }

    var sanitizedAmount = Utils.sanitizeParseUnits(amount);

    if (sanitizedAmount.lte(0)) {
      this.messagingService.errors.next({
        message: 'Error allocating LAND - Please enter a valid amount',
        error: 'Invalid value entered to allocate'
      });
      return new Promise((resolve, reject) => {});
    }

    var cropContract = this.ethereumService.networkManager!.network!.contracts[this.assetId]!;

    return this.loadBalances().then(() => {
      return cropContract.allocate(this.ethereumService.walletData.walletAddress, sanitizedAmount).then(
        () => this.messagingService.events.next({ message: 'Your LAND is being allocated to a farm', pending: true }),
        (error: any) =>
          this.messagingService.errors.next({
            message: `Error allocating LAND : ${Utils.getContractErrorMessage(error)}`,
            error: error
          })
      );
    });
  }

  releaseLand(): void {
    this.loadBalances().then(() => {
      this.cropContract!.release().then(
        () => this.messagingService.events.next({ message: 'Release of LAND is pending', pending: true }),
        (error: any) =>
          this.messagingService.errors.next({
            message: `Error releasing LAND : ${Utils.getContractErrorMessage(error)}`,
            error: error
          })
      );
    });
  }

  harvest(): void {
    this.loadBalances().then(() => {
      this.ethereumService.getBlockNumber().then(blockNumber => {
        if (blockNumber == null) {
          this.messagingService.errors.next({
            message: 'Error getting block number',
            error: 'Error getting block number'
          });
        } else {
          const blockHex = BigNumber.from(blockNumber).toHexString();
          this.cropContract!.harvest(
            this.ethereumService.walletData.walletAddress,
            this.ethereumService.walletData.walletAddress,
            blockHex
          ).then(
            () =>
              this.messagingService.events.next({ message: `Your ${this.assetId} harvest is pending`, pending: true }),
            (error: any) =>
              this.messagingService.errors.next({
                message: `Error harvesting ${this.assetId} : ${Utils.getContractErrorMessage(error)}`,
                error: error
              })
          );
        }
      });
    });
  }

  compost(amount: any): Promise<any> {
    if (!Utils.isEthAmountValid(amount)) {
      this.messagingService.errors.next({
        message: `Error allocating ${this.assetId} - Please enter a valid amount`,
        error: 'Invalid value entered to allocate'
      });
      return new Promise((resolve, reject) => {});
    }

    var sanitizedAmount = Utils.sanitizeParseUnits(amount);

    if (sanitizedAmount.lte(0)) {
      this.messagingService.errors.next({
        message: `Error composting ${this.assetId} - Please enter a valid amount`,
        error: 'Invalid value entered to compost'
      });
      return new Promise((resolve, reject) => {});
    }

    return this.loadBalances().then(() => {
      return this.cropContract!.compost(this.ethereumService.walletData.walletAddress, sanitizedAmount).then(
        () => this.messagingService.events.next({ message: `Composting ${this.assetId} is pending`, pending: true }),
        (error: any) =>
          this.messagingService.errors.next({
            message: `Error composting ${this.assetId} : ${Utils.getContractErrorMessage(error)}`,
            error: error
          })
      );
    });
  }

  getCollectibles(collectibleContract: ethers.Contract): Promise<ICharacterDetails[]> {
    return new Promise(() => []);
  }

  releaseCollectible(collectible: ICharacterDetails): Promise<any> {
    return new Promise(() => null);
  }
}
