import { ethers } from 'ethers';
import { Utils } from './Utils';
import { EthereumService } from './EthereumService';
import { TokenId } from '../models/Enums';
import { PricesServiceBase } from './IPricesService';

import { request, gql } from 'graphql-request';

interface IPairResult {
  pairs: IPair[];
}

interface IPair {
  id: string;
  token0: IToken;
  token1: IToken;
  reserve0: number;
  reserve1: number;
  reserveETH: number;
  reserveUSD: number;
}

interface IToken {
  symbol: string;
}

export class GraphPricesService extends PricesServiceBase {
  public ethersProvider?: ethers.providers.BaseProvider;

  constructor(private ethereumService: EthereumService) {
    super();
  }

  async update(): Promise<any> {
    if (
      !this.ethereumService.provider ||
      !this.ethereumService.provider.network ||
      !this.ethereumService.networkManager!.network
    ) {
      return;
    }

    let network = this.ethereumService.networkManager!.network;

    this.ethereumService!.provider.getGasPrice().then(price => {
      this.prices.gas = Utils.formatUnits(price, 'gwei');
    });

    if (network.loadPrices && network.graphAPIExchangeEndpoint) {
      try {
        const result: IPairResult = await request(
          network.graphAPIExchangeEndpoint!,
          gql`
            {
              pairs(
                where: {
                  id_in: ["0x629aa19df31882fac22d7f845ee8053c137a1741", "0x968f56dcbb7d870ddfbc7ea053ce27ea50d1c0f0"]
                }
              ) {
                id
                token0 {
                  symbol
                }
                token1 {
                  symbol
                }
                reserve0
                reserve1
                reserveETH
                reserveUSD
              }
            }
          `
        );

        result.pairs.forEach(p => {
          this.prices.tokenUsd.set(TokenId[p.token0.symbol! as keyof typeof TokenId], p.reserveUSD / 2 / p.reserve0);
          this.prices.tokenUsd.set(TokenId[p.token1.symbol! as keyof typeof TokenId], p.reserveUSD / 2 / p.reserve1);
        });
      } catch (error: any) {
        if (error && error.reason) {
          console.error('Error loading prices: ' + error.reason);
        } else {
          console.error(error);
        }
      }
    }
  }
}
