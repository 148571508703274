import React, { Component } from 'react';
// @ts-ignore
import { Subscription } from 'rxjs';
import { EthereumService } from '../../services/EthereumService';
import { MessagingService } from '../../services/MessagingService';
import '../Content.scss';
import '../Tables.scss';
import './Barn.scss';
// @ts-ignore
import CollapsibleCard from '../Common/CollapsibleCard';
import { ItemService } from '../../services/ItemService';
import { ICraftingDetails, IItemDetails } from '../../models/ItemDetails';
import { IPackBalance, PackService } from '../../services/PackService';
import { Utils } from '../../services/Utils';
import { BigNumber, ethers } from 'ethers';
import { IPackDetails } from '../../models/PackDetails';
import { Mutex } from 'async-mutex';

import { DndContext } from '@dnd-kit/core';
import Draggable from '../Common/Draggable';
import Droppable from '../Common/Droppable';
import { CraftingService, IRecipe, IRecipeIngredient, IngredientStatus } from '../../services/CraftingService';
import { isMobile } from 'react-device-detect';
import ScrollWrapper from '../Common/ScrollWrapper';
import ViewWrapper from '../Common/ViewWrapper';
import { InView } from 'react-intersection-observer';

enum AssetsToDisplay {
  All,
  Wallet
}

enum AssetDisplayOrder {
  Alpha,
  Balance,
  Rarity
}

enum Mode {
  Display,
  CraftItems,
  CraftArtifacts
}

interface IBarnProps {
  messagingService: MessagingService;
  ethereumService: EthereumService;
}

interface IClaimItems {
  received: number;
  blockNumber: number;
  item: IItemDetails;
}

interface IBarnState {
  isConnected: boolean;
  loaded: boolean;
  materials: IItemDetails[];
  items: IItemDetails[];
  artifacts: IItemDetails[];
  rewards: IPackBalance[];
  claimItems: any;
  showDetail: boolean;
  assetsDisplayed: AssetsToDisplay;
  assetsDisplayOrder: AssetDisplayOrder;
  sortAzDirection: boolean;
  zoomedItem: IItemDetails | null;
  zoomedPack: IPackBalance | null;
  zoomedBuyPack: IPackDetails | null;
  mintPackAmount: number;
  refreshedAtBlock: number;

  craftDnD: boolean;
  mode: Mode;
  activeDragItem: IItemDetails | null;
  craftableItems: IItemDetails[];
  craftingItems: IItemDetails[];
  selectedItem: IItemDetails | null;
  curentRecipe: IRecipe | null;
  paymentBalance: BigNumber;
  isCraftingPaymentApproved: boolean;
  isCraftingItemUseApproved: boolean;
  ingredientStatus: IngredientStatus;

  isRecipeSelected: boolean;
  amountToCraft: number;

  inView: boolean;
}

class Barn extends Component<IBarnProps, IBarnState> {
  subscription: Subscription;
  walletAddress?: string;
  chainId?: number;
  itemService?: ItemService;
  packService?: PackService;
  craftingService?: CraftingService;
  maxMintPackAmount = 3;
  lastClick = 0;
  waitingClick: any = null;
  mutex = new Mutex();

  constructor(props: IBarnProps) {
    super(props);

    var toDisplay = localStorage.getItem('barnAssetsToDisplay');
    var displayOrder = localStorage.getItem('barnAssetDisplayOrder');
    var craftDnD = localStorage.getItem('barnCraftDnD') === 'true';

    this.state = {
      isConnected: true,
      loaded: false,
      materials: [],
      items: [],
      artifacts: [],
      rewards: [],
      claimItems: new Map<number, IClaimItems[]>(),
      showDetail: false,
      assetsDisplayed: toDisplay === 'Wallet' ? AssetsToDisplay.Wallet : AssetsToDisplay.All,
      assetsDisplayOrder:
        displayOrder === 'Rarity'
          ? AssetDisplayOrder.Rarity
          : displayOrder === 'Balance'
          ? AssetDisplayOrder.Balance
          : AssetDisplayOrder.Alpha,
      sortAzDirection: true,
      zoomedItem: null,
      zoomedPack: null,
      zoomedBuyPack: null,
      mintPackAmount: 1,
      refreshedAtBlock: 0,

      craftDnD: isMobile ? false : craftDnD,
      mode: Mode.Display,
      activeDragItem: null,
      craftableItems: [],
      craftingItems: [],
      selectedItem: null,
      curentRecipe: null,
      paymentBalance: BigNumber.from(0),
      isCraftingPaymentApproved: false,
      isCraftingItemUseApproved: false,
      ingredientStatus: IngredientStatus.NoMatch,
      isRecipeSelected: false,
      amountToCraft: 1,

      inView: false
    };

    this.subscription = new Subscription();

    this.sortItems = this.sortItems.bind(this);
    this.sortCraftingItems = this.sortCraftingItems.bind(this);
    this.onItemDblClicked = this.onItemDblClicked.bind(this);
    this.setZoomedItem = this.setZoomedItem.bind(this);
    this.setZoomedPack = this.setZoomedPack.bind(this);
    this.setZoomedBuyPack = this.setZoomedBuyPack.bind(this);
    this.handleAmountToMint = this.handleAmountToMint.bind(this);
    this.handleAmountToMintChange = this.handleAmountToMintChange.bind(this);
    this.openPack = this.openPack.bind(this);
    this.approveMint = this.approveMint.bind(this);
    this.mintPack = this.mintPack.bind(this);
    this.dismissClaimed = this.dismissClaimed.bind(this);
    this.toggleAssetsDisplayed = this.toggleAssetsDisplayed.bind(this);
    this.toggleSortDirection = this.toggleSortDirection.bind(this);
    this.toggleCraftDnD = this.toggleCraftDnD.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleRecipeItemAmountChange = this.handleRecipeItemAmountChange.bind(this);
    this.canUpdateRecipeItemAmountByValue = this.canUpdateRecipeItemAmountByValue.bind(this);
    this.handleCraftAmountChangeValue = this.handleCraftAmountChangeValue.bind(this);
    this.canUpdateAmountToCraftByValue = this.canUpdateAmountToCraftByValue.bind(this);
    this.handleCraftAmountChange = this.handleCraftAmountChange.bind(this);
    this.handleSelectRecipe = this.handleSelectRecipe.bind(this);
    this.handleUnselectRecipe = this.handleUnselectRecipe.bind(this);
    this.handleCraftItems = this.handleCraftItems.bind(this);
    this.handleCraftingItemClick = this.handleCraftingItemClick.bind(this);
    this.setInView = this.setInView.bind(this);
  }

  componentDidMount() {
    var eventSubscription = this.props.messagingService.events.subscribe(event => {
      this.refresh();
    });
    this.subscription.add(eventSubscription);
    this.refresh();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
    this.craftingService?.craftingContract?.removeListener(this.recipeCraftedFilter, this.handleRecipeCrafted);
    if (this.waitingClick) {
      clearTimeout(this.waitingClick);
      this.waitingClick = null;
    }
  }

  private recipeCraftedFilter = {
    address: this.props.ethereumService.walletData.walletAddress,
    topics: [ethers.utils.id('RecipeCrafted(address,uint256,uint256,uint256)')]
  };

  private handleRecipeCrafted = (account: string, recipeId: number, itemId: number, amount: number) => {
    if (account === this.props.ethereumService.walletData.walletAddress) {
      this.onModeChanged(Mode.Display);
      this.refresh();
    }
  };

  setInView = (inView: boolean) => {
    this.setState({ inView: inView });
  };

  onItemDblClicked(item: IItemDetails | null) {
    if (this.state.isRecipeSelected) return;

    if (item) {
      if (item.craftingDetails.isCrafting) {
        this.removeItemFromCraftingTable(item);
      } else if (!item.craftingDetails.isCrafting) {
        this.addItemToCraftingTable(item);
      }
    }
  }

  setZoomedItem(item: IItemDetails | null) {
    this.setState({ zoomedItem: item });
  }

  setZoomedPack(pack: IPackBalance | null) {
    this.setState({ zoomedPack: pack });
  }

  setZoomedBuyPack(packDetails: IPackDetails | null) {
    this.setState({ zoomedBuyPack: packDetails });
  }

  handleAmountToMint(e: any) {
    var sanitized = !e.target.value || isNaN(e.target.value) ? 0 : +e.target.value;
    var amount = Math.min(this.maxMintPackAmount, Math.max(1, sanitized));
    this.setState({ mintPackAmount: amount });
  }

  handleAmountToMintChange(changeBy: number) {
    this.setState({ mintPackAmount: this.state.mintPackAmount + changeBy });
  }

  openPack(pack: IPackBalance) {
    this.packService?.claim(pack);
    this.setState({ zoomedPack: null });
  }

  approveMint() {
    if (!this.packService!.isMintingApproved) {
      this.packService!.approveMint();
    }
  }

  mintPack() {
    if (this.packService!.isMintingApproved && this.state.zoomedBuyPack) {
      this.packService!.mint(this.state.zoomedBuyPack, this.amountToSize(this.state.mintPackAmount));
      this.setState({ zoomedBuyPack: null });
    }
  }

  amountToSize(amount: number) {
    return amount === 3 ? 10 : amount === 2 ? 5 : 3;
  }

  amountToSizeString(amount: number) {
    return amount === 3 ? 'Large' : amount === 2 ? 'Medium' : 'Small';
  }

  sizeToSizeString(size: number) {
    return size === 10 ? 'Large' : size === 5 ? 'Medium' : 'Small';
  }

  async dismissClaimed(claimIndex: number) {
    this.packService?.removeFromClaims(claimIndex);
    await this.update();
  }

  toggleAssetsDisplayed() {
    this.setState({
      assetsDisplayed:
        this.state.assetsDisplayed === AssetsToDisplay.Wallet ? AssetsToDisplay.All : AssetsToDisplay.Wallet
    });

    localStorage.setItem(
      'barnAssetsToDisplay',
      AssetsToDisplay[
        this.state.assetsDisplayed === AssetsToDisplay.Wallet ? AssetsToDisplay.All : AssetsToDisplay.Wallet
      ]
    );
  }

  toggleCraftDnD() {
    var craftDnD = !this.state.craftDnD;

    this.setState({
      craftDnD: craftDnD
    });

    localStorage.setItem('barnCraftDnD', craftDnD.toString());
  }

  toggleSortDirection() {
    this.setState({ sortAzDirection: !this.state.sortAzDirection });
  }

  onAssetsDisplayOrderChanged(selectedOrderIndex: number) {
    var displayOrder = AssetDisplayOrder.Alpha;
    switch (selectedOrderIndex) {
      case 1:
        displayOrder = AssetDisplayOrder.Balance;
        break;
      case 2:
        displayOrder = AssetDisplayOrder.Rarity;
        break;
    }
    this.setState({ assetsDisplayOrder: displayOrder });
    localStorage.setItem('barnAssetDisplayOrder', AssetDisplayOrder[displayOrder]);
  }

  onModeChanged(mode: Mode) {
    switch (mode) {
      case Mode.Display:
        this.setState({ mode: mode, craftableItems: [], craftingItems: [] });
        break;
      case Mode.CraftItems:
        var materials = this.state.materials.filter(i => i.balance > 0 && i.craftingDetails.isCraftable);
        var items = this.state.items.filter(i => i.balance > 0 && i.craftingDetails.isCraftable);
        this.setState({
          mode: mode,
          craftableItems: [...materials, ...items],
          craftingItems: [],
          curentRecipe: null,
          ingredientStatus: IngredientStatus.NoMatch
        });
        break;
      case Mode.CraftArtifacts:
        var artifacts = this.state.artifacts.filter(i => i.balance > 0 && i.craftingDetails.isCraftable);
        this.setState({
          mode: mode,
          craftableItems: [...artifacts],
          craftingItems: [],
          curentRecipe: null,
          ingredientStatus: IngredientStatus.NoMatch
        });
        break;
    }
  }

  handleDragStart(event: any) {
    if (!this.state.isCraftingItemUseApproved || this.state.isRecipeSelected) return;

    var item = event.active.data.current;

    if (item.craftingDetails.isCrafting) {
      this.setState({
        activeDragItem: this.cloneCraftItem(
          item,
          { balance: item.balance },
          {
            isDragging: true,
            isCrafting: true
          }
        )
      });
    } else {
      this.setState({
        activeDragItem: this.cloneCraftItem(
          item,
          { balance: item.balance },
          {
            isDragging: true,
            isCrafting: false
          }
        )
      });
    }
  }

  handleDragEnd(event: any) {
    if (!this.state.activeDragItem || !event.over || this.state.isRecipeSelected) return;

    if (event.over.id === 'items' && this.state.activeDragItem.craftingDetails.isCrafting) {
      this.removeItemFromCraftingTable(this.state.activeDragItem);
    } else if (event.over.id === 'table' && !this.state.activeDragItem.craftingDetails.isCrafting) {
      this.addItemToCraftingTable(this.state.activeDragItem);
    }
  }

  handleRecipeItemAmountChange(item: IItemDetails, value: number) {
    if (value === -1) {
      this.removeItemFromCraftingTable(item);
    } else {
      var originalItemId = item.id * -1; // invert id to get original item id
      var originalItem = this.state.craftableItems.find(i => i.id === originalItemId);
      if (originalItem !== null && originalItem!.balance >= value) {
        this.addItemToCraftingTable(originalItem!);
      }
    }
  }

  canUpdateRecipeItemAmountByValue(item: IItemDetails, value: number): boolean {
    var originalItemId = item.id * -1; // invert id to get original item id
    var originalItem = this.state.craftableItems.find(i => i.id === originalItemId);
    return !!originalItem && originalItem!.balance >= value;
  }

  handleCraftAmountChange(e: any) {
    if (!this.state.curentRecipe) return;
    var amount = +e.target.value;
    if (amount > 0 || this.canUpdateAmountToCraft(amount)) {
      this.updateAmountToCraft(+e.target.value);
    }
  }

  handleCraftAmountChangeValue(value: number) {
    if (!this.state.curentRecipe) return;
    this.updateAmountToCraft(this.state.amountToCraft + value);
  }

  canUpdateAmountToCraftByValue(value: number): boolean {
    if (!this.state.curentRecipe) return false;
    return this.canUpdateAmountToCraft(this.state.amountToCraft + value);
  }

  handleSelectRecipe() {
    if (!this.state.curentRecipe) return;

    var craftableItems =
      this.state.mode === Mode.CraftItems
        ? [
            ...this.state.materials.filter(i => i.balance > 0 && i.craftingDetails.isCraftable),
            ...this.state.items.filter(i => i.balance > 0 && i.craftingDetails.isCraftable)
          ]
        : this.state.artifacts.filter(i => i.balance > 0 && i.craftingDetails.isCraftable);

    var craftingItems = this.state.curentRecipe!.incredients!.map(i => {
      var originalItem = craftableItems.find(ci => ci!.id === i.itemId);
      var amount = i.amount * this.state.amountToCraft;
      return this.cloneCraftItem(
        originalItem!,
        { balance: amount, id: originalItem!.id * -1 },
        { isDragging: false, isCrafting: true }
      );
    });

    craftableItems = craftableItems.map(i => {
      var craftingItem = craftingItems.find(ci => ci.id === i.id * -1);

      var amount = craftingItem ? i.balance - craftingItem.balance : i.balance;

      return this.cloneCraftItem(i, { balance: amount }, { isDragging: false, isCrafting: false });
    });

    this.setState({
      craftableItems: craftableItems,
      craftingItems: craftingItems,
      isRecipeSelected: true
    });
  }

  handleUnselectRecipe() {
    if (!this.state.curentRecipe) return;

    this.updateAmountToCraft(1, true);
  }

  handleCraftItems() {
    this.craftingService?.craftItems(this.state.curentRecipe!, this.state.amountToCraft);
  }

  async refresh(): Promise<any> {
    await this.mutex.runExclusive(async () => {
      await this.refreshImpl();
    });
  }

  async refreshImpl() {
    if (
      this.props.ethereumService.walletData.walletAddress &&
      this.props.ethereumService.networkManager?.network?.chainId
    ) {
      this.setState({ isConnected: true });
    } else {
      this.setState({ isConnected: false });
      return;
    }

    if (this.props.ethereumService.walletData.blockNumber > this.state.refreshedAtBlock) {
      if (
        this.props.ethereumService.walletData.walletAddress !== this.walletAddress ||
        this.props.ethereumService.networkManager!.network?.chainId !== this.chainId
      ) {
        this.walletAddress = this.props.ethereumService.walletData.walletAddress;
        this.chainId = this.props.ethereumService.networkManager!.network!.chainId;
        await this.init();
      } else {
        await this.update();
      }
    }
  }

  async init(): Promise<any> {
    await this.loadItemService();
    await this.loadRewardService();
    await this.update();
  }

  async loadItemService() {
    if (!this.props.ethereumService.networkManager!.network!.itemConfig) {
      return;
    }

    if (!this.itemService) {
      this.itemService = new ItemService(this.props.messagingService, this.props.ethereumService);

      await this.loadCraftingService();
    }

    await this.itemService.update();
  }

  async loadRewardService() {
    if (!this.props.ethereumService.networkManager!.network!.rewardConfig) {
      return;
    }

    this.packService = new PackService(
      this.props.ethereumService.networkManager!.network!.rewardConfig!,
      this.props.ethereumService.networkManager!.network!.characterConfig!,
      this.itemService!,
      this.props.messagingService,
      this.props.ethereumService
    );
  }

  async loadCraftingService() {
    if (!this.props.ethereumService.networkManager!.network!.craftingConfig || !this.itemService) {
      return;
    }

    if (!this.craftingService) {
      this.craftingService = new CraftingService(
        this.props.ethereumService.networkManager!.network!.craftingConfig,
        this.itemService,
        this.props.messagingService,
        this.props.ethereumService
      );
      this.craftingService!.craftingContract!.on(this.recipeCraftedFilter, this.handleRecipeCrafted);
    }
  }

  hasEnoughToPay(): boolean {
    return BigNumber.from(this.state.paymentBalance).gte(this.state.curentRecipe!.price) ?? false;
  }

  private cloneCraftItem(
    item: IItemDetails,
    itemPartial: Partial<IItemDetails>,
    craftingDetailsPartial: Partial<ICraftingDetails>
  ): IItemDetails {
    return {
      ...item,
      ...itemPartial,
      craftingDetails: {
        ...item.craftingDetails,
        ...craftingDetailsPartial
      }
    };
  }

  private removeItemFromCraftingTable(item: IItemDetails) {
    if (this.state.amountToCraft > 1) {
      this.updateAmountToCraft(1);
    }

    var originalItemId = item.id * -1; // invert id to get original item id
    var udpatedReplacedOriginalItem = this.state.craftableItems.find(i => i.id === originalItemId);
    udpatedReplacedOriginalItem = this.cloneCraftItem(
      item,
      {
        id: originalItemId,
        balance: udpatedReplacedOriginalItem ? udpatedReplacedOriginalItem.balance + 1 : 1
      },
      {
        isDragging: false,
        isCrafting: false
      }
    );
    var updatedRemovedCraftingItem = this.cloneCraftItem(
      item,
      {
        balance: item.balance - 1
      },
      {
        isDragging: false,
        isCrafting: true
      }
    );

    var draggableItems = [
      ...this.state.craftableItems.filter(i => i.id !== udpatedReplacedOriginalItem!.id),
      udpatedReplacedOriginalItem
    ];
    var droppedItems =
      updatedRemovedCraftingItem.balance > 0
        ? [...this.state.craftingItems.filter(i => i.id !== updatedRemovedCraftingItem.id), updatedRemovedCraftingItem]
        : this.state.craftingItems.filter(i => i.id !== updatedRemovedCraftingItem.id);

    this.updateCraftingState(droppedItems, draggableItems);
  }

  private addItemToCraftingTable(item: IItemDetails) {
    if (this.state.amountToCraft > 1) {
      this.updateAmountToCraft(1);
    }

    var udpatedRemovedOriginalItem = this.cloneCraftItem(
      item,
      {
        balance: item.balance - 1
      },
      {
        isDragging: false,
        isCrafting: false
      }
    );

    var craftingItemId = item.id * -1; // invert id to get crafting item id
    var updatedDroppedCraftingItem = this.state.craftingItems.find(i => i.id === craftingItemId);
    updatedDroppedCraftingItem = this.cloneCraftItem(
      item,
      {
        id: craftingItemId,
        balance: updatedDroppedCraftingItem ? updatedDroppedCraftingItem.balance + 1 : 1
      },
      {
        isDragging: false,
        isCrafting: true
      }
    );

    var draggableItems =
      udpatedRemovedOriginalItem.balance > 0
        ? [...this.state.craftableItems.filter(i => i.id !== udpatedRemovedOriginalItem.id), udpatedRemovedOriginalItem]
        : [...this.state.craftableItems.filter(i => i.id !== udpatedRemovedOriginalItem.id)];

    var droppedItems = [
      ...this.state.craftingItems.filter(i => i.id !== updatedDroppedCraftingItem!.id),
      updatedDroppedCraftingItem
    ];

    this.updateCraftingState(droppedItems, draggableItems);
  }

  private updateAmountToCraft(amountToCraft: number, unselectRecipe: boolean = false) {
    const newAmountMultiplier = amountToCraft / this.state.amountToCraft;

    const updatedCraftableItems = this.state.craftingItems
      .map(craftingItem => {
        const craftableItem = this.state.craftableItems.find(i => i.id === craftingItem.id * -1);
        if (!craftableItem) return null;

        const updatedCraftableItem = this.cloneCraftItem(
          craftableItem,
          {
            balance: Math.round(
              craftableItem.balance - (craftingItem.balance * newAmountMultiplier - craftingItem.balance)
            )
          },
          {}
        );

        const updatedCraftingItem = this.cloneCraftItem(
          craftingItem,
          {
            balance: Math.round(craftingItem.balance * newAmountMultiplier)
          },
          {}
        );

        return [updatedCraftableItem, updatedCraftingItem];
      })
      .filter(item => item !== null)
      .reduce((acc, val) => acc!.concat(val!), []) as IItemDetails[];

    const newCraftingItems = this.state.craftingItems.map(item => {
      const updatedItem = updatedCraftableItems.find(updated => updated.id === item.id);
      return updatedItem ? { ...updatedItem } : item;
    });

    const newCraftableItems = this.state.craftableItems.map(item => {
      const updatedItem = updatedCraftableItems.find(updated => updated.id === item.id);
      return updatedItem ? { ...updatedItem } : item;
    });

    this.updateCraftingState(newCraftingItems, newCraftableItems);

    this.setState({ amountToCraft, isRecipeSelected: !unselectRecipe });
  }

  private canUpdateAmountToCraft(amountToCraft: number): boolean {
    var newAmountMultiplier = amountToCraft / this.state.amountToCraft;

    var canUpdate = true;
    this.state.craftingItems.forEach(craftingItem => {
      var craftableItem = this.state.craftableItems.find(i => i.id === craftingItem.id * -1);
      if (!craftableItem) return;
      if (craftableItem.balance - (craftingItem.balance * newAmountMultiplier - craftingItem.balance) < 0) {
        canUpdate = false;
      }
    });

    return canUpdate;
  }

  private updateCraftingState(craftingItems: IItemDetails[], craftableItems: IItemDetails[]) {
    var recipeStatus = this.craftingService!.getRecipe(this.getRecipeIngredients(craftingItems));

    Promise.all([
      this.craftingService!.getPaymentBalance(recipeStatus.recipe!),
      this.craftingService!.isPaymentApproved(recipeStatus.recipe!)
    ]).then(([paymentBalance, isCraftingPaymentApproved]) => {
      this.setState({
        activeDragItem: null,
        craftableItems: craftableItems,
        craftingItems: craftingItems,
        curentRecipe: recipeStatus.recipe,
        ingredientStatus: recipeStatus.status,
        paymentBalance: paymentBalance,
        isCraftingPaymentApproved: isCraftingPaymentApproved
      });
    });
  }

  sortItems(a: IItemDetails, b: IItemDetails): number {
    var result = 0;
    switch (this.state.assetsDisplayOrder) {
      case AssetDisplayOrder.Balance:
        result = b.balance - a.balance;
        break;
      case AssetDisplayOrder.Rarity:
        result = b.rarity - a.rarity;
        break;
    }

    result = result === 0 ? (a.name < b.name ? -1 : 1) : result;
    return this.state.sortAzDirection ? result : -result;
  }

  sortCraftingItems(a: IItemDetails, b: IItemDetails): number {
    var result = a.name < b.name ? -1 : 1;
    return this.state.sortAzDirection ? result : -result;
  }

  getRecipeIngredients(items: IItemDetails[]): IRecipeIngredient[] {
    return items.map(i => {
      return {
        itemId: i.id * -1, // invert id to get original item id
        amount: i.balance
      };
    });
  }

  hasRecipeIngredients() {
    return;
  }

  getItemTypeClassName(itemType: string, i: IItemDetails) {
    return itemType === 'item' && i.itemType !== 1 ? 'material' : itemType;
  }

  private handleCraftingItemClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, i: IItemDetails) => {
    if (this.lastClick && e.timeStamp - this.lastClick < 350 && this.waitingClick) {
      this.lastClick = 0;
      if (this.waitingClick) {
        clearTimeout(this.waitingClick);
        this.waitingClick = null;
      }
      this.onItemDblClicked(i);
    } else {
      this.lastClick = e.timeStamp;
      this.waitingClick = setTimeout(() => {
        this.waitingClick = null;
      }, 351);

      if (i.id < 0) {
        // crafting item
        this.setState({ selectedItem: i });
      }
    }
  };

  async update(): Promise<any> {
    var itemServiceState = {};
    var packServiceState = {};

    var artifactItemsNames = ['Map Artifact', 'Statue Artifact'];

    if (this.itemService) {
      await this.itemService!.update();

      var materials = this.itemService!.allItems.filter((i: IItemDetails) => i.itemType === 0);
      var items = this.itemService!.allItems.filter(
        (i: IItemDetails) => i.itemType === 1 && !artifactItemsNames.includes(i.name)
      );
      var artifacts = this.itemService!.allItems.filter(
        (i: IItemDetails) =>
          ((i.itemType === 1 && artifactItemsNames.includes(i.name)) || i.itemType === 2) && !i.name.includes('Statue')
      );

      itemServiceState = {
        loaded: true,
        materials: materials,
        items: items,
        artifacts: artifacts,
        isCraftingItemUseApproved: await this.craftingService?.isCraftingApproved()
      };
    }

    if (this.packService) {
      await this.packService!.update();

      var claimItems = Array.from(this.packService!.packClaimsMap, ([index, claims]) => ({ index, claims }))
        .map(x => {
          return {
            index: x.index,
            claimItems: x.claims.map(c => {
              return {
                received: BigNumber.from(c.amount).toNumber(),
                blockNumber: c.blockNumber,
                item: this.itemService!.itemMap.get(BigNumber.from(c.item).toNumber())
              };
            })
          };
        })
        .sort((a, b) => a.index - b.index);

      packServiceState = {
        rewards: this.packService!.allPacksWithBalance,
        claimItems: claimItems
      };
    }

    this.setState({
      refreshedAtBlock: this.props.ethereumService!.walletData.blockNumber,
      ...itemServiceState,
      ...packServiceState
    });
  }

  render() {
    return (
      <section id="content" className="barn is-vcentered">
        {this.renderZoomedItem()}
        {this.renderZoomedPack()}
        {this.renderZoomedBuyPack()}
        <div className="container">
          {!this.state.isConnected && (
            <span
              className="column meme is-half has-text-centered mt-4"
              style={{ cursor: 'pointer' }}
              onClick={() => this.props.ethereumService.connectWallet()}>
              <h3 className="sub-title py-3">Connect Wallet</h3>
            </span>
          )}
          {this.state.isConnected && (
            <>
              {!this.itemService && (
                <span className="column meme is-half has-text-centered mt-4">
                  <h3 className="sub-title py-3">Loading</h3>
                </span>
              )}
              {this.itemService && (
                <div className="tile is-ancestor mx-3 ,">
                  <div className="tile is-vertical is-12 mb-6">
                    <div className="field pl-1 mt-1">
                      <div className="is-flex is-flex-wrap-wrap is-justify-content-center">
                        <input
                          id="assetsDisplayed"
                          type="checkbox"
                          name="assetsDisplayed"
                          disabled={this.state.mode !== Mode.Display}
                          className={`${
                            this.state.assetsDisplayed === AssetsToDisplay.Wallet ? 'is-active' : ''
                          }  switch is-rounded is-small`}
                          onClick={() => this.toggleAssetsDisplayed()}
                          defaultChecked={this.state.assetsDisplayed === AssetsToDisplay.Wallet}
                        />
                        <label htmlFor="assetsDisplayed">
                          <span className="has-light-text noselect" style={{ marginTop: '-3px' }}>
                            Hide Zero Balances
                          </span>
                        </label>
                        <span className="icon ml-5 has-action-text mb-3" onClick={this.toggleSortDirection}>
                          {this.state.sortAzDirection && <i className="fas fa-sort-amount-down"></i>}
                          {!this.state.sortAzDirection && <i className="fas fa-sort-amount-up"></i>}
                        </span>
                        <div className="select is-small ml-1">
                          <select
                            className="is-control"
                            value={this.state.assetsDisplayOrder}
                            onChange={e => this.onAssetsDisplayOrderChanged(+e.target.value)}>
                            <option value={0}>A-Z</option>
                            <option value={1}>Balance</option>
                            <option value={2}>Rarity</option>
                          </select>
                        </div>
                        <div className="fill-remaining-space is-hidden-mobile"></div>
                        {this.craftingService && (
                          <>
                            {this.state.mode !== Mode.Display && !isMobile && (
                              <>
                                {' '}
                                <input
                                  id="craftDnD"
                                  type="checkbox"
                                  name="craftDnD"
                                  className="is-active switch is-rounded is-small"
                                  onClick={() => this.toggleCraftDnD()}
                                  defaultChecked={this.state.craftDnD}
                                />
                                <label htmlFor="craftDnD">
                                  <span className="has-light-text noselect mr-4" style={{ marginTop: '-3px' }}>
                                    <>
                                      {!this.state.craftDnD && 'Dbl Click to Craft'}
                                      {this.state.craftDnD && 'Drag & Drop to Craft'}
                                    </>
                                  </span>
                                </label>
                              </>
                            )}

                            <div className="field has-addons">
                              <p className="control">
                                <button
                                  className={`${
                                    this.state.mode === Mode.Display ? 'is-active-control' : 'is-control'
                                  } button is-dark is-small`}
                                  onClick={() => this.onModeChanged(Mode.Display)}
                                  style={{
                                    borderTopLeftRadius: '4px',
                                    borderBottomLeftRadius: '4px',
                                    borderRight: 'solid 2px var(--bg-grey2)'
                                  }}>
                                  <span className="icon is-small">
                                    <i className="fas fa-eye"></i>
                                  </span>
                                  <span>Display</span>
                                </button>
                              </p>
                              <p className="control">
                                <button
                                  className={`${
                                    this.state.mode === Mode.CraftItems ? 'is-active-control' : 'is-control'
                                  } button is-dark is-small`}
                                  onClick={() => this.onModeChanged(Mode.CraftItems)}>
                                  <span className="icon is-small">
                                    <i className="fas fa-shield-alt"></i>
                                  </span>
                                  <span>Craft Items</span>
                                </button>
                              </p>
                              <p className="control">
                                <button
                                  className={`${
                                    this.state.mode === Mode.CraftArtifacts ? 'is-active-control' : 'is-control'
                                  } button is-dark is-small`}
                                  onClick={() => this.onModeChanged(Mode.CraftArtifacts)}
                                  style={{
                                    borderTopRightRadius: '4px',
                                    borderBottomRightRadius: '4px',
                                    borderLeft: 'solid 2px var(--bg-grey2)'
                                  }}>
                                  <span className="icon is-small">
                                    <i className="fas fa-scroll"></i>
                                  </span>
                                  <span>Craft Artifacts</span>
                                </button>
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    {this.state.loaded && (
                      <>
                        {this.state.materials.length > 0 && this.state.mode === Mode.Display && (
                          <CollapsibleCard
                            title="MATERIALS"
                            key={'MATERIALS' + this.state.materials.length + this.state.assetsDisplayed}
                            bgColor="#382510"
                            id="barn-materials-card">
                            {this.renderItems(this.state.materials, 'material')}
                          </CollapsibleCard>
                        )}
                        {this.state.items.length > 0 && this.state.mode === Mode.Display && (
                          <CollapsibleCard
                            title="ITEMS"
                            key={'ITEMS' + this.state.items.length + this.state.assetsDisplayed}
                            bgColor="#00695c80"
                            id="barn-items-card">
                            {this.renderItems(this.state.items, 'item')}
                          </CollapsibleCard>
                        )}
                        {this.state.artifacts.length > 0 && this.state.mode === Mode.Display && (
                          <CollapsibleCard
                            title="ARTIFACTS"
                            key={'ARTIFACTS' + this.state.artifacts.length + this.state.assetsDisplayed}
                            bgColor="#69000080"
                            id="barn-artifacts-card">
                            {this.renderItems(this.state.artifacts, 'artifact')}
                          </CollapsibleCard>
                        )}
                        {this.state.mode === Mode.Display && (
                          <CollapsibleCard
                            title="PACKS"
                            key={
                              'PACKS' +
                              this.state.rewards.length +
                              this.state.claimItems.length +
                              this.packService?.isMintingApproved
                            }
                            bgColor="#2e319485"
                            id="barn-packs-card">
                            {this.renderPacks()}
                          </CollapsibleCard>
                        )}
                        {this.state.materials.length > 0 && this.state.mode === Mode.CraftItems && (
                          <CollapsibleCard
                            title="CRAFT ITEMS"
                            key={
                              'CRAFTITEMS' +
                              this.state.materials.length +
                              this.state.items.length +
                              this.state.craftingItems.length +
                              this.state.isCraftingItemUseApproved +
                              this.state.ingredientStatus +
                              this.state.isRecipeSelected
                            }
                            initialCollapsed={false}
                            bgColor="#382510"
                            id="barn-materials-card">
                            {this.state.mode === Mode.CraftItems && this.renderCraftTable('item')}
                          </CollapsibleCard>
                        )}
                        {this.state.artifacts.length > 0 && this.state.mode === Mode.CraftArtifacts && (
                          <CollapsibleCard
                            title="CRAFT ARTIFACTS"
                            key={
                              'CRAFTARTIFACTS' +
                              this.state.artifacts.length +
                              this.state.craftingItems.length +
                              this.state.isCraftingItemUseApproved +
                              this.state.ingredientStatus +
                              this.state.isRecipeSelected
                            }
                            initialCollapsed={false}
                            bgColor="#69000080"
                            id="barn-artifacts-card">
                            {this.state.mode === Mode.CraftArtifacts && this.renderCraftTable('artifact')}
                          </CollapsibleCard>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </section>
    );
  }

  renderCraftTable(itemType: string) {
    return this.state.craftDnD ? this.renderCraftTableDragAndDrop(itemType) : this.renderCraftTableDblClick(itemType);
  }

  renderCraftTableDragAndDrop(itemType: string) {
    return (
      <>
        <DndContext onDragStart={this.handleDragStart} onDragEnd={this.handleDragEnd}>
          <Droppable id={'items'}>
            <div className="grid mt-4">
              {this.state.craftableItems.sort(this.sortItems).map(i => (
                <Draggable key={i.id} id={i.id} data={i}>
                  {this.renderItem(i, itemType, true)}
                </Draggable>
              ))}
            </div>
            {this.state.craftableItems.length === 0 && (
              <span className="column meme is-half has-text-centered mt-3">
                <h3 className="empty-items pt-3">No Craftables Remaining</h3>
              </span>
            )}
          </Droppable>

          {this.renderCraftTableStatus()}

          {this.state.isRecipeSelected && <> {this.renderRecipeItem()} </>}
          {!this.state.isRecipeSelected && (
            <Droppable id={'table'}>
              {this.state.craftingItems.length > 0 ? (
                <div className="grid mt-4 mb-3">
                  {this.state.craftingItems.sort(this.sortCraftingItems).map(i => (
                    <>{this.renderItem(i, itemType, true)}</>
                  ))}
                </div>
              ) : (
                <>{this.renderCraftingApprove()}</>
              )}
            </Droppable>
          )}
        </DndContext>
        {this.renderCraftTableControls()}
      </>
    );
  }

  renderCraftTableDblClick(itemType: string) {
    return (
      <>
        <ScrollWrapper inView={this.state.inView}>
          <div className="grid mt-4">
            {this.state.craftableItems.sort(this.sortItems).map(i => (
              <>{this.renderItem(i, itemType, true)}</>
            ))}
          </div>
          {this.state.craftableItems.length === 0 && (
            <span className="column meme is-half has-text-centered mt-3">
              <h3 className="empty-items pt-3">No Craftables Remaining</h3>
            </span>
          )}
          <InView onChange={this.setInView}>
            {({ ref }) => (
              <ViewWrapper ref={ref}>
                {this.renderCraftTableStatus()}

                {this.state.isRecipeSelected && <> {this.renderRecipeItem()} </>}
                {!this.state.isRecipeSelected && (
                  <>
                    {this.state.craftingItems.length > 0 ? (
                      <div className="grid mt-4 mb-3">
                        {this.state.craftingItems.sort(this.sortCraftingItems).map(i => (
                          <>{this.renderItem(i, itemType, true)}</>
                        ))}
                      </div>
                    ) : (
                      <>{this.renderCraftingApprove()}</>
                    )}
                  </>
                )}
                {this.renderCraftTableControls()}
              </ViewWrapper>
            )}
          </InView>
          {!this.state.inView && (
            <div className="sticky-craft-table">
              {this.renderCraftTableStatus()}

              {this.state.isRecipeSelected && <> {this.renderRecipeItem()} </>}
              {!this.state.isRecipeSelected && (
                <>
                  {this.state.craftingItems.length > 0 ? (
                    <div className="grid mt-4 mb-3">
                      {this.state.craftingItems.sort(this.sortCraftingItems).map(i => (
                        <>{this.renderItem(i, itemType, true)}</>
                      ))}
                    </div>
                  ) : (
                    <>{this.renderCraftingApprove()}</>
                  )}
                </>
              )}
              {this.renderCraftTableControls()}
            </div>
          )}
        </ScrollWrapper>
      </>
    );
  }

  renderRecipeItem() {
    var recipeItem = this.itemService?.itemMap.get(this.state.curentRecipe!.itemId)!;
    var craftingRecipeItemId = recipeItem.id * -1; // invert id to get crafting item id
    recipeItem = this.cloneCraftItem(
      recipeItem,
      {
        id: craftingRecipeItemId,
        balance: this.state.amountToCraft
      },
      {
        isDragging: false,
        isCrafting: true
      }
    );

    return <div className="grid recipe-selected mt-4">{this.renderItem(recipeItem, 'item', true)}</div>;
  }

  renderCraftingApprove() {
    return (
      <>
        {!this.state.isCraftingItemUseApproved && (
          <span className="column has-text-centered">
            <button className="is-action button" onClick={() => this.craftingService?.approveCrafting()}>
              Approve Item Use
            </button>
          </span>
        )}
        {this.state.isCraftingItemUseApproved && (
          <span className="column meme mb-3">
            <h3 className="empty-items pt-3">
              {!this.state.craftDnD && isMobile && <>Double Tap To Add</>}
              {!this.state.craftDnD && !isMobile && <>Double Click To Add</>}
              {this.state.craftDnD && <>Drop Here</>}
            </h3>
          </span>
        )}
      </>
    );
  }

  renderCraftTableStatus() {
    return (
      <div className="craft-status divider is-dark">
        {this.state.craftingItems.length === 0 && <span>CRAFTING TABLE</span>}
        {this.state.craftingItems.length > 0 && (
          <>
            {!this.state.curentRecipe && <span>Hmmm, not sure</span>}
            {this.state.curentRecipe && (
              <>
                {this.state.ingredientStatus === IngredientStatus.MissingIngredient && 'Keep going'}
                {this.state.ingredientStatus === IngredientStatus.WrongAmounts && 'You are on to something'}
                {this.state.ingredientStatus === IngredientStatus.Match && (
                  <>
                    {!this.state.isRecipeSelected && <span>{this.state.curentRecipe.name} recipe matched</span>}
                    {this.state.isRecipeSelected && <span>{this.state.curentRecipe.name} recipe selected</span>}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  }

  renderCraftTableControls() {
    return (
      <div className="craft-controls mt-1">
        {this.state.curentRecipe && (
          <>
            <span className="column has-text-centered">
              {!this.state.isCraftingPaymentApproved && (
                <button
                  className="is-action button"
                  onClick={() => {
                    this.craftingService?.approvePayment(this.state.curentRecipe!);
                  }}>
                  Approve {this.craftingService?.getPaymentSymbol(this.state.curentRecipe!)} Use
                </button>
              )}
              {this.state.isCraftingPaymentApproved && !this.state.isRecipeSelected && (
                <div className="is-flex is-flex-direction-row is-justify-content-center">
                  <button
                    className={`is-action button ${
                      this.state.ingredientStatus === IngredientStatus.Match && this.hasEnoughToPay()
                        ? 'craft-button'
                        : ''
                    }`}
                    disabled={this.state.ingredientStatus !== IngredientStatus.Match || !this.hasEnoughToPay()}
                    onClick={this.handleSelectRecipe}>
                    {this.state.ingredientStatus === IngredientStatus.MissingIngredient && 'Missing Ingredients'}
                    {this.state.ingredientStatus === IngredientStatus.WrongAmounts && 'Needs A Bit More'}
                    {this.state.ingredientStatus === IngredientStatus.Match &&
                      !this.hasEnoughToPay() &&
                      'Not Enough Funds'}
                    {this.state.ingredientStatus === IngredientStatus.Match && this.hasEnoughToPay() && 'Select Recipe'}
                  </button>
                </div>
              )}
              {this.state.isCraftingPaymentApproved && this.state.isRecipeSelected && (
                <div className="is-flex is-flex-direction-row is-justify-content-center">
                  <button className="is-ghost-action button craft-button left" onClick={this.handleUnselectRecipe}>
                    Deselect Recipe
                  </button>
                  <button
                    className="is-action button craft-button right ml-3"
                    disabled={this.state.ingredientStatus !== IngredientStatus.Match || !this.hasEnoughToPay()}
                    onClick={this.handleCraftItems}>
                    {this.state.ingredientStatus === IngredientStatus.Match &&
                      !this.hasEnoughToPay() &&
                      'Not Enough Funds'}
                    {this.state.ingredientStatus === IngredientStatus.Match && this.hasEnoughToPay() && (
                      <>Craft Item(s)</>
                    )}
                  </button>
                </div>
              )}
            </span>
            {this.state.isCraftingPaymentApproved && this.state.isRecipeSelected && (
              <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                <span className="has-light-text">
                  Cost {Utils.toFixed(+Utils.formatUnits(this.state.curentRecipe!.price), 2)}{' '}
                  {this.craftingService!.getPaymentSymbol(this.state.curentRecipe!)} (+ gas)
                </span>
                <span className={`is-size-7 ${!this.hasEnoughToPay() ? 'has-warning-text' : 'has-light-text'}`}>
                  {Utils.toFixed(+Utils.formatUnits(this.state.paymentBalance), 2)} in wallet
                </span>
              </div>
            )}
          </>
        )}
      </div>
    );
  }

  renderItems(items: IItemDetails[], itemType: string) {
    var filtered = items
      .filter(i => this.state.assetsDisplayed === AssetsToDisplay.All || i.balance > 0)
      .sort(this.sortItems);

    if (filtered.length > 0) {
      return <div className="grid mt-4">{filtered.map(i => this.renderItem(i, itemType))}</div>;
    } else {
      return (
        <span className="column meme is-half has-text-centered mt-3">
          <h3 className="empty-items pt-3">Empty</h3>
        </span>
      );
    }
  }

  private renderItem(i: IItemDetails, itemType: string, isCrafting: boolean = false): JSX.Element {
    itemType = this.getItemTypeClassName(itemType, i);

    var extraClass = '';
    if (i.name === 'Map Artifact') {
      extraClass = 'whole-artifact';
    }

    if (this.state.selectedItem?.id === i.id) {
      extraClass += 'is-active-item';
    }

    if (isCrafting) {
      return (
        <div className="grid-item-container is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
          <div
            className={`grid-item is-${itemType} ${extraClass} ${Utils.toRarityDisplay(i.rarity)}`}
            key={i.id}
            onClick={e => this.handleCraftingItemClick(e, i)}>
            {this.renderItemInner(i, isCrafting)}
          </div>

          <div className="crafting-item-controls">
            <>
              {i.balance > 0 && i.id < 0 && (
                <div className="field has-addons mt-1">
                  <div className="control">
                    <button
                      className="button minus-button is-action is-small"
                      disabled={this.state.isRecipeSelected ? this.state.amountToCraft <= 1 : i.balance <= 0}
                      onClick={() =>
                        this.state.isRecipeSelected
                          ? this.handleCraftAmountChangeValue(-1)
                          : this.handleRecipeItemAmountChange(i, -1)
                      }>
                      <span className="icon">
                        <i className="fas fa-minus"></i>
                      </span>
                    </button>
                  </div>
                  <div className="control">
                    <button
                      className="button plus-button is-action is-small"
                      disabled={
                        this.state.isRecipeSelected
                          ? !this.canUpdateAmountToCraftByValue(1)
                          : !this.canUpdateRecipeItemAmountByValue(i, 1)
                      }
                      onClick={() =>
                        this.state.isRecipeSelected
                          ? this.handleCraftAmountChangeValue(1)
                          : this.handleRecipeItemAmountChange(i, 1)
                      }>
                      <span className="icon">
                        <i className="fas fa-plus"></i>
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      );
    } else {
      return (
        <div className="grid-item-container is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
          <div
            className={`grid-item is-${itemType} ${extraClass} ${Utils.toRarityDisplay(i.rarity)}`}
            key={i.id}
            onClick={() => this.setZoomedItem(i)}>
            {this.renderItemInner(i, isCrafting)}
          </div>
        </div>
      );
    }
  }

  private renderItemInner(i: IItemDetails, isCrafting: boolean = false): JSX.Element {
    return (
      <>
        <div className="rarity-display">
          <span className="item-rarity">{Utils.toRarityDisplay(i.rarity)}</span>
        </div>
        <img alt={i.name} className="token" src={i.imageUrl.replace('.png', 's.png')} />
        <div className="item-title">
          <div>
            <span className="item-name">
              {i.name}
              {/* {i.id} */}
            </span>
            {i.itemType !== 2 && (
              <>
                {i.balance > 0 && <span className="item-balance pl-2">{Utils.numberToShortDisplay(i.balance)}</span>}
                {!i.balance && <span className="item-balance pl-2">-</span>}
              </>
            )}
            {i.itemType === 2 && (
              <>
                {i.balance > 0 && isCrafting && (
                  <span className="item-balance pl-2">{Utils.numberToShortDisplay(i.balance)}</span>
                )}
                {i.balance > 0 && !isCrafting && (
                  <span className="item-balance pl-2">
                    <i style={{ color: 'green', display: 'inline' }} className="fas fa-check"></i>
                  </span>
                )}
                {!i.balance && <span className="item-balance pl-2">-</span>}
              </>
            )}
          </div>
        </div>
      </>
    );
  }

  renderPacks() {
    return (
      <div className="grid mt-4 mb-4">
        {this.packService?.allPacksWithBalance &&
          this.packService?.allPacksWithBalance.map(p => (
            <div className="grid-item-container is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
              <div key={p.index + p.pack.name} className="grid-item is-pack" onClick={() => this.setZoomedPack(p)}>
                <img
                  alt={p.pack.name}
                  className="pack-image"
                  src={'./assets/packs/.png'.replace('.png', `${p.pack.id}s.png`)}
                />
                <div className="item-title">
                  <div>
                    <span className="item-name pt-2">{p!.pack.name}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        {this.state.claimItems.length > 0 && (
          <>
            {this.state.claimItems.map((i: any) => (
              <div className="grid-item-container is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                <div key={i.index} className="grid-item">
                  <span className="claim-title">Claimed</span>
                  <div className="claim-items">
                    {i.claimItems.map((ci: IClaimItems, i: number) => (
                      <div key={ci.item.id + i}>
                        <span className="claim-name">{ci.item?.name}</span>
                        <span className="claim-amount pl-2">{ci.received}</span>
                      </div>
                    ))}
                  </div>
                  <button className="button is-ghost-action mt-3" onClick={() => this.dismissClaimed(i.index)}>
                    Dismiss
                  </button>
                </div>
              </div>
            ))}
          </>
        )}
        {!this.packService?.isMintingApproved && (
          <>
            <div className="grid-item-container is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
              <div className="grid-item is-approve-buy" onClick={() => this.approveMint()}>
                <img alt="Approve Buy" className="pack-image" src={'./assets/packs/2s-buy.png'} />
                <div className="item-title">
                  <div>
                    <span className="item-name">Approve Buy</span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {this.packService?.isMintingApproved && (
          <>
            {this.packService.buyablePacks.map(p => (
              <div className="grid-item-container is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                <div key={p.id + p.name} className="grid-item is-buy-pack" onClick={() => this.setZoomedBuyPack(p)}>
                  <img
                    alt={p.name}
                    className="pack-image"
                    src={'./assets/packs/.png'.replace('.png', `${p.id}s-buy.png`)}
                  />
                  <div className="item-title">
                    <div>
                      <div className="item-name pt-2">Buy {p.name}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    );
  }

  renderZoomedItem() {
    return (
      <>
        {this.state.zoomedItem && (
          <div className="is-active modal">
            <div className="modal-background" onClick={() => this.setZoomedItem(null)}></div>
            <div className="modal-content characters zoomed-item">
              <button
                className="modal-close is-medium"
                aria-label="close"
                onClick={() => this.setZoomedItem(null)}></button>
              <img alt={this.state.zoomedItem.name} className="token" src={this.state.zoomedItem.imageUrl} />
              <span className="item-name px-4">
                {this.state.zoomedItem.name} (#{this.state.zoomedItem.id})
              </span>
              <span className="item-description px-4">{this.state.zoomedItem.description}</span>
              {this.state.zoomedItem.balance > 0 && (
                <span className="item-balance px-4">
                  You have {Utils.numberToShortDisplay(this.state.zoomedItem.balance)} available
                </span>
              )}
              {!this.state.zoomedItem.balance && <span className="item-balance px-4">You have none available</span>}
            </div>
          </div>
        )}
      </>
    );
  }

  renderZoomedPack() {
    return (
      <>
        {this.state.zoomedPack && (
          <div className="is-active modal">
            <div className="modal-background" onClick={() => this.setZoomedPack(null)}></div>
            <div className="modal-content characters zoomed-pack">
              <button
                className="modal-close is-medium"
                aria-label="close"
                onClick={() => this.setZoomedPack(null)}></button>
              <img
                alt={this.state.zoomedPack.pack.name}
                className="pack-image"
                src={'./assets/packs/.png'.replace('.png', `${this.state.zoomedPack.pack.id}.png`)}
              />
              <span className="item-name px-4">
                {this.sizeToSizeString(this.state.zoomedPack.balance)} {this.state.zoomedPack.pack.name}
              </span>
              <span className="item-description px-4">{this.state.zoomedPack.pack.description}</span>
              <button className="button is-action my-3" onClick={() => this.openPack(this.state.zoomedPack!)}>
                Open {this.state.zoomedPack.pack.name}
              </button>
            </div>
          </div>
        )}
      </>
    );
  }

  renderZoomedBuyPack() {
    return (
      <>
        {this.state.zoomedBuyPack && (
          <div className="is-active modal">
            <div className="modal-background" onClick={() => this.setZoomedBuyPack(null)}></div>
            <div className="modal-content characters zoomed-buy-pack">
              <button
                className="modal-close is-medium"
                aria-label="close"
                onClick={() => this.setZoomedBuyPack(null)}></button>
              <img
                alt={this.state.zoomedBuyPack.name}
                className="pack-image"
                src={'./assets/packs/.png'.replace('.png', `${this.state.zoomedBuyPack.id}-buy.png`)}
              />
              <span className="item-name px-4">{this.state.zoomedBuyPack.name}</span>
              <span className="item-description-fixed px-4">{this.state.zoomedBuyPack.description}</span>
              <div className="is-flex my-5" style={{ margin: 'auto' }}>
                <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center character-mint buy-buttons">
                  <ul className="action-control">
                    <li className="action-form">
                      <div className="field has-addons">
                        <div className="control">
                          <button
                            className="button minus-button is-action"
                            disabled={this.state.mintPackAmount <= 1}
                            onClick={() => this.handleAmountToMintChange(-1)}>
                            <span className="icon">
                              <i className="fas fa-minus"></i>
                            </span>
                          </button>
                        </div>
                        <div className="control">
                          <input
                            className="input is-action"
                            type="text"
                            value={this.amountToSizeString(this.state.mintPackAmount)}
                            onChange={this.handleAmountToMint}
                          />
                        </div>
                        <div className="control">
                          <button
                            className="button plus-button is-action"
                            disabled={this.state.mintPackAmount >= this.maxMintPackAmount}
                            onClick={() => this.handleAmountToMintChange(+1)}>
                            <span className="icon">
                              <i className="fas fa-plus"></i>
                            </span>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li>
                      <button className="button boost-button is-action is-fullwidth" onClick={this.mintPack}>
                        Buy {this.state.zoomedBuyPack.name}
                      </button>
                    </li>
                  </ul>

                  <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center mt-5">
                    <p>
                      <span className="mr-1">
                        Cost {this.state.zoomedBuyPack.price * this.amountToSize(this.state.mintPackAmount)}
                      </span>
                      CORN (+ gas)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default Barn;
