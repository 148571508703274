import { BigNumber } from '@ethersproject/bignumber';
import unrevealed from '../assets/unrevealed.png';
import { getImageSrc } from '../components/Characters/ImagePart';
import { Utils } from '../services/Utils';
import vb from '../assets/avatars/vitalik.jpg';

export enum CharacterStat {
  Stamina = 1,
  Strength = 2,
  Speed = 3,
  Courage = 4,
  Intelligence = 5,
  Health = 6,
  Morale = 7
}

export interface ICharacterAttribute {
  id: number;
  display_type: string;
  trait_type: string;
  badge_type: string;
  value: any;
}

export interface ICharacterStats {
  name: string;
  value: number;
}

export interface IImageParts {
  background: string;
  base: string;
  gender: string;
  hair: string;
  eyes: string;
  mouth: string;
  clothing: string;
  feature: string;
}

export interface ICharacterWrapper {
  index: number;
  id: number;
  uri: string;
  inView: boolean;
  details: ICharacterDetails | null;
  detailsRefreshedAtBlock: number;
}

export interface ICharacterDetails {
  symbol: string;
  index: number;
  id: number;
  attributes: ICharacterAttribute[];
  stats: ICharacterStats[];
  morale: number;
  xp: number | undefined;
  level: number | undefined;
  shortDescription: string;
  description: string;
  type: string;
  image?: string;
  imageParts?: IImageParts;
  revealed: boolean;
  name: string;
  statBoostsAvailable: number;
}

export interface ICharactersActivity {
  isActive: boolean;
  numberOfActivities: number;
  activityDuration: number;
  startBlock: number;
  endBlock: number;
  completedBlock: number;
}

export interface ICharacterStat {
  name: string;
  bottomColor: string;
  topColor: string;
  max: number;
  remaining: number;
}

export function getStatPercent(stat: ICharacterStat) {
  if (!stat) return 0;
  var statPercent = (stat!.remaining / stat!.max) * 100;
  return statPercent > 100 ? 100 : statPercent;
}

export function isStatMax(stat: ICharacterStat): boolean {
  if (!stat) return false;
  return stat.remaining === 99;
}

export function isStatFull(stat: ICharacterStat): boolean {
  if (!stat) return false;
  return stat.remaining >= stat.max;
}

export async function loadCitizenDetails(
  index: number,
  tokenId: BigNumber,
  tokenUri: string,
  stats: any,
  boostsRemaining: number
): Promise<ICharacterDetails> {
  var character: ICharacterDetails = await loadCitizenCharacterFromUri(index, tokenId, tokenUri, boostsRemaining);

  if (character.revealed) {
    character.stats.push({ name: 'Stamina', value: stats['trait' + CharacterStat.Stamina].toNumber() });
    character.stats.push({ name: 'Strength', value: stats['trait' + CharacterStat.Strength].toNumber() });
    character.stats.push({ name: 'Speed', value: stats['trait' + CharacterStat.Speed].toNumber() });
    character.stats.push({ name: 'Courage', value: stats['trait' + CharacterStat.Courage].toNumber() });
    character.stats.push({ name: 'Intelligence', value: stats['trait' + CharacterStat.Intelligence].toNumber() });
  }

  updateCitizenStatBasedAttributes(character);

  return character;
}

export async function loadCitizenMercenaryDetails(
  index: number,
  tokenId: BigNumber,
  tokenUri: string,
  stats: any,
  statBoostsAvailable: number
): Promise<ICharacterDetails> {
  var character: ICharacterDetails = await loadCitizenCharacterFromUri(index, tokenId, tokenUri, statBoostsAvailable);
  applyStats(character, stats);
  updateCitizenStatBasedAttributes(character);

  return character;
}

export async function loadMercenaryDetails(
  tokenId: BigNumber,
  wrappedTokenId: BigNumber | null,
  tokenUri: string,
  stats: any,
  statBoostsAvailable: number
): Promise<ICharacterDetails> {
  var character: ICharacterDetails = {
    index: -1,
    id: tokenId.toNumber(),
    name: 'Unsupported',
    shortDescription: 'Unsupported NFT',
    description: 'Unsupported NFT',
    type: 'Unsupported',
    attributes: [],
    statBoostsAvailable: 0,
    revealed: true,
    stats: [],
    symbol: 'MERCENARY',
    morale: 0,
    xp: 0,
    level: 0
  };

  applyStats(character, stats);

  try {
    if (tokenUri.startsWith('data:application/json;base64,')) {
      character = await loadNativeMercenaryDetails(character, tokenId, tokenUri);
    } else if (tokenUri.includes('arbidudes')) {
      character = await loadArbidudeMercenaryDetails(character, wrappedTokenId!);
    } else if (tokenUri.includes('castledao')) {
      if (tokenUri.includes('defihero')) {
        character = await loadDefiHeroMercenaryDetails(character, wrappedTokenId!, tokenUri);
      } else if (tokenUri.includes('generals')) {
        character = await loadCryptoGeneralsMercenaryDetails(character, wrappedTokenId!, tokenUri);
      }
    }
  } catch (error) {}

  character.statBoostsAvailable = statBoostsAvailable;

  return character;
}

function applyStats(character: ICharacterDetails, stats: any) {
  character.stats.push({ name: 'Stamina', value: stats['stamina'].toNumber() });
  character.stats.push({ name: 'Strength', value: stats['strength'].toNumber() });
  character.stats.push({ name: 'Speed', value: stats['speed'].toNumber() });
  character.stats.push({ name: 'Courage', value: stats['courage'].toNumber() });
  character.stats.push({ name: 'Intelligence', value: stats['intelligence'].toNumber() });
  character.morale = stats['morale'].toNumber();
  character.xp = stats['experience'].toNumber();
  character.level = stats['level'].toNumber();
}

async function loadNativeMercenaryDetails(
  character: ICharacterDetails,
  tokenId: BigNumber,
  tokenUri: string
): Promise<ICharacterDetails> {
  character = Object.assign(character, {
    name: `Mercenary ${tokenId.toNumber()}`,
    shortDescription: 'A common Farmland Mercenary',
    description: 'A common Farmland Mercenary',
    type: 'Mercenary'
  });

  var attributes = getMercenaryAttributesFromTokenUri(tokenUri);
  if (attributes) {
    character.imageParts = {
      background: getAttributeValue(attributes, 'background'),
      base: getAttributeValue(attributes, 'base'),
      hair: getAttributeValue(attributes, 'hair'),
      gender: getAttributeValue(attributes, 'gender'),
      eyes: getAttributeValue(attributes, 'eyes'),
      mouth: getAttributeValue(attributes, 'mouth'),
      clothing: getAttributeValue(attributes, 'clothing'),
      feature: getAttributeValue(attributes, 'feature')
    };

    character.image = getImageSrc('background', character.imageParts.background);
  }

  return character;
}

async function loadArbidudeMercenaryDetails(
  character: ICharacterDetails,
  tokenId: BigNumber
): Promise<ICharacterDetails> {
  return Object.assign(character, {
    name: `Abidude ${tokenId.toNumber()}`,
    description: 'Your friends from a parallel metaverse',
    shortDescription: 'A friend from a parallel metaverse',
    type: 'Abidude',
    attributes: [],
    symbol: 'MERCENARY',
    image: `https://www.arbidudes.com/build/images/${tokenId.toNumber()}.png`
  });
}

async function loadDefiHeroMercenaryDetails(
  character: ICharacterDetails,
  tokenId: BigNumber,
  tokenUri: string
): Promise<ICharacterDetails> {
  const tokenResponse = await fetch(tokenUri.replace('https://', 'https://www.'));
  var meta = await tokenResponse.json();
  var imgSrc = meta.image.replace('ipfs:', 'https://ipfs.io/ipfs/');

  return Object.assign(character, {
    name: `DefiHero ${tokenId.toNumber()}`,
    description: 'DefiHeroes is a fun collection developed by CastleDAO. Go on quests, level up, fight other players',
    shortDescription: 'DefiHeroes is a fun collection developed by CastleDAO',
    type: 'DefiHero',
    attributes: [],
    symbol: 'MERCENARY',
    image: imgSrc
  });
}

async function loadCryptoGeneralsMercenaryDetails(
  character: ICharacterDetails,
  tokenId: BigNumber,
  tokenUri: string
): Promise<ICharacterDetails> {
  const tokenResponse = await fetch(tokenUri);
  var meta = await tokenResponse.json();

  return Object.assign(character, {
    name: `CryptoGeneral ${tokenId.toNumber()}`,
    description: 'Crypto Generals are the first collectible claimable by Castle owners.',
    shortDescription: 'Crypto Generals are the first collectible claimable by Castle owners',
    type: 'CryptoGeneral',
    attributes: [],
    symbol: 'MERCENARY',
    image: meta.image
  });
}

async function loadCitizenCharacterFromUri(
  index: number,
  tokenId: BigNumber,
  tokenUri: string,
  statBoostsAvailable: number
): Promise<ICharacterDetails> {
  const meta = tokenUri
    .replace('https://farmlandgame.net/assets/', './assets/')
    .replace('https://farmland-game.github.io/assets/', './assets/');
  const revealed = Utils.fileExists(meta);

  var character: ICharacterDetails;
  if (revealed) {
    const tokenResponse = await fetch(meta);
    character = (await tokenResponse.json()) as ICharacterDetails;
    character.type = 'Citizen';
    character.image = character.image!.replace('https://farmlandgame.net/assets/', './assets/');
    character.revealed = true;
    character.index = index;
    character.id = tokenId.toNumber();
    character.stats = [];
    character.statBoostsAvailable = statBoostsAvailable;

    // TODO: implement building citizen image from attributes
    // var attributes = character.attributes;
    // if (attributes) {
    //   character.imageParts = {
    //     background: getAttributeValue(attributes, 'background'),
    //     base: getAttributeValue(attributes, 'base'),
    //     hair: getAttributeValue(attributes, 'hair'),
    //     gender: getAttributeValue(attributes, 'sex'),
    //     eyes: getAttributeValue(attributes, 'eyes'),
    //     mouth: getAttributeValue(attributes, 'mouth'),
    //     clothing: getAttributeValue(attributes, 'clothing'),
    //     feature: getAttributeValue(attributes, 'feature')
    //   };
    // }
  } else {
    character = newUnrevealed(index);
  }

  return character;
}

function updateCitizenStatBasedAttributes(character: ICharacterDetails) {
  if (character.revealed) {
    var maxAttId = Math.max.apply(
      Math,
      character.attributes.map(a => {
        return a.id;
      })
    );

    if (getStatValue(character, 'Stamina') > 95) {
      character.attributes.push({
        id: ++maxAttId,
        display_type: 'Stat',
        trait_type: 'Stat Badge',
        badge_type: 'Stat',
        value: 'Tank'
      });
    }

    if (getStatValue(character, 'Strength') > 95) {
      character.attributes.push({
        id: ++maxAttId,
        display_type: 'Stat',
        trait_type: 'Stat Badge',
        badge_type: 'Stat',
        value: 'Warrior'
      });
    }

    if (getStatValue(character, 'Speed') > 95) {
      character.attributes.push({
        id: ++maxAttId,
        display_type: 'Stat',
        trait_type: 'Stat Badge',
        badge_type: 'Stat',
        value: 'Lightning'
      });
    }

    if (getStatValue(character, 'Courage') > 95) {
      character.attributes.push({
        id: ++maxAttId,
        display_type: 'Stat',
        trait_type: 'Stat Badge',
        badge_type: 'Stat',
        value: 'Hero'
      });
    }

    if (getStatValue(character, 'Intelligence') > 95) {
      character.attributes.push({
        id: ++maxAttId,
        display_type: 'Stat',
        trait_type: 'Stat Badge',
        badge_type: 'Stat',
        value: 'Genius'
      });
    }

    var badgesAttribute = character.attributes.find(a => a.trait_type === 'Badges');

    if (badgesAttribute) {
      var badges = badgesAttribute?.value.split(',');

      badges.forEach((b: string) => {
        if (b !== 'None') {
          character.attributes.push({
            id: ++maxAttId,
            display_type: 'Badge',
            trait_type: 'Badge',
            badge_type: b === 'Founder' || b === 'Unique' ? 'Special' : 'Image',
            value: b
          });
        }
      });
    }

    character.attributes = character.attributes.sort((a: ICharacterAttribute, b: ICharacterAttribute) => {
      return a.badge_type?.length < b.badge_type?.length ? 1 : a.badge_type?.length > b.badge_type?.length ? -1 : 0;
    });

    if (character.attributes.filter(a => a.badge_type === 'Special' && a.value === 'Unique').length > 0)
      character.shortDescription = 'A unique Farmland citizen';
    else {
      var specialAttributes = character.attributes.filter(
        a => ['Special', 'Image', 'Stat'].indexOf(a.badge_type) >= 0
      ).length;

      if (specialAttributes > 3) character.shortDescription = `A legendary Farmland ${character.type}`;
      else if (specialAttributes > 2) character.shortDescription = `An epic Farmland ${character.type}`;
      else if (specialAttributes > 1) character.shortDescription = `A rare Farmland ${character.type}`;
      else if (specialAttributes > 0) character.shortDescription = `An uncommon Farmland ${character.type}`;
      else character.shortDescription = `A common Farmland ${character.type}`;
    }
  }
}

function getStatValue(character: ICharacterDetails, statType: string): number {
  return character.stats.find(a => a.name === statType)!.value;
}

function getAttributeValue(attributes: any[], traitType: string): string {
  return attributes.find(a => a.trait_type.toUpperCase() === traitType.toUpperCase()).value;
}

function getMercenaryAttributesFromTokenUri(tokenUri: string): any {
  var tokenUriBase64Component = tokenUri
    .split(',')
    .filter(x => x)
    .slice(-1)[0];

  var tokenUriBaseString = Buffer.from(tokenUriBase64Component, 'base64').toString();
  var tokenUrJson = JSON.parse(tokenUriBaseString);
  return tokenUrJson.attributes;
}

export function newSampleCharacterDetails(
  name: string,
  img: any,
  attributes: ICharacterAttribute[],
  index: number
): ICharacterDetails {
  return {
    symbol: 'CHARACTER',
    index: index,
    id: index,
    type: 'Citizen',
    attributes: attributes,
    stats: [
      {
        name: 'Strength',
        value: Math.floor(Math.random() * 70 + 20)
      },
      {
        name: 'Speed',
        value: attributes.some(a => a.value === 'Lightning') ? 97 : Math.floor(Math.random() * 70 + 20)
      },
      { name: 'Stamina', value: Math.floor(Math.random() * 70 + 20) },
      { name: 'Intelligence', value: Math.floor(Math.random() * 70 + 20) },
      { name: 'Courage', value: Math.floor(Math.random() * 70 + 20) }
    ],
    description:
      attributes.length === 0
        ? 'A common Farmland Citizen'
        : attributes.length === 1
        ? 'An uncommon Farmland Citizen'
        : 'A rare Farmland Citizen',
    shortDescription:
      attributes.length === 0
        ? 'A common Farmland Citizen'
        : attributes.length === 1
        ? 'An uncommon Farmland Citizen'
        : 'A rare Farmland Citizen',
    image: img,
    revealed: true,
    name: name,
    statBoostsAvailable: 10,
    morale: 0,
    xp: undefined,
    level: undefined
  };
}

function newUnrevealed(index: number): ICharacterDetails {
  return {
    symbol: 'CHARACTER',
    index: index,
    id: index,
    type: 'Citizen',
    attributes: [{ id: 0, display_type: '', trait_type: 'Unknown', badge_type: 'Unknown', value: 'Unknown' }],
    stats: [
      { name: 'Strength', value: 0 },
      { name: 'Speed', value: 0 },
      { name: 'Stamina', value: 0 },
      { name: 'Intelligence', value: 0 },
      { name: 'Courage', value: 0 }
    ],
    description: `An unknown Farmland Citizen`,
    shortDescription: `An unknown Farmland Citizen`,
    image: unrevealed,
    revealed: false,
    name: '??? ???',
    statBoostsAvailable: 10,
    morale: 0,
    xp: undefined,
    level: undefined
  };
}

export function newLoadingCharacter(index: number): ICharacterDetails {
  return {
    symbol: 'CHARACTER',
    index: index,
    id: index,
    type: 'Citizen',
    attributes: [{ id: 0, display_type: '', trait_type: 'Loading', badge_type: 'Loading', value: 'Loading' }],
    stats: [
      { name: 'Strength', value: 0 },
      { name: 'Speed', value: 0 },
      { name: 'Stamina', value: 0 },
      { name: 'Intelligence', value: 0 },
      { name: 'Courage', value: 0 }
    ],
    description: `Loading ...`,
    shortDescription: `Loading ...`,
    image: vb,
    revealed: true,
    name: 'Loading ...',
    statBoostsAvailable: 10,
    morale: 0,
    xp: undefined,
    level: undefined
  };
}
