import React, { Component } from 'react';
import { Subscription } from 'rxjs';
import { AssetId } from '../../models/NetworkConfig';
import { EthereumService } from '../../services/EthereumService';
import { ISuperdrawService } from '../../services/Interfaces';
import { Utils } from '../../services/Utils';

interface ISuperdrawProps {
  ethereumService: EthereumService;
  superdraw: ISuperdrawService;
}

class Superdraw extends Component<ISuperdrawProps> {
  subscription: Subscription;
  walletAddress?: string;
  chainId?: number;
  countdownTimer?: NodeJS.Timer;

  constructor(props: any) {
    super(props);

    this.subscription = new Subscription();

    this.closeDraw = this.closeDraw.bind(this);
  }

  closeDraw() {
    this.props.superdraw!.closeDraw();
  }

  render() {
    return (
      <div className="content">
        <div className="is-flex is-justify-content-space-between info-control card-title">
          <h2 className="info">
            SUPER DRAW
            <span className="icon-text tooltip pl-2">
              <span className="tooltip-text tooltip-text-wide">
                Every entry into the Harvest Lotto or Chicken Feed will give you another chance of winning in the Super
                Draw. Once the bar fills up you will be able to draw the winner and start a new Super Draw.
              </span>
              <span className="icon has-tooltip-text is-small">
                <i className="fas fa-question-circle fa-xs"></i>
              </span>
            </span>
          </h2>
          {this.props.superdraw!.currentPrizePool >= this.props.superdraw!.prizePoolTarget && (
            <button
              className="button is-action"
              style={{ width: '200px', marginTop: '-10px' }}
              onClick={this.closeDraw}>
              <span>Draw Winner</span>
            </button>
          )}
        </div>
        <div className="info-control">
          <h2 className="info">
            Prize pool
            <span className="icon-text tooltip pl-1 pr-2">
              <span className="tooltip-text tooltip-text-medium">
                <div>
                  <em>Prize pool breakdown:</em>
                </div>
                <div>Burned : {Utils.toFixed(this.props.superdraw!.prizeSplit.burnAmount, 2)} CORN</div>
                <div>Closers Fee : {Utils.toFixed(this.props.superdraw!.prizeSplit.closersFee, 2)} CORN</div>
                <div>Next Draw : {Utils.toFixed(this.props.superdraw!.prizeSplit.nextDrawAmount, 2)} CORN</div>
                <div>Winners Prize : {Utils.toFixed(this.props.superdraw!.prizeSplit.winnersPrize, 2)} CORN</div>
              </span>
              <span className="icon has-tooltip-text is-small">
                <i className="fas fa-question-circle fa-xs"></i>
              </span>
            </span>
            :{' '}
          </h2>
          <h2 className="value">
            <span className="pre">
              {Utils.toFixed(this.props.superdraw!.currentPrizePool, 2)} {this.props.superdraw!.config.token}
            </span>
            {this.props.ethereumService.pricesService!.renderPrice(
              this.props.superdraw!.config.token as AssetId,
              this.props.superdraw!.currentPrizePool
            )}
          </h2>
          <progress
            className="progress is-info"
            value={this.props.superdraw!.currentPrizePool}
            max={this.props.superdraw!.prizePoolTarget}></progress>
        </div>

        <div className="info-control mt-5">
          <h2 className="info">Tickets in current draw : </h2>
          <h2 className="value">
            <span className="pre">{Utils.toFixed(this.props.superdraw!.currentDrawTickets, 0)} Tickets</span>
            {/* <span className="post">{Utils.toFixed(this.props.superdraw!.currentDrawTicketsPercent, 2)}%</span> */}
          </h2>
        </div>
      </div>
    );
  }
}

export default Superdraw;
