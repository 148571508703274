import React, { Component } from 'react';
import { Utils } from '../../services/Utils';
import { ILiquidityAssetService } from '../../services/Interfaces';
import { PoolType } from '../../models/LiquidityConfig';

interface ILiquidityPoolRowProps {
  liquidityAssetService?: ILiquidityAssetService;
  onApproveClick: (liquidityAssetService: ILiquidityAssetService) => void;
  onAddClick: (liquidityAssetService: ILiquidityAssetService) => void;
  onRemoveClick: (liquidityAssetService: ILiquidityAssetService) => void;
  onClaimClick: (liquidityAssetService: ILiquidityAssetService) => void;
}

interface ILiquidityPoolRowState {
  dropdownActiveClass: string;
}

class LiquidityPoolRow extends Component<ILiquidityPoolRowProps, ILiquidityPoolRowState> {
  dropdownBody: React.RefObject<HTMLDivElement>;

  constructor(props: ILiquidityPoolRowProps) {
    super(props);

    this.dropdownBody = React.createRef();

    this.state = {
      dropdownActiveClass: ''
    };

    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  approve = () => {
    this.props.onApproveClick(this.props.liquidityAssetService!);
  };
  add = () => {
    this.props.onAddClick(this.props.liquidityAssetService!);
  };
  remove = () => {
    this.props.onRemoveClick(this.props.liquidityAssetService!);
  };
  claim = () => {
    this.props.onClaimClick(this.props.liquidityAssetService!);
  };

  toggleDropdown(e: any) {
    if (this.dropdownBody!.current!.contains(e.relatedTarget)) return;

    this.setState({
      dropdownActiveClass: this.state.dropdownActiveClass === '' ? 'is-active' : ''
    });
  }

  render() {
    return (
      <tr>
        <td>
          <div className="double_cell">
            <div className="tLogo">
              <div className="tLogo_group">
                <img
                  alt={this.props.liquidityAssetService!.config.token1}
                  className="token"
                  src={this.props.liquidityAssetService!.config.token1Icon}
                />
                {this.props.liquidityAssetService!.config.poolType === PoolType.Pair && (
                  <img
                    alt={this.props.liquidityAssetService!.config.token2!}
                    className="token"
                    src={this.props.liquidityAssetService!.config.token2Icon}
                  />
                )}
              </div>
              {this.props.liquidityAssetService!.config.poolType === PoolType.Pair && (
                <img
                  alt={this.props.liquidityAssetService!.config.pool}
                  className="tExtra"
                  src={this.props.liquidityAssetService!.config.poolIcon}
                />
              )}
            </div>
          </div>
        </td>
        <td>
          <div className="cell">
            <div className="cell_PrimaryText">
              {this.props.liquidityAssetService!.config.token1}
              {this.props.liquidityAssetService!.config.poolType === PoolType.Pair && (
                <React.Fragment> / {this.props.liquidityAssetService!.config.token2}</React.Fragment>
              )}
            </div>
            {this.props.liquidityAssetService!.config.poolType === PoolType.Pair && (
              <div className="cell_SecondaryText">{this.props.liquidityAssetService!.config.pool}</div>
            )}
          </div>
        </td>
        <td>
          <div className="cell">
            <div className="cell_TitleText">APY</div>
            <div className="cell_SecondaryText">
              <span className="value px-1">{Utils.toFixed(this.props.liquidityAssetService!.apy, 2)}</span>%
            </div>
          </div>
        </td>
        <td>
          <div className="cell">
            <div className="cell_TitleText">Available</div>
            <div className="cell_SecondaryText">
              {this.props.liquidityAssetService!.lptBalance <= 0 && <span>0.00</span>}
              {this.props.liquidityAssetService!.lptBalance > 0 && (
                <span className="value px-1">{Utils.toFixed(this.props.liquidityAssetService!.lptBalance, 8)}</span>
              )}
              <p>{this.props.liquidityAssetService!.config.lpToken}</p>
            </div>
          </div>
        </td>
        <td>
          <div className="cell">
            <div className="cell_TitleText">Staked</div>
            <div className="cell_SecondaryText">
              {this.props.liquidityAssetService!.liquidityBalance <= 0 && <span>0.00</span>}
              {this.props.liquidityAssetService!.liquidityBalance > 0 && (
                <span className="value px-1">
                  {Utils.toFixed(this.props.liquidityAssetService!.liquidityBalance, 8)}
                </span>
              )}
              <p>{this.props.liquidityAssetService!.config.lpToken}</p>
            </div>
            {this.props.liquidityAssetService!.config.expired &&
              (this.props.liquidityAssetService!.currentReward > 0 ||
                this.props.liquidityAssetService!.liquidityBalance > 0) && (
                <div className="cell_SecondaryText is-fullwidth has-text-danger has-text-weight-semibold">
                  Expired! Please Unstake &amp; Claim Rewards by 26/10/21 09:00 UTC
                </div>
              )}
          </div>
        </td>
        {/* <td>
          <div className="cell">
            <div className="cell_TitleText">Global</div>
            <div className="cell_SecondaryText">
              {this.props.liquidityAssetService!.totalLiquidity <= 0 && <span>0.00</span>}
              {this.props.liquidityAssetService!.totalLiquidity > 0 && (
                <span className="value px-1">{Utils.toFixed(this.props.liquidityAssetService!.totalLiquidity, 2)}</span>
              )}
              <p>{this.props.liquidityAssetService!.config.lpToken}</p>
            </div>
          </div>
        </td> */}
        <td>
          <div className="cell">
            <div className="cell_TitleText">Rewards</div>
            <div
              className={
                'cell_SecondaryText ' +
                (this.props.liquidityAssetService!.currentReward > 0 ? 'has-action-text has-text-weight-semibold' : '')
              }>
              {this.props.liquidityAssetService!.currentReward <= 0 && <span>0.00</span>}
              {this.props.liquidityAssetService!.currentReward > 0 && (
                <span className="value px-1">
                  <span className="value px-1">
                    {Utils.toFixed(this.props.liquidityAssetService!.currentReward, 8)}
                  </span>
                </span>
              )}

              <p>{this.props.liquidityAssetService!.config.rewardToken} Claimable</p>
            </div>
          </div>
        </td>
        <td>
          <div className="has-text-centered">
            <div>{this.renderActionDropDown()}</div>
          </div>
        </td>
      </tr>
    );
  }

  renderActionDropDown() {
    if (
      this.props.liquidityAssetService!.config.expired &&
      this.props.liquidityAssetService!.currentReward <= 0 &&
      this.props.liquidityAssetService!.liquidityBalance <= 0
    ) {
      return <div className="cell_PrimaryText is-fullwidth has-text-danger has-text-weight-semibold">Expired!</div>;
    } else if (
      !this.props.liquidityAssetService!.config.expired &&
      this.props.liquidityAssetService!.lptBalance <= 0 &&
      this.props.liquidityAssetService!.liquidityBalance <= 0
    ) {
      return (
        <a
          className="button is-action"
          target="_blank"
          rel="noopener noreferrer"
          href={this.props.liquidityAssetService!.config.poolUrl}>
          Add
        </a>
      );
    } else {
      return (
        <div className={`action-dropdown dropdown ${this.state.dropdownActiveClass}`}>
          <div className="dropdown-trigger">
            <button
              className="button is-action"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onBlur={this.toggleDropdown}
              onFocus={this.toggleDropdown}>
              <span>Action</span>
              <span className="icon is-small">
                <i className="fas fa-angle-down" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div
            className="dropdown-menu"
            id="dropdown-menu"
            role="menu"
            ref={this.dropdownBody}
            onClick={this.toggleDropdown}>
            <div className="dropdown-content">
              {!this.props.liquidityAssetService!.config.expired &&
                this.props.liquidityAssetService!.unapprovedLptBalance > 0 && (
                  <button className="button is-link is-transparent" onClick={this.approve}>
                    <span>Approve</span>
                  </button>
                )}
              {!this.props.liquidityAssetService!.config.expired &&
                this.props.liquidityAssetService!.approvedLptBalance > 0 &&
                this.props.liquidityAssetService!.lptBalance > 0 && (
                  <button className="button is-link is-transparent" onClick={this.add}>
                    <span>{this.props.liquidityAssetService!.actionDisplayName}</span>
                  </button>
                )}
              {this.props.liquidityAssetService!.liquidityBalance > 0 && (
                <button className="button is-link is-transparent" onClick={this.remove}>
                  <span>{this.props.liquidityAssetService!.undoActionDisplayName}</span>
                </button>
              )}
              {this.props.liquidityAssetService!.currentReward > 0 && (
                <button className="button is-link is-transparent" onClick={this.claim}>
                  <span>Claim Reward</span>
                </button>
              )}
              {!this.props.liquidityAssetService!.config.expired && (
                <a
                  className="button is-transparent"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={this.props.liquidityAssetService!.config.poolUrl}>
                  Add
                </a>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default LiquidityPoolRow;
