import React, { Component } from 'react';
import logo from '../assets/logo.png';
import landIcon from '../assets/land.png';
import cornIcon from '../assets/corn.png';
import './NavBar.scss';
import { Utils } from '../services/Utils';
import { EthereumService } from '../services/EthereumService';
// @ts-ignore
import { NavLink } from 'react-router-dom';
import { Subscription } from 'rxjs';
import { MessagingService } from '../services/MessagingService';

interface INavBarProps {
  messagingService: MessagingService;
  ethereumService: EthereumService;
}

interface INavBarState {
  menuActiveClass: string;
  networkDropdownActiveClass: string;
  questionDropdownActiveClass: string;
  isConnected: boolean;
}

class NavBar extends Component<INavBarProps, INavBarState> {
  menuBody: React.RefObject<HTMLDivElement>;
  networkDropdownBody: React.RefObject<HTMLDivElement>;
  questionDropdownBody: React.RefObject<HTMLDivElement>;
  networkButton: React.RefObject<HTMLButtonElement>;
  questionButton: React.RefObject<HTMLButtonElement>;
  subscription = new Subscription();

  constructor(props: INavBarProps) {
    super(props);

    this.menuBody = React.createRef();
    this.networkDropdownBody = React.createRef();
    this.questionDropdownBody = React.createRef();
    this.networkButton = React.createRef();
    this.questionButton = React.createRef();

    this.state = {
      menuActiveClass: '',
      networkDropdownActiveClass: '',
      questionDropdownActiveClass: '',
      isConnected: false
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleNetworkDropdown = this.toggleNetworkDropdown.bind(this);
    this.toggleQuestionDropdown = this.toggleQuestionDropdown.bind(this);
    this.renderNetworkDropDown = this.renderNetworkDropDown.bind(this);
    this.renderQuestionDropDown = this.renderQuestionDropDown.bind(this);
  }

  componentDidMount() {
    this.subscription.add(
      this.props.messagingService.events.subscribe((event: any) => {
        this.setState({ isConnected: this.props.ethereumService?.walletData.isConnected });
      })
    );
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  toggleMenu(e: any) {
    if (this.menuBody!.current!.contains(e.relatedTarget)) return;
    if (this.questionButton!.current!.contains(e.target)) return;

    this.setState({
      menuActiveClass: this.state.menuActiveClass === '' ? 'is-active' : ''
    });
  }

  render() {
    return (
      <nav className="navbar is-transparent is-fixed-top" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <NavLink className="navbar-item" exact={true} to="/">
            <img alt="Farmland Logo" className="logo" src={logo} width="45" />
            <span className="navbar-item logo-text">FARMLAND</span>
          </NavLink>
          <button
            onClick={this.toggleMenu}
            className={`is-transparent navbar-burger burger ${this.state.menuActiveClass}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbar-menu-items">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>

        <div
          id="navbar-menu-items"
          className={`navbar-menu ${this.state.menuActiveClass}`}
          ref={this.menuBody}
          onClick={this.toggleMenu}>
          <div className="navbar-start">
            <NavLink className="navbar-item" to="/farm" activeStyle={{ color: 'var(--orange2)' }}>
              Farm
            </NavLink>
            <NavLink className="navbar-item" to="/bank" activeStyle={{ color: 'var(--orange2)' }}>
              Bank
            </NavLink>
            <NavLink className="navbar-item" to="/citizens" activeStyle={{ color: 'var(--orange2)' }}>
              Citizens
            </NavLink>
            <NavLink className="navbar-item" to="/quests" activeStyle={{ color: 'var(--orange2)' }}>
              Quests
            </NavLink>
            <NavLink className="navbar-item" to="/barn" activeStyle={{ color: 'var(--orange2)' }}>
              Barn
            </NavLink>
            <NavLink className="navbar-item" to="/pools" activeStyle={{ color: 'var(--orange2)' }}>
              Pools
            </NavLink>
            <NavLink className="navbar-item" to="/saloon" activeStyle={{ color: 'var(--orange2)' }}>
              Saloon
            </NavLink>
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons is-centered">
                {this.renderNetworkDropDown()}
                {this.props.ethereumService.networkManager?.network?.chainId === 1 && (
                  <button className="button is-dark">
                    <span className="icon">
                      <i className="fas fa-gas-pump"></i>
                    </span>
                    <span>{Utils.toFixed(this.props.ethereumService.pricesService!.prices.gas, 0)}</span>
                  </button>
                )}
                <button className="button is-dark">
                  <span className="icon mr-2">
                    <img alt="LAND" src={landIcon} />
                  </span>
                  {Utils.toFixed(this.props.ethereumService.walletData.landBalance, 2)}
                </button>
                <button className="button is-dark">
                  <span className="icon mr-2">
                    <img alt="LAND" src={cornIcon} />
                  </span>
                  {Utils.toFixed(this.props.ethereumService.walletData.cornBalance, 2)}
                </button>
                {!this.state.isConnected && (
                  <button className="button is-dark" onClick={() => this.props.ethereumService.connectWallet()}>
                    Connect to a wallet
                  </button>
                )}
                {this.state.isConnected && (
                  <a
                    className="button is-dark"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      this.props.ethereumService.networkManager!.network?.blockExplorerUrls[0] +
                      '/address/' +
                      this.props.ethereumService.walletData.walletAddress
                    }>
                    {this.props.ethereumService.walletData.walletAddress.substring(0, 6)}...
                    {this.props.ethereumService.walletData.walletAddress.substring(
                      this.props.ethereumService.walletData.walletAddress.length - 4
                    )}
                  </a>
                )}
                {this.renderQuestionDropDown()}
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }

  toggleNetworkDropdown(e: any) {
    if (this.networkDropdownBody!.current!.contains(e.relatedTarget)) return;

    this.setState({
      networkDropdownActiveClass: this.state.networkDropdownActiveClass === '' ? 'is-active' : ''
    });
  }

  renderNetworkDropDown() {
    if (this.props.ethereumService.networkManager?.network?.displayChainName) {
      return (
        <React.Fragment>
          {this.props.ethereumService.networkManager.network.chainId === 1 && (
            <a className="button is-danger" target="_blank" rel="noopener noreferrer" href="https://offchainlabs.com/">
              We have moved to L2 Arbitrum
            </a>
          )}

          {this.props.ethereumService.networkManager.network.chainId !== 42161 && (
            <div className={`dropdown ${this.state.networkDropdownActiveClass}`}>
              <div className="dropdown-trigger">
                <button
                  className="button is-dark"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                  ref={this.networkButton}
                  onBlur={this.toggleNetworkDropdown}
                  onFocus={this.toggleNetworkDropdown}>
                  <span className="icon mr-2">
                    <img
                      className={`${this.props.ethereumService.networkManager!.network.isTestnet ? 'testnet' : ''}`}
                      alt={this.props.ethereumService.networkManager!.network!.displayChainName}
                      src={this.props.ethereumService.networkManager!.network!.icon}
                    />
                  </span>
                  <span>{this.props.ethereumService.networkManager!.network!.displayChainName}</span>
                  <span className="icon is-small">
                    <i className="fas fa-angle-down" aria-hidden="true"></i>
                  </span>
                </button>
              </div>
              <div
                className="dropdown-menu"
                id="dropdown-menu"
                role="menu"
                ref={this.networkDropdownBody}
                onClick={this.toggleNetworkDropdown}>
                <div className="dropdown-content">
                  {this.props.ethereumService
                    .networkManager!.availableNetworks.filter(network => !network.isTestnet)
                    .map((network, index) => (
                      <button
                        className="button is-link is-transparent"
                        key={network.chainId}
                        onClick={() => this.props.ethereumService.networkManager?.changeNetwork(network)}>
                        <span className="icon mr-2">
                          <img
                            className={`${network.isTestnet ? 'testnet' : ''}`}
                            alt={network.displayChainName}
                            src={network.icon}
                          />
                        </span>
                        {network.displayChainName}
                      </button>
                    ))}
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      );
    }
  }

  toggleQuestionDropdown(e: any) {
    if (this.questionDropdownBody!.current!.contains(e.relatedTarget)) return;

    this.setState({
      questionDropdownActiveClass: this.state.questionDropdownActiveClass === '' ? 'is-active' : ''
    });
  }

  renderQuestionDropDown() {
    return (
      <div className={`dropdown ${this.state.questionDropdownActiveClass}`}>
        <div className="dropdown-trigger">
          <button
            className="button is-dark"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            ref={this.questionButton}
            onBlur={this.toggleQuestionDropdown}
            onFocus={this.toggleQuestionDropdown}>
            <span className="icon">
              <i className="fas fa-question"></i>
            </span>
            <span className="icon is-small">
              <i className="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div
          className="dropdown-menu"
          id="dropdown-menu"
          role="menu"
          ref={this.questionDropdownBody}
          onClick={this.toggleQuestionDropdown}>
          <div className="dropdown-content">
            <a className="navbar-item" target="_blank" rel="noopener noreferrer" href="http://tools.farmlandgame.net/">
              Tools
            </a>
            <a
              className="navbar-item"
              target="_blank"
              rel="noopener noreferrer"
              href="https://datamine-crypto.github.io/datamine-pro-portal/#/dashboard?tokenContext=FARMLAND">
              Analytics
            </a>
            <a
              className="navbar-item"
              target="_blank"
              rel="noopener noreferrer"
              href="https://farmlandgame.gitbook.io/">
              Docs
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default NavBar;
