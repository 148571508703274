export interface IWalletData {
  isConnected: boolean;
  walletAddress: string;
  ethBalance: string;
  landBalance: string;
  cornBalance: string;
  stdBlockNumber: number;
  blockNumber: number;
}

export class WalletData implements IWalletData {
  isConnected = false;
  walletAddress = '';
  ethBalance = '0';
  landBalance = '0';
  cornBalance = '0';
  stdBlockNumber = 0;
  blockNumber = 0;
}
