import React, { Component } from 'react';
import { EthereumService } from '../../services/EthereumService';
import { IQuestService } from '../../services/QuestService';
import { IQuestCharacter } from './Quests';
import { Character } from '../Characters/Character';
import { ICharacterAction } from '../../models/ICharacterAction';
import { ItemService } from '../../services/ItemService';
import { IItemDetails } from '../../models/ItemDetails';
import Flip from '../Common/Flip';
import { Utils } from '../../services/Utils';
import unknownItem from '../../assets/unknown-item.png';
import landIcon from '../../assets/land.png';
import { MercenaryService } from '../../services/MercenaryService';
import './Explorer.scss';
import { Mutex } from 'async-mutex';
import { Subscription } from 'rxjs';
import { MessagingService } from '../../services/MessagingService';
import { ExplorerState, ExplorerStateEnum } from './ExplorerState';

interface IExplorerProps {
  explorerState?: ExplorerState;
  questService?: IQuestService;
  mercenaryService?: MercenaryService;
  messagingService?: MessagingService;
  itemService?: ItemService;
  ethereumService?: EthereumService;
  refreshedAtBlock: number;
  isLoading: boolean;
  onBeginQuest: (questCharacter: IQuestCharacter) => void;
  onRestoreHealth: (questCharacter: IQuestCharacter) => void;
  onRestoreMorale: (questCharacter: IQuestCharacter) => void;
  onallocateStatBoosts: (questCharacter: IQuestCharacter) => void;
  onupdateAppearance: (questCharacter: IQuestCharacter) => void;
}

interface IExplorerItems {
  received: number;
  blockNumber: number;
  item: IItemDetails;
}

interface IExplorerState {
  characterActivityImg: string;
  dropdownActiveClass: string;
  activeQuestDetailsTab: string;
}

export class Explorer extends Component<IExplorerProps, IExplorerState> {
  subscription: Subscription;
  dropdownBody: React.RefObject<HTMLDivElement>;
  questPaymentName?: string;
  characterId: number;
  mutex = new Mutex();
  constructor(props: IExplorerProps) {
    super(props);

    this.characterId = props.explorerState!.character.id;
    this.dropdownBody = React.createRef();

    this.state = {
      characterActivityImg: props.explorerState!.character!.image!,
      dropdownActiveClass: 'Quest',
      activeQuestDetailsTab: 'Activity'
    };

    this.subscription = new Subscription();

    this.aprovePayment = this.aprovePayment.bind(this);
    this.approveRestoration = this.approveRestoration.bind(this);
    this.retireCitizen = this.retireCitizen.bind(this);
    this.beginQuest = this.beginQuest.bind(this);
    this.restoreHealth = this.restoreHealth.bind(this);
    this.restoreMorale = this.restoreMorale.bind(this);
    this.allocateStatBoosts = this.allocateStatBoosts.bind(this);
    this.updateAppearance = this.updateAppearance.bind(this);
    this.completeQuest = this.completeQuest.bind(this);
    this.abortQuest = this.abortQuest.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  getPaymentSymbol(): string {
    return this.props.questService!.getPaymentSymbol();
  }

  aprovePayment() {
    this.props.questService?.approvePayment();
  }

  approveQuestItemUse() {
    this.props.questService?.approveQuestItemUse();
  }

  approveRestoration() {
    this.props.questService?.approveItemUse();
  }

  retireCitizen() {
    this.props.mercenaryService?.unwrap(this.characterId);
  }

  beginQuest() {
    this.props.onBeginQuest({
      questService: this.props.questService!,
      mercenaryService: this.props.mercenaryService!,
      itemService: this.props.itemService!,
      explorerState: this.props.explorerState!
    });
  }

  restoreHealth() {
    this.props.onRestoreHealth({
      questService: this.props.questService!,
      mercenaryService: this.props.mercenaryService!,
      itemService: this.props.itemService!,
      explorerState: this.props.explorerState!
    });
  }

  restoreMorale() {
    this.props.onRestoreMorale({
      questService: this.props.questService!,
      mercenaryService: this.props.mercenaryService!,
      itemService: this.props.itemService!,
      explorerState: this.props.explorerState!
    });
  }

  allocateStatBoosts() {
    this.props.onallocateStatBoosts({
      questService: this.props.questService!,
      mercenaryService: this.props.mercenaryService!,
      itemService: this.props.itemService!,
      explorerState: this.props.explorerState!
    });
  }

  updateAppearance() {
    this.props.onupdateAppearance({
      questService: this.props.questService!,
      mercenaryService: this.props.mercenaryService!,
      itemService: this.props.itemService!,
      explorerState: this.props.explorerState!
    });
  }

  completeQuest() {
    this.props.questService?.completeQuest(this.characterId);
  }

  abortQuest() {
    this.props.questService?.abortQuest(this.characterId);
  }

  toggleDropdown(e: any) {
    if (this.dropdownBody!.current!.contains(e.relatedTarget)) return;

    this.setState({
      dropdownActiveClass: this.state.dropdownActiveClass === '' ? 'is-active' : ''
    });
  }

  setQuestDetailsTab(tab: string) {
    this.setState({
      activeQuestDetailsTab: tab
    });
  }

  tickerClass() {
    if (+this.props.explorerState!.questCountdownTime.days > 0) {
      return 'days';
    }
    if (+this.props.explorerState!.questCountdownTime.hours > 0) {
      return 'hours';
    }
    if (+this.props.explorerState!.questCountdownTime.mins > 0) {
      return 'mins';
    }
  }

  render() {
    return (
      <div className="explorer is-flex is-flex-direction-column is-justify-content-center">
        {/* <ul>
          <li>state:{this.props.explorerState!.explorerState}</li>
          <li>block:{this.props.explorerState!.refreshedAtBlock}</li>
          <li>charid:{this.characterId}</li>
          <li>countdownSecs:{this.props.explorerState!.questCountdownSecs}</li>
          <li>questLength:{this.props.explorerState!.characterActivity?.activityDuration}</li>
          <li>hasrecent:{this.props.explorerState!.recent ? 'true' : 'false'}</li>
          <li>active:{this.props.explorerState!.characterActivity?.isActive ? 'true' : 'false'}</li>
          <li>
            didComplete:
            {this.props.explorerState!.characterActivity &&
            this.props.explorerState!.characterActivity!.completedBlock > this.props.explorerState!.characterActivity!.endBlock
              ? 'true'
              : 'false'}
          </li>
          <li>startBlock:{this.props.explorerState!.characterActivity?.startBlock}</li>
          <li>endBlock:{this.props.explorerState!.characterActivity?.endBlock}</li>
          <li>completedBlock:{this.props.explorerState!.characterActivity?.completedBlock}</li>
        </ul> */}
        <Character
          actionsLv1={this.getActions1()}
          actionsLv2={this.getActions2()}
          actionsLv3={this.getActions3()}
          onCardClick={() => {}}
          character={this.props.explorerState!.character}
          isSample={false}
          isLoading={this.props.isLoading}
          health={this.props.explorerState!.health!}
          morale={this.props.explorerState!.morale!}></Character>
        {(this.props.isLoading ||
          !this.props.questService ||
          this.props.explorerState!.explorerState === ExplorerStateEnum.None) && (
          <div className="quest-details is-loading">
            <div className="quest-details">
              <div
                className="quest-items"
                style={{
                  backgroundImage: `linear-gradient(rgba(10, 10, 10, 0.6), rgba(10, 10, 10, 1)),url("${
                    this.props.explorerState!.character!.image
                  }")`
                }}>
                <React.Fragment>
                  <div className="quest-item-title pt-5 pb-5 has-light-text">Loading...</div>
                  <div className="pt-5 pb-5 last-quest">
                    <button className="button is-transparent is-loading"></button>
                  </div>
                </React.Fragment>
              </div>
            </div>
          </div>
        )}
        {!this.props.isLoading && this.props.questService && !this.props.questService!.isPaymentApproved && (
          <div className="quest-details">
            <div
              className="quest-items"
              style={{
                backgroundImage: `linear-gradient(rgba(10, 10, 10, 0.6), rgba(10, 10, 10, 1)),url("${
                  this.props.explorerState!.character!.image
                }")`
              }}>
              <div className="quest-item-title pt-2">Mercenary Waiting</div>
              <div className="my-2 pt-2 px-4 is-size-7 has-light-text">
                You must give your Mercenaries permission to use your {this.getPaymentSymbol()} before then can quest.
              </div>
              <div className="first-quest">
                <div className="quest-action"></div>
              </div>
            </div>
          </div>
        )}
        {!this.props.isLoading &&
          this.props.questService &&
          this.props.questService?.isPaymentApproved &&
          this.props.explorerState!.explorerState !== ExplorerStateEnum.None && (
            <div className="quest-details">
              <React.Fragment>
                <div
                  className="quest-items"
                  style={{
                    backgroundImage: `linear-gradient(rgba(10, 10, 10, 0.6), rgba(10, 10, 10, 1)),url("${
                      this.state!.characterActivityImg
                    }")`
                  }}>
                  <div className="quest-item-title is-flex is-justify-content-space-around p-0">
                    <span
                      className={`quest-details-tab left-tab ${
                        this.state.activeQuestDetailsTab === 'Activity' ? 'is-active' : ''
                      }`}
                      onClick={() => this.setQuestDetailsTab('Activity')}>
                      Activity
                    </span>
                    <span
                      className={`quest-details-tab right-tab ${
                        this.state.activeQuestDetailsTab === 'Discoveries' ? 'is-active' : ''
                      }`}
                      onClick={() => this.setQuestDetailsTab('Discoveries')}>
                      Discoveries
                    </span>
                  </div>
                  {this.state.activeQuestDetailsTab === 'Activity' && (
                    <React.Fragment>
                      {/* FIRST QUEST */}
                      {this.props.explorerState!.explorerState === ExplorerStateEnum.New && (
                        <React.Fragment>
                          <div className="question-activity-title pt-2">Mercenary Waiting</div>
                          <div className="my-2 pt-2 px-6 is-size-7 has-light-text">
                            Your mercenary is waiting to go on their first quest
                          </div>
                          <div className="first-quest">
                            <div className="quest-action">
                              <button className="button is-transparent-action mb-3" onClick={() => this.beginQuest()}>
                                <span>Begin Quest</span>
                              </button>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                      {/* EXPLORING */}
                      {this.props.explorerState!.explorerState === ExplorerStateEnum.Questing && (
                        <React.Fragment>
                          <div className="question-activity-title pt-2">{this.props.explorerState!.lastQuestName}</div>
                          {/* {this.props.explorerState!.activeItems.length > 0 && (
                              <div className="quest-item">
                                <span className="my-2 pt-2 is-size-7 has-light-text">With </span>
                                {this.props.explorerState!.activeItems.map((item, index) => (
                                  <span className="my-2 pt-2 is-size-7 has-light-text" key={index}>
                                    {item.name}
                                  </span>
                                ))}
                              </div>
                            )} */}
                          <div className="my-2 pt-2 is-size-7 has-light-text">Returning in</div>
                          <div className="questing">{this.renderCountDown()}</div>
                        </React.Fragment>
                      )}
                      {/* RETURNED */}
                      {this.props.explorerState!.explorerState === ExplorerStateEnum.Completed && (
                        <React.Fragment>
                          <div className="question-activity-title pt-2">Mercenary Returned</div>
                          <div className="my-2 pt-2 is-size-7 has-light-text">Your mercenary has discoveries</div>
                          <div className="last-quest">{this.renderPack()}</div>
                        </React.Fragment>
                      )}
                      {/* IDLE */}
                      {this.props.explorerState!.explorerState === ExplorerStateEnum.Idle && (
                        <React.Fragment>
                          <div className="question-activity-title pt-2">Mercenary Idle</div>
                          {(this.props.explorerState!.recent.length > 0 ||
                            this.props.explorerState!.landRecent > 0) && (
                            <React.Fragment>
                              <div className="my-2 pt-2 is-size-7 has-light-text">
                                Your mercenary last returned with
                              </div>
                              <div className="last-quest">
                                <React.Fragment>
                                  {this.props.explorerState!.recent.map((i, index) => (
                                    <div key={index}>{this.renderDiscoveredItem(i)}</div>
                                  ))}
                                  {this.renderDiscoveredLand(this.props.explorerState!.landRecent)}
                                </React.Fragment>
                              </div>
                            </React.Fragment>
                          )}
                          {this.props.explorerState!.recent.length === 0 &&
                            this.props.explorerState!.landRecent === 0 && (
                              <React.Fragment>
                                <div className="my-2 pt-2 is-size-7 has-light-text">
                                  Your mercenary failed on the last quest
                                </div>
                                <div className="last-quest">
                                  <span className="is-flex is-justify-content-center mt-2">
                                    <span className="fa-fw fa-2x">
                                      <i className="fas fa-exclamation-triangle fa-inverse has-warning-text"></i>
                                    </span>
                                  </span>
                                </div>
                              </React.Fragment>
                            )}
                        </React.Fragment>
                      )}
                      {/* ABORTED */}
                      {this.props.explorerState!.explorerState === ExplorerStateEnum.Aborted && (
                        <React.Fragment>
                          <div className="question-activity-title pt-2">Mercenary Waiting</div>
                          <div className="my-2 pt-2 px-6 is-size-7 has-light-text aborted">
                            Your mercenary was recalled from their last quest
                          </div>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                  {this.state.activeQuestDetailsTab === 'Discoveries' && (
                    <React.Fragment>
                      {this.props.explorerState!.history.length === 0 && (
                        <div className="quest-history py-2 is-flex is-flex-direction-column is-justify-content-center is-size-7 has-light-text">
                          No discoveries yet
                        </div>
                      )}
                      {this.props.explorerState!.history.length > 0 && (
                        <div className="quest-history py-2">
                          {this.props.explorerState!.history.map((i, index) => (
                            <div key={index}>{this.renderDiscoveredItem(i)}</div>
                          ))}
                          {this.renderDiscoveredLand(this.props.explorerState!.landHistory)}
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </div>
              </React.Fragment>
            </div>
          )}
      </div>
    );
  }

  getActions1(): ICharacterAction[] {
    if (!this.props.questService || this.props.isLoading) return [];

    if (!this.props.questService.isPaymentApproved) {
      return [this.getApprovePaymentAction()];
    } else if (this.props.explorerState!.explorerState === ExplorerStateEnum.Completed) {
      return [this.getCompleteQuestAction()];
    } else if (
      this.props.explorerState!.explorerState === ExplorerStateEnum.New ||
      this.props.explorerState!.explorerState === ExplorerStateEnum.Idle ||
      this.props.explorerState!.explorerState === ExplorerStateEnum.Aborted
    ) {
      if (this.props.explorerState!.character!.type === 'Citizen') {
        return [this.getBeginQuestAction(), this.getRetireAction()];
      } else {
        return [this.getBeginQuestAction()];
      }
    } else {
      return [this.getAbortQuestAction()];
    }
  }

  getActions2(): ICharacterAction[] {
    if (!this.props.questService || this.props.isLoading) return [];

    if (!this.props.questService.isStatRestoreItemUseApproved ?? false) {
      return [this.getApproveRestorationAction()];
    } else if (
      this.props.explorerState!.explorerState === ExplorerStateEnum.New ||
      this.props.explorerState!.explorerState === ExplorerStateEnum.Idle ||
      this.props.explorerState!.explorerState === ExplorerStateEnum.Aborted
    ) {
      return [this.getRestoreHealthAction(), this.getRestoreMoraleAction()];
    } else if (this.props.explorerState!.character?.type === 'Mercenary') {
      return [this.getUpdateAppearanceAction()];
    }
    return [];
  }

  getActions3(): ICharacterAction[] {
    if (!this.props.questService || this.props.isLoading) return [];

    if (
      this.props.explorerState!.explorerState === ExplorerStateEnum.New ||
      this.props.explorerState!.explorerState === ExplorerStateEnum.Idle ||
      this.props.explorerState!.explorerState === ExplorerStateEnum.Aborted
    ) {
      return this.getBoostAndUpdate();
    }

    return [];
  }

  private getBoostAndUpdate() {
    var actions: ICharacterAction[] = [this.getAllocateStatBoostsAction()];

    if (this.props.explorerState!.character?.type === 'Mercenary') {
      actions.push(this.getUpdateAppearanceAction());
    }

    return actions;
  }

  renderDiscoveredItem(ei: IExplorerItems) {
    return (
      <div className="quest-item">
        <img alt={ei.item?.name} className="token" src={ei.item?.imageUrl.replace('.png', 's.png') ?? unknownItem} />
        <span className="item-name">{ei.item?.name}</span>
        <span className="item-balance pl-2">{ei.received}</span>
      </div>
    );
  }

  renderDiscoveredLand(landAmount: number) {
    return (
      <React.Fragment>
        {+Utils.formatUnits(landAmount) > 0 && (
          <div className="quest-item">
            <div className="land-token">
              <img alt="LAND" className="token" src={landIcon} />
            </div>
            <span className="item-name">LAND</span>
            <span className="item-balance pl-2">{Utils.toFixed(landAmount, 0)}</span>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderPack() {
    return (
      <React.Fragment>
        <div className="pack-item">
          <img alt="Quest Pack" className="pack-image" src={'./assets/packs/Generics.png'} />
        </div>
        <div className="pack-action">
          <button className="button is-transparent-action mt-2 mb-3" onClick={() => this.completeQuest()}>
            <span>Open Pack</span>
          </button>
        </div>
      </React.Fragment>
    );
  }

  renderCountDown() {
    return (
      <div className="countdown-item quest-countdown">
        <div className="countdown">
          {this.props.explorerState!.questCountdownSecs > 0 && (
            <div className="is-flex is-flex-direction-column">
              <div className="is-flex">
                <div className={'ticker ' + this.tickerClass()}>
                  <div className="digits">
                    <Flip value={this.props.explorerState!.questCountdownTime.days} />
                  </div>
                  <div>
                    <div className="units">days</div>
                  </div>
                </div>
                <div className={'ticker ' + this.tickerClass()}>
                  <div className="digits">
                    <Flip value={this.props.explorerState!.questCountdownTime.hours} />
                  </div>
                  <div>
                    <div className="units">hours</div>
                  </div>
                </div>
                <div className={'ticker ' + this.tickerClass()}>
                  <div className="digits">
                    <Flip value={this.props.explorerState!.questCountdownTime.mins} />
                  </div>
                  <div>
                    <div className="units">mins</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  getExploringAction() {
    return {
      name: 'On Quest',
      isApproved: true,
      onClick: () => {},
      onHoverMessage: `${
        this.props.explorerState!.character!.name
      } is out a quest. You must wait for the quest to end before being able to do anything else.`,
      enabled: true
    };
  }

  getRetireAction() {
    return {
      name: 'Retire Mercenary',
      isApproved: true,
      onClick: () => this.retireCitizen(),
      onHoverMessage: `Retire from employment as an Mercenary, they will return to the Citizens page`,
      enabled: true
    };
  }

  getRestoreHealthAction() {
    var health = this.props.explorerState!.health!;
    return {
      name: 'Restore Health',
      isApproved: true,
      onClick: () => this.restoreHealth(),
      onHoverMessage:
        health && health!.remaining < health!.max
          ? `Restore an mercenary's health using items from your barn`
          : 'Health is already full',
      enabled: health != null && health!.remaining < health!.max
    };
  }

  getRestoreMoraleAction() {
    var morale = this.props.explorerState!.morale!;
    return {
      name: 'Restore Morale',
      isApproved: true,
      onClick: () => this.restoreMorale(),
      onHoverMessage:
        morale && morale!.remaining < morale!.max
          ? `Restore an mercenary's morale using items from your barn`
          : 'Morale is already full',
      enabled: morale != null && morale!.remaining < morale!.max
    };
  }

  getAllocateStatBoostsAction() {
    var character = this.props.explorerState!.character;
    return {
      name: 'Boost Stats',
      isApproved: true,
      onClick: () => this.allocateStatBoosts(),
      onHoverMessage:
        character!.statBoostsAvailable > 0 ? `Allocate stat boosts to an mercenary` : 'No stat boosts available',
      enabled: character!.statBoostsAvailable > 0
    };
  }

  getUpdateAppearanceAction() {
    return {
      name: 'Update Appearance',
      isApproved: true,
      onClick: () => this.updateAppearance(),
      onHoverMessage: `Update the appearance of an mercenary`,
      enabled: true
    };
  }

  getApprovePaymentAction() {
    return {
      name: 'Approve Corn Use',
      isApproved: true,
      onClick: () => this.aprovePayment(),
      onHoverMessage: 'Mercenaries need Corn to survive on a quest, you must approve the use of Corn',
      enabled: true
    };
  }

  getCompleteQuestAction() {
    return {
      name: 'Open Pack',
      isApproved: true,
      onClick: () => this.completeQuest(),
      onHoverMessage: `${
        this.props.explorerState!.character!.name
      } has returned exploring. Open Pack to see what they have discovered.`,
      enabled: true
    };
  }

  getAbortQuestAction() {
    return {
      name: 'Recall Mercenary',
      isApproved: true,
      onClick: () => this.abortQuest(),
      onHoverMessage: `Recall ${
        this.props.explorerState!.character!.name
      } from their quest, they will return immediately without any items.`,
      enabled: true
    };
  }

  getBeginQuestAction() {
    return {
      name: 'Begin Quest',
      isApproved: true,
      onClick: () => this.beginQuest(),
      onHoverMessage: `Send ${
        this.props.explorerState!.character.name
      } on a quest to find materials and perhaps new Land`,
      enabled: true
    };
  }

  getApproveRestorationAction() {
    return {
      name: 'Approve Restoration',
      isApproved: true,
      onClick: () => this.approveRestoration(),
      onHoverMessage: `Mercenary's can use items for restoring health and morale. You must approve use of these items before you can do this`,
      enabled: true
    };
  }
}
