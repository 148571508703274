import React, { Component } from 'react';
import { Subscription } from 'rxjs';
import { LiquidityAssetType } from '../../models/Enums';
import { EthereumService } from '../../services/EthereumService';
import { ILiquidityAssetService } from '../../services/Interfaces';
import { StakingAssetService } from '../../services/StakingAssetService';
import { StakingAssetServiceV2 } from '../../services/StakingAssetServiceV2';
import { LpLandAssetService } from '../../services/LpLandAssetService';
import { MessagingService } from '../../services/MessagingService';
import LiquidityPool from './LiquidityPool';
import '../Content.scss';
import '../Tables.scss';
import './Opportunities.scss';
import { Mutex } from 'async-mutex';

interface IOpportunitiesProps {
  messagingService: MessagingService;
  ethereumService: EthereumService;
}

interface IOpportunitiesState {
  isConnected: boolean;
  isLoaded: boolean;
  liquidityAssetService?: ILiquidityAssetService | null;
  liquidityAssetServices: ILiquidityAssetService[];
  refreshedAtBlock: number;
}

class Opportunities extends Component<IOpportunitiesProps, IOpportunitiesState> {
  subscription: Subscription;
  walletAddress?: string;
  chainId?: number;
  mutex = new Mutex();

  constructor(props: any) {
    super(props);

    this.state = {
      isConnected: true,
      isLoaded: false,
      liquidityAssetServices: [],
      refreshedAtBlock: 0
    };

    this.subscription = new Subscription();
  }

  componentDidMount() {
    var eventSubscription = this.props.messagingService.events.subscribe(event => {
      this.refresh();
    });
    this.subscription.add(eventSubscription);
    this.refresh();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  async refresh(): Promise<any> {
    await this.mutex.runExclusive(async () => {
      await this.refreshImpl();
    });
  }

  private async refreshImpl(): Promise<any> {
    if (
      this.props.ethereumService.walletData.walletAddress &&
      this.props.ethereumService.networkManager?.network?.chainId &&
      this.props.ethereumService.walletData.blockNumber > this.state.refreshedAtBlock
    ) {
      if (
        this.props.ethereumService.walletData.walletAddress !== this.walletAddress ||
        this.props.ethereumService.networkManager!.network!?.chainId !== this.chainId
      ) {
        this.walletAddress = this.props.ethereumService.walletData.walletAddress;
        this.chainId = this.props.ethereumService.networkManager!.network!.chainId;
        await this.init();
      } else {
        await this.update();
      }
    }
  }

  async init() {
    if (
      this.props.ethereumService.networkManager?.network &&
      this.props.ethereumService.networkManager?.network?.liquidityConfigs.length > 0
    ) {
      var liquidityUpdatePromises = this.props.ethereumService.networkManager!.network!.liquidityConfigs.map(lc => {
        switch (lc.liquidityType) {
          case LiquidityAssetType.Staking:
            var liquidityAssetService = new StakingAssetService(
              lc,
              this.props.ethereumService,
              this.props.messagingService
            ) as ILiquidityAssetService;
            break;
          case LiquidityAssetType.StakingV2:
            liquidityAssetService = new StakingAssetServiceV2(
              lc,
              this.props.ethereumService,
              this.props.messagingService
            ) as ILiquidityAssetService;
            break;
          case LiquidityAssetType.wLand:
            liquidityAssetService = new LpLandAssetService(
              lc,
              this.props.ethereumService,
              this.props.messagingService
            ) as ILiquidityAssetService;
            break;
          default:
            throw new Error('Unknown LiquidityAssetType');
        }

        return liquidityAssetService.update().then(() => {
          return liquidityAssetService;
        });
      });

      var liquidityAssetServices = await Promise.all(liquidityUpdatePromises);
      this.setState({
        liquidityAssetServices: liquidityAssetServices,
        isLoaded: true,
        isConnected: this.props.ethereumService.walletData.isConnected
      });
    }
  }

  async update() {
    await Promise.all(this.state.liquidityAssetServices.map(s => s.update()));

    this.setState({ liquidityAssetServices: this.state.liquidityAssetServices });
  }

  render(): React.ReactNode {
    return (
      <section id="content" className="pools is-vcentered">
        <div className="container">
          {!this.state.isConnected && (
            <span
              className="column meme is-half has-text-centered mt-4"
              style={{ cursor: 'pointer' }}
              onClick={() => this.props.ethereumService.connectWallet()}>
              <h3 className="sub-title py-3">Connect Wallet</h3>
            </span>
          )}
          {this.state.isConnected && !this.state.isLoaded && (
            <span className="column meme is-half has-text-centered mt-4">
              <h3 className="sub-title py-3">Loading...</h3>
            </span>
          )}

          {this.state.isConnected && this.state.isLoaded && this.state.liquidityAssetServices.length === 0 && (
            <span className="column meme is-half has-text-centered">
              <h3 className="sub-title py-3">Nothing going on here, try a different network!</h3>
            </span>
          )}

          {this.state.isConnected && this.state.isLoaded && this.state.liquidityAssetServices.length > 0 && (
            <LiquidityPool liquidityAssetServices={this.state.liquidityAssetServices}></LiquidityPool>
          )}
        </div>
      </section>
    );
  }
}

export default Opportunities;
