import { Mutex } from 'async-mutex';
import { BigNumber, ethers } from 'ethers';
import { EthereumService } from './EthereumService';
import { MessagingService } from './MessagingService';

export class OwnedTokenCacheService {
  private mutex = new Mutex();
  private readonly tokensKey: string;
  private readonly blockKey: string;
  walletAddress: string;

  ownedTokens: BigNumber[];

  constructor(
    private contract: ethers.Contract,
    private transfersInfilter: ethers.EventFilter,
    private transfersOutFilter: ethers.EventFilter,
    private contractStartBlock: number,
    private ethereumService: EthereumService,
    private messagingService?: MessagingService | null,
    private newEventsMessage?: string | null
  ) {
    this.walletAddress = ethereumService.walletData.walletAddress;
    const prefix = `${ethereumService.networkManager!.network!.chainId}.${contract.address}.${this.walletAddress}`;
    this.tokensKey = `${prefix}.OwnedTokens`;
    this.blockKey = `${prefix}.OwnedTokensBlock`;
    this.ownedTokens = [];
  }

  async update() {
    await this.mutex.runExclusive(async () => {
      await this.refreshEvents();
    });
  }

  private async refreshEvents() {
    var cachedTokensJson = localStorage.getItem(this.tokensKey);
    var lastBlockCached = localStorage.getItem(this.blockKey) ?? this.contractStartBlock;

    var blockNumber = await this.ethereumService.provider!.getBlockNumber();

    if (cachedTokensJson) {
      this.ownedTokens = JSON.parse(cachedTokensJson).map((_hex: string) => BigNumber.from(_hex));
    }

    if (!cachedTokensJson || blockNumber > +lastBlockCached) {
      await this.applyLatestEvents(+lastBlockCached, blockNumber);

      localStorage.setItem(this.tokensKey, JSON.stringify(this.ownedTokens.map(t => t._hex)));
    }

    localStorage.setItem(this.blockKey, blockNumber.toString());
  }

  private async applyLatestEvents(fromBlock: number, toBlock: number) {
    var transferInEvents: any[] = [];
    var transferOutEvents: any[] = [];
    try {
      var response = await Promise.all([
        this.contract!.queryFilter(this.transfersInfilter, fromBlock, toBlock),
        this.contract!.queryFilter(this.transfersOutFilter, fromBlock, toBlock)
      ]);
      transferInEvents = response[0];
      transferOutEvents = response[1];
    } catch (e) {
      console.error(e);
    }

    var orderedEvents = transferInEvents.concat(transferOutEvents).sort((a, b) => a.blockNumber - b.blockNumber);
    if (orderedEvents.length === 0) return;

    if (this.messagingService && this.newEventsMessage) {
      this.messagingService.events.next({
        message: this.newEventsMessage
      });
    }

    this.ownedTokens = orderedEvents.reduce((p: any[], c: any) => {
      var cTokenId = BigNumber.from(c.args[2]);

      if (c.args[0] === this.walletAddress) {
        p = p.filter(t => !cTokenId.eq(t));
      } else if (c.args[1] === this.walletAddress) {
        if (!p.includes(cTokenId)) {
          p.push(cTokenId);
        }
      }

      return p;
    }, this.ownedTokens);
  }
}
