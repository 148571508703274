import { ethers } from 'ethers';
import { ILiquidityConfig } from '../models/LiquidityConfig';
import { EthereumService } from './EthereumService';
import { MessagingService } from './MessagingService';
import { Utils } from './Utils';
import { ILiquidityAssetService } from './Interfaces';

export class StakingAssetService implements ILiquidityAssetService {
  walletAddress?: string;

  poolContract?: ethers.Contract | any;
  stakingContract?: ethers.Contract | any;

  lptBalance: string | 0 = 0;
  approvedLptBalance: string | 0 = 0;
  unapprovedLptBalance: string | 0 = 0;
  liquidityBalance: string | 0 = 0;

  hasReward = true;
  currentReward: string | 0 = 0;
  upcomingReward: string | 0 = 0;
  isInstantReward = false;
  totalLiquidity: string = '0';
  apy: number | 0 = 0;

  actionDisplayName = 'Stake';
  undoActionDisplayName = 'Unstake';

  constructor(
    public config: ILiquidityConfig,
    private ethereumService: EthereumService,
    private messagingService: MessagingService
  ) {
    this.walletAddress = this.ethereumService!.walletData.walletAddress;
    this.poolContract = new ethers.Contract(
      this.config.poolContractAddress,
      this.config.poolContractAbi,
      ethereumService.signer
    );
    this.stakingContract = new ethers.Contract(
      this.config.liquidityContractAddress,
      this.config.liquidityContractAbi,
      ethereumService.signer
    );

    this.setupEventHandlers();
  }

  update(): Promise<ILiquidityAssetService> {
    return Promise.all([
      this.poolContract.balanceOf(this.walletAddress),
      this.poolContract.allowance(this.walletAddress, this.config.liquidityContractAddress),
      this.stakingContract.balanceOf(this.walletAddress),
      this.stakingContract.calculateReward(this.walletAddress),
      this.stakingContract.estimateReward(this.walletAddress)
    ]).then(response => {
      this.lptBalance = Utils.formatUnits(response[0], 18);
      this.approvedLptBalance = Utils.formatUnits(response[1], 18);
      this.unapprovedLptBalance = Utils.formatUnits(response[0].sub(response[1]), 18);
      this.liquidityBalance = Utils.formatUnits(response[2], 18);
      this.currentReward = Utils.formatUnits(response[3], 18);
      this.upcomingReward = Utils.formatUnits(response[4], 18);

      // TODO: error checking

      // TODO: determine total liquidity
      var totalLiquidity = 0;
      this.totalLiquidity = totalLiquidity.toLocaleString();

      // TODO: determine ROI
      this.apy = 0;

      return this;
    });
  }

  approve() {
    var amount = Utils.parseUnits('1000000000');

    this.poolContract.approve(this.config.liquidityContractAddress, amount).then(
      (approveResponse: any) =>
        this.messagingService.events.next({
          message: `Approval of ${this.config.lpToken} is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error approving ${this.config.lpToken} : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  add(amount: any) {
    var sanitizedAmount = Utils.sanitizeParseUnits(amount);

    this.stakingContract.stake(sanitizedAmount).then(
      (stakeResponse: any) =>
        this.messagingService.events.next({
          message: `Staking of ${this.config.lpToken} is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error staking ${this.config.lpToken} : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  remove(amount: any) {
    var sanitizedAmount = Utils.sanitizeParseUnits(amount);

    this.stakingContract.withdraw(sanitizedAmount).then(
      (withdrawResponse: any) =>
        this.messagingService.events.next({
          message: `Unstaking of ${this.config.lpToken} is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error unstaking ${this.config.lpToken} : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  claim() {
    this.stakingContract.claimReward().then(
      (claimResponse: any) =>
        this.messagingService.events.next({
          message: `Claim of ${this.currentReward} staking reward is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error claiming staking reward : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  exit() {
    this.stakingContract.exit().then(
      (exitResponse: any) =>
        this.messagingService.events.next({
          message: `Unstaking of ${this.config.lpToken} and reward claim is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error unstaking ${this.config.lpToken} : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  removeEventHandlers() {
    this.poolContract?.removeAllListeners();
    this.stakingContract?.removeAllListeners();
  }

  setupEventHandlers() {
    this.removeEventHandlers();

    var update = (message: string) =>
      this.update().then(() => {
        this.messagingService.events.next({ message: message });
      });

    if (this.poolContract) {
      this.poolContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('Approval(address,address,uint256)')]
        },
        (owner: any, spender: any, amount: any) => {
          if (owner === this.ethereumService.walletData.walletAddress) {
            update(`Approved ${this.config.lpToken}`);
          }
        }
      );
    }

    if (this.stakingContract) {
      this.stakingContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('Staked(address,uint256)')]
        },
        (user: any, amount: any) => {
          if (user === this.ethereumService.walletData.walletAddress) {
            update(`${Utils.formatUnits(amount, 18)} ${this.config.lpToken} staked`);
          }
        }
      );

      this.stakingContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('Withdrawn(address,uint256)')]
        },
        (user: any, amount: any) => {
          if (user === this.ethereumService.walletData.walletAddress) {
            update(`${Utils.formatUnits(amount, 18)} ${this.config.lpToken} unstaked`);
          }
        }
      );

      this.stakingContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('RewardPaid(address,uint256)')]
        },
        (user: any, amount: any) => {
          if (user === this.ethereumService.walletData.walletAddress) {
            update(`${Utils.formatUnits(amount, 18)} ${this.config.lpToken} reward collected`);
          }
        }
      );
    }
  }
}
