import { Component } from 'react';
import './MintBox.scss';
import { BigNumber, ethers } from 'ethers';
import { EthereumService } from '../../services/EthereumService';
import { Utils } from '../../services/Utils';
import box from '../../assets/box.png';
import { MessagingService } from '../../services/MessagingService';

export interface IMintBoxProps {
  ethereumService: EthereumService;
  messagingService: MessagingService;
  close: any;
}

enum BoxSize {
  Small = 0,
  Medium = 1,
  Large = 2
}

export interface IMintBoxState {
  name: string;
  boxPrice: BigNumber;
  boxSize: BoxSize;
  citizenPrice: BigNumber;
  numberOfCitizens: BigNumber;
  numberOfMercenaries: BigNumber;
  numberOfPacks: BigNumber;
  numberOfItemsInPack: BigNumber;
  packID: BigNumber;
  amountOfLand: BigNumber;
  amountOfLandNumber: number;
}

export class MintBox extends Component<IMintBoxProps, IMintBoxState> {
  boxContract: ethers.Contract;
  small?: IMintBoxState;
  medium?: IMintBoxState;
  large?: IMintBoxState;

  constructor(props: IMintBoxProps) {
    super(props);

    this.close = this.close.bind(this);
    this.mint = this.mint.bind(this);

    this.boxContract = new ethers.Contract(
      this.props.ethereumService.networkManager!.network!.boxConfig!.contractAddress,
      this.props.ethereumService.networkManager!.network!.boxConfig!.contractConstants.contractAbi,
      this.props.ethereumService.signer
    );

    this.state = {
      boxSize: BoxSize.Small,
      name: 'Small Box',
      boxPrice: ethers.constants.Zero,
      citizenPrice: ethers.constants.Zero,
      numberOfCitizens: ethers.constants.One,
      numberOfMercenaries: ethers.constants.One,
      numberOfPacks: ethers.constants.One,
      numberOfItemsInPack: BigNumber.from(3),
      packID: ethers.constants.Zero,
      amountOfLand: BigNumber.from(5000),
      amountOfLandNumber: 5000
    };
  }

  handleBoxSizeChange(amount: number) {
    var boxSize = this.state.boxSize + amount;
    this.boxContract.boxes(boxSize).then((box: any) => {
      this.setState({ ...box, boxSize: boxSize, amountOfLandNumber: Utils.formatUnits(box.amountOfLand) });
    });
  }

  componentDidMount() {
    this.handleBoxSizeChange(0);

    const account = this.props.ethereumService.walletData.walletAddress;
    const eventSignature = this.boxContract.interface.getEventTopic('BoxesMinted');
    const filter = {
      address: account,
      topics: [eventSignature]
    };

    this.boxContract.on(filter, (account, price, land, packId, noOfPacks, noOfItems, noOfMercenaries) => {
      if (account === this.props.ethereumService.walletData.walletAddress) {
        this.props.messagingService.errors.next({
          message: 'Box was minted'
        });
      }
    });
  }

  componentWillUnmount() {
    this.boxContract.removeAllListeners();
  }

  close() {
    this.props.close();
  }

  async mint() {
    try {
      var amount = BigNumber.from(1);
      var ethAmount = {
        value: this.state.boxPrice!.mul(amount)
      };

      const gasEstimated = await this.boxContract.estimateGas.mintBoxes(this.state.boxSize, amount, ethAmount);

      await this.boxContract.mintBoxes(this.state.boxSize, amount, {
        ...ethAmount,
        gasLimit: Math.ceil(gasEstimated.toNumber() * 1.5)
      });
      this.props.messagingService.events.next({
        message: `Minting a Box is pending`,
        pending: true
      });
    } catch (error) {
      this.props.messagingService.errors.next({
        message: `Error minting Box : ${Utils.getContractErrorMessage(error)}`,
        error: error
      });
    }

    this.close();
  }

  render() {
    return (
      <div className="is-active modal noselect">
        <div className="modal-background" onClick={this.close}></div>
        <div id="buy-box" className="modal-content">
          <div className="card pb-0">
            <button className="modal-close is-medium" aria-label="close" onClick={this.close}></button>
            <div className="is-flex is-justify-content-center buy-box-title">Starter Box</div>
            <div className="buy-box-content is-flex flex-column is-flex-direction-column">
              <h4 className="has-light-text is-size-6-touch" style={{ textAlign: 'center' }}>
                All you need to get started in one handy (virtual) box.
              </h4>
              <div className="buy-box-content is-flex flex-column is-flex-direction-column is-align-items-center is-justify-content-center">
                <img src={box} width="280px" alt="Farmland" />
              </div>
              <span className="has-light3-text is-size-7" style={{ textAlign: 'center' }}>
                Box contents : {this.state.numberOfCitizens.toNumber()} {this.state.numberOfCitizens.toNumber() > 1 ? 'Citizens' : 'Citizen' },{' '}
                {this.state.numberOfMercenaries.toNumber()} {this.state.numberOfMercenaries.toNumber() > 1 ? 'Mercenaries' : 'Mercenary' } and {this.state.numberOfPacks.toNumber()} Packs
                (plus {Utils.toFixed(this.state.amountOfLandNumber, 0)} free LAND).
              </span>
              <div className="is-flex is-justify-content-center mt-4 mb-2">
                <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center restore-stat-amount">
                  <ul className="action-control">
                    <li className="action-form">
                      <div className="size-controls field has-addons">
                        <div className="control">
                          <button
                            className="button minus-button is-action"
                            disabled={this.state.boxSize <= BoxSize.Small}
                            onClick={() => this.handleBoxSizeChange(-1)}>
                            <span className="icon">
                              <i className="fas fa-minus"></i>
                            </span>
                          </button>
                        </div>
                        <div className="control">
                          <input className="input is-action" type="text" disabled={true} value={this.state.name} />
                        </div>
                        <div className="control">
                          <button
                            className="button plus-button is-action"
                            disabled={this.state.boxSize >= BoxSize.Large}
                            onClick={() => this.handleBoxSizeChange(+1)}>
                            <span className="icon">
                              <i className="fas fa-plus"></i>
                            </span>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li>
                      <button className="buy-button button mb-2 is-action is-fullwidth" onClick={this.mint}>
                        Mint
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <span style={{ textAlign: 'center' }}>
                Cost {Utils.toFixed(Utils.formatUnits(this.state.boxPrice), 3)} ETH (+ gas)
              </span>
              <span className="is-size-7 mt-2" style={{ textAlign: 'center' }}>
                Land & Citizen(s) will be sent to your wallet automatically. Mercenaries and Packs must be claimed.
              </span>
              <span className="is-size-7" style={{ textAlign: 'center' }}>
                (Note: The box contains virtual items)
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
