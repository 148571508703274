import { Mutex } from 'async-mutex';
import { ethers, EventFilter } from 'ethers';
import { EthereumService } from './EthereumService';
import { MessagingService } from './MessagingService';

function areEventsDifferent(a: any, b: any): boolean {
  return JSON.stringify(a) !== JSON.stringify(b);
}

/* Example
    var addedCache = new EventCacheService(
      this.config.contractAddress,
      this.config.contractContants.contractAbi,
      this.config.deployedBlockNumber,
      this.contract!.filters.Added(this.walletAddress),
      ethereumService
    );

    var added = addedCache.events;
*/
export class EventCacheService {
  private mutex = new Mutex();
  private readonly eventKey: string;
  private readonly blockKey: string;

  public events: any[] = [];

  constructor(
    private contract: ethers.Contract,
    private eventFilter: EventFilter,
    private contractStartBlock: number,
    protected ethereumService: EthereumService,
    private messagingService?: MessagingService | null,
    private newEventsMessage?: string | null
  ) {
    const prefix = `${ethereumService.networkManager!.network!.chainId}.${contract.address}.${
      ethereumService.walletData.walletAddress
    }`;
    this.eventKey = `${prefix}.Events`;
    this.blockKey = `${prefix}.Block`;
  }

  async update() {
    await this.mutex.runExclusive(async () => {
      await this.refreshEvents();
    });
  }

  private async refreshEvents() {
    var cachedEventsJson = localStorage.getItem(this.eventKey);
    var lastBlockCached = localStorage.getItem(this.blockKey) ?? this.contractStartBlock;

    var blockNumber = await this.ethereumService.provider!.getBlockNumber();

    if (cachedEventsJson) {
      this.events = JSON.parse(cachedEventsJson);
    }

    if (!cachedEventsJson || blockNumber > +lastBlockCached) {
      var latestEvents = await this.loadLatestEvents(+lastBlockCached, blockNumber);

      if (latestEvents.length > 0) {
        latestEvents.forEach((i: any) => {
          if (this.events.length === 0 || this.events.some((j: any) => areEventsDifferent(i, j))) {
            this.events.push(i);
          }
        });

        localStorage.setItem(this.eventKey, JSON.stringify(this.events));

        if (this.messagingService && this.newEventsMessage) {
          this.messagingService.events.next({
            message: this.newEventsMessage
          });
        }
      }
    }

    localStorage.setItem(this.blockKey, blockNumber.toString());
  }

  private async loadLatestEvents(fromBlock: number, toBlock: number): Promise<any[]> {
    var events: any[] = [];
    try {
      events = await this.contract!.queryFilter(this.eventFilter, fromBlock, toBlock);
    } catch (e) {
      console.error(e);
    }

    return events;
  }
}
