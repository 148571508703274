import { EthereumService } from '../../services/EthereumService';
import { ItemService } from '../../services/ItemService';
import { MercenaryService } from '../../services/MercenaryService';
import { MessagingService } from '../../services/MessagingService';
import { IQuestService } from '../../services/QuestService';
import { Explorer } from './Explorer';
import { ExplorerState } from './ExplorerState';
import { IQuestCharacter } from './Quests';

interface IExplorerWrapperProps {
  explorer?: ExplorerState;
  questService?: IQuestService;
  mercenaryService?: MercenaryService;
  messagingService?: MessagingService;
  itemService?: ItemService;
  ethereumService?: EthereumService;
  refreshedAtBlock: number;
  onBeginQuest: (questCharacter: IQuestCharacter) => void;
  onRestoreHealth: (questCharacter: IQuestCharacter) => void;
  onRestoreMorale: (questCharacter: IQuestCharacter) => void;
  onallocateStatBoosts: (questCharacter: IQuestCharacter) => void;
  onupdateAppearance: (questCharacter: IQuestCharacter) => void;
}

const ExplorerWrapper = (props: IExplorerWrapperProps) => {
  return (
    <>
      {/* <div className="has-light-text">Id: {props.character!.id}</div>
      <div className="has-light-text">
        DetailsRefreshedAtBlock: {props.character!.detailsRefreshedAtBlock.toString()}
      </div>
      <div className="has-light-text">{props.character!.details !== null ? 'Loaded' : 'Not'}</div> */}
      <Explorer
        itemService={props.itemService}
        questService={props.questService}
        mercenaryService={props.mercenaryService}
        messagingService={props.messagingService}
        explorerState={props.explorer!}
        ethereumService={props.ethereumService}
        refreshedAtBlock={props.refreshedAtBlock}
        isLoading={!props.explorer!.isCharacterLoaded}
        onBeginQuest={props.onBeginQuest}
        onRestoreHealth={props.onRestoreHealth}
        onRestoreMorale={props.onRestoreMorale}
        onallocateStatBoosts={props.onallocateStatBoosts}
        onupdateAppearance={props.onupdateAppearance}></Explorer>
    </>
  );
};

export default ExplorerWrapper;
