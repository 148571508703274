import { BigNumber, ethers } from 'ethers';
import { Token } from '@uniswap/sdk';
import { EthereumService } from './EthereumService';
import { INetworkConfig } from '../models/NetworkConfig';
import { ArbitrumNetworks } from '../models/Networks/ArbitrumNetworks';
import { EthereumNetworks } from '../models/Networks/EthereumNetworks';
import { TokenId } from '../models/Enums';
import { MessagingService } from './MessagingService';

export class NetworkManager {
  public ethersProvider?: ethers.providers.BaseProvider;
  public network?: INetworkConfig;
  public availableNetworks: INetworkConfig[] = [
    EthereumNetworks.HOMESTEAD,
    EthereumNetworks.RINKEBY,
    ArbitrumNetworks.ONE,
    ArbitrumNetworks.GOERLI
  ];

  constructor(private ethereumService: EthereumService, private messagingService: MessagingService) {}

  update(): Promise<void | ethers.providers.Network> {
    return this.ethereumService.provider!.getNetwork().then(network => {
      switch (network.chainId) {
        case EthereumNetworks.HOMESTEAD.chainId:
          this.network = EthereumNetworks.HOMESTEAD;
          break;
        case EthereumNetworks.RINKEBY.chainId:
          this.network = EthereumNetworks.RINKEBY;
          break;
        case ArbitrumNetworks.ONE.chainId:
          this.network = ArbitrumNetworks.ONE;
          break;
        case ArbitrumNetworks.GOERLI.chainId:
          this.network = ArbitrumNetworks.GOERLI;
          break;
      }

      if (this.network) {
        this.ethersProvider = this.getProvider(network);

        this.network!.assets.forEach((asset, id) => {
          this.network!.contracts[id] = new ethers.Contract(
            asset.contractAddress,
            asset.assetConstants!.contractAbi,
            this.ethereumService.signer
          );

          this.network!.tokens[id] = new Token(
            this.network!.chainId,
            asset.contractAddress,
            asset.assetConstants!.decimals,
            asset.assetConstants?.symbol,
            id
          );
        });

        if (this.network?.loadPrices && this.network?.usdcContractAddress) {
          this.network.tokens.USDC = new Token(this.network.chainId, this.network!.usdcContractAddress, 6);
          this.network.tokens.WETH = new Token(this.network.chainId, this.network!.wethContractAddres, 18);
        }
      }
    });
  }

  getProvider(network: ethers.providers.Network): ethers.providers.BaseProvider {
    if (this.network?.isL2) {
      var providers = [
        new ethers.providers.EtherscanProvider(this.network.name),
        new ethers.providers.AlchemyProvider(this.network.name, this.network!.providerConfig.alchemyKey),
        ...this.network!.rpcUrls.map(u => {
          return new ethers.providers.JsonRpcProvider({
            url: u,
            throttleLimit: 1,
            throttleSlotInterval: 100000
          });
        })
      ];
      return new ethers.providers.FallbackProvider(providers);
    } else {
      return ethers.getDefaultProvider(network, this.network!.providerConfig);
    }
  }

  addToken(tokenName: string) {
    var options: any = {};
    switch (tokenName.toLowerCase()) {
      case 'land':
        Object.assign(options, this.network!.tokens.LAND);
        options.address = this.network!.assets.get(TokenId.LAND)!.contractAddress;
        options.image = 'https://farmlandgame.net/land.png';
        break;
      case 'corn':
        Object.assign(options, this.network!.tokens.CORN);
        options.address = this.network!.assets.get(TokenId.CORN)!.contractAddress;
        options.image = 'https://farmlandgame.net/corn.png';
        break;
      default:
        return;
    }
    window.ethereum
      .request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: options
        }
      })
      .then(
        (added: any) =>
          added
            ? this.messagingService.events.next({ message: 'Token Added' })
            : this.messagingService.events.next({ message: 'Cancelled Add Token', error: 'Token add cancelled' }),
        (error: any) => this.messagingService.errors.next({ message: 'Failed to add token', error: error })
      );
  }

  async changeNetwork(network: INetworkConfig) {
    if (window.ethereum) {
      try {
        await window.ethereum.enable();

        const accounts = await window.ethereum.request({ method: 'eth_accounts' });

        var hexChainId = ethers.utils.hexStripZeros(BigNumber.from(network.chainId).toHexString());

        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [
              {
                chainId: hexChainId
              },
              accounts[0]
            ]
          });
        } catch (error) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: hexChainId,
                chainName: network.fullDisplayChainName,
                nativeCurrency: {
                  name: network.nativeCurrencyName,
                  symbol: network.nativeCurrencySymbol,
                  decimals: 18
                },
                rpcUrls: network.rpcUrls,
                blockExplorerUrls: network.blockExplorerUrls
              },
              accounts[0]
            ]
          });
        }
      } catch (error) {
        console.error('Error switching network' + error);
      }
    }
  }
}
