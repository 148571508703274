import * as React from 'react';
import { CSSProperties } from 'react';

type Props = {
  children: React.ReactNode;
  style?: CSSProperties;
};

const ViewWrapper = React.forwardRef<HTMLDivElement, Props>(({ style, ...props }, ref) => {
  return <span ref={ref} style={{ ...style }} {...props} />;
});

export default ViewWrapper;
