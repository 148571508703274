import { render } from 'react-dom';
import App from './components/App';
import { HashRouter } from 'react-router-dom';

import './index.scss';

render(
  <HashRouter>
    <App />
  </HashRouter>,
  document.getElementById('root')
);
