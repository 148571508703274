import { BigNumber, ethers, providers } from 'ethers';
import Web3Modal from 'web3modal';

import { NetworkManager } from './NetworkManager';
import { DefaultPricesService, IPriceService } from './IPricesService';
import { UniswapPricesService } from './UniswapPricesService';
import { GraphPricesService } from './GraphPricesService';
import { ProvidersConfig } from '../models/ProvidersConfig';
import { Utils } from './Utils';
import { IWalletData, WalletData } from '../models/WalletData';
import { MessagingService } from './MessagingService';
import { EthereumNetworks } from '../models/Networks/EthereumNetworks';

import { debounce } from 'ts-debounce';

export class EthereumService {
  initError = false;
  hasError = false;
  errorMessage = '';
  walletData: IWalletData;

  web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions: ProvidersConfig.web3ModalProviderOptions
  });

  networkManager?: NetworkManager;
  pricesService?: IPriceService;

  provider?: providers.Web3Provider;
  signer?: providers.JsonRpcSigner;

  constructor(private messagingService: MessagingService) {
    this.connectWallet();

    this.reset();

    this.walletData = new WalletData();
  }

  reset() {
    this.removeEventHandlers();

    this.hasError = false;
    this.errorMessage = '';

    this.networkManager = new NetworkManager(this, this.messagingService);
    this.pricesService = new DefaultPricesService();
  }

  resetConnectedWallet() {
    this.web3Modal.clearCachedProvider();
  }

  connectWallet() {
    this.web3Modal.connect().then(
      provider => {
        if (provider.code < 0) {
          this.messagingService!.errors.next({
            message: `Error connecting to node: ${provider.message}`,
            error: 'Error connecting to node'
          });
        } else {
          this.provider = new providers.Web3Provider(provider);
          provider.pollingInterval = 12000;
          this.connect().then(e => this.messagingService!.networkEvents.next({}));
        }
      },
      error =>
        this.messagingService!.errors.next({
          message: 'Error connecting to wallet, check wallet and try again',
          error: 'Error connecting to wallet'
        })
    );
  }

  connect() {
    try {
      this.signer = this.provider!.getSigner();
      return this.initAccount().then(() => {
        this.messagingService!.networkEvents.next({});

        this.pricesService!.update().then(() => {
          this.messagingService!.networkEvents.next({});
        });

        this.setupEventHandlers();
      });
    } catch (e) {
      console.error(e);
      this.initError = true;
      return new Promise((resolve, reject) => {});
    }
  }

  async initAccount(): Promise<any> {
    this.reset();

    try {
      this.walletData.walletAddress = await this.signer!.getAddress();
      this.walletData.isConnected = true;
    } catch {
      this.walletData = new WalletData();
    }

    return this.networkManager!.update().then(() => {
      if (!this.networkManager!.network?.loadPrices) {
      } else if (this.networkManager!.network?.chainId === EthereumNetworks.HOMESTEAD.chainId) {
        this.pricesService = new UniswapPricesService(this);
      } else {
        this.pricesService = new GraphPricesService(this);
      }
    });
  }

  async loadBalance(): Promise<any> {
    if (this.walletData.isConnected) {
      var response = await this.provider!.getBalance(this.walletData.walletAddress);
      this.walletData.ethBalance = Utils.formatUnits(response);
    }

    if (this.networkManager?.network) {
      if (this.networkManager.network.contracts.LAND) {
        var landContract = this.networkManager.network.contracts.LAND;
        this.walletData.landBalance = Utils.formatUnits(await landContract.balanceOf(this.walletData.walletAddress));
      }
      if (this.networkManager.network.contracts.CORN) {
        var cornContract = this.networkManager.network.contracts.CORN;
        this.walletData.cornBalance = Utils.formatUnits(await cornContract.balanceOf(this.walletData.walletAddress));
      }
    }
  }

  removeEventHandlers() {
    this.provider?.removeAllListeners();
  }

  async refresh() {
    if (!this.networkManager?.network?.contracts.LAND) return;

    if (!this.walletData.stdBlockNumber) {
      await this.loadBlockNumber();
    }

    this.loadBalance().then(() => {
      this.messagingService!.events.next({ block: this.walletData.blockNumber });
    });

    if (this.pricesService) {
      await this.pricesService!.update();
    }
  }

  async loadBlockNumber() {
    if (this.walletData.isConnected) {
      var stdBlockNumber = await this.getBlockNumber();
      if (stdBlockNumber != null) {
        this.walletData.stdBlockNumber = stdBlockNumber;
      }
    }
  }

  async getBlockNumber(): Promise<number | null> {
    var blockNumber = await this.provider!.getBlockNumber();
    return await this.standardizeBlockNumber(blockNumber);
  }

  async onNewBlock(blockNumber: number) {
    var stdBlockNumber = await this.standardizeBlockNumber(blockNumber);
    if (stdBlockNumber && this.walletData.stdBlockNumber !== stdBlockNumber) {
      this.walletData.stdBlockNumber = stdBlockNumber;
    }

    if (blockNumber && this.walletData.blockNumber !== blockNumber) {
      this.walletData.blockNumber = blockNumber;
      this.refresh();
    }
  }

  async standardizeBlockNumber(blockNumber: number): Promise<number | null> {
    // TODO: supports arbitrum only at the moment
    var l1BlockNumber = blockNumber;

    if (this.networkManager?.network?.isL2) {
      try {
        const normalizedBlockNumber = ethers.utils.hexStripZeros(BigNumber.from(blockNumber).toHexString());
        const l2Block = await this.provider!.send('eth_getBlockByNumber', [normalizedBlockNumber, false]);
        l1BlockNumber = Number.parseInt(Utils.formatUnits(l2Block.l1BlockNumber, 0));
      } catch (error) {
        return null;
      }
    }

    return l1BlockNumber;
  }

  setupEventHandlers() {
    window.ethereum.on('accountsChanged', (accounts: any) => {
      if (!accounts) {
        this.resetConnectedWallet();
      }
      window.location.reload();
      this.connect();
    });

    window.ethereum.on('chainChanged', (networkId: any) => {
      if (!networkId) {
        this.resetConnectedWallet();
      }

      window.location.reload();
      this.connectWallet();
    });

    var onNewBlock = debounce((block: number) => this.onNewBlock(block), 100);
    this.provider!.on('block', currentBlock => {
      onNewBlock(currentBlock);
    });

    this.refresh();
  }
}
