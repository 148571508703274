import { Component } from 'react';
import './Unrevealed.scss';

export interface IUnrevealedSProps {
  scale: number;
}
export class UnrevealedS extends Component<IUnrevealedSProps> {
  render() {
    return (
      <div className="unrevealed-outer-container">
        <div className="unrevealed-scaled-container" style={{ transform: `scale(${this.props.scale})` }}>
          <Unrevealed></Unrevealed>
        </div>
      </div>
    );
  }
}

export interface IUnrevealedProps {}
export class Unrevealed extends Component<IUnrevealedProps> {
  render() {
    return (
      <div className="unrevealed is-flex is-justify-content-center is-align-items-center">
        <div className="cube">
          <div className="face front">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <path d="m0 0h16v16h-16z" />
              <path d="m0 0h15v15h-15z" fill="#E86010" />
              <path d="m1 1h14v14h-14z" fill="#FFA048" />
              <path d="m2 13h1v1h-1z" />
              <path d="m13 13h1v1h-1z" />
              <path d="m13 2h1v1h-1z" />
              <path d="m2 2h1v1h-1z" />
              <path d="m10 14h-2v-2h2zm-1-5h-1v2h2v-2h2v-4h-1v-1h-5v1h-1v3h2v-3h3v3h-1z" />
              <path d="m9 13h-2v-2h2zm-1-5h-1v2h2v-2h2v-4h-1v-1h-5v1h-1v3h2v-3h3v3h-1z" fill="#E86010" />
            </svg>
            <div className="shade"></div>
          </div>
          <div className="face right">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <path d="m0 0h16v16h-16z" />
              <path d="m0 0h15v15h-15z" fill="#E86010" />
              <path d="m1 1h14v14h-14z" fill="#FFA048" />
              <path d="m2 13h1v1h-1z" />
              <path d="m13 13h1v1h-1z" />
              <path d="m13 2h1v1h-1z" />
              <path d="m2 2h1v1h-1z" />
              <path d="m10 14h-2v-2h2zm-1-5h-1v2h2v-2h2v-4h-1v-1h-5v1h-1v3h2v-3h3v3h-1z" />
              <path d="m9 13h-2v-2h2zm-1-5h-1v2h2v-2h2v-4h-1v-1h-5v1h-1v3h2v-3h3v3h-1z" fill="#E86010" />
            </svg>
            <div className="shade"></div>
          </div>
          <div className="face back">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <path d="m0 0h16v16h-16z" />
              <path d="m0 0h15v15h-15z" fill="#E86010" />
              <path d="m1 1h14v14h-14z" fill="#FFA048" />
              <path d="m2 13h1v1h-1z" />
              <path d="m13 13h1v1h-1z" />
              <path d="m13 2h1v1h-1z" />
              <path d="m2 2h1v1h-1z" />
              <path d="m10 14h-2v-2h2zm-1-5h-1v2h2v-2h2v-4h-1v-1h-5v1h-1v3h2v-3h3v3h-1z" />
              <path d="m9 13h-2v-2h2zm-1-5h-1v2h2v-2h2v-4h-1v-1h-5v1h-1v3h2v-3h3v3h-1z" fill="#E86010" />
            </svg>
            <div className="shade"></div>
          </div>
          <div className="face left">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <path d="m0 0h16v16h-16z" />
              <path d="m0 0h15v15h-15z" fill="#E86010" />
              <path d="m1 1h14v14h-14z" fill="#FFA048" />
              <path d="m2 13h1v1h-1z" />
              <path d="m13 13h1v1h-1z" />
              <path d="m13 2h1v1h-1z" />
              <path d="m2 2h1v1h-1z" />
              <path d="m10 14h-2v-2h2zm-1-5h-1v2h2v-2h2v-4h-1v-1h-5v1h-1v3h2v-3h3v3h-1z" />
              <path d="m9 13h-2v-2h2zm-1-5h-1v2h2v-2h2v-4h-1v-1h-5v1h-1v3h2v-3h3v3h-1z" fill="#E86010" />
            </svg>
            <div className="shade"></div>
          </div>

          <div className="face top">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <path d="m0 0h16v16h-16z" />
              <path d="m0 0h15v15h-15z" fill="#E86010" />
              <path d="m1 1h14v14h-14z" fill="#FFA048" />
              <path d="m2 13h1v1h-1z" />
              <path d="m13 13h1v1h-1z" />
              <path d="m13 2h1v1h-1z" />
              <path d="m2 2h1v1h-1z" />
            </svg>
          </div>
          <div className="face bottom">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <path d="m0 0h16v16h-16z" />
              <path d="m0 0h15v15h-15z" fill="#E86010" />
              <path d="m1 1h14v14h-14z" fill="#FFA048" />
              <path d="m2 13h1v1h-1z" />
              <path d="m13 13h1v1h-1z" />
              <path d="m13 2h1v1h-1z" />
              <path d="m2 2h1v1h-1z" />
            </svg>
          </div>
        </div>
      </div>
    );
  }
}
