import React, { Component } from 'react';
//@ts-ignore
import { Redirect } from 'react-router-dom';
import { EthereumService } from '../services/EthereumService';
import Content from './Content';
import NavBar from './NavBar';
import { IWalletData, WalletData } from '../models/WalletData';
import { MessagingService } from '../services/MessagingService';
import { CharacterImagePage } from './Characters/CharacterImagePage';
import { Footer } from './Footer';

interface IAppProps {}

interface IAppState {
  approvedTerms: boolean;
  initError: boolean;
  message: string;
  messagePending: string;
  error: string;
  walletData: IWalletData;
}

class App extends Component<IAppProps, IAppState> {
  messagingService: MessagingService;
  ethereumService: EthereumService;
  footerContainer: React.RefObject<HTMLDivElement>;
  footer: React.RefObject<Footer>;

  constructor(props: IAppProps) {
    super(props);

    this.footerContainer = React.createRef();
    this.footer = React.createRef();

    this.state = {
      approvedTerms: localStorage.getItem('approvedTermsv2') === 'true',
      initError: false,
      message: '',
      messagePending: '',
      error: '',
      walletData: new WalletData()
    };

    this.messagingService = new MessagingService();
    this.ethereumService = new EthereumService(this.messagingService);
    this.confirmTerms = this.confirmTerms.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside(e: any) {
    if (this.footerContainer!.current?.contains(e.target)) {
      if (!this.footer.current!.state.isMaximised) this.footer.current!.toggleMinMax();
    } else if (this.footer.current!.state.isMaximised) {
      this.footer.current!.toggleMinMax();
    }
  }

  confirmTerms() {
    this.setState({
      approvedTerms: true
    });

    localStorage.setItem('approvedTermsv2', 'true');
  }

  renderTerms() {
    return (
      <div className={`${this.state.approvedTerms ? '' : 'is-active'} modal`}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Notice</p>
          </header>
          <section className="modal-card-body">
            By clicking "I agree" and continuing to use the Farmland site and associated smart contracts you agree to
            each the following:
            <ul className="mt-2">
              <li className="mt-4">
                1. You will only make use of the Farmland site and associated smart contracts in accordance with all
                domestic and international laws.
              </li>
              <li className="mt-4">
                2. Farmland and associated smart contracts are provided "as is", without warranty of any kind, express
                or implied.
              </li>
              <li className="mt-4">
                3. Farmland is currently in beta and any action you take on the site or interacting with the smart
                contracts is strictly at your own risk.
              </li>
            </ul>
          </section>
          <footer className="modal-card-foot is-justify-content-center">
            <button className="button is-success" onClick={this.confirmTerms}>
              I Agree
            </button>
          </footer>
        </div>
      </div>
    );
  }

  render() {
    var matches = window.location.href.match(/\/(\w+)#\//);
    if (matches?.length === 2) {
      var newPath = `/#/${matches![1]}`;
      var redirect = `${window.origin}${newPath}`;
      window.location.href = redirect;
      return <Redirect to="/" />;
    }

    if (window.location.href.includes('character-image') || window.location.href.includes('citizen-image')) {
      var characterId = window.location.href.split('/').at(-1)?.split('.')[0];
      return (
        <CharacterImagePage
          ethereumService={this.ethereumService}
          isCitizen={window.location.href.includes('citizen-image')}
          characterId={characterId}></CharacterImagePage>
      );
    } else if (this.state.initError) {
      return (
        <div id="app">
          <section className="hero is-fullheight-with-navbar">
            <div className="hero-body">
              <div className="container">
                <h1 className="title">No Wallet</h1>
                <h2 className="subtitle">
                  <a href="https://metamask.io">Metamask</a> or equivalent required to access this page.
                </h2>
              </div>
            </div>
          </section>
        </div>
      );
    } else {
      return (
        <div id="app">
          <React.StrictMode>
            <NavBar messagingService={this.messagingService} ethereumService={this.ethereumService} />
            <div id="main">
              {this.renderTerms()}

              {this.state.approvedTerms && (
                <Content messagingService={this.messagingService} ethereumservice={this.ethereumService} />
              )}
            </div>
            <div ref={this.footerContainer}>
              <Footer
                ref={this.footer}
                messagingService={this.messagingService}
                ethereumService={this.ethereumService}
              />
            </div>
          </React.StrictMode>
        </div>
      );
    }
  }
}

export default App;
