import React, { Component } from 'react';
import { getStatPercent, ICharacterDetails, ICharacterStat, isStatFull } from '../../../models/CharacterDetails';
import { IQuestService } from '../../../services/QuestService';
import { IItemDetails } from '../../../models/ItemDetails';
import { ItemService } from '../../../services/ItemService';
import './RestoreStat.scss';

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/css/bundle';
import 'swiper/scss/navigation';
import { Utils } from '../../../services/Utils';

export interface IRestoreStatProps {
  questService: IQuestService;
  itemService: ItemService;
  character: ICharacterDetails;
  stat: ICharacterStat;
  restoreFunction: (characterDetails: ICharacterDetails, item: IItemDetails, amount: number) => Promise<any>;
  close: any;
}

export interface IRestoreStatState {
  selectedItem: IItemDetails | null;
  itemCount: number;
}

export class RestoreStat extends Component<IRestoreStatProps, IRestoreStatState> {
  statRestoreItems: IItemDetails[];

  constructor(props: any) {
    super(props);

    this.state = {
      selectedItem: null,
      itemCount: 1
    };

    this.selectRestorationItem = this.selectRestorationItem.bind(this);
    this.restoreStat = this.restoreStat.bind(this);
    this.close = this.close.bind(this);

    this.statRestoreItems = this.props.itemService.allItems.filter(
      // TODO: this is a hack, make more generic
      i => (i.isHealth && this.props.stat.name === 'Health') || (i.isMorale && this.props.stat.name === 'Morale')
    );

    SwiperCore.use([Navigation]);
  }

  selectRestorationItem(restorationItem: IItemDetails): void {
    this.setState({ selectedItem: restorationItem, itemCount: 1 });
  }

  handleAmountChange(amount: number) {
    this.setState({ itemCount: this.state.itemCount + amount });
  }

  restoreStat(): void {
    if (this.state.selectedItem !== null) {
      this.props.restoreFunction(this.props.character, this.state.selectedItem, this.state.itemCount);
      this.close();
    }
  }

  close() {
    this.props.close();
  }

  render() {
    return (
      <div className="is-active modal noselect">
        <div className="modal-background" onClick={this.close}></div>
        <div id="restore-stat" className="modal-content restore-stat">
          <div
            className="card"
            style={{
              backgroundImage: `linear-gradient(rgba(10, 10, 10, 0.6), rgba(10, 10, 10, 1)),url("${
                this.props.character!.image
              }")`
            }}>
            <button className="modal-close is-medium is-button" aria-label="close" onClick={this.close}></button>
            <div className="is-flex is-justify-content-center restore-stat-title pb-3">{this.props.character.name}</div>
            <div className="restore-stat-content">
              <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center mt-1">
                {this.renderStat()}
              </div>

              <div
                className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center mt-5"
                style={{ overflow: 'hidden' }}>
                {this.renderItems()}
              </div>

              {!this.state.selectedItem && (
                <span className="column meme is-half has-text-centered mt-4 mb-2">
                  <span>Select an Item</span>
                </span>
              )}

              {this.state.selectedItem && (
                <div className="is-flex is-justify-content-center mt-4 mb-2">
                  <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center restore-stat-amount">
                    <ul className="action-control">
                      <li className="action-form">
                        <div className="field has-addons">
                          <div className="control">
                            <button
                              className="button minus-button is-action"
                              disabled={this.state.itemCount <= 0}
                              onClick={() => this.handleAmountChange(-1)}>
                              <span className="icon">
                                <i className="fas fa-minus"></i>
                              </span>
                            </button>
                          </div>
                          <div className="control">
                            <input
                              className="input is-action"
                              type="text"
                              disabled={true}
                              value={this.state.itemCount}
                            />
                          </div>
                          <div className="control">
                            <button
                              className="button plus-button is-action"
                              disabled={
                                !this.state.selectedItem || this.state.itemCount >= this.state.selectedItem!.balance
                              }
                              onClick={() => this.handleAmountChange(+1)}>
                              <span className="icon">
                                <i className="fas fa-plus"></i>
                              </span>
                            </button>
                          </div>
                        </div>
                      </li>
                      <li>
                        <button
                          className="button restore-button is-action is-fullwidth"
                          disabled={isStatFull(this.props.stat) || !this.state.selectedItem}
                          onClick={() => this.restoreStat()}>
                          {isStatFull(this.props.stat) && <span>{this.props.stat.name} is Full</span>}
                          {!isStatFull(this.props.stat) && this.state.selectedItem && (
                            <span>
                              Use {Utils.toTitleCase(Utils.toRarityDisplay(this.state.selectedItem.rarity))}{' '}
                              {this.state.selectedItem.name}
                            </span>
                          )}
                          {!isStatFull(this.props.stat) && !this.state.selectedItem && <span>Select an Item</span>}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderStat() {
    var statWithRestore: ICharacterStat = !this.state.selectedItem
      ? this.props.stat
      : {
          name: this.props.stat.name,
          max: this.props.stat.max,
          remaining: this.props.stat.remaining + this.state.itemCount * this.state.selectedItem!.statRestoreAmount,
          bottomColor: this.props.stat.bottomColor,
          topColor: this.props.stat.topColor
        };

    return (
      <React.Fragment>
        {this.props.stat && (
          <div className="stat-container">
            <div
              className={`stat-bar ${isStatFull(statWithRestore) ? 'stat-full' : ''}`}
              style={{
                background: `linear-gradient(to right, var(--red) 0%, var(--green) ${
                  65 + (100 - getStatPercent(statWithRestore))
                }%)`,
                width: `${getStatPercent(statWithRestore)}%`
              }}>
              {this.props.stat.name}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderItems() {
    if (!this.props.questService.isStatRestoreItemUseApproved) {
      return (
        <React.Fragment>
          <span className="column meme is-half has-text-centered">
            <h3 className="empty-items py-3">Using Restoration Items Not Approved</h3>
          </span>
        </React.Fragment>
      );
    }

    var availableItems = this.statRestoreItems.filter(i => i.balance > 0);

    if (availableItems.length > 0) {
      return (
        <React.Fragment>
          <Swiper
            navigation={{
              prevEl: '.swiper-stat-prev',
              nextEl: '.swiper-stat-next'
            }}
            spaceBetween={8}
            slidesPerView="auto">
            {availableItems.map(i => (
              <SwiperSlide key={i.id}>
                <div
                  className={`stat-restore-item ${
                    this.state.selectedItem?.id === i.id ? 'stat-restore-item-selected' : ''
                  } ${Utils.toRarityDisplay(i.rarity)}`}
                  onClick={() => {
                    this.selectRestorationItem(i);
                  }}>
                  <div className="rarity-display">{Utils.toRarityDisplay(i.rarity)}</div>
                  <img alt={i.name} className="token" src={i.imageUrl} />
                  <div className="item-title">
                    <div>
                      <span className="item-name">{i.name}</span>
                      {i.id === this.state.selectedItem?.id && (
                        <span className="item-balance pl-2">
                          {Utils.numberToShortDisplay(i.balance - this.state.itemCount)}
                        </span>
                      )}
                      {i.id !== this.state.selectedItem?.id && (
                        <>
                          {i.balance > 0 && (
                            <span className="item-balance pl-2">{Utils.numberToShortDisplay(i.balance)}</span>
                          )}
                          {!i.balance && <span className="item-balance pl-2">-</span>}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <div className="swiper-button-prev swiper-stat-prev"></div>
            <div className="swiper-button-next swiper-stat-next"></div>
          </Swiper>
        </React.Fragment>
      );
    } else {
      return (
        <span className="column meme is-half has-text-centered">
          <h3 className="empty-items py-3">No {this.props.stat.name} Restore Items</h3>
        </span>
      );
    }
  }
}
