//@ts-ignore
import { RouteComponentProps } from 'react-router';
import React, { Component } from 'react';
import { ICharacterDetails, loadCitizenDetails, loadMercenaryDetails } from '../../models/CharacterDetails';
import { BigNumber, ethers } from 'ethers';
import { EthereumService } from '../../services/EthereumService';

import { Stage, Layer } from 'react-konva';
import { renderImagePart } from './ImagePart';
import { Unrevealed } from './Unrevealed';

export interface ICharacterImageProps {
  ethereumService: EthereumService;
  characterId: number;
  isCitizen: boolean;
}

export interface ICharacterImageState {
  character?: ICharacterDetails | null;
}

interface IReactRouterParams {
  characterId: number;
}

export class CharacterImagePage extends Component<
  ICharacterImageProps & RouteComponentProps<IReactRouterParams>,
  ICharacterImageState
> {
  constructor(props: ICharacterImageProps) {
    super(props);

    this.state = {
      character: null
    };

    var characterId = this.props.characterId;
    this.loadCharacter(characterId);
  }

  async delay() {
    return new Promise(res => {
      setTimeout(res, 500);
    });
  }

  async loadCharacter(characterId: number) {
    while (!this.props.ethereumService?.networkManager?.network) {
      await this.delay();
    }

    if (this.props.isCitizen) {
      await this.loadCitizen(characterId);
    } else {
      await this.loadMercenary(characterId);
    }
  }

  async loadCitizen(characterId: number) {
    const contract = new ethers.Contract(
      this.props.ethereumService.networkManager!.network!.characterConfig.citizenConfig.contractAddress,
      this.props.ethereumService.networkManager!.network!.characterConfig.citizenConfig.contractConstants.contractAbi,
      this.props.ethereumService.signer
    );

    const tokenId = BigNumber.from(characterId);

    var result = await Promise.all([
      contract!.tokenURI(tokenId.toNumber()),
      contract!.collectibleTraits(tokenId.toNumber())
    ]);

    const tokenUri = result[0];
    const stats = result[1];

    const character = await loadCitizenDetails(characterId, tokenId, tokenUri, stats, 0);
    this.setState({ character: character });
  }

  async loadMercenary(characterId: number) {
    const contract = new ethers.Contract(
      this.props.ethereumService.networkManager!.network!.characterConfig.mercenaryConfig.contractAddress,
      this.props.ethereumService.networkManager!.network!.characterConfig.mercenaryConfig.contractConstants.contractAbi,
      this.props.ethereumService.signer
    );

    const tokenId = BigNumber.from(characterId);

    var result = await Promise.all([
      contract!.tokenURI(tokenId.toNumber()),
      contract!.getStats(tokenId.toNumber()),
      contract!.getWrappedTokenDetails(tokenId)
    ]);
    const tokenUri = result[0];
    const stats = result[1];
    const wrappedTokenDetails = result[2];
    const wrappedTokenId = wrappedTokenDetails.status ? wrappedTokenDetails.tokenId : null;

    const character = await loadMercenaryDetails(tokenId, wrappedTokenId, tokenUri, stats, 0);
    this.setState({ character: character });
  }

  render() {
    if (this.state.character) {
      document.querySelector('html')?.removeAttribute('class');

      return (
        <React.Fragment>
          {!this.state.character?.revealed && <Unrevealed></Unrevealed>}
          {this.state.character?.revealed && this.state.character!.image && !this.state.character!.imageParts && (
            <img className="nft" src={this.state.character!.image} alt={this.state.character.name} />
          )}
          {this.state.character?.revealed && !this.state.character!.image && this.state.character!.imageParts && (
            <Stage width={350} height={350}>
              <Layer>
                {renderImagePart('background', this.state.character!.imageParts.background, 350, 350)}
                {renderImagePart('base', this.state.character!.imageParts.base, 350, 350)}
                {renderImagePart('hair', this.state.character!.imageParts.hair, 350, 350)}
                {renderImagePart('eyes', this.state.character!.imageParts.eyes, 350, 350)}
                {renderImagePart('clothing', this.state.character!.imageParts.clothing, 350, 350)}
                {renderImagePart('mouth', this.state.character!.imageParts.mouth, 350, 350)}
                {renderImagePart('feature', this.state.character!.imageParts.feature, 350, 350)}
              </Layer>
            </Stage>
          )}
        </React.Fragment>
      );
    } else {
      return <div></div>;
    }
  }
}
