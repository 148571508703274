import { IAssetConfig } from '../../../models/AssetConfig';
import { AssetId } from '../../../models/NetworkConfig';
import { EthereumService } from '../../../services/EthereumService';
import { MessagingService } from '../../../services/MessagingService';
import { ICropService } from './CropServiceBase';
import { CropServiceV1 } from './CropServiceV1';
import { CropServiceV2 } from './CropServiceV2';

export function createCropService(
  messagingService: MessagingService,
  ethereumService: EthereumService,
  assetId: AssetId,
  asset: IAssetConfig
): ICropService {
  switch (asset.assetConstants?.contractVersion) {
    case 1:
      return new CropServiceV1(messagingService, ethereumService, assetId, asset);
    case 2:
      return new CropServiceV2(messagingService, ethereumService, assetId, asset);
    default:
      throw new Error(`Unsupported crop version : ${asset.assetConstants?.contractVersion}`);
  }
}
