import React, { Component } from 'react';
import './Landing.scss';
// @ts-ignore
import { NavLink } from 'react-router-dom';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/css/bundle';
import 'swiper/scss/navigation';

import starter_kit from '../assets/box.png';
import intro_video from '../assets/video/farmland_v2.mp4';
import ReactPlayer from 'react-player';
import { EthereumService } from '../services/EthereumService';
import { MintBox } from './Modals/MintBox';
import { MessagingService } from '../services/MessagingService';

export interface ILandingProps {
  ethereumService: EthereumService;
  messagingService: MessagingService;
}

export interface ILandingState {
  isBuyingStarterBox: boolean;
}

class Landing extends Component<ILandingProps, ILandingState> {
  constructor(props: ILandingProps) {
    super(props);

    this.state = {
      isBuyingStarterBox: false
    };

    SwiperCore.use([Autoplay, Pagination]);
  }

  render() {
    return (
      <>
        <section id="landing" className="is-vcentered">
          <div className="container">
            <section className="landing">
              <span className="is-upper">
                <h1 className="hero mb-1 is-size-2 is-size-3-mobile" style={{ fontFamily: 'Audiowide' }}>
                  The #1 play to own farm game
                </h1>
                <h2 className="hero mb-6 is-size-4 is-size-5-mobile" style={{ fontFamily: 'Audiowide' }}>
                  Secured by ethereum
                </h2>
              </span>
            </section>
            <section className="starter">
              <div className="tile is-parent is-center">
                <span className="column meme news has-text-centered">
                  <h4 className="subtitle is-size-5-touch mb-1">
                    NEW! All you need to get started in one handy
                    <button
                      onClick={() => this.setState({ isBuyingStarterBox: true })}
                      className="is-text has-action-text">
                      (virtual) box.
                    </button>
                  </h4>
                  <img
                    src={starter_kit}
                    style={{ cursor: 'pointer' }}
                    width="280px"
                    alt="Farmland"
                    onClick={() => this.setState({ isBuyingStarterBox: true })}
                  />
                  <p className="has-light-text is-size-7">
                    Box contents : Ready made farm with Citizen(s), Mercenaries and Packs (plus free LAND).
                  </p>
                  <p className="has-light-text is-size-7">(Note: The box contains virtual items)</p>
                </span>
              </div>
            </section>
            <section className="event my-5">
              <div className="tile is-parent is-center">
                <span className="column meme news has-text-centered">
                  <Swiper
                    autoplay={{
                      delay: 4000,
                      disableOnInteraction: false
                    }}
                    pagination={{
                      clickable: true
                    }}>
                    <SwiperSlide>
                      <React.Fragment>
                        <h2 className="title is-spaced pt-3 is-size-4-touch">SCAVENGER HUNT</h2>
                        <h3 className="subtitle is-size-6-touch">
                          Are you lookin' to add a little excitement to your farm life? Then head on over to{' '}
                          <NavLink className="has-action-text" to="/quests" activeStyle={{ color: 'var(--orange2)' }}>
                            Quests
                          </NavLink>{' '}
                          and start your Mercenary a Questin'. With this fearless companion by your side, you'll be
                          takin' on adventures and uncoverin' treasures in no time.
                        </h3>
                      </React.Fragment>
                    </SwiperSlide>
                    <SwiperSlide>
                      <React.Fragment>
                        <h2 className="title is-spaced pt-3 is-size-4-touch">BARN</h2>
                        <h3 className="subtitle is-size-6-touch">
                          At the{' '}
                          <NavLink className="has-action-text" to="/barn" activeStyle={{ color: 'var(--orange2)' }}>
                            Barn
                          </NavLink>{' '}
                          you will find all the materials you've gathered on your adventures along with any items you
                          have received in packs.
                        </h3>
                      </React.Fragment>
                    </SwiperSlide>
                    <SwiperSlide>
                      <React.Fragment>
                        <h2 className="title is-spaced pt-3 is-size-4-touch">MERCENARIES</h2>
                        <h3 className="subtitle is-size-6-touch">
                          Mercenaries are now available to hire ready for some{' '}
                          <NavLink className="has-action-text" to="/quests" activeStyle={{ color: 'var(--orange2)' }}>
                            Questin'
                          </NavLink>
                        </h3>
                        <h3 className="subtitle is-size-6-touch">
                          Don't forget to customize your Mercenary, more styles coming soon!
                        </h3>
                      </React.Fragment>
                    </SwiperSlide>
                    <SwiperSlide>
                      <React.Fragment>
                        <h2 className="title is-spaced pt-3 is-size-4-touch">MOONSHINE SALOON</h2>
                        <h3 className="subtitle is-size-6-touch">
                          Why not head on over to the{' '}
                          <NavLink className="has-action-text" to="/saloon" activeStyle={{ color: 'var(--orange2)' }}>
                            Moonshine Saloon
                          </NavLink>{' '}
                          for some well earned rest and recreation. Grab a cold brew and play some games
                        </h3>
                        <h3 className="subtitle is-size-6-touch">Harvest Lotto and Chicken Feed games running now!</h3>
                      </React.Fragment>
                    </SwiperSlide>
                    <SwiperSlide>
                      <React.Fragment>
                        <h2 className="title is-spaced pt-3 is-size-4-touch">CITIZENS</h2>
                        <h3 className="subtitle is-size-6-touch">
                          <NavLink className="has-action-text" to="/citizens" activeStyle={{ color: 'var(--orange2)' }}>
                            Mint
                          </NavLink>{' '}
                          your own Citizen NFTs on Arbitrum One
                        </h3>
                        <h3 className="subtitle is-size-6-touch">
                          We are now more than halfway through the initial allotment
                        </h3>
                      </React.Fragment>
                    </SwiperSlide>
                    <SwiperSlide>
                      <React.Fragment>
                        <h2 className="title is-spaced pt-3 is-size-4-touch">POOLS</h2>
                        <h3 className="subtitle is-size-6-touch">
                          Put your hard earned LAND and CORN to work in the Farming{' '}
                          <NavLink className="has-action-text" to="/pools" activeStyle={{ color: 'var(--orange2)' }}>
                            Pools.
                          </NavLink>{' '}
                          Earn rewards by staking you LAND and CORN in the Land Bank, Farmers Market or Grain Silo.
                        </h3>
                        <a
                          className="button is-ghost-action pb-6"
                          href=" https://t.co/PZRQ5wuZ2d"
                          target="_blank"
                          rel="noopener noreferrer">
                          More Information
                        </a>
                      </React.Fragment>
                    </SwiperSlide>
                    <SwiperSlide>
                      <React.Fragment>
                        <h2 className="title is-spaced pt-3 is-size-4-touch">CORN FARMING</h2>
                        <h3 className="subtitle is-size-6-touch">Buy Land - Build Farms - Harvest - Repeat</h3>
                        <a
                          className="button is-ghost-action pb-6"
                          href=" https://t.co/PZRQ5wuZ2d"
                          target="_blank"
                          rel="noopener noreferrer">
                          More Information
                        </a>
                      </React.Fragment>
                    </SwiperSlide>
                    <SwiperSlide>
                      <React.Fragment>
                        <div className="tile is-parent is-center">
                          <span className="column meme news is-flex is-justify-content-center">
                            <ReactPlayer
                              playing={true}
                              muted={true}
                              controls={true}
                              url={intro_video}
                              style={{ padding: '5px' }}
                              width="80%"
                              height="80%"
                            />
                          </span>
                        </div>
                      </React.Fragment>
                    </SwiperSlide>
                  </Swiper>
                </span>
              </div>
            </section>
          </div>

          <div className="socials is-flex is-flex-direction-column is-justify-content-space-evenly is-align-items-flex-end">
            <div>
              <a
                className="discord"
                title="Discord"
                href="https://discord.gg/b3WacYQNwa"
                target="_blank"
                rel="noopener noreferrer">
                <span className="icon">
                  <i className="fab fa-discord"></i>
                </span>
              </a>
            </div>
            <a
              className="medium"
              title="Medium Blog"
              href="https://farmland-game.medium.com/"
              target="_blank"
              rel="noopener noreferrer">
              <span className="icon">
                <i className="fab fa-medium"></i>
              </span>
            </a>
            <a
              className="twitter"
              title="Twitter"
              href="https://twitter.com/FarmlandGame"
              target="_blank"
              rel="noopener noreferrer">
              <span className="icon">
                <i className="fab fa-twitter"></i>
              </span>
            </a>
            <a
              className="medium"
              title="Docs"
              href="https://farmlandgame.gitbook.io/"
              target="_blank"
              rel="noopener noreferrer">
              <span className="icon">
                <i className="fa fa-bookmark"></i>
              </span>
            </a>
            <a
              className="discord"
              title="Tools"
              href="http://tools.farmlandgame.net/"
              target="_blank"
              rel="noopener noreferrer">
              <span className="icon">
                <i className="fa fa-wrench"></i>
              </span>
            </a>
          </div>
        </section>

        {this.state.isBuyingStarterBox && (
          <MintBox
            ethereumService={this.props.ethereumService}
            messagingService={this.props.messagingService}
            close={() => this.setState({ isBuyingStarterBox: false })}></MintBox>
        )}
      </>
    );
  }
}

export default Landing;
