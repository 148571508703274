import React, { Component } from 'react';
import { ICharacterDetails, ICharacterWrapper } from '../../models/CharacterDetails';
import { CitizenService } from '../../services/CitizenService';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CitizenBoost } from './CitizenBoost';
import { ICharacterEmploymentService } from '../../services/CharacterEmploymentService';
import { InView } from 'react-intersection-observer';
import CharacterWrapper from './CharacterWrapper';

export interface ICharacterSetProps {
  characterService: CitizenService;
  setInView: (character: ICharacterWrapper, inView: boolean) => void;
}

export interface ICharacterSetState {
  employ?: ICharacterDetails | null;
  nftBoost?: ICharacterDetails | null;
}

export class CharacterSet extends Component<ICharacterSetProps, ICharacterSetState> {
  navigationPrevRef: any;
  navigationNextRef: any;

  constructor(props: any) {
    super(props);
    this.state = {
      employ: null,
      nftBoost: null
    };

    this.employ = this.employ.bind(this);
    this.boost = this.boost.bind(this);

    this.navigationPrevRef = React.createRef();
    this.navigationNextRef = React.createRef();
  }

  async employ(es: ICharacterEmploymentService, character: ICharacterDetails) {
    if (es.isEmploymentApproved) {
      es.employ(character);
    } else {
      es.approveEmployment();
    }
  }

  async boost(character: ICharacterDetails) {
    if (this.props.characterService.isBoostApproved) {
      this.setState({ nftBoost: character });
    } else {
      this.props.characterService.approveBoost();
    }
  }

  render() {
    return (
      <React.Fragment>
        <Swiper className="px-1" navigation spaceBetween={10} slidesPerView="auto" centerInsufficientSlides>
          {this.props.characterService!.ownedCharacters.map((character, index) => (
            <SwiperSlide key={index + character.id + character.detailsRefreshedAtBlock}>
              <InView
                onChange={inView => {
                  this.props.setInView(character, inView);
                }}>
                <CharacterWrapper
                  actionsLv1={
                    character.details !== null
                      ? [
                          {
                            name: 'Boost',
                            isApproved: this.props.characterService.isBoostApproved,
                            onClick: () => this.boost(character.details!),
                            onHoverMessage: 'Boost your Citizens stats by up to 10 points',
                            enabled: character.details.revealed
                          }
                        ]
                      : []
                  }
                  actionsLv2={character.details !== null ? this.getEmployActions(character.details) : []}
                  actionsLv3={[]}
                  onCardClick={() => {}}
                  character={character}
                  characterService={this.props.characterService}
                  isSample={false}></CharacterWrapper>
              </InView>
            </SwiperSlide>
          ))}
        </Swiper>
        {this.state.nftBoost != null && (
          <CitizenBoost
            characterService={this.props.characterService}
            nft={this.state.nftBoost}
            close={() => this.setState({ nftBoost: null })}></CitizenBoost>
        )}
      </React.Fragment>
    );
  }

  getEmployActions(character: ICharacterDetails) {
    return this.props.characterService.employmentServices!.map((es: ICharacterEmploymentService) => {
      return {
        name: es.isEmploymentApproved
          ? `Employ as ${es.employmentConfig.jobType}`
          : `Approve as ${es.employmentConfig.jobType}`,
        isApproved: es.isEmploymentApproved,
        onClick: () => this.employ(es, character),
        onHoverMessage: es.employmentConfig.jobHelp,
        enabled: character.revealed
      };
    });
  }
}
