import { BigNumber } from '@ethersproject/bignumber';
import React, { Component } from 'react';
import { ICharacterDetails } from '../../../models/CharacterDetails';
import { Utils } from '../../../services/Utils';
import { IQuestService, IQuestStatics } from '../../../services/QuestService';
import { IItemDetails } from '../../../models/ItemDetails';
import { ItemService } from '../../../services/ItemService';
import { EthereumService } from '../../../services/EthereumService';
import { Mutex } from 'async-mutex';
import Flip from '../../Common/Flip';
import { ITime } from '../../../services/Interfaces';
import SwiperCore, { Navigation, EffectCube } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/css/bundle';
import 'swiper/scss/navigation';
import './BeginQuest.scss';
import { IQuestConfig } from '../../../models/CharacterConfig';

export interface IBeginQuestProps {
  ethereumService: EthereumService;
  questService: IQuestService;
  itemService: ItemService;
  character: ICharacterDetails;
  close: any;
}

export interface IBeginQuestState {
  wizardStep: number;
  maxQuests: number;
  questDurationBlocks: number;
  questLength: number;
  questDurationEst: string;
  selectedQuestConfigIndex: number;
  selectedQuest: IQuestStatics | null;
  selectedQuestDifficulty: number;
  selectedQuestLevel: number;
  equipItems: IItemDetails[];
  selectedItem: IItemDetails | null;
  questCountdownSecs: number;
  questCountdownTime: ITime;
}

export class BeginQuest extends Component<IBeginQuestProps, IBeginQuestState> {
  quests: IQuestConfig[];
  mutex = new Mutex();

  constructor(props: any) {
    super(props);

    this.quests = this.props.ethereumService.networkManager!.network!.questConfigs;

    this.state = {
      wizardStep: 0,
      maxQuests: 0,
      questDurationBlocks: 0,
      questDurationEst: '',
      questLength: 1,
      selectedItem: null,
      questCountdownSecs: 0,
      questCountdownTime: { days: '  ', hours: '  ', mins: '  ', secs: '  ', isComplete: false },
      ...this.getQuestState(0, 0)
    };

    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleAmountChangeValue = this.handleAmountChangeValue.bind(this);
    this.handleSetMax = this.handleSetMax.bind(this);
    this.selectPrevQuest = this.selectPrevQuest.bind(this);
    this.selectNextQuest = this.selectNextQuest.bind(this);
    this.selectEquipItem = this.selectEquipItem.bind(this);
    this.beginQuest = this.beginQuest.bind(this);
    this.getQuestCost = this.getQuestCost.bind(this);
    this.close = this.close.bind(this);

    SwiperCore.use([Navigation]);
  }

  componentDidMount() {
    this.updateState();
  }

  async updateState(): Promise<any> {
    await this.mutex.runExclusive(async () => {
      await this.refreshImpl();
    });
  }

  private async refreshImpl(): Promise<any> {
    if (this.state.selectedQuest === null) return;

    this.props.questService.getMaxQuests(this.state.selectedQuest!.index, this.props.character.id).then(mq => {
      this.setState({ maxQuests: BigNumber.from(mq).toNumber() });
    });

    this.props.questService.getQuestDuration(this.state.selectedQuest!.index, this.props.character.id).then(qd => {
      const questDurationBlocks = BigNumber.from(qd).toNumber();

      const questCountdownSecs =
        this.props.ethereumService!.networkManager!.network!.blocktime * questDurationBlocks * this.state.questLength;
      const questCountdownTime = this.getTimeRemaining(questCountdownSecs);

      this.setState({
        questDurationBlocks: questDurationBlocks,
        questCountdownSecs: questCountdownSecs,
        questCountdownTime: questCountdownTime
      });
    });
  }

  getTimeRemaining(questCountdownSecs: number): ITime {
    const secondsRemaining = questCountdownSecs < 0 ? 0 : Math.trunc(questCountdownSecs);

    return {
      days: secondsRemaining < 0 ? '00' : ('0' + Math.floor(secondsRemaining / (60 * 60 * 24))).slice(-2),
      hours: secondsRemaining < 0 ? '00' : ('0' + Math.floor((secondsRemaining / (60 * 60)) % 24)).slice(-2),
      mins: secondsRemaining < 0 ? '00' : ('0' + Math.floor((secondsRemaining / 60) % 60)).slice(-2),
      secs: secondsRemaining < 0 ? '00' : ('0' + (secondsRemaining % 60)).slice(-2),
      isComplete: secondsRemaining < 0
    };
  }

  nextStep() {
    if (this.state.wizardStep > 1) return;

    var newStep = this.state.wizardStep + 1;

    if (this.state.wizardStep === 0 && this.state.equipItems.length === 0) {
      newStep = 2;
    }

    this.setState({ wizardStep: newStep });
  }

  prevStep() {
    if (this.state.wizardStep < 1) return;

    var newStep = this.state.wizardStep - 1;

    if (this.state.wizardStep === 2 && this.state.equipItems.length === 0) {
      newStep = 0;
    }

    if (newStep === 0) {
      this.selectQuest(0, 0);
    }

    this.setState({ wizardStep: newStep });
  }

  handleAmountChange(e: any) {
    if (e.target.value > 0 && e.target.value <= this.state.maxQuests) {
      const questLength = +e.target.value;
      const questCountdownSecs =
        this.props.ethereumService!.networkManager!.network!.blocktime * this.state.questDurationBlocks * questLength;
      const questCountdownTime = this.getTimeRemaining(questCountdownSecs);

      this.setState({
        questLength: questLength,
        questCountdownSecs: questCountdownSecs,
        questCountdownTime: questCountdownTime
      });
    }
  }

  handleAmountChangeValue(value: number) {
    const questLength = this.state.questLength + value;
    const questCountdownSecs =
      this.props.ethereumService!.networkManager!.network!.blocktime * this.state.questDurationBlocks * questLength;
    const questCountdownTime = this.getTimeRemaining(questCountdownSecs);

    this.setState({
      questLength: questLength,
      questCountdownSecs: questCountdownSecs,
      questCountdownTime: questCountdownTime
    });
  }

  handleSetMax() {
    const questLength = this.state.maxQuests;
    const questCountdownSecs =
      this.props.ethereumService!.networkManager!.network!.blocktime * this.state.questDurationBlocks * questLength;
    const questCountdownTime = this.getTimeRemaining(questCountdownSecs);

    this.setState({
      questLength: questLength,
      questCountdownSecs: questCountdownSecs,
      questCountdownTime: questCountdownTime
    });
  }

  selectPrevQuest(): void {
    if (this.state.selectedQuestConfigIndex > 0) {
      this.selectQuest(this.state.selectedQuestConfigIndex - 1, this.state.selectedQuestDifficulty);
    }
  }

  selectNextQuest(): void {
    if (this.state.selectedQuestConfigIndex < this.quests.length - 1) {
      this.selectQuest(this.state.selectedQuestConfigIndex + 1, this.state.selectedQuestDifficulty);
    }
  }

  selectQuest(questConfigIndex: number, questDifficulty: number) {
    this.setState({ ...this.getQuestState(questConfigIndex, questDifficulty) });
  }

  selectQuestDifficulty(questDifficulty: number) {
    this.selectQuest(this.state.selectedQuestConfigIndex, questDifficulty);
  }

  getQuestState(questConfigIndex: number, questDifficulty: number) {
    var questIndex = this.quests[questConfigIndex].difficultyQuestIndexes[questDifficulty];
    var quest = this.props.questService.getQuest(questIndex);
    var equipItems = this.props.itemService.allItems.filter(i => i.questUseMap.get(quest.index));
    var questLevel = BigNumber.from(quest!.minimumLevel).toNumber();

    return {
      selectedQuestConfigIndex: questConfigIndex,
      selectedQuestDifficulty: questDifficulty,
      selectedQuest: quest,
      selectedQuestLevel: questLevel,
      equipItems: equipItems
    };
  }

  selectEquipItem(healthItem: IItemDetails): void {
    this.setState({ selectedItem: healthItem });
  }

  beginQuest(nft: ICharacterDetails): void {
    if (this.state.questLength) {
      this.props.questService.beginQuest(
        this.state.selectedQuest!.index,
        this.props.character.id,
        this.state.questLength,
        this.state.selectedItem ? [this.state.selectedItem.id] : []
      );
      this.close();
    }
  }

  getQuestCost(): string {
    return Utils.formatUnits(
      this.props.questService.getQuestCost(this.state.selectedQuest!.index, this.state.questLength)
    );
  }

  getQuestPaymentBalance(): string {
    return Utils.formatUnits(this.props.questService.paymentBalance);
  }

  hasEnoughToPay(): boolean {
    var price = this.props.questService.getQuestCost(this.state.selectedQuest!.index, this.state.questLength);
    var remaining = this.props.questService.paymentBalance!;
    return remaining.gte(price!);
  }

  canBeginQuest(): boolean {
    return this.state.questLength > 0 && !!this.state.selectedQuest && this.hasEnoughToPay();
  }

  close() {
    this.props.close();
  }

  render() {
    if (!this.state.selectedQuest) return <></>;
    return (
      <div className="is-active modal noselect">
        <div className="modal-background" onClick={this.close}></div>
        <div id="begin-quest" className="modal-content begin-quest">
          <div
            className="card"
            style={{
              backgroundImage: `linear-gradient(rgba(10, 10, 10, 0.6), rgba(10, 10, 10, 1)),url("./quests/${
                this.state.selectedQuest!.index
              }.png")`
            }}>
            <button className="modal-close is-medium is-button" aria-label="close" onClick={this.close}></button>
            <div className="is-flex is-justify-content-center begin-quest-title pb-3">
              {this.state.wizardStep === 0 && <span>Begin Quest</span>}
              {this.state.wizardStep > 0 && <span>Begin “{this.state.selectedQuest!.name}”</span>}
            </div>
            <div className="begin-quest-content is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
              {this.state.wizardStep === 0 && <React.Fragment>{this.renderQuests()}</React.Fragment>}
              {this.state.wizardStep === 1 && <React.Fragment>{this.renderItems()}</React.Fragment>}
              {this.state.wizardStep === 2 && <React.Fragment>{this.renderQuestLength()}</React.Fragment>}
            </div>
            <div className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center pb-2">
              <button
                className="button begin-button is-ghost-action"
                disabled={this.state.wizardStep === 0}
                onClick={() => this.prevStep()}>
                Prev
              </button>
              <button
                className="button begin-button is-transparent-action"
                disabled={this.state.wizardStep === 2 || this.state.selectedQuestLevel > this.props.character.level!}
                onClick={() => this.nextStep()}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderQuests() {
    return (
      <React.Fragment>
        <p className="mb-3" style={{ textAlign: 'center' }}>
          {this.state.selectedQuestLevel <= this.props.character.level! && <span>Select Quest</span>}
          {this.state.selectedQuestLevel > this.props.character.level! && (
            <span className="has-warning-text">Level {this.state.selectedQuestLevel} Required</span>
          )}
        </p>
        <Swiper
          className="fullheight"
          effect={'cube'}
          cubeEffect={{
            shadow: false
          }}
          navigation={{
            prevEl: '.swiper-quest-prev',
            nextEl: '.swiper-quest-next'
          }}
          modules={[EffectCube]}
          slidesPerView={1}>
          {this.quests.map(q => (
            <SwiperSlide key={q.questType}>
              <div
                className={`quest-item ${
                  this.state.selectedQuestLevel > this.props.character.level! ? 'quest-disabled' : ''
                }`}>
                <img
                  alt={q.questType}
                  src={`./quests/${q.difficultyQuestIndexes[this.state.selectedQuestDifficulty]}.png`}
                />
                <span className="quest-title">{q.questType}</span>
                <>
                  <input
                    id="microQuest"
                    type="checkbox"
                    name="microQuest"
                    className="is-active switch is-rounded is-small"
                    onChange={(e: any) => {
                      this.selectQuestDifficulty(e.target.checked ? 1 : 0);
                    }}
                    checked={this.state.selectedQuestDifficulty === 1}
                  />
                  <label htmlFor="microQuest">
                    <span className="has-light-text noselect" style={{ marginTop: '-3px' }}>
                      <>
                        {this.state.selectedQuestDifficulty === 0 && 'Easy'}
                        {this.state.selectedQuestDifficulty === 1 && 'Hard'}
                      </>
                    </span>
                  </label>
                </>
              </div>
            </SwiperSlide>
          ))}
          <div
            className="swiper-button-prev swiper-quest-prev"
            onClick={() => {
              this.selectPrevQuest();
            }}></div>
          <div
            className="swiper-button-next swiper-quest-next"
            onClick={() => {
              this.selectNextQuest();
            }}></div>
        </Swiper>
      </React.Fragment>
    );
  }

  renderItems() {
    var availableItems = this.state.equipItems.filter(i => i.balance > 0);
    return (
      <React.Fragment>
        {availableItems.length > 0 && (
          <React.Fragment>
            <p className="mb-4">
              {this.state.selectedItem && (
                <span>
                  Equipping {Utils.toTitleCase(Utils.toRarityDisplay(this.state.selectedItem.rarity))}{' '}
                  {this.state.selectedItem.name}
                </span>
              )}
              {!this.state.selectedItem && <span>Select Item to Equip</span>}
            </p>
            <Swiper
              navigation={{
                prevEl: '.swiper-equip-prev',
                nextEl: '.swiper-equip-next'
              }}
              spaceBetween={8}
              slidesPerView="auto">
              {availableItems.map(i => (
                <SwiperSlide key={i.id}>
                  <div
                    className={`equip-item ${
                      this.state.selectedItem?.id === i.id ? 'equip-item-selected' : ''
                    } ${Utils.toRarityDisplay(i.rarity)}`}
                    onClick={() => {
                      this.selectEquipItem(i);
                    }}>
                    <div className="rarity-display">{Utils.toRarityDisplay(i.rarity)}</div>
                    <img alt={i.name} className="token" src={i.imageUrl} />
                    <div className="item-title">
                      <div>
                        <span className="item-name">{i.name}</span>
                        {i.balance > 0 && (
                          <span className="item-balance pl-2">{Utils.numberToShortDisplay(i.balance)}</span>
                        )}
                        {!i.balance && <span className="item-balance pl-2">-</span>}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
              <div className="swiper-button-prev swiper-equip-prev"></div>
              <div className="swiper-button-next swiper-equip-next"></div>
            </Swiper>
          </React.Fragment>
        )}
        {availableItems.length === 0 && (
          <React.Fragment>
            <span className="column meme is-half has-text-centered">
              <h3 className="empty-items py-3">No Equipable Items Available</h3>
            </span>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderQuestLength() {
    return (
      <React.Fragment>
        <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center mb-3">
          <p>
            Set Length (
            {this.state.questLength < this.state.maxQuests && (
              <button onClick={this.handleSetMax} className="is-text has-action-text">
                max {this.state.maxQuests}
              </button>
            )}
            {this.state.questLength === this.state.maxQuests && <span>max {this.state.maxQuests}</span>})
          </p>
        </div>

        <div className="is-flex is-flex-direction-column is-align-items-stretch is-justify-content-center mt-4">
          <div className="field has-addons">
            <div className="control">
              <button
                className="button minus-button is-action"
                disabled={this.state.questLength <= 1}
                onClick={() => this.handleAmountChangeValue(-1)}>
                <span className="icon">
                  <i className="fas fa-minus"></i>
                </span>
              </button>
            </div>
            <div className="control is-fullwidth">
              <input
                className="input is-action"
                type="text"
                value={this.state.questLength}
                onChange={this.handleAmountChange}
              />
            </div>
            <div className="control">
              <button
                className="button plus-button is-action"
                disabled={this.state.questLength >= this.state.maxQuests}
                onClick={() => this.handleAmountChangeValue(+1)}>
                <span className="icon">
                  <i className="fas fa-plus"></i>
                </span>
              </button>
            </div>
          </div>
          <button
            className="button begin-button is-action is-fullwidth"
            onClick={() => this.beginQuest(this.props.character)}
            disabled={!this.canBeginQuest()}>
            Begin {this.state.selectedQuest!.name}
          </button>
        </div>

        <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center mt-5">
          <p>
            <span>Cost {this.getQuestCost()} </span>
            {this.props.questService.getPaymentSymbol()} (+ gas)
          </p>
          <p className={`mr-1 is-size-7 ${!this.hasEnoughToPay() ? 'has-warning-text' : ''}`}>
            {Utils.toFixed(this.getQuestPaymentBalance()!, 2)} in wallet
          </p>

          <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center mt-5">
            <div>Estimated return</div>
            <div className="mt-2 estimated-countdown">{this.renderCountDown()}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  tickerClass() {
    if (+this.state.questCountdownTime.days > 0) {
      return 'days';
    }
    if (+this.state.questCountdownTime.hours > 0) {
      return 'hours';
    }
    if (+this.state.questCountdownTime.mins > 0) {
      return 'mins';
    }
  }

  renderCountDown() {
    return (
      <div className="countdown-item quest-countdown">
        <div className="countdown">
          {this.state.questCountdownSecs > 0 && (
            <div className="is-flex is-flex-direction-column">
              <div className="is-flex">
                <div className={'ticker ' + this.tickerClass()}>
                  <div className="digits">
                    <Flip value={this.state.questCountdownTime.days} />
                  </div>
                  <div>
                    <div className="units">days</div>
                  </div>
                </div>
                <div className={'ticker ' + this.tickerClass()}>
                  <div className="digits">
                    <Flip value={this.state.questCountdownTime.hours} />
                  </div>
                  <div>
                    <div className="units">hours</div>
                  </div>
                </div>
                <div className={'ticker ' + this.tickerClass()}>
                  <div className="digits">
                    <Flip value={this.state.questCountdownTime.mins} />
                  </div>
                  <div>
                    <div className="units">mins</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
