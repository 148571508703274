import React, { Component } from 'react';
import { Subscription } from 'rxjs';
import { AssetId } from '../../models/NetworkConfig';
import { EthereumService } from '../../services/EthereumService';
import { ILottoService } from '../../services/Interfaces';
import { Utils } from '../../services/Utils';
import Flip from '../Common/Flip';
import Lottie from 'react-lottie-player';

import chicken_idle from '../../assets/anim/chicken_idle_2.json';
import chicken_peck from '../../assets/anim/chicken_peck_2.json';

interface IChickenFeedProps {
  ethereumService: EthereumService;
  lottoService: ILottoService;
}

enum Action {
  None = 1,
  Approve
}

interface IChickeFeedState {
  tickets: number;
  action: Action;
  showDetail: boolean;
}

class ChickenFeed extends Component<IChickenFeedProps, IChickeFeedState> {
  subscription: Subscription;
  walletAddress?: string;
  chainId?: number;
  countdownTimer?: NodeJS.Timer;

  constructor(props: any) {
    super(props);

    this.state = {
      tickets: 0,
      action: Action.None,
      showDetail: false
    };

    this.feedChicken = this.feedChicken.bind(this);
    this.clearAction = this.clearAction.bind(this);
    this.setAmount = this.setAmount.bind(this);
    this.approveSpend = this.approveSpend.bind(this);
    this.closeDraw = this.closeDraw.bind(this);
    this.handleAmountToBuyChange = this.handleAmountToBuyChange.bind(this);

    this.subscription = new Subscription();
  }

  clearAction() {
    this.setState({ action: Action.None, tickets: 0 });
  }

  setAmount(e: any) {
    this.setState({ tickets: e.target.value });
  }

  approveSpend() {
    this.props.lottoService!.approve();
    this.clearAction();
  }

  feedChicken() {
    this.props.lottoService!.buyTicket(1);
    this.clearAction();
  }

  closeDraw() {
    this.props.lottoService!.closeDraw();
    this.clearAction();
  }

  handleAmountToBuyChange(changeBy: number) {
    this.setState({ tickets: this.state.tickets + changeBy });
  }

  tickerClass() {
    if (+this.props.lottoService.currentDrawCountdownTime.days > 0) {
      return 'days';
    }
    if (+this.props.lottoService.currentDrawCountdownTime.hours > 0) {
      return 'hours';
    }
    if (+this.props.lottoService.currentDrawCountdownTime.mins > 0) {
      return 'mins';
    }
  }

  render() {
    return <div className="content">{this.renderChickenFeed()}</div>;
  }

  renderChickenFeed() {
    return (
      <React.Fragment>
        <div className="is-flex is-justify-content-space-between info-control card-title">
          <h2 className="info">
            CHICKEN FEED
            <span className="icon-text tooltip pl-2">
              <span className="tooltip-text tooltip-text-wide">
                Feed the chicken to enter the game, the countdown resets whenever anyone feeds the chicken. Each feed
                also enters you in to the Super Draw. 10% of Corn added into the game is sent to the Super Draw prize
                pool.
              </span>
              <span className="icon has-tooltip-text is-small">
                <i className="fas fa-question-circle fa-xs"></i>
              </span>
            </span>
          </h2>
        </div>

        <div className="info-control">
          <h2 className="info">
            Prize pool
            <span className="icon-text tooltip pl-1 pr-2">
              <span className="tooltip-text tooltip-text-medium">
                <div>
                  <em>Prize pool breakdown:</em>
                </div>
                <div>Burned : {Utils.toFixed(this.props.lottoService!.prizeSplit.burnAmount, 2)} CORN</div>
                <div>Closers Fee : {Utils.toFixed(this.props.lottoService!.prizeSplit.closersFee, 2)} CORN</div>
                <div>Next Draw : {Utils.toFixed(this.props.lottoService!.prizeSplit.nextDrawAmount, 2)} CORN</div>
                <div>Winners Prize : {Utils.toFixed(this.props.lottoService!.prizeSplit.winnersPrize, 2)} CORN</div>
              </span>
              <span className="icon has-tooltip-text is-small">
                <i className="fas fa-question-circle fa-xs"></i>
              </span>
            </span>
            :{' '}
          </h2>
          <h2 className="value">
            <span className="pre">
              {Utils.toFixed(this.props.lottoService!.currentPrizePool, 2)} {this.props.lottoService!.config.token}
            </span>
            {this.props.ethereumService.pricesService!.renderPrice(
              this.props.lottoService!.config.token as AssetId,
              this.props.lottoService!.currentPrizePool
            )}
          </h2>
        </div>

        <div className="info-control is-justify-content-space-between">
          <h2 className="info">Current round countdown: </h2>

          {this.props.lottoService.currentDrawCountdownSecs <= 0 && (
            <div className="countdown">
              <div className="ticker mins">
                <div className="digits">
                  <Flip value="DONE" />
                </div>
                <div>
                  <div className="units">Start a new game!</div>
                </div>
              </div>
            </div>
          )}
          {this.props.lottoService.currentDrawCountdownSecs > 0 && (
            <div className="countdown">
              <div className={'ticker ' + this.tickerClass()}>
                <div className="digits">
                  <Flip value={this.props.lottoService!.currentDrawCountdownTime.days} />
                </div>
                <div>
                  <div className="units">days</div>
                </div>
              </div>
              <div className={'ticker ' + this.tickerClass()}>
                <div className="digits">
                  <Flip value={this.props.lottoService!.currentDrawCountdownTime.hours} />
                </div>
                <div>
                  <div className="units">hours</div>
                </div>
              </div>
              <div className={'ticker ' + this.tickerClass()}>
                <div className="digits">
                  <Flip value={this.props.lottoService!.currentDrawCountdownTime.mins} />
                </div>
                <div>
                  <div className="units">mins</div>
                </div>
              </div>
            </div>
          )}
        </div>

        {!this.props.lottoService!.currentDrawDeadlinePassed && (
          <div className="info-control">
            {this.props.lottoService.isWinning && (
              <div className="info-control no-wrap is-justify-content-space-between">
                <h2 className="info winning">
                  <span>You are winning!</span>
                </h2>
                <Lottie loop animationData={chicken_peck} play className="game-animation" />
              </div>
            )}
            {!this.props.lottoService.isWinning && (
              <div className="info-control no-wrap is-justify-content-space-between">
                <h2 className="info" style={{ paddingRight: '34px' }}>
                  <span>You are not winning, feed the chicken!</span>
                </h2>
                <Lottie loop animationData={chicken_idle} play className="game-animation" />
              </div>
            )}
          </div>
        )}
        {this.props.lottoService!.currentDrawDeadlinePassed && (
          <div className="info-control">
            {this.props.lottoService.isWinning && (
              <div className="info-control no-wrap is-justify-content-space-between">
                <h2 className="info winning" style={{ paddingRight: '34px' }}>
                  <span>You won! Start a new game to claim.</span>
                </h2>
                <Lottie loop animationData={chicken_idle} play className="game-animation" />
              </div>
            )}
            {!this.props.lottoService.isWinning && (
              <div className="info-control no-wrap is-justify-content-space-between">
                <h2 className="info">
                  <span>You didn't win!</span>
                </h2>
                <Lottie loop animationData={chicken_idle} play className="game-animation" />
              </div>
            )}
          </div>
        )}
        <hr></hr>

        <ul className="action-control mt-2">
          {!this.props.lottoService!.isSpendApproved && (
            <li>
              <button className="button is-action" onClick={this.approveSpend}>
                <span>Approve</span>
              </button>
            </li>
          )}
          {this.props.lottoService!.isSpendApproved && !this.props.lottoService!.currentDrawDeadlinePassed && (
            <li>
              <button className="button is-action" onClick={this.feedChicken}>
                <span>{this.props.lottoService!.actionDisplayName}</span>
              </button>
            </li>
          )}
          {this.props.lottoService!.currentDrawDeadlinePassed && (
            <li>
              <button className="button is-action" onClick={this.closeDraw}>
                <span>Start New Game</span>
              </button>
            </li>
          )}
        </ul>
      </React.Fragment>
    );
  }
}

export default ChickenFeed;
