import React, { Component } from 'react';
import { Subscription } from 'rxjs';
import { AssetId } from '../../models/NetworkConfig';
import { EthereumService } from '../../services/EthereumService';
import { ILottoService } from '../../services/Interfaces';
import { Utils } from '../../services/Utils';
import Flip from '../Common/Flip';

interface ILottoProps {
  ethereumService: EthereumService;
  lottoService: ILottoService;
}

enum Action {
  None = 1,
  Approve,
  Buy
}

interface ILottoState {
  tickets: number;
  action: Action;
  showDetail: boolean;
}

class Lotto extends Component<ILottoProps, ILottoState> {
  maxAmount = 100;
  subscription: Subscription;
  walletAddress?: string;
  chainId?: number;
  countdownTimer?: NodeJS.Timer;

  constructor(props: any) {
    super(props);

    this.state = {
      tickets: 0,
      action: Action.None,
      showDetail: false
    };

    this.buyTickets = this.buyTickets.bind(this);
    this.handleBuyTicketAmount = this.handleBuyTicketAmount.bind(this);
    this.handleBuyMinAmount = this.handleBuyMinAmount.bind(this);
    this.handleBuyMaxAmount = this.handleBuyMaxAmount.bind(this);
    this.clearAction = this.clearAction.bind(this);
    this.setAmount = this.setAmount.bind(this);
    this.approveSpend = this.approveSpend.bind(this);
    this.confirmBuy = this.confirmBuy.bind(this);
    this.closeDraw = this.closeDraw.bind(this);

    this.subscription = new Subscription();
  }

  buyTickets() {
    this.setState({
      action: Action.Buy,
      tickets: 1
    });
  }

  sanitizeBuyAmount(value: any) {
    var sanitized = !value || isNaN(value) ? 0 : +value;
    return sanitized > 100 ? 100 : sanitized < 1 ? 1 : sanitized;
  }

  handleBuyTicketAmount(e: any) {
    var amount = this.sanitizeBuyAmount(e.target.value);
    this.setState({ tickets: amount });
  }

  handleAmountToBuyChange(changeBy: number) {
    var amount = this.sanitizeBuyAmount(this.state.tickets + +changeBy);
    this.setState({ tickets: amount });
  }

  handleBuyMinAmount(e: any) {
    this.setState({ tickets: 1 });
  }

  handleBuyMaxAmount(e: any) {
    this.setState({ tickets: 100 });
  }

  clearAction() {
    this.setState({ action: Action.None, tickets: 0 });
  }

  setAmount(e: any) {
    this.setState({ tickets: e.target.value });
  }

  approveSpend() {
    this.props.lottoService!.approve();
    this.clearAction();
  }

  confirmBuy() {
    this.props.lottoService!.buyTicket(this.state.tickets);
    this.clearAction();
  }

  closeDraw() {
    this.props.lottoService!.closeDraw();
    this.clearAction();
  }

  tickerClass() {
    if (+this.props.lottoService.currentDrawCountdownTime.days > 0) {
      return 'days';
    }
    if (+this.props.lottoService.currentDrawCountdownTime.hours > 0) {
      return 'hours';
    }
    if (+this.props.lottoService.currentDrawCountdownTime.mins > 0) {
      return 'mins';
    }
  }

  render() {
    return <div className="content">{this.renderLotto()}</div>;
  }

  renderLotto() {
    return (
      <React.Fragment>
        <div className="is-flex is-justify-content-space-between info-control card-title">
          <h2 className="info">
            HARVEST LOTTO
            <span className="icon-text tooltip pl-2">
              <span className="tooltip-text tooltip-text-wide">
                Buy tickets to the Havest Lotto, when the countdown completes the winner must be drawn to close the
                current game and start a new one. Each Lotto ticket also enters you in to the Super Draw. 10% of Corn
                added into the game is sent to the Super Draw prize pool.
              </span>
              <span className="icon has-tooltip-text is-small">
                <i className="fas fa-question-circle fa-xs"></i>
              </span>
            </span>
          </h2>
        </div>

        <div className="info-control">
          <h2 className="info">
            Prize pool
            <span className="icon-text tooltip pl-1 pr-2">
              <span className="tooltip-text tooltip-text-medium">
                <div>
                  <em>Prize pool breakdown:</em>
                </div>
                <div>Burned : {Utils.toFixed(this.props.lottoService!.prizeSplit.burnAmount, 2)} CORN</div>
                <div>Closers Fee : {Utils.toFixed(this.props.lottoService!.prizeSplit.closersFee, 2)} CORN</div>
                <div>Next Draw : {Utils.toFixed(this.props.lottoService!.prizeSplit.nextDrawAmount, 2)} CORN</div>
                <div>Winners Prize : {Utils.toFixed(this.props.lottoService!.prizeSplit.winnersPrize, 2)} CORN</div>
              </span>
              <span className="icon has-tooltip-text is-small">
                <i className="fas fa-question-circle fa-xs"></i>
              </span>
            </span>
            :
          </h2>
          <h2 className="value">
            <span className="pre">
              {Utils.toFixed(this.props.lottoService!.currentPrizePool, 2)} {this.props.lottoService!.config.token}
            </span>
            {this.props.ethereumService.pricesService!.renderPrice(
              this.props.lottoService!.config.token as AssetId,
              this.props.lottoService!.currentPrizePool
            )}
          </h2>
        </div>

        <div className="info-control is-justify-content-space-between">
          <h2 className="info">Current draw countdown: </h2>

          {this.props.lottoService.currentDrawCountdownSecs <= 0 && (
            <div className="countdown">
              <div className="ticker mins">
                <div className="digits">
                  <Flip value="DONE" />
                </div>
                <div>
                  <div className="units">Draw the winner!</div>
                </div>
              </div>
            </div>
          )}
          {this.props.lottoService.currentDrawCountdownSecs > 0 && (
            <div className="countdown">
              <div className={'ticker ' + this.tickerClass()}>
                <div className="digits">
                  <Flip value={this.props.lottoService!.currentDrawCountdownTime.days} />
                </div>
                <div>
                  <div className="units">days</div>
                </div>
              </div>
              <div className={'ticker ' + this.tickerClass()}>
                <div className="digits">
                  <Flip value={this.props.lottoService!.currentDrawCountdownTime.hours} />
                </div>
                <div>
                  <div className="units">hours</div>
                </div>
              </div>
              <div className={'ticker ' + this.tickerClass()}>
                <div className="digits">
                  <Flip value={this.props.lottoService!.currentDrawCountdownTime.mins} />
                </div>
                <div>
                  <div className="units">mins</div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="info-control no-game-animation-spacing">
          <h2 className="info">Tickets in current draw : </h2>
          <h2 className="value">
            <span className="pre">{Utils.toFixed(this.props.lottoService!.currentDrawTickets, 0)} Tickets</span>
            {/* <span className="post">{Utils.toFixed(this.props.lottoService!.currentDrawTicketsPercent, 2)}%</span> */}
          </h2>
        </div>

        {this.state.action !== Action.Buy && (
          <React.Fragment>
            <hr></hr>

            <ul className="action-control mt-2">
              {!this.props.lottoService!.isSpendApproved && (
                <li>
                  <button className="button is-action" onClick={this.approveSpend}>
                    <span>Approve</span>
                  </button>
                </li>
              )}
              {this.props.lottoService!.isSpendApproved && !this.props.lottoService!.currentDrawDeadlinePassed && (
                <li>
                  <button className="button is-action" onClick={this.buyTickets}>
                    <span>{this.props.lottoService!.actionDisplayName}</span>
                  </button>
                </li>
              )}
              {this.props.lottoService!.currentDrawDeadlinePassed && (
                <li>
                  <button className="button is-action" onClick={this.closeDraw}>
                    <span>Draw Winner</span>
                  </button>
                </li>
              )}
            </ul>
          </React.Fragment>
        )}
        {this.state.action === Action.Buy && this.renderBuyTicket()}
      </React.Fragment>
    );
  }

  renderBuyTicket() {
    return (
      <div className="is-flex mt-4 mb-3">
        <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center buy-buttons is-fullwidth">
          <ul className="ml-0">
            <li className="action-form">
              <div className="field has-addons">
                <div className="control">
                  <button
                    className="button min-button is-action"
                    disabled={this.state.tickets <= 1}
                    onClick={this.handleBuyMinAmount}>
                    <span className="icon">Min</span>
                  </button>
                </div>
                <div className="control">
                  <button
                    className="button minus-button is-action"
                    disabled={this.state.tickets <= 1}
                    onClick={() => this.handleAmountToBuyChange(-1)}>
                    <span className="icon">
                      <i className="fas fa-minus"></i>
                    </span>
                  </button>
                </div>
                <div className="control">
                  <input
                    className="input is-action"
                    type="text"
                    onChange={this.handleBuyTicketAmount}
                    value={this.state.tickets}
                  />
                </div>
                <div className="control">
                  <button
                    className="button plus-button is-action"
                    disabled={this.state.tickets >= this.maxAmount}
                    onClick={() => this.handleAmountToBuyChange(+1)}>
                    <span className="icon">
                      <i className="fas fa-plus"></i>
                    </span>
                  </button>
                </div>
                <div className="control">
                  <button
                    className="button max-button is-action"
                    disabled={this.state.tickets >= this.maxAmount}
                    onClick={this.handleBuyMaxAmount}>
                    <span className="icon">Max</span>
                  </button>
                </div>
              </div>
            </li>
            <li className="is-flex">
              <button className="button is-cancel is-fullwidth" onClick={this.clearAction}>
                Cancel{' '}
              </button>
              <button className="button boost-button is-action is-fullwidth" onClick={this.confirmBuy}>
                Buy
              </button>
            </li>
          </ul>

          <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center mt-3">
            <p>
              <span className="mr-1">Cost {+this.props.lottoService!.ticketPrice * this.state.tickets}</span>
              {this.props.lottoService!.config.token} (+ gas)
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Lotto;
