import { BigNumber, constants, ethers } from 'ethers';
import { CharacterStat, ICharacterDetails, ICharacterWrapper, loadCitizenDetails } from '../models/CharacterDetails';
import { EthereumService } from './EthereumService';
import { MessagingService } from './MessagingService';
import { Utils } from './Utils';
import { ICharacterService } from './Interfaces';
import { ICharacterConfig, IEmploymentConfig } from '../models/CharacterConfig';
import { CharacterEmploymentServiceFactory, ICharacterEmploymentService } from './CharacterEmploymentService';
import { IAssetConfig } from '../models/AssetConfig';
import { Mutex } from 'async-mutex';

export class CitizenService implements ICharacterService {
  private mutex = new Mutex();
  walletAddress?: string;
  citizenContract?: ethers.Contract;
  boostPaymentContract?: ethers.Contract;
  count: number = 0;
  gasPrice?: string | number;
  tokenUri?: string;
  isBoostApproved: boolean = false;
  maxStatBoosts: number = 10;
  mintPrice?: BigNumber;
  boostPrice?: BigNumber;
  boostPaymentBalance?: number;
  ownedCharacters: ICharacterWrapper[] = [];
  config: IAssetConfig;

  employmentServices?: ICharacterEmploymentService[];

  actionText: string = 'Mint';
  actionUrl: string = '/citizens';

  constructor(
    public characterConfig: ICharacterConfig,
    private messagingService: MessagingService,
    private ethereumService: EthereumService
  ) {
    this.walletAddress = this.ethereumService!.walletData.walletAddress;

    this.config = this.characterConfig.citizenConfig;

    this.citizenContract = new ethers.Contract(
      this.characterConfig.citizenConfig.contractAddress,
      this.characterConfig.citizenConfig.contractConstants.contractAbi,
      ethereumService.signer
    );

    this.boostPaymentContract = new ethers.Contract(
      this.characterConfig.boostPayConfig.contractAddress,
      this.characterConfig.boostPayConfig.assetConstants!.contractAbi,
      this.ethereumService.signer!
    );

    this.employmentServices = [...this.characterConfig.employmentConfigs].map((ec: IEmploymentConfig) => {
      return CharacterEmploymentServiceFactory.create(
        this.characterConfig,
        ec,
        this.messagingService,
        this.ethereumService
      );
    });

    this.loadStatics();
    this.setupEventHandlers();
  }

  async loadStatics(): Promise<any> {
    this.mintPrice = await this.citizenContract!.price();
    this.maxStatBoosts = await this.citizenContract!.maxTraitBoosts();
    this.boostPrice = await this.citizenContract!.traitBoostPrice();
    this.boostPaymentBalance = +Utils.formatUnits(await this.boostPaymentContract!.balanceOf(this.walletAddress));
  }

  async update(): Promise<any> {
    await this.mutex.runExclusive(async () => {
      await this.loadOwnedWrappers();
      await this.loadCharacterDetails();
      if (this.boostPaymentContract) {
        const boostAllowance = await this.boostPaymentContract!.allowance(
          this.walletAddress,
          this.characterConfig.citizenConfig.contractAddress
        );
        this.isBoostApproved = boostAllowance._hex !== constants.Zero._hex;
      }
    });
  }

  setInView(characterWrapper: ICharacterWrapper, inView: boolean) {
    characterWrapper.inView = inView;
  }

  private async loadOwnedWrappers() {
    var ownedCount = await this.citizenContract!.balanceOf(this.walletAddress);
    var ownedIndexes = [...Array(ownedCount.toNumber()).keys()];
    var newOwnedIndexes = ownedIndexes.filter(index => !this.ownedCharacters.some(c => c.index === index));
    var newCharacterStaticPromises = newOwnedIndexes.map(index => {
      return this.loadStaticDetails(index);
    });

    var characterWrappers = await Promise.all(newCharacterStaticPromises);
    this.ownedCharacters = [...this.ownedCharacters, ...characterWrappers].sort((a, b) => (a.id < b.id ? -1 : 1));
  }

  private async loadStaticDetails(index: number): Promise<ICharacterWrapper> {
    const id = await this.citizenContract!.tokenOfOwnerByIndex(this.walletAddress, BigNumber.from(index));
    const uri = await this.citizenContract!.tokenURI(id.toNumber());
    return { index: index, id: id.toNumber(), uri, inView: false, details: null, detailsRefreshedAtBlock: 0 };
  }

  private async loadCharacterDetails(): Promise<any> {
    var characterDetailsPromises = this.ownedCharacters.map(async characterWrapper => {
      if (
        characterWrapper.inView &&
        characterWrapper.detailsRefreshedAtBlock < this.ethereumService.walletData.blockNumber
      ) {
        characterWrapper.details = await this.loadDetails(characterWrapper);
        characterWrapper.detailsRefreshedAtBlock = this.ethereumService.walletData.blockNumber;
      }
      return characterWrapper;
    });

    var characterWrappers = await Promise.all(characterDetailsPromises);

    this.ownedCharacters = characterWrappers.sort((a, b) => (a.id < b.id ? -1 : 1));
  }

  private async loadDetails(characterStatics: ICharacterWrapper): Promise<ICharacterDetails> {
    const stats = await this.citizenContract!.collectibleTraits(characterStatics.id);
    const boostsRemaining =
      this.maxStatBoosts - (await this.citizenContract!.collectibleTraitBoostTracker(characterStatics.id));

    return await loadCitizenDetails(
      characterStatics.index,
      BigNumber.from(characterStatics.id),
      characterStatics.uri,
      stats,
      boostsRemaining
    );
  }

  mint(amount: number) {
    let overrides = {
      value: this.mintPrice!.mul(BigNumber.from(amount))
    };
    return this.citizenContract!.mintCollectible(BigNumber.from(amount), overrides).then(
      () =>
        this.messagingService.events.next({
          message: `Minting of Citizen(s) is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error minting Citizens : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  approveBoost(): Promise<any> {
    var amount = Utils.parseUnits('1000000000');

    return this.boostPaymentContract!.approve(this.characterConfig.citizenConfig.contractAddress, amount).then(
      () =>
        this.messagingService.events.next({
          message: `Approval of Citizen stat boost is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error approving Citizen stat boost: ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  async boost(characterDetails: ICharacterDetails, stat: CharacterStat, boost: number) {
    const tokenId = await this.citizenContract!.tokenOfOwnerByIndex(
      this.walletAddress,
      BigNumber.from(characterDetails.index)
    );

    return this.citizenContract!.boostTrait(tokenId, BigNumber.from(stat), BigNumber.from(boost)).then(
      () =>
        this.messagingService.events.next({
          message: `Boosting of Citizen is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error boosting Citizen : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  removeEventHandlers() {
    this.citizenContract?.removeAllListeners();
  }

  setupEventHandlers() {
    this.removeEventHandlers();

    var update = (message: string) =>
      this.update().then(() => {
        this.messagingService.events.next({ message: message });
      });

    // Commented out because approvals emitted on ticket buy

    // if (this.boostPaymentContract) {
    //   this.boostPaymentContract!!.on(
    //     {
    //       address: this.ethereumService.walletData.walletAddress,
    //       topics: [ethers.utils.id('Approval(address,address,uint256)')]
    //     },
    //     (owner: any, spender: any, amount: any) => {
    //       if (owner === this.ethereumService.walletData.walletAddress) {
    //         update(`${this.config.assetConstants?.symbol} stat boost approved`);
    //       }
    //     }
    //   );
    // }

    if (this.citizenContract) {
      this.citizenContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('ApprovalForAll(address,address,bool)')]
        },
        (owner, operator, approved) => {
          if (owner === this.ethereumService.walletData.walletAddress) {
            if (approved) {
              update(`Citizens have been approved`);
            } else {
              this.messagingService.errors.next({
                message: `Citizens were not approved`,
                error: `Citizens were not approved`
              });
            }
          }
        }
      );

      this.citizenContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('Transfer(address,address,uint256)')]
        },
        (from, to, tokenId) => {
          if (to === this.ethereumService.walletData.walletAddress) {
            update(`Citizen available`);
          }
        }
      );

      this.citizenContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('CharacterBoosted(address,uint256,uint256,uint256,uint256)')]
        },
        (booster, id, trait, boost, pricePaid) => {
          if (booster === this.ethereumService.walletData.walletAddress) {
            update(`Citizen stat boosted`);
          }
        }
      );
    }
  }
}
