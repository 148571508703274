import { Mutex } from 'async-mutex';
import { BigNumber, constants, ethers } from 'ethers';
import { IRewardConfig } from '../models/ClaimConfig';
import { IPackDetails, toPackDetails } from '../models/PackDetails';
import { EthereumService } from './EthereumService';
import { EventCacheService } from './EventCacheService';
import { ItemService } from './ItemService';
import { MessagingService } from './MessagingService';
import { Utils } from './Utils';
import { ICharacterConfig } from '../models/CharacterConfig';

export interface IPackBalance {
  pack: IPackDetails;
  isOffChain: boolean;
  index: number;
  balance: number;
  balanceBN: BigNumber;
}

export interface IPackClaim {
  account: string;
  blockNumber: number;
  amount: number;
  index: BigNumber;
  item: BigNumber;
  packID: BigNumber;
}

export class PackService {
  mutex = new Mutex();
  walletAddress?: string;
  packContract?: ethers.Contract;
  mintPaymentContract?: ethers.Contract;
  itemContract?: ethers.Contract;
  mintMercenaryContract?: ethers.Contract;
  isMintingApproved: boolean = false;
  allPacks: IPackDetails[] = [];
  buyablePacks: IPackDetails[] = [];
  allPacksWithBalance: IPackBalance[] = [];
  packMap: Map<number, IPackDetails> = new Map<number, IPackDetails>();
  packClaimsMap: Map<number, IPackClaim[]> = new Map<number, IPackClaim[]>();
  packTypes: number[] = [];
  packClaimedEventCache: EventCacheService;
  packMintEventCache: EventCacheService;

  constructor(
    public config: IRewardConfig,
    public characterConfig: ICharacterConfig,
    private itemService: ItemService,
    private messagingService: MessagingService,
    private ethereumService: EthereumService
  ) {
    this.walletAddress = this.ethereumService!.walletData.walletAddress;

    this.packContract = new ethers.Contract(
      this.config.contractAddress,
      this.config.contractAbi,
      ethereumService.signer
    );

    this.mintPaymentContract = new ethers.Contract(
      this.config.mintPayConfig.contractAddress,
      this.config.mintPayConfig.assetConstants!.contractAbi,
      this.ethereumService.signer!
    );

    this.itemContract = new ethers.Contract(
      this.itemService.config.contractAddress,
      this.itemService.config.contractConstants.contractAbi,
      ethereumService.signer
    );

    this.mintMercenaryContract = new ethers.Contract(
      this.characterConfig.mintMercenaryConfig.contractAddress,
      this.characterConfig.mintMercenaryConfig.contractConstants!.contractAbi,
      this.ethereumService.signer!
    );

    this.packClaimedEventCache = new EventCacheService(
      this.packContract!,
      this.packContract!.filters.PackClaimed(this.walletAddress),
      this.config.deployedBlockNumber,
      ethereumService,
      messagingService,
      `Pack claimed`
    );

    this.packMintEventCache = new EventCacheService(
      this.packContract!,
      this.packContract!.filters.PackMinted(this.walletAddress),
      this.config.deployedBlockNumber,
      ethereumService,
      messagingService,
      `Pack minted`
    );

    this.setupEventHandlers();
  }

  async update() {
    await this.mutex.runExclusive(async () => {
      await this.loadStatics();
      await this.loadClaims();
      await this.refreshPackClaims();
    });
  }

  private async loadStatics() {
    if (this.allPacks.length > 0) {
      return;
    }

    this.mintPaymentContract!.allowance(this.walletAddress, this.config.contractAddress).then(
      (boostAllowance: BigNumber) => (this.isMintingApproved = boostAllowance._hex !== constants.Zero._hex)
    );

    var packsTypes = await this.packContract!.getPacks();

    var packs: IPackDetails[] = [];
    var packsJson = localStorage.getItem('packs');
    var packsLoadedContract = localStorage.getItem('packsLoadedContractv2');
    var packsLoadedMs = localStorage.getItem('packsLoaded') ?? Date.now();
    var packsTypesLength = +(localStorage.getItem('packsTypesLength') ?? 0);

    if (
      !packsJson ||
      packsLoadedContract !== this.config.contractAddress ||
      Date.now() - +packsLoadedMs > 60 * 60 * 1000 * 24 ||
      packsTypesLength !== packsTypes.length
    ) {
      for (var i = 0; i < packsTypes.length; i++) {
        var p = await this.packContract!.packs(i);
        var packDetails = await toPackDetails(i, p);
        packs.push(packDetails);
      }

      localStorage.setItem('packs', JSON.stringify(packs));
      localStorage.setItem('packsLoadedContract', this.config.contractAddress);
      localStorage.setItem('packsLoaded', Date.now().toString());
      localStorage.setItem('packsTypesLength', packsTypes.length);
    } else {
      packs = JSON.parse(packsJson);
    }

    this.allPacks = packs.filter(i => i.name);

    this.buyablePacks = this.allPacks.filter(i => i.price > 0); // harcoded for now

    packs.forEach((pack: any) => {
      this.packMap = this.packMap.set(pack.id, pack);
    });
  }

  removeFromClaims(index: number) {
    this.packClaimsMap.delete(index);

    var packClaimsJson = localStorage.getItem('packClaims');
    if (packClaimsJson) {
      var packClaims = JSON.parse(packClaimsJson).filter((pc: any) => BigNumber.from(pc.index).toNumber() !== index);
      localStorage.setItem('packClaims', JSON.stringify(packClaims));
    }
  }

  async loadClaims() {
    if (!this.packContract) {
      return;
    }

    var packs: IPackBalance[] = [];

    var ofChainClaimBalances = this.allPacks
      .filter(p => +Utils.formatUnits(p.packMerkleRoot))
      .map(async (pack: IPackDetails) => this.getOffChainClaimBalance(pack.id));

    var offChainClaimResults = await Promise.all(ofChainClaimBalances);
    this.allPacks
      .filter(p => +Utils.formatUnits(p.packMerkleRoot))
      .forEach((pack: IPackDetails, i: number) => {
        var offChainClaim = offChainClaimResults[i];
        if (offChainClaim?.gt(0)) {
          packs.push({
            pack: pack,
            isOffChain: true,
            index: -1,
            balanceBN: offChainClaim,
            balance: +Utils.formatUnits(offChainClaim, 0)
          });
        }
      });

    var onChainClaimBalances = this.allPacks.map(async (pack: IPackDetails) => this.getOnChainClaimBalance(pack.id));
    var onChainClaimResults = await Promise.all(onChainClaimBalances);
    this.allPacks.forEach((pack: IPackDetails, i: number) => {
      var onChainClaim = onChainClaimResults[i];
      onChainClaim.forEach(([claim, i]: [BigNumber, number]) => {
        if (claim?.gt(0)) {
          packs.push({
            pack: pack,
            isOffChain: false,
            index: i,
            balanceBN: claim,
            balance: +Utils.formatUnits(claim, 0)
          });
        }
      });
    });
    this.allPacksWithBalance = packs.sort((a, b) => (a.pack.name < b.pack.name ? -1 : 1));
  }

  async getOffChainClaimBalance(packId: number): Promise<BigNumber> {
    var merkle = this.config!.claimMerkle.claims[this.ethereumService.walletData.walletAddress];

    if (!merkle) {
      return BigNumber.from(0);
    }

    const claimed = await this.packContract!.isClaimed(merkle.index, packId);

    if (claimed) {
      return BigNumber.from(0);
    } else {
      return BigNumber.from(merkle.amount);
    }
  }

  async getOnChainClaimBalance(packId: number): Promise<[BigNumber, number][]> {
    var allowList = await this.packContract!.getPacksAllowList(packId);
    return allowList
      .map((claim: any, i: number) => [claim, i])
      .filter((tuple: any) => tuple[0].account === this.ethereumService.walletData.walletAddress && !tuple[0].claimed)
      .map((tuple: any) => [tuple[0].amount, tuple[1]]);
  }

  approveMint(): Promise<any> {
    var amount = Utils.parseUnits('1000000000');

    return this.mintPaymentContract!.approve(this.config.contractAddress, amount).then(
      () =>
        this.messagingService.events.next({
          message: `Approval of Pack minting is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error approving Pack minting: ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  async mint(pack: IPackDetails, amount: number): Promise<any> {
    try {
      const gasEstimated = await this.packContract!.estimateGas.mint(pack.id, amount);

      await this.packContract!.mint(pack.id, amount, { gasLimit: Math.ceil(gasEstimated.toNumber() * 2) });

      this.messagingService.events.next({
        message: `Pack mint is pending`,
        pending: true
      });
    } catch (error) {
      this.messagingService.errors.next({
        message: `Error during pack mint : ${Utils.getContractErrorMessage(error)}`,
        error: error
      });
    }
  }

  async claim(toClaim: IPackBalance) {
    if (!this.packContract) {
      this.messagingService.errors.next({
        message: `Error: unable to load claim contract`,
        error: 'Unable to load claim contract'
      });
      return Promise.reject('Error: unable to load claim contract');
    }

    if (toClaim.balance <= 0) {
      this.messagingService.errors.next({
        message: `Error: nothing to claim`,
        error: 'Nothing to claim'
      });
      return Promise.reject('Error: nothing to claim');
    }

    if (toClaim.isOffChain) {
      var merkle = this.config!.claimMerkle.claims[this.ethereumService.walletData.walletAddress];

      if (!merkle) {
        this.messagingService.errors.next({
          message: `Error: address not found in merkle root`,
          error: 'Address not found in merkle root'
        });
        return Promise.reject('Error: address not found in merkle root');
      }

      const gasEstimated = await this.packContract!.estimateGas.claimAirDrop(
        merkle.index,
        merkle.proof,
        merkle.amount,
        toClaim.pack.id
      );

      return this.packContract
        .claimAirDrop(merkle.index, merkle.proof, merkle.amount, toClaim.pack.id, {
          gasLimit: Math.ceil(gasEstimated.toNumber() * 1.5)
        })
        .then(
          (response: any) =>
            this.messagingService.events.next({
              message: `Pack claim is pending`,
              pending: true
            }),
          (error: any) =>
            this.messagingService.errors.next({
              message: `Error during claim : ${Utils.getContractErrorMessage(error)}`,
              error: error
            })
        );
    } else {
      const gasEstimated = await this.packContract!.estimateGas.claim(toClaim.index, toClaim.pack.id);

      return this.packContract
        .claim(toClaim.index, toClaim.pack.id, { gasLimit: Math.ceil(gasEstimated.toNumber() * 1.5) })
        .then(
          (response: any) =>
            this.messagingService.events.next({
              message: `Pack claim is pending`,
              pending: true
            }),
          (error: any) =>
            this.messagingService.errors.next({
              message: `Error during claim : ${Utils.getContractErrorMessage(error)}`,
              error: error
            })
        );
    }
  }

  async refreshPackClaims() {
    var packClaims = await this.getWalletPackClaimed();

    this.packClaimsMap = packClaims.reduce((acc: Map<number, IPackClaim[]>, curr: IPackClaim) => {
      if (curr.index) {
        var claimIndex = BigNumber.from(curr.index).toNumber();
        var claims = acc.get(claimIndex);
        if (claims) {
          acc.set(claimIndex, claims.concat(curr));
        } else {
          acc.set(claimIndex, [curr]);
        }
      }
      return acc;
    }, new Map<number, IPackClaim[]>());
  }

  async getWalletPackClaimed(): Promise<IPackClaim[]> {
    await this.packClaimedEventCache.update();

    return this.packClaimedEventCache.events
      .filter((i: any) => i.args['account'] === this.walletAddress)
      .map<IPackClaim>((i: any) => {
        return {
          account: i.args['account'],
          blockNumber: i.blockNumber,
          amount: i.args['amount'],
          index: i.args['index'],
          item: i.args['item'],
          packID: i.args['packID']
        };
      });
  }

  removeEventHandlers() {
    this.packContract?.removeAllListeners();
    // this.mintPaymentContract?.removeAllListeners();
    this.mintMercenaryContract?.removeAllListeners();
  }

  setupEventHandlers() {
    this.removeEventHandlers();

    var update = (message: string) => {
      this.update().then(() => {
        this.messagingService.events.next({ message: message });
      });
    };

    if (this.itemContract) {
      this.packContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('TransferSingle(address,address,address,uint256,uint256)')]
        },
        (operator, from, to, id, value) => {
          if (to === this.ethereumService.walletData.walletAddress) {
            var itemDetails = this.itemService!.itemMap.get(BigNumber.from(id).toNumber());
            update(`Item has been added to your barn: ${value} ${itemDetails?.name}`);
          }
        }
      );

      // this.mintPaymentContract!.on(
      //   {
      //     address: this.ethereumService.walletData.walletAddress,
      //     topics: [ethers.utils.id('Approval(address,spender,value)')]
      //   },
      //   (owner, spender, value) => {
      //     update(`You have approved Pack minting`);
      //   }
      // );

      this.mintMercenaryContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('AccountAllowlisted(address,address)')]
        },
        (sender, account) => {
          if (account === this.ethereumService.walletData.walletAddress) {
            update(`You have received a free Mercenary`);
          }
        }
      );
    }
  }
}
