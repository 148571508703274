export enum GameType {
  HarvestLotto = 'Harvest Lotto',
  ChickenFeed = 'Chicken Feed',
  SuperDraw = 'Super Draw'
}

export interface IGameConfig {
  gameIcon: any | null;
  gameType: GameType;
  token: string;
  tokenIcon: any;
  tokenUrl: string;
  tokenContractAddress: string;
  tokenContractAbi: any;
  gameContractAddress: string;
  gameContractAbi: any;
  rewardToken: string;
  rewardTokenIcon: any;
  expired: boolean;
}
