import { ethers } from 'ethers';
import { ICharacterDetails, loadCitizenDetails } from '../../../models/CharacterDetails';
import { Utils } from '../../../services/Utils';
import { CropServiceBase } from './CropServiceBase';

export class CropServiceV2 extends CropServiceBase {
  async loadBalancesImpl(): Promise<any> {
    return Promise.all([
      //0 getAddressDetails [0] blockNumber [1] cropBalance [2] availableToHarvest, [3] MaturityBoost, [4] CompostBoost
      // (CROP V2) [5] CollectibleBoost [6] TotalBoost
      this.cropContract!.getAddressDetails(this.ethereumService.walletData.walletAddress),
      //1 struct Farm [0] landInFarm [1] CROP composted [2] Farm built, [3] Last harvested [4] Delegated Harvester
      // (CROP v2) [5] numberOfCollectibles
      this.cropContract!.ownerOfFarm(this.ethereumService.walletData.walletAddress),
      //2 balanceOf
      this.landContract!.balanceOf(this.ethereumService.walletData.walletAddress),
      //3 -  0 totalFarms, 1 totalAllocatedAmount, 2 totalCompostedAmount,3 maximumCompostBoost, 4 maximumMaturityBoost, 5 maximumGrowthCycle, 6 maximumGrowthCycleWithFarmer,
      // 7 maximumMaturityCollectibleBoost, 8 endMaturityBoostBlocks, 9 maximumFarmSizeWithoutFarmer, 10 maximumFarmSizeWithoutTractor, 11 bonusCompostBoostWithAFarmer, 12 bonusCompostBoostWithATractor);
      this.cropContract!.getFarmlandVariables(),
      //4 isOperatorFor
      this.landContract!.isOperatorFor(this.cropContract!.address, this.ethereumService.walletData.walletAddress),
      // 5 (Crop V2) returns an array of collectibles - TokenId, CollectibleName, collectibleType; maxBoostLevel; blocksToMaxBoost; addedBlockNumber; expiry;
      this.cropContract!.getCollectiblesByFarm(this.ethereumService.walletData.walletAddress)
    ]).then(
      response => {
        this.contracts.cropBalance = Utils.formatUnits(response[0] ? response[0][1] : null);
        this.contracts.cropToHarvest = Utils.formatUnits(response[0] ? response[0][2] : null);
        this.contracts.farmMaturityBoost = Utils.formatUnits(response[0] ? response[0][3] : null, 4);
        this.contracts.farmCompostBoost = Utils.formatUnits(response[0] ? response[0][4] : null, 4);
        this.contracts.totalBoost = Utils.formatUnits(response[0] ? response[0][5] : null, 4);
        this.contracts.landInFarm = Utils.formatUnits(response[1] ? response[1][0] : null);
        this.contracts.cropComposted = Utils.formatUnits(response[1] ? response[1][1] : null);
        this.contracts.farmBuiltBlockNumber = parseInt(response[1] ? response[1][2] : null);
        this.contracts.lastHarvestBlockNumber = parseInt(response[1] ? response[1][3] : null);
        this.contracts.numberofCollectibles = parseInt(response[1] ? response[1][5] : null);
        this.contracts.landBalance = Utils.formatUnits(response[2] ? response[2] : null);
        this.contracts.globalNumberOfFarms = Utils.formatUnits(response[3] ? response[3][0] : null, 0);
        this.contracts.globalLandInFarm = Utils.formatUnits(response[3] ? response[3][1] : null);
        this.contracts.globalComposted = Utils.formatUnits(response[3] ? response[3][2] : null);
        this.contracts.maxCompostBoost = Utils.formatUnits(response[3] ? response[3][3] : null, 4);
        this.contracts.maxMaturityBoost = Utils.formatUnits(response[3] ? response[3][4] : null, 4);
        this.contracts.maximumMaturityCollectibleBoost = Utils.formatUnits(response[3] ? response[3][11] : null, 4);
        this.contracts.maxGrowthCycle = parseInt(response[3] ? response[3][5] : null);
        this.contracts.maxGrowthCycleWithFarmer = parseInt(response[3] ? response[3][6] : null);
        this.contracts.isAuthorized = response[4] ? response[4] : null;

        // Only calculate this when we have at least one farm globally to avoid division by zero errors
        if (this.contracts.globalNumberOfFarms !== 0) {
          this.contracts.globalAverageLandInFarms =
            this.contracts.globalLandInFarm / this.contracts.globalNumberOfFarms;
          this.contracts.globalAverageComposted = this.contracts.globalComposted / this.contracts.globalNumberOfFarms;
        }

        // TODO: Just initialise the variable to avoid error ... Sparta to find a proper fix
        this.contracts.growingCycleCountDown = '';

        // Only calculate this stuff when there is a farm
        if (this.contracts.landInFarm > 0) {
          if (this.contracts.numberofCollectibles === 0) {
            // Calculates the number of blocks until the end of the growing cycle:
            this.contracts.endOfGrowingCycle = this.contracts.lastHarvestBlockNumber + this.contracts.maxGrowthCycle;
          } else {
            this.contracts.endOfGrowingCycle =
              this.contracts.lastHarvestBlockNumber + this.contracts.maxGrowthCycleWithFarmer;
          }
          // Calculates the approx. number of seconds until the end of the growing cycle
          // TODO: Will be dependant on the Network ( Mainnet = 13.5 / Kovan = 5 / BSC = 3 / Polygon = 2)
          const networkBlockTime = this.ethereumService.networkManager!.network!.blocktime;
          this.contracts.growingCycleCountDownSecs =
            networkBlockTime * (this.contracts.endOfGrowingCycle - this.ethereumService.walletData.stdBlockNumber);
          // Decides how to present the countdown in hours or complete message
          if (this.contracts.growingCycleCountDownSecs > 0) {
            this.contracts.growingCycleCountDown = this.calculateCountdown(this.contracts.growingCycleCountDownSecs);
          } else {
            this.contracts.growingCycleCountDown = 'COMPLETE';
          }

          // x 10 composting boost calc. x10 = 9 * global composted average
          if (this.contracts.farmCompostBoost < 10) {
            var averageMaxTo10x = (9 * this.contracts.globalComposted) / this.contracts.globalNumberOfFarms;
            var myFarmMultiple = this.contracts.landInFarm / this.contracts.globalAverageLandInFarms;
            this.contracts.maxTo10x = averageMaxTo10x * myFarmMultiple;
            this.contracts.burnRequiredTo10x = this.contracts.maxTo10x - this.contracts.cropComposted;
          } else {
            this.contracts.burnRequiredTo10x = 'OVER COMPOSTED';
          }
        }
      },
      error => {
        this.handleLoadBalancesError(error);
      }
    );
  }

  async getCollectibles(collectibleContract: ethers.Contract): Promise<ICharacterDetails[]> {
    var collectibles = await this.cropContract!.getCollectiblesByFarm(this.ethereumService!.walletData.walletAddress);
    var maxStatBoosts = await collectibleContract.maxTraitBoosts();
    var n: ICharacterDetails[] = [];

    var i = 0;
    for (const collectible of collectibles) {
      var character = await this.loadCharacter(collectibleContract, collectible, i++, maxStatBoosts);
      if (character && character.revealed) {
        n.push(character);
      }
    }

    return n;
  }

  async loadCharacter(
    collectibleContract: ethers.Contract,
    collectible: any,
    index: number,
    maxStatBoosts: number
  ): Promise<ICharacterDetails | null> {
    try {
      const tokenUri = await collectibleContract!.tokenURI(collectible.id.toNumber());
      const stats = await collectibleContract!.collectibleTraits(collectible.id);
      const boostsRemaining =
        maxStatBoosts - (await collectibleContract!.collectibleTraitBoostTracker(collectible.id.toNumber()));
      return await loadCitizenDetails(index, collectible.id, tokenUri, stats, boostsRemaining);
    } catch (e) {
      return null;
    }
  }

  releaseCollectible(collectible: ICharacterDetails): Promise<any> {
    return this.cropContract!.releaseCollectible(collectible.index).then(
      () =>
        this.messagingService.events.next({
          message: `Removal of ${collectible.name} is pending`,
          pending: true
        }),
      (error: any) => {
        this.messagingService.errors.next({
          message: `Error removing ${collectible.name} : ${Utils.getContractErrorMessage(error)}`,
          error: error
        });
      }
    );
  }

  setupEventHandlers() {
    super.setupEventHandlers();

    if (this.ethereumService.networkManager!.network && this.cropContract) {
      this.cropContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('CollectibleEquipped(address,uint256,uint256,string)')]
        },
        (sender, blockNumber, TokenID, equipmentName) => {
          if (sender === this.ethereumService.walletData.walletAddress) {
            this.messagingService.events.next({ message: `Your ${equipmentName} has been added` });
          }
        }
      );

      this.cropContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('CollectibleReleased(address,uint256,uint256,string)')]
        },
        (sender, blockNumber, TokenID, equipmentName) => {
          if (sender === this.ethereumService.walletData.walletAddress) {
            this.messagingService.events.next({ message: `Your ${equipmentName} has been removed` });
          }
        }
      );
    }
  }
}
