export enum TokenId {
  LAND = 'LAND',

  // CROPS
  CORN = 'CORN',
  WHEAT = 'WHEAT',

  // NFTS
  ITEM = 'Items',

  // EXTERNAL
  USDC = 'USDC',
  ETH = 'ETH',
  WETH = 'WETH',
  BTC = 'BTC',
  BPT = 'BPT',
  UNI_V2 = 'UNI-V2',
  SLP = 'SLP',
  ETHCORN = 'ETH/CORN SLP',
  ARB = 'ARB'
}

export enum PoolId {
  Uniswap = 'Uniswap',
  Balancer = 'Balancer',
  Sushiswap = 'Sushiswap',
  Farmland = 'Farmland'
}

export enum LiquidityAssetType {
  Staking = 'Staking',
  StakingV2 = 'StakingV2',
  wLand = 'Wrapping'
}

export enum JobType {
  Farmer = 'Farmer',
  Explorer = 'Explorer'
}

export enum QuestType {
  ScavangeHunt = 'Scavanger Hunt',
  Mine = 'The Mine',
  Still = 'The Still',
  DarkForest = 'The Dark Forest',
  Quarry = 'The Quarry'
}
