import React, { Component } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { Utils } from '../../../services/Utils';
import { EthereumService } from '../../../services/EthereumService';
import { Stage, Layer } from 'react-konva';

import { Subscription } from 'rxjs';
import { MercenaryService } from '../../../services/MercenaryService';
import { ICharacterConfig } from '../../../models/CharacterConfig';
import { MessagingService } from '../../../services/MessagingService';

import { getImageSrc, renderImagePart } from '../../Characters/ImagePart';

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/css/bundle';
import 'swiper/scss/navigation';

import './CustomizeMercenary.scss';
import { ICharacterDetails } from '../../../models/CharacterDetails';

export enum CustomizeMercenaryType {
  Mint,
  Claim,
  updateAppearance
}

export interface ICustomizeMercenaryProps {
  ethereumService: EthereumService;
  messagingService: MessagingService;
  mercenaryService: MercenaryService;
  character?: ICharacterDetails;
  customizeType: CustomizeMercenaryType;
  close: any;
}

export interface ICustomizeMercenaryState {
  mintCost: string | null;
  updateAppearanceCost: string | null;
  background: string;
  base: string;
  hair: string;
  eyes: string;
  mouth: string;
  clothing: string;
  feature: string;
}

export class CustomizeMercenary extends Component<ICustomizeMercenaryProps, ICustomizeMercenaryState> {
  navigationPrevRef: any;
  navigationNextRef: any;

  subscription: Subscription;
  characterConfig?: ICharacterConfig | null;
  backgrounds = ['Farm', 'Desert', 'Snow', 'Mountains', 'Apocalypse 1', 'Apocalypse 2'];
  bases = [
    'Pale Male',
    'Pale Female',
    'Tan Male',
    'Tan Female',
    'Green Male',
    'Green Female',
    'Android Brown',
    'Android Silver'
  ];
  hair = ['Bald', 'Shaved', 'Beanie', 'Dreads', 'Military Helmet'];
  eyes = ['Dark', 'Red', 'Android', 'Goofy', 'Goggles', 'Cracked Glasses'];
  mouths = ['Closed', 'Grin', 'Goofy', 'Respirator', 'Bandana'];
  clothing = ['None', 'Old T-shirt', 'Khaki Hoodie', 'Khaki T-Shirt', 'Striped Hoodie', 'Brown Vest', 'Tartan Shirt'];
  features = ['None', 'Band Aid', 'Open Wound', 'Comms Device'];

  constructor(props: any) {
    if (props.customizeType === CustomizeMercenaryType.updateAppearance && !props.character) {
      throw new Error('Character must be provided when changing appearance');
    }

    super(props);

    this.state = {
      mintCost: this.getMintCost(1),
      updateAppearanceCost: this.getupdateAppearanceCost(),
      background: this.props.character?.imageParts?.background || this.backgrounds[0],
      base: this.props.character?.imageParts?.base || this.bases[0],
      hair: this.props.character?.imageParts?.hair || this.hair[0],
      eyes: this.props.character?.imageParts?.eyes || this.eyes[0],
      mouth: this.props.character?.imageParts?.mouth || this.mouths[0],
      clothing: this.props.character?.imageParts?.clothing || this.clothing[0],
      feature: this.props.character?.imageParts?.feature || this.features[0]
    };

    this.subscription = new Subscription();

    this.handleImageTraitChange = this.handleImageTraitChange.bind(this);
    this.performAction = this.performAction.bind(this);
    this.randomizeTraits = this.randomizeTraits.bind(this);
    this.close = this.close.bind(this);

    this.navigationPrevRef = React.createRef();
    this.navigationNextRef = React.createRef();

    SwiperCore.use([Navigation]);
  }

  handleImageTraitChange(name: string, selected: string) {
    if (selected === 'Respirator' || selected === 'Bandana') {
      this.setState({ ...this.state, [name.toLowerCase()]: selected, feature: 'None' });
    } else {
      this.setState({ ...this.state, [name.toLowerCase()]: selected });
    }
  }

  randomizeTraits() {
    var mouth = this.mouths[Math.floor(Math.random() * this.mouths.length)];

    this.setState({
      ...this.state,
      background: this.backgrounds[Math.floor(Math.random() * this.backgrounds.length)],
      base: this.bases[Math.floor(Math.random() * this.bases.length)],
      hair: this.hair[Math.floor(Math.random() * this.hair.length)],
      eyes: this.eyes[Math.floor(Math.random() * this.eyes.length)],
      mouth: mouth,
      clothing: this.clothing[Math.floor(Math.random() * this.clothing.length)],
      feature:
        mouth === 'Respirator' || mouth === 'Bandana'
          ? 'None'
          : this.features[Math.floor(Math.random() * this.features.length)]
    });
  }

  performAction() {
    const traits = [
      Utils.formatBytes32String(this.state.background),
      Utils.formatBytes32String(this.state.base),
      Utils.formatBytes32String(
        this.state.base.includes('Male') ? 'Male' : this.state.base.includes('Female') ? 'Female' : 'Unknown'
      ),
      Utils.formatBytes32String(this.state.hair),
      Utils.formatBytes32String(this.state.eyes),
      Utils.formatBytes32String(this.state.mouth),
      Utils.formatBytes32String(this.state.clothing),
      Utils.formatBytes32String(this.state.feature)
    ];

    switch (this.props.customizeType) {
      case CustomizeMercenaryType.Mint:
        this.props.mercenaryService.mint(traits);
        break;
      case CustomizeMercenaryType.Claim:
        this.props.mercenaryService.claim(traits);
        break;
      case CustomizeMercenaryType.updateAppearance:
        this.props.mercenaryService.updateAppearance(this.props.character!, traits);
        break;
    }

    this.props.close();
  }

  getMintCost(amountToMint: number) {
    return Utils.formatUnits(this.props.mercenaryService!.mintPrice?.mul(BigNumber.from(amountToMint)));
  }

  getupdateAppearanceCost() {
    return Utils.formatUnits(this.props.mercenaryService!.updateTraitsPrice);
  }

  close() {
    this.props.close();
  }

  render() {
    return (
      <div className="is-active modal noselect">
        <div className="modal-background" onClick={this.close}></div>
        <div id="mint-mercenary" className="modal-content mint-mercenary">
          <div
            className="card"
            style={{
              backgroundImage: `linear-gradient(rgba(10, 10, 10, 0.6), rgba(10, 10, 10, 1)),url("${getImageSrc(
                'background',
                this.state.background
              )}")`
            }}>
            <button className="modal-close is-medium is-button" aria-label="close" onClick={this.close}></button>
            <div className="is-flex is-justify-content-center mint-mercenary-title pb-3">
              <span>Customize Mercenary</span>
            </div>
            <div className="is-flex">
              <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center character-mint">
                <div className="is-flex is-justify-content-center my-4">{this.renderImage()}</div>

                <div className="is-flex is-justify-content-center mt-2 mb-5">
                  <Swiper navigation spaceBetween={20} slidesPerView={1}>
                    <SwiperSlide>
                      {this.renderImageTraitSelect('Background', this.backgrounds, this.state.background)}
                    </SwiperSlide>

                    <SwiperSlide>{this.renderImageTraitSelect('Base', this.bases, this.state.base)}</SwiperSlide>

                    <SwiperSlide>{this.renderImageTraitSelect('Hair', this.hair, this.state.hair)}</SwiperSlide>

                    <SwiperSlide>{this.renderImageTraitSelect('Eyes', this.eyes, this.state.eyes)}</SwiperSlide>

                    <SwiperSlide>
                      {this.renderImageTraitSelect('Clothing', this.clothing, this.state.clothing)}
                    </SwiperSlide>

                    <SwiperSlide>{this.renderImageTraitSelect('Mouth', this.mouths, this.state.mouth)}</SwiperSlide>

                    <SwiperSlide>
                      {this.renderImageTraitSelect(
                        'Feature',
                        this.state.mouth === 'Respirator' || this.state.mouth === 'Bandana' ? ['None'] : this.features,
                        this.state.feature
                      )}
                    </SwiperSlide>
                  </Swiper>
                </div>

                <div className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center mb-5">
                  <button className="button is-transparent-action is-fullwidth mr-3 " onClick={this.randomizeTraits}>
                    Randomize
                  </button>
                  <button className="button boost-button is-action is-fullwidth" onClick={this.performAction}>
                    {this.props.customizeType === CustomizeMercenaryType.Mint && <span>Mint</span>}
                    {this.props.customizeType === CustomizeMercenaryType.Claim && <span>Claim</span>}
                    {this.props.customizeType === CustomizeMercenaryType.updateAppearance && <span>Update</span>}
                  </button>
                </div>

                {this.props.customizeType === CustomizeMercenaryType.Mint && (
                  <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center pb-3">
                    <p>
                      <span className="mr-1">Cost {this.state.mintCost}</span>
                      ETH (+ gas)
                    </p>
                  </div>
                )}

                {this.props.customizeType === CustomizeMercenaryType.updateAppearance && (
                  <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center pb-3">
                    <p>
                      <span className="mr-1">Cost {this.state.updateAppearanceCost}</span>
                      ETH (+ gas)
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderImageTraitSelect(name: string, options: string[], current: string) {
    return (
      <ImageTraitSelect
        imageTraitName={name}
        imageTraitOptions={options}
        selectedImageTrait={current}
        handleImageTraitChange={this.handleImageTraitChange}></ImageTraitSelect>
    );
  }

  renderImage() {
    return (
      <figure className="image is-3by3">
        <React.Fragment>
          <Stage width={200} height={200}>
            <Layer>
              {renderImagePart('background', this.state.background, 200, 200)}
              {renderImagePart('base', this.state.base, 200, 200)}
              {renderImagePart('hair', this.state.hair, 200, 200)}
              {renderImagePart('eyes', this.state.eyes, 200, 200)}
              {renderImagePart('clothing', this.state.clothing, 200, 200)}
              {renderImagePart('mouth', this.state.mouth, 200, 200)}
              {renderImagePart('feature', this.state.feature, 200, 200)}
            </Layer>
          </Stage>
        </React.Fragment>
      </figure>
    );
  }
}

interface IImageTraitSelectProps {
  imageTraitName: string;
  imageTraitOptions: string[];
  selectedImageTrait: string;
  handleImageTraitChange: any;
}

class ImageTraitSelect extends Component<IImageTraitSelectProps> {
  render() {
    return (
      <React.Fragment>
        <div>Select {this.props.imageTraitName}</div>
        <div className="select is-small mt-2">
          <select
            className="is-action"
            style={{ height: '2em', width: '100px' }}
            onChange={e => this.props.handleImageTraitChange(this.props.imageTraitName, e.target.value)}>
            {this.props.imageTraitOptions.map((option: string, i: number) => (
              <option key={i} selected={this.props.selectedImageTrait === option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </React.Fragment>
    );
  }
}
