import React, { Component } from 'react';
import { EthereumService } from '../../services/EthereumService';
import { IGameService } from '../../services/Interfaces';
import { Utils } from '../../services/Utils';

interface IPrizesProps {
  ethereumService: EthereumService;
  games: IGameService[];
}

interface IPrizesState {
  gamesWithPrizes: IGameService[];
}

class Prizes extends Component<IPrizesProps, IPrizesState> {
  walletAddress?: string;
  chainId?: number;
  countdownTimer?: NodeJS.Timer;

  constructor(props: any) {
    super(props);

    this.state = {
      gamesWithPrizes: this.props.games.filter(g => g.unclaimedPrizeTotal > 0)
    };
  }

  render() {
    return (
      <div className="content">
        <div className="is-flex is-justify-content-space-between info-control card-title">
          <h2 className="info">PRIZES</h2>
        </div>
        {this.state.gamesWithPrizes.length === 0 && (
          <div className="info-control">
            <h2 className="info">You have no winnings to claim</h2>
          </div>
        )}
        <div>
          {this.state.gamesWithPrizes.length > 0 && (
            <React.Fragment>
              {this.state.gamesWithPrizes.map((g: IGameService) => this.renderWinnings(g))}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  renderWinnings(gameService: IGameService) {
    return (
      <div
        className="info-control no-wrap is-justify-content-space-between"
        key={gameService.config.gameContractAddress}>
        <h2 className="info">
          {gameService.unclaimedPrizes.length === 1 && (
            <span>
              You have won {Utils.toFixed(gameService.unclaimedPrizeTotal, 2)} {gameService.config.token} in the{' '}
              {gameService.config.gameType} game!
            </span>
          )}
          {gameService.unclaimedPrizes.length > 1 && (
            <span>
              You have won {Utils.toFixed(gameService.unclaimedPrizeTotal, 2)} {gameService.config.token} in the{' '}
              {gameService.config.gameType} game! ({Utils.toFixed(gameService.unclaimedPrizes.length, 0)} Prizes)
            </span>
          )}
        </h2>
        <div className="action">
          <button
            className="button is-action"
            disabled={gameService.unclaimedPrizes.length === 0}
            onClick={() => gameService.claimPrize()}>
            <span>Claim Prize</span>
          </button>
        </div>
      </div>
    );
  }
}

export default Prizes;
