import { Token } from '@uniswap/sdk';
import { BigNumber, ethers } from 'ethers';
import { IClaimConfig } from '../models/ClaimConfig';
import { TokenId } from '../models/Enums';
import { EthereumService } from './EthereumService';
import { MessagingService } from './MessagingService';
import { Utils } from './Utils';
import { IFungibleAssetService } from './Interfaces';

export class ClaimableFungibleAssetService implements IFungibleAssetService {
  walletAddress?: string;
  claimsContract?: ethers.Contract;
  balance: string | 0 = 0;
  gasPrice?: string | number;
  price: string | number = 0.1;
  showPrice = true;
  tokenId: TokenId;
  token: Token;

  constructor(
    public config: IClaimConfig,
    private messagingService: MessagingService,
    private ethereumService: EthereumService
  ) {
    this.walletAddress = this.ethereumService!.walletData.walletAddress;
    this.claimsContract = new ethers.Contract(
      this.config.contractAddress,
      this.config.contractAbi,
      ethereumService.signer
    );
    this.token = new Token(
      ethereumService.networkManager!.network!.chainId,
      this.config.contractAddress,
      this.config.assetConstants!.decimals
    );
    this.tokenId = TokenId[this.config.assetConstants?.symbol! as keyof typeof TokenId];

    this.setupEventHandlers();
  }

  async update(): Promise<any> {
    this.balance = Utils.formatUnits(await this.getClaimBalance(), this.config.assetConstants!.decimals);
  }

  async getClaimBalance(): Promise<any> {
    if (!this.claimsContract) {
      return new Promise((resolve, reject) => {});
    }

    var merkle = this.config!.claimMerkle.claims[this.walletAddress!];

    if (!merkle) {
      return new Promise((resolve, reject) => {});
    }

    const claimed = await this.claimsContract.isClaimed(merkle.index);

    if (claimed) {
      return BigNumber.from(0);
    } else {
      return merkle.amount;
    }
  }

  async sendClaim(): Promise<any> {
    if (!this.claimsContract) {
      this.messagingService.errors.next({
        message: `Error: unable to load claim contract`,
        error: 'Unable to load claim contract'
      });
      return Promise.reject('Error: unable to load claim contract');
    }

    if (+this.balance <= 0) {
      this.messagingService.errors.next({
        message: `Error: nothing to claim`,
        error: 'Nothing to claim'
      });
      return Promise.reject('Error: nothing to claim');
    }

    var merkle = this.config!.claimMerkle.claims[this.walletAddress!];

    if (!merkle) {
      this.messagingService.errors.next({
        message: `Error: address not found in merkle root`,
        error: 'Address not found in merkle root'
      });
      return Promise.reject('Error: address not found in merkle root');
    }

    return this.claimsContract.claim(merkle.index, this.walletAddress!, merkle.amount, merkle.proof).then(
      (response: any) =>
        this.messagingService.events.next({
          message: `${this.config.assetConstants.symbol} claim is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error during claim : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  removeEventHandlers() {
    this.claimsContract?.removeAllListeners();
  }

  setupEventHandlers() {
    this.removeEventHandlers();

    var update = (message: string) =>
      this.update().then(() => {
        this.messagingService.events.next({ message: message });
      });

    if (this.claimsContract) {
      this.claimsContract!.on(
        {
          address: this.ethereumService.walletData.walletAddress,
          topics: [ethers.utils.id('Claimed(uint256,address,uint256)')]
        },
        (index, account, amount) => {
          if (account === this.ethereumService.walletData.walletAddress) {
            update(`${Utils.formatUnits(amount, 18)} ${this.config.assetConstants.symbol} claimed`);
          }
        }
      );
    }
  }
}
