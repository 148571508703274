import { Token } from '@uniswap/sdk';
import { ethers } from 'ethers';
import { IAssetConfig } from '../models/AssetConfig';
import { TokenId } from '../models/Enums';
import { EthereumService } from './EthereumService';
import { Utils } from './Utils';
import { IFungibleAssetService } from './Interfaces';

export class FungibleAssetService implements IFungibleAssetService {
  walletAddress?: string;
  contract?: ethers.Contract | any;
  balance: string | 0 = 0;
  gasPrice?: string | number;
  tokenId: TokenId;
  token: Token;

  constructor(public config: IAssetConfig, private ethereumService: EthereumService) {
    this.walletAddress = this.ethereumService!.walletData.walletAddress;
    this.contract = new ethers.Contract(
      this.config.contractAddress,
      this.config.assetConstants!.contractAbi,
      ethereumService.signer
    );
    this.token = new Token(
      ethereumService.networkManager!.network!.chainId,
      this.config.contractAddress,
      this.config.assetConstants!.decimals
    );
    this.tokenId = TokenId[this.config.assetConstants?.symbol! as keyof typeof TokenId];
  }

  update(): Promise<FungibleAssetService> {
    var work = [this.contract.balanceOf(this.walletAddress), this.ethereumService!.provider!.getGasPrice()];

    return Promise.all(work).then(response => {
      this.balance = Utils.formatUnits(response[0], this.config.assetConstants!.decimals);
      this.gasPrice = Utils.formatUnits(response[1], 'gwei');
      return this;
    });
  }
}
