import { TokenId } from './Enums';
import { IContractConstants } from './ModelInterfaces';
import { AssetType, IAssetConstants } from './AssetConfig';

import landContract from '../contracts/Land.json';
import cropV1 from '../contracts/Crops/CornV1.json';
import cropV2 from '../contracts/Crops/CornV2.json';
import CharacterV1 from '../contracts/characters/characterV1.json';
import MercenariesV1 from '../contracts/characters/mercenariesV1.json';
import MintMercenariesV1 from '../contracts/characters/mintMercenariesV1.json';
import FarmlandItemV1 from '../contracts/items/itemV1.json';
import UniswapPoolV2 from '../contracts/liquidity/Uniswap-Pool-V2.json';
import StatManagerV1 from '../contracts/quests/StatManagerV1.json';
import QuestsV1 from '../contracts/quests/QuestsV1.json';
import ItemSetsV1 from '../contracts/items/itemSetsV1.json';
import CraftingV1 from '../contracts/items/craftingV1.json';
import Box from '../contracts/misc/BoxV1.json';
import landIcon from '../assets/LAND-icon.png';
import landImage from '../assets/land.png';
import cornIcon from '../assets/CORN-icon.png';
import cornImage from '../assets/corn.png';
import arbContract from '../contracts/tokens/ARB.json';
import arbIcon from '../assets/arbitrum-icon.png';
// import wheatIcon from '../assets/WHEAT-icon.png';
// import wheatImage from '../assets/wheat.png';
import knapsackIcon from '../assets/ITEM-icon.png';
import knapsackImage from '../assets/ITEM-icon.png';

export class Constants {
  static readonly LAND: IAssetConstants = {
    symbol: TokenId.LAND,
    icon: landIcon,
    image: landImage,
    contractVersion: 1,
    contractAbi: landContract.abi,
    decimals: 18,
    displayDecimals: 8,
    assetType: AssetType.Land,
    assetTypeCode: 0,
    loadPrices: true
  };

  static readonly CORNv1: IAssetConstants = {
    symbol: TokenId.CORN,
    icon: cornIcon,
    image: cornImage,
    contractVersion: 1,
    contractAbi: cropV1.abi,
    decimals: 18,
    displayDecimals: 8,
    assetType: AssetType.Crop,
    assetTypeCode: 0,
    loadPrices: true
  };

  static readonly CORNv2: IAssetConstants = {
    symbol: TokenId.CORN,
    icon: cornIcon,
    image: cornImage,
    contractVersion: 2,
    contractAbi: cropV2.abi,
    decimals: 18,
    displayDecimals: 8,
    assetType: AssetType.Crop,
    assetTypeCode: 0,
    loadPrices: true
  };

  // static readonly WHEAT: IAssetConstants = {
  //   symbol: TokenId.WHEAT,
  //   icon: wheatIcon,
  //   image: wheatImage,
  //   contractVersion: 3,
  //   contractAbi: cropV2.abi,
  //   decimals: 18,
  //   displayDecimals: 8,
  //   assetType: AssetType.Crop,
  //   assetTypeCode: 0,
  //   loadPrices: true
  // };

  static readonly CornEthSLP: IAssetConstants = {
    symbol: TokenId.ETHCORN,
    icon: cornIcon,
    image: cornImage,
    contractVersion: 1,
    contractAbi: UniswapPoolV2.abi,
    decimals: 0,
    displayDecimals: 0,
    assetType: AssetType.SLP,
    assetTypeCode: 0,
    loadPrices: false
  };

  static readonly ARB: IAssetConstants = {
    symbol: TokenId.ARB,
    icon: arbIcon,
    image: arbIcon,
    contractVersion: 1,
    contractAbi: arbContract.abi,
    decimals: 18,
    displayDecimals: 8,
    assetType: AssetType.Token,
    assetTypeCode: 0,
    loadPrices: false
  };

  static readonly CITIZENSV1: IContractConstants = {
    contractVersion: 1,
    contractAbi: CharacterV1.abi
  };

  static readonly MERCENARIESV1: IContractConstants = {
    contractVersion: 1,
    contractAbi: MercenariesV1.abi
  };

  static readonly MINTMERCENARIESV1: IContractConstants = {
    contractVersion: 1,
    contractAbi: MintMercenariesV1.abi
  };

  static readonly QUESTSV1: IContractConstants = {
    contractVersion: 1,
    contractAbi: QuestsV1.abi
  };

  static readonly STATMANAGERV1: IContractConstants = {
    contractVersion: 1,
    contractAbi: StatManagerV1.abi
  };

  static readonly FARMER_EMPLOY_HELP =
    'Consider harvesting your growing Corn before employing a character as your farm boost will be reset to 1x and your growing Corn will be temporarily reduced';

  static readonly EXPLORER_QUEST_EMPLOY_HELP =
    'Citizens employed as an explorer will become available for a quest, you must go to the quest page to begin the quest';

  static readonly ITEM: IAssetConstants = {
    symbol: TokenId.ITEM,
    icon: knapsackIcon,
    image: knapsackImage,
    contractVersion: 1,
    contractAbi: FarmlandItemV1.abi,
    decimals: 0,
    displayDecimals: 0,
    assetType: AssetType.Item,
    assetTypeCode: 1,
    loadPrices: false
  };

  static readonly ITEMSETS: IContractConstants = {
    contractVersion: 1,
    contractAbi: ItemSetsV1.abi
  };

  static readonly CRAFTING: IContractConstants = {
    contractVersion: 1,
    contractAbi: CraftingV1.abi
  };

  static readonly BOX: IContractConstants = {
    contractVersion: 1,
    contractAbi: Box.abi
  };
}
