import { BigNumber, ethers } from 'ethers';
import { ICharacterConfig, IEmploymentConfig } from '../models/CharacterConfig';
import { ICharacterDetails } from '../models/CharacterDetails';
import { JobType } from '../models/Enums';
import { EthereumService } from './EthereumService';
import { MessagingService } from './MessagingService';
import { Utils } from './Utils';

export interface ICharacterEmploymentService {
  employmentConfig: IEmploymentConfig;
  isEmploymentApproved: boolean;
  approveEmployment(): Promise<any>;
  employ(characterDetails: ICharacterDetails): Promise<any>;
}

abstract class CharacterEmploymentServiceBase implements ICharacterEmploymentService {
  walletAddress?: string;
  citizenContract?: ethers.Contract;
  employmentContract?: ethers.Contract;
  mintMercenaryContract?: ethers.Contract;
  isEmploymentApproved: boolean = false;

  constructor(
    public characterConfig: ICharacterConfig,
    public employmentConfig: IEmploymentConfig,
    protected messagingService: MessagingService,
    protected ethereumService: EthereumService
  ) {
    this.walletAddress = this.ethereumService!.walletData.walletAddress;

    this.citizenContract = new ethers.Contract(
      this.characterConfig.citizenConfig.contractAddress,
      this.characterConfig.citizenConfig.contractConstants.contractAbi,
      ethereumService.signer
    );

    this.employmentContract = new ethers.Contract(
      employmentConfig.contractAddress,
      employmentConfig.contractContants!.contractAbi,
      this.ethereumService.signer!
    );

    this.mintMercenaryContract = new ethers.Contract(
      this.characterConfig.mintMercenaryConfig.contractAddress,
      this.characterConfig.mintMercenaryConfig.contractConstants.contractAbi,
      this.ethereumService.signer!
    );

    this.citizenContract!.isApprovedForAll(this.walletAddress, this.employmentConfig.contractAddress).then(
      (result: boolean) => (this.isEmploymentApproved = result)
    );
  }

  async approveEmployment(): Promise<any> {
    return this.citizenContract!.setApprovalForAll(this.employmentConfig.contractAddress, 1).then(
      () =>
        this.messagingService.events.next({
          message: `Approval of Character employment as ${this.employmentConfig.jobType} is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error approving Character ${
            this.employmentConfig.jobType
          } employment : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  abstract employ(characterDetails: ICharacterDetails): Promise<any>;

  protected async getTokenId(characterDetails: ICharacterDetails): Promise<any> {
    return await this.citizenContract!.tokenOfOwnerByIndex(this.walletAddress, BigNumber.from(characterDetails.index));
  }
}

export class FarmerEmploymentService extends CharacterEmploymentServiceBase {
  async employ(characterDetails: ICharacterDetails): Promise<any> {
    const tokenId = await this.getTokenId(characterDetails);

    return this.employmentContract!.equipCollectible(tokenId, 0).then(
      () =>
        this.messagingService.events.next({
          message: `${this.employmentConfig.jobType} employment is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error employing as ${this.employmentConfig.jobType} : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }
}

export class ExplorerEmploymentService extends CharacterEmploymentServiceBase {
  async employ(characterDetails: ICharacterDetails): Promise<any> {
    const tokenId = await this.getTokenId(characterDetails);

    return this.mintMercenaryContract!.wrap(tokenId, this.characterConfig.citizenConfig.contractAddress).then(
      () =>
        this.messagingService.events.next({
          message: `${this.employmentConfig.jobType} employment is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error employing as ${this.employmentConfig.jobType} : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }
}

export class CharacterEmploymentServiceFactory {
  static create(
    characterConfig: ICharacterConfig,
    employmentConfig: IEmploymentConfig,
    messagingService: MessagingService,
    ethereumService: EthereumService
  ): ICharacterEmploymentService {
    switch (employmentConfig.jobType) {
      case JobType.Farmer:
        return new FarmerEmploymentService(characterConfig, employmentConfig, messagingService, ethereumService);
      case JobType.Explorer:
        return new ExplorerEmploymentService(characterConfig, employmentConfig, messagingService, ethereumService);
    }
  }
}
