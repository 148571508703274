import { BigNumber, ethers } from 'ethers';
import { IQuestConfig } from '../models/CharacterConfig';
import { ICharacterStat, ICharactersActivity } from '../models/CharacterDetails';
import { EthereumService } from './EthereumService';
import { MessagingService } from './MessagingService';
import { Utils } from './Utils';
import { Mutex } from 'async-mutex';
import { EventCacheService } from './EventCacheService';
import { ActiveQuestEventCacheService } from './ActiveQuestEventCache';

export interface IQuestStatics {
  index: number;
  name: string;
  itemSet: number;
  questPrice: BigNumber;
  chanceOfFindingLand: number;
  minimumLevel: BigNumber;
  paymentAddress: string;
  questDuration: number;
  maxNumberOfActivitiesBase: number;
  active: boolean;
}

export interface IItemsReceivedDetails {
  blockNumber: number;
  itemAmount: BigNumber;
  itemID: BigNumber;
  landAmount: BigNumber;
  quest: BigNumber;
  tokenID: BigNumber;
}

export interface IQuestService {
  questConfigs: IQuestConfig[];
  questStaticsMap: Map<number, IQuestStatics>;
  isPaymentApproved: boolean;
  paymentBalance?: BigNumber;
  isStatRestoreItemUseApproved: boolean;
  update(): Promise<any>;
  approvePayment(): Promise<any>;
  getPaymentSymbol(): string;
  approveQuestItemUse(): Promise<any>;
  approveItemUse(): Promise<any>;
  getQuestState(characterId: number): Promise<ICharactersActivity>;
  getMaxQuests(questIndex: number, characterId: number): Promise<number>;
  getQuestDuration(questIndex: number, characterId: number): Promise<number>;
  getQuestCost(questIndex: number, questLength: number): BigNumber;
  getQuest(questIndex: number): IQuestStatics;
  getAvailableQuests(): IQuestStatics[];
  beginQuest(questIndex: number, characterId: number, questLength: number, items: number[]): Promise<any>;
  completeQuest(characterId: number): Promise<any>;
  abortQuest(characterId: number): Promise<any>;
  getCharactersItemsReceived(characterId: number): Promise<IItemsReceivedDetails[]>;
  getLatestItemsReceived(fromBlock: number): Promise<IItemsReceivedDetails[]>;
  getCharactersHealth(characterId: number): Promise<ICharacterStat>;
  getLastQuest(characterId: number): number;
}

abstract class QuestServiceBase implements IQuestService {
  private mutex = new Mutex();
  private itemsFoundEventCache: EventCacheService;
  private activeQuestEventCache: ActiveQuestEventCacheService;
  protected healthItemSet = 21;
  protected moraleItemSet = 20;

  protected historicItemMap: Map<number, IItemsReceivedDetails[]> = new Map<number, IItemsReceivedDetails[]>();
  protected walletAddress: string;
  protected questContract: ethers.Contract;
  protected paymentContract?: ethers.Contract;
  protected itemContract?: ethers.Contract;
  protected itemSetsContract?: ethers.Contract;
  protected mercenaryContract?: ethers.Contract;
  protected statManagerContract?: ethers.Contract;

  // TODO: is this needed
  protected maxStatBoosts: number = 0;
  public questStaticsMap: Map<number, IQuestStatics> = new Map<number, IQuestStatics>();
  public isPaymentApproved: boolean = false;
  public paymentBalance?: BigNumber;
  public isStatRestoreItemUseApproved: boolean = false;

  constructor(
    public questConfigs: IQuestConfig[],
    protected messagingService: MessagingService,
    protected ethereumService: EthereumService
  ) {
    this.walletAddress = this.ethereumService!.walletData.walletAddress;

    this.questContract = new ethers.Contract(
      this.questConfigs[0].contractAddress,
      this.questConfigs[0].contractConstants.contractAbi,
      ethereumService.signer
    );

    this.itemContract = new ethers.Contract(
      this.ethereumService.networkManager!.network!.itemConfig!.contractAddress,
      this.ethereumService.networkManager!.network!.itemConfig!.contractConstants!.contractAbi,
      ethereumService.signer
    );

    this.itemSetsContract = new ethers.Contract(
      this.ethereumService.networkManager!.network!.itemSetsConfig!.contractAddress,
      this.ethereumService.networkManager!.network!.itemSetsConfig!.contractConstants!.contractAbi,
      ethereumService.signer
    );

    this.mercenaryContract = new ethers.Contract(
      this.ethereumService.networkManager!.network!.characterConfig!.mercenaryConfig.contractAddress,
      this.ethereumService.networkManager!.network!.characterConfig!.mercenaryConfig.contractConstants!.contractAbi,
      this.ethereumService.signer!
    );

    this.statManagerContract = new ethers.Contract(
      this.ethereumService.networkManager!.network!.characterConfig!.statManagerConfig.contractAddress,
      this.ethereumService.networkManager!.network!.characterConfig!.statManagerConfig.contractConstants!.contractAbi,
      this.ethereumService.signer!
    );

    this.paymentContract = new ethers.Contract(
      this.questConfigs[0].questPayConfig.contractAddress,
      this.questConfigs[0].questPayConfig.assetConstants!.contractAbi,
      ethereumService.signer
    );

    this.itemsFoundEventCache = new EventCacheService(
      this.questContract,
      this.questContract!.filters.ItemFound(this.walletAddress),
      this.questConfigs[0].deployedBlockNumber,
      ethereumService,
      messagingService,
      `Your explorer's discoveries have been added to your barn`
    );

    this.activeQuestEventCache = new ActiveQuestEventCacheService(this.questContract, ethereumService);

    this.setupEventHandlers();
  }

  async update(): Promise<any> {
    await this.mutex.runExclusive(async () => {
      if (this.questStaticsMap.size === 0) {
        await this.loadStatics();
      }

      await this.updateImpl();
    });
  }

  async loadStatics() {
    var questStatics = await Promise.all(
      this.questConfigs.map(async questConfig => {
        return {
          difficultyQuestIndexes: questConfig.difficultyQuestIndexes,
          easy: {
            index: questConfig.difficultyQuestIndexes[0],
            ...(await this.questContract.getQuest(questConfig.difficultyQuestIndexes[0]))
          },
          hard: {
            index: questConfig.difficultyQuestIndexes[1],
            ...(await this.questContract.getQuest(questConfig.difficultyQuestIndexes[1]))
          }
        };
      })
    );

    questStatics.forEach((result: any) => {
      this.questStaticsMap.set(result.easy.index, { ...result.easy });
      this.questStaticsMap.set(result.hard.index, { ...result.hard });
    });

    this.paymentBalance = await this.paymentContract!.balanceOf(this.walletAddress);

    // TODO:
    this.maxStatBoosts = 0; //  await this.mercenaryContract!.maxTraitBoosts();
  }

  async updateImpl(): Promise<any> {
    this.isPaymentApproved = await this.paymentContract!.isOperatorFor(
      this.questContract!.address,
      this.ethereumService.walletData.walletAddress
    );

    this.isStatRestoreItemUseApproved = await this.itemContract!.isApprovedForAll(
      this.ethereumService.walletData.walletAddress,
      this.statManagerContract!.address
    );

    await this.refreshHistoricItems();
  }

  async refreshHistoricItems() {
    var latestReceivedItems = await this.getLatestItemsReceived();

    this.historicItemMap = latestReceivedItems.reduce(
      (acc: Map<number, IItemsReceivedDetails[]>, curr: IItemsReceivedDetails) => {
        var characterIndex = BigNumber.from(curr.tokenID).toNumber();
        var items = acc.get(characterIndex);
        if (items) {
          acc.set(characterIndex, items.concat(curr));
        } else {
          acc.set(characterIndex, [curr]);
        }
        return acc;
      },
      new Map<number, IItemsReceivedDetails[]>()
    );
  }

  async getLatestItemsReceived(): Promise<IItemsReceivedDetails[]> {
    await this.activeQuestEventCache.update();
    await this.itemsFoundEventCache.update();

    return this.itemsFoundEventCache.events.map<IItemsReceivedDetails>((i: any) => {
      return {
        blockNumber: i.blockNumber,
        itemID: i.args[3],
        itemAmount: i.args[4],
        landAmount: i.args[5],
        quest: i.args[1],
        tokenID: i.args[2]
      };
    });
  }

  async approvePayment(): Promise<any> {
    return this.paymentContract!.authorizeOperator(this.questContract!.address, {
      from: this.ethereumService.walletData.walletAddress
    }).then(
      () => this.messagingService.events.next({ message: 'Quest payment approval is pending', pending: true }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error approving quest payment : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  getPaymentSymbol(): string {
    return Utils.toTitleCase(this.questConfigs[0].questPayConfig.assetConstants!.symbol);
  }

  async approveQuestItemUse(): Promise<any> {
    return this.itemContract!.setApprovalForAll(this.questContract!.address, true).then(
      () => this.messagingService.events.next({ message: 'Quest item use approval is pending', pending: true }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error approving quest item use : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  async approveItemUse(): Promise<any> {
    return this.itemContract!.setApprovalForAll(this.statManagerContract!.address, true).then(
      () => this.messagingService.events.next({ message: 'Restoration item use approval is pending', pending: true }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error approving restoration item use : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  async getQuestState(characterId: number): Promise<ICharactersActivity> {
    const activity = await this.mercenaryContract!.charactersActivity(characterId);
    return {
      isActive: activity.active,
      numberOfActivities: BigNumber.from(activity.numberOfActivities).toNumber(),
      activityDuration: BigNumber.from(activity.activityDuration).toNumber(),
      startBlock: BigNumber.from(activity.startBlock).toNumber(),
      endBlock: BigNumber.from(activity.endBlock).toNumber(),
      completedBlock: BigNumber.from(activity.completedBlock).toNumber()
    };
  }

  getMaxQuests(questIndex: number, characterId: number): Promise<number> {
    return this.questContract.getExplorersMaxQuests(
      characterId,
      this.questStaticsMap.get(questIndex)?.maxNumberOfActivitiesBase
    );
  }

  getQuestDuration(questIndex: number, characterId: number): Promise<number> {
    return this.questContract.getExplorersQuestDuration(
      characterId,
      this.questStaticsMap.get(questIndex)?.questDuration
    );
  }

  getQuestCost(questIndex: number, questLength: number): BigNumber {
    return this.questStaticsMap.get(questIndex)!.questPrice?.mul(BigNumber.from(questLength));
  }

  getQuest(questIndex: number): IQuestStatics {
    return this.questStaticsMap.get(questIndex)!;
  }

  getAvailableQuests(): IQuestStatics[] {
    return [...this.questStaticsMap.values()];
  }

  abstract beginQuest(questIndex: number, characterId: number, questLength: number, items: number[]): Promise<any>;

  abstract completeQuest(characterId: number): Promise<any>;

  abstract abortQuest(characterId: number): Promise<any>;

  async getCharactersItemsReceived(characterId: number): Promise<IItemsReceivedDetails[]> {
    return this.historicItemMap.get(characterId) ?? [];
  }

  abstract getCharactersHealth(characterId: number): Promise<ICharacterStat>;

  getLastQuest(characterId: number): number {
    return this.activeQuestEventCache.lastActiveQuestsMap.get(characterId.toString()) ?? 0;
  }

  removeEventHandlers() {
    this.questContract?.removeAllListeners();
  }

  setupEventHandlers() {
    this.removeEventHandlers();

    var update = (message: string) => {
      this.update().then(() => {
        this.messagingService.events.next({ message: message });
      });
    };

    this.questContract!.on(
      {
        address: this.ethereumService.walletData.walletAddress,
        topics: [ethers.utils.id('ActivityStarted(address,uint256,uint256,uint256,uint256,uint256)')]
      },
      (account, wrappedTokenID, activityDuration, numberOfActivities, startBlock, endBlock) => {
        if (account === this.ethereumService.walletData.walletAddress) {
          update(`Your character has started exploring`);
        }
      }
    );

    this.questContract!.on(
      {
        address: this.ethereumService.walletData.walletAddress,
        topics: [ethers.utils.id('ActivityStatusUpdated(address,uint256,bool)')]
      },
      (account, wrappedTokenID, active) => {
        if (account === this.ethereumService.walletData.walletAddress) {
          update(`Your character has returned from a quest`);
        }
      }
    );
  }
}

export class BasicQuestService extends QuestServiceBase {
  async beginQuest(questIndex: number, characterId: number, questLength: number, items: number[]): Promise<any> {
    var sanitizedQuestLength = Utils.sanitizeParseUnits(questLength);

    // get the quest config with the index
    var questConfig = this.questConfigs.find(q => q.difficultyQuestIndexes.includes(questIndex));
    if (questConfig === undefined) {
      throw new Error('Quest config not found');
    }

    try {
      const gasEstimated = await this.questContract!.estimateGas.beginQuest(
        questIndex,
        characterId,
        sanitizedQuestLength,
        items.length ? items[0] : 0
      );

      await this.questContract!.beginQuest(questIndex, characterId, sanitizedQuestLength, items.length ? items[0] : 0, {
        gasLimit: Math.ceil(gasEstimated.toNumber() * 1.5)
      });

      this.messagingService.events.next({
        message: `${questConfig.questType} begin quest is pending`,
        pending: true
      });
    } catch (error: any) {
      var errorMessage = Utils.getContractErrorMessage(error);
      if (errorMessage === 'ERC777: burn amount exceeds balance') {
        var questPaymentName = Utils.toTitleCase(questConfig.questPayConfig.assetConstants!.symbol);
        errorMessage = `You do not have enough ${questPaymentName} to begin this quest`;
      }

      this.messagingService.errors.next({
        message: `Error beginning ${questConfig.questType} quest : ${errorMessage}`,
        error: error
      });
    }
  }

  async completeQuest(characterId: number): Promise<any> {
    const gasEstimated = await this.questContract!.estimateGas.completeQuest(characterId);

    return this.questContract!.completeQuest(characterId, {
      gasLimit: Math.ceil(gasEstimated.toNumber() * 1.5)
    }).then(
      () =>
        this.messagingService.events.next({
          //message: `${questConfig.questType} complete quest is pending`,
          message: `Complete quest is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          //message: `Error completing ${this.questConfigs.questType} quest : ${Utils.getContractErrorMessage(error)}`,
          message: `Error completing quest : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  async abortQuest(characterId: number): Promise<any> {
    const gasEstimated = await this.questContract!.estimateGas.abortQuest(characterId);

    return this.questContract!.abortQuest(characterId, {
      gasLimit: Math.ceil(gasEstimated.toNumber() * 1.5)
    }).then(
      () =>
        this.messagingService.events.next({
          //message: `${this.questConfigs.questType} abort quest is pending`,
          message: `Abort quest is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          // message: `Error abort ${this.questConfigs.questType} quest : ${Utils.getContractErrorMessage(error)}`,
          message: `Error abort quest : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  async getCharactersHealth(characterId: number): Promise<ICharacterStat> {
    var max = await this.mercenaryContract!.getMaxHealth(characterId);

    try {
      var remaining = await this.questContract!.calculateHealth(characterId);
    } catch (e) {
      console.error(e);
    }

    return {
      name: 'Health',
      bottomColor: '#e42e52',
      topColor: 'green',
      max: BigNumber.from(max).toNumber(),
      remaining: remaining ? BigNumber.from(remaining).toNumber() : BigNumber.from(max).toNumber()
    };
  }
}

export class QuestServiceFactory {
  static createQuests(messagingService: MessagingService, ethereumService: EthereumService): IQuestService[] {
    if (!ethereumService.networkManager?.network?.questConfigs) {
      return [];
    }

    var basicQuests = ethereumService.networkManager!.network!.questConfigs;
    var contractAddress = basicQuests[0].contractAddress;
    if (basicQuests.some(qc => qc.contractAddress !== contractAddress)) {
      throw new Error('All quests in a service must have the same contract address');
    }

    var questPayConfig = basicQuests[0].questPayConfig;
    if (basicQuests.some(qc => qc.questPayConfig.contractAddress !== questPayConfig.contractAddress)) {
      throw new Error('All quests in a service must have the same quest pay config');
    }

    return [new BasicQuestService(basicQuests, messagingService, ethereumService)];
  }
}
