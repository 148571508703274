import WalletConnectProvider from '@walletconnect/web3-provider';
import { EthereumNetworks } from './Networks/EthereumNetworks';
import { ArbitrumNetworks } from './Networks/ArbitrumNetworks';
// import Fortmatic from "fortmatic";
// import Portis from "@portis/web3";
// import Torus from "@toruslabs/torus-embed";

export class ProvidersConfig {
  static readonly web3ModalProviderOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        rpc: {
          1: EthereumNetworks.HOMESTEAD.rpcUrls[0],
          4: EthereumNetworks.RINKEBY.rpcUrls[0],
          42161: ArbitrumNetworks.ONE.rpcUrls[0],
          421613: ArbitrumNetworks.GOERLI.rpcUrls[0]
        }
      }
    }
    // portis: {
    //   package: Portis,
    //   options: {
    //     id: "PORTIS_ID"
    //   }
    // },
    // fortmatic: {
    //   package: Fortmatic,
    //   options: {
    //     key: "Fortmatic"
    //   }
    // },
    // torus: {
    //   package: Torus,
    // },
  };
}
