import { ChainId } from '@uniswap/sdk';
import { IAssetConfig } from '../AssetConfig';
import { JobType, LiquidityAssetType, PoolId, TokenId } from '../Enums';
import { AssetId, INetworkConfig } from '../NetworkConfig';
import { Constants } from '../Constants';
import { PoolType } from '../LiquidityConfig';
import { GameType } from '../GameConfig';

// Icons
import ethIcon from '../../assets/ETH-icon.png';
import SushiswapIcon from '../../assets/SUSHISWAP-icon.png';

// Claims files
import claimLandRinkeby from '../../contracts/claims/ethereum/rinkeby/claimLand2.json';

// Contracts
import UniswapPoolV2 from '../../contracts/liquidity/Uniswap-Pool-V2.json';
import StakingPoolV2 from '../../contracts/liquidity/StakingPoolV2.json';
import landDistributorContract from '../../contracts/claims/FarmlandDistributor.json';
import cropV2 from '../../contracts/Crops/CornV2.json';
import LottoV1 from '../../contracts/minigames/LottoV1.json';
import ChickenFeedV1 from '../../contracts/minigames/ChickenFeedV1.json';
import SuperdrawV1 from '../../contracts/minigames/SuperdrawV1.json';

const MainnetCornContractAddress = '0x3CD1833Ce959E087D0eF0Cb45ed06BffE60F23Ba'; // https://etherscan.io/token/0x3CD1833Ce959E087D0eF0Cb45ed06BffE60F23Ba
// const RinkebyCornContractAddress = '0x05739862347b4b228A8Bdef27410966C95a19b58'; // https://rinkeby.etherscan.io/address/0x05739862347b4b228A8Bdef27410966C95a19b58
const RinkebyCornContractAddress = '0x63b0A77E8390aAE9CDf13AcB1009228Fd0577428'; // https://rinkeby.etherscan.io/address/0x05739862347b4b228A8Bdef27410966C95a19b58
const RinkebyCornEthSlpContractAddress = '0x85251De8bf9ed7eBf26e93FEdf997bA6E4C8929a'; // https://rinkeby.etherscan.io/address/0x85251De8bf9ed7eBf26e93FEdf997bA6E4C8929a

export class EthereumNetworks {
  static readonly HOMESTEAD: INetworkConfig = {
    name: 'Homestead',
    chainId: ChainId.MAINNET,
    blocktime: 13.5,
    icon: ethIcon,
    loadPrices: true,
    displayChainName: 'Ethereum',
    fullDisplayChainName: 'Ethereum Mainnet',
    nativeCurrencyName: 'Ether',
    nativeCurrencySymbol: 'ETH',
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://etherscan.io'],
    dexBaseUrl: 'https://app.uniswap.org/#/swap?outputCurrency=',
    usdcContractAddress: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    wethContractAddres: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    assets: new Map<AssetId, IAssetConfig>([
      [
        TokenId.LAND,
        {
          contractAddress: '0x3258cd8134b6b28e814772dd91d5ecceea512818', // https://etherscan.io/token/0x3258cd8134b6b28e814772dd91d5ecceea512818
          assetConstants: Constants.LAND
        }
      ],
      [
        TokenId.CORN,
        {
          contractAddress: MainnetCornContractAddress,
          assetConstants: Constants.CORNv1
        }
      ]
    ]),
    boxConfig: null,
    itemConfig: null,
    itemSetsConfig: null,
    craftingConfig: null,
    characterConfig: null,
    questConfigs: [],
    contracts: {},
    tokens: {},
    liquidityConfigs: [],
    gameConfigs: [],
    claims: [],
    rewardConfig: null,
    isL2: false,
    isTestnet: false,
    hasCollectibles: false,
    providerConfig: {
      infuraKey: '610707a11c34489299fd7124b9b2a0c9',
      etherscanKey: 'GPNPEWCTND2YACQGJU9J95DB628NQTMHVI',
      alchemyKey: 'ekJBF3eeRBcjOihAhK-mgd15xwjUurc9'
    },
    graphAPIExchangeEndpoint: null
  };

  static readonly RINKEBY: INetworkConfig = {
    name: 'Rinkeby',
    chainId: ChainId.RINKEBY,
    blocktime: 15,
    icon: ethIcon,
    loadPrices: false,
    displayChainName: 'Ethereum Rinkeby',
    fullDisplayChainName: 'Ethereum Rinkeby Test Network ',
    nativeCurrencyName: 'Rinkeby Ether',
    nativeCurrencySymbol: 'RIN',
    rpcUrls: ['https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://rinkeby.etherscan.io'],
    dexBaseUrl: 'https://app.uniswap.org/#/swap?outputCurrency=',
    usdcContractAddress: '0xdcfab8057d08634279f8201b55d311c2a67897d2',
    wethContractAddres: '',
    assets: new Map<AssetId, IAssetConfig>([
      [
        TokenId.LAND,
        {
          contractAddress: '0x393FD5B96F6459511d7368778318c31d719720ad', // https://rinkeby.etherscan.io/address/0x393FD5B96F6459511d7368778318c31d719720ad
          assetConstants: Constants.LAND
        }
      ],
      [
        TokenId.CORN,
        {
          contractAddress: RinkebyCornContractAddress,
          assetConstants: Constants.CORNv2
        }
      ]
    ]),
    boxConfig: null,
    itemConfig: null,
    itemSetsConfig: null,
    craftingConfig: null,
    //   {
    //     contractAddress: '0xaA1c6a63EBd7d3D3fc6D146Dce000310FDd63F36', // https://testnet.arbiscan.io/address/0xaA1c6a63EBd7d3D3fc6D146Dce000310FDd63F36
    //     assetConstants: Assets.ITEM
    //   }
    characterConfig: {
      citizenConfig: {
        contractAddress: '0x9b06c9580AA6Ff74CD51757B2CA1916388764173',
        contractConstants: Constants.CITIZENSV1,
        deployedBlockNumber: 0
      },
      mercenaryConfig: {
        contractAddress: '',
        contractConstants: Constants.MERCENARIESV1,
        deployedBlockNumber: 0
      },
      mintMercenaryConfig: {
        contractAddress: '',
        contractConstants: Constants.MINTMERCENARIESV1,
        deployedBlockNumber: 0,
        claimMerkle: null
      },
      statManagerConfig: {
        contractAddress: '',
        contractConstants: Constants.STATMANAGERV1,
        deployedBlockNumber: 0
      },
      boostPayConfig: {
        contractAddress: RinkebyCornContractAddress,
        assetConstants: Constants.CORNv2
      },
      employmentConfigs: [
        {
          jobType: JobType.Farmer,
          contractAddress: RinkebyCornContractAddress,
          contractContants: Constants.CORNv2,
          jobHelp: Constants.FARMER_EMPLOY_HELP
        }
      ]
    },
    questConfigs: [],
    contracts: {},
    tokens: {},
    liquidityConfigs: [
      {
        area: 'Land Bank',
        token1: TokenId.ETH,
        token1Icon: ethIcon,
        token2: TokenId.LAND,
        token2Icon: Constants.LAND.icon,
        pool: PoolId.Sushiswap,
        poolType: PoolType.Pair,
        poolReserveIndex: 0,
        poolIcon: SushiswapIcon,
        poolId: 0, // Pool ID for LAND-ETH
        lpToken: TokenId.SLP,
        poolUrl: 'https://app.sushi.com/add/0x393FD5B96F6459511d7368778318c31d719720ad/ETH', // Add LAND-ETH liquidity
        poolContractAddress: '0xE76C9c290028d8d7e6e567b278B3f5a16B42Ffd5', // LAND-ETH SLP https://rinkeby.etherscan.io/address/0xE76C9c290028d8d7e6e567b278B3f5a16B42Ffd5
        poolContractAbi: UniswapPoolV2.abi,
        liquidityContractAddress: '0xf2da65F70AE1F3472BD00bb57c4722B0ADFd5665', // StakingPools contract https://rinkeby.etherscan.io/address/0xf2da65F70AE1F3472BD00bb57c4722B0ADFd5665
        liquidityContractAbi: StakingPoolV2.abi,
        liquidityType: LiquidityAssetType.StakingV2,
        rewardToken: TokenId.LAND,
        rewardTokenIcon: Constants.LAND.icon,
        expired: false
      },
      {
        area: 'Farmers Market',
        token1: TokenId.ETH,
        token1Icon: ethIcon,
        token2: TokenId.CORN,
        token2Icon: Constants.CORNv2.icon,
        pool: PoolId.Sushiswap,
        poolType: PoolType.Pair,
        poolReserveIndex: 0,
        poolIcon: SushiswapIcon,
        poolId: 1, // Pool ID for CORN-ETH
        lpToken: TokenId.SLP,
        poolUrl: 'https://app.sushi.com/add/0x63b0A77E8390aAE9CDf13AcB1009228Fd0577428/ETH', // Add CORN-ETH liquidity
        poolContractAddress: RinkebyCornEthSlpContractAddress,
        poolContractAbi: UniswapPoolV2.abi,
        //liquidityContractAddress: '0xf2da65F70AE1F3472BD00bb57c4722B0ADFd5665', // StakingPools contract https://rinkeby.etherscan.io/address/0xf2da65F70AE1F3472BD00bb57c4722B0ADFd5665
        liquidityContractAddress: '0x70C1B881FdB263Ec475afa7083e5A03beeeefF48', // StakingPools contract https://rinkeby.etherscan.io/address/0x70C1B881FdB263Ec475afa7083e5A03beeeefF48
        liquidityContractAbi: StakingPoolV2.abi,
        liquidityType: LiquidityAssetType.StakingV2,
        rewardToken: TokenId.LAND,
        rewardTokenIcon: Constants.LAND.icon,
        expired: false
      }
    ],
    gameConfigs: [
      // Random Contract-> https://rinkeby.etherscan.io/address/0xDcf6bF8817c088fB6D97dFa4ebd5eD7F1069d4FA#code
      {
        gameIcon: null,
        gameType: GameType.HarvestLotto,
        token: TokenId.CORN,
        tokenIcon: Constants.CORNv2.icon,
        tokenUrl: `https://app.uniswap.org/#/swap?outputCurrency=${RinkebyCornContractAddress}`,
        tokenContractAddress: RinkebyCornContractAddress,
        tokenContractAbi: cropV2.abi,
        gameContractAddress: '0x425283299856ACb4aF98dFc452F11b335E9906d3', //  https://rinkeby.etherscan.io/address/0x425283299856ACb4aF98dFc452F11b335E9906d3#code
        gameContractAbi: LottoV1.abi,
        rewardToken: TokenId.CORN,
        rewardTokenIcon: Constants.CORNv2.icon,
        expired: false
      },
      {
        gameIcon: null,
        gameType: GameType.ChickenFeed,
        token: TokenId.CORN,
        tokenIcon: Constants.CORNv2.icon,
        tokenUrl: `https://app.uniswap.org/#/swap?outputCurrency=${RinkebyCornContractAddress}`,
        tokenContractAddress: RinkebyCornContractAddress,
        tokenContractAbi: cropV2.abi,
        gameContractAddress: '0x1a956bda183E2a0D36F5aa471fFEBE6360720AB3', //https://rinkeby.etherscan.io/address/0x1a956bda183E2a0D36F5aa471fFEBE6360720AB3#code
        gameContractAbi: ChickenFeedV1.abi,
        rewardToken: TokenId.CORN,
        rewardTokenIcon: Constants.CORNv2.icon,
        expired: false
      },
      {
        gameIcon: null,
        gameType: GameType.SuperDraw,
        token: TokenId.CORN,
        tokenIcon: Constants.CORNv2.icon,
        tokenUrl: `https://app.uniswap.org/#/swap?outputCurrency=${RinkebyCornContractAddress}`,
        tokenContractAddress: RinkebyCornContractAddress,
        tokenContractAbi: cropV2.abi,
        gameContractAddress: '0xA5ce187DC3270d4bD7EdD6000a4975E480E7032a', //  https://rinkeby.etherscan.io/address/0xA5ce187DC3270d4bD7EdD6000a4975E480E7032a#code
        gameContractAbi: SuperdrawV1.abi,
        rewardToken: TokenId.CORN,
        rewardTokenIcon: Constants.CORNv2.icon,
        expired: false
      }
    ],
    claims: [
      {
        name: 'Land-Drop',
        contractAbi: landDistributorContract.abi,
        claimMerkle: claimLandRinkeby,
        contractAddress: '0x987221861ce64e054eA2BE78C24263c0292FF4F4', // https://rinkeby.etherscan.io/address/0x987221861ce64e054eA2BE78C24263c0292FF4F4
        assetConstants: Constants.LAND
      }
    ],
    rewardConfig: null,
    isL2: false,
    isTestnet: true,
    hasCollectibles: true,
    providerConfig: {
      infuraKey: '610707a11c34489299fd7124b9b2a0c9',
      etherscanKey: 'GPNPEWCTND2YACQGJU9J95DB628NQTMHVI',
      alchemyKey: 'EO4DqnyB_N6YJy672JBOxOkeIGPiwkap'
    },
    graphAPIExchangeEndpoint: null
  };
}
