import { Component } from 'react';
import { Image } from 'react-konva';

interface IImagePartProps {
  src: string;
  width: number;
  height: number;
}

interface IImagePartState {
  image: any;
}

export function renderImagePart(partType: string, partName: string, width: number, height: number) {
  return (
    <div style={{ height: 0 }}>
      <ImagePart src={getImageSrc(partType, partName)} width={width} height={height} />
    </div>
  );
}

export function getImageSrc(partType: string, partName: string) {
  return `./assets/mercs/${partType}/${partName}.png`;
}

export class ImagePart extends Component<IImagePartProps, IImagePartState> {
  image: any;
  imageNode: any;

  constructor(props: IImagePartProps) {
    super(props);

    this.state = {
      image: null
    };
  }

  componentDidMount() {
    this.loadImage();
  }

  componentDidUpdate(oldProps: any) {
    if (oldProps.src !== this.props.src) {
      this.loadImage();
    }
  }

  componentWillUnmount() {
    this.image?.removeEventListener('load', this.handleLoad);
  }

  loadImage() {
    this.image = new window.Image();
    this.image.src = this.props.src;
    this.image.addEventListener('load', this.handleLoad);
  }

  handleLoad = () => {
    this.setState({
      image: this.image
    });
  };

  render() {
    return (
      <Image
        width={this.props.width}
        height={this.props.height}
        image={this.state.image}
        ref={node => {
          this.imageNode = node;
        }}
      />
    );
  }
}
