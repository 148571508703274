import { BigNumber } from 'ethers';

export interface ICraftingDetails {
  isCraftable: boolean;
  isDragging: boolean;
  isCrafting: boolean;
}

export interface IItemDetails {
  balance: number;
  id: number;
  price: BigNumber;
  maxSupply: BigNumber;
  name: string;
  description?: string;
  imageUrl: string;
  animationUrl: string;
  paymentContract: string;
  mintingActive: boolean;
  itemType: number;
  rarity: number;
  questUseMap: Map<number, boolean>;
  isHealth: boolean;
  isMorale: boolean;
  statRestoreAmount: number;
  craftingDetails: ICraftingDetails;
}

export class ItemDetails {
  balance: number = 0;
  id?: number;
  price?: BigNumber;
  maxSupply?: BigNumber;
  name?: string;
  description?: string;
  imageUrl?: string;
  animationUrl?: string;
  paymentContract?: string;
  mintingActive?: boolean;
  itemType?: number;
  rarity?: number;
  questUseMap?: Map<number, boolean> = new Map<number, boolean>();
  isHealth?: boolean;
  isMorale?: boolean;
  statRestoreAmount: number = 0;
  craftingDetails: ICraftingDetails = {
    isCraftable: false,
    isDragging: false,
    isCrafting: false
  };
}

export function getItemId(details: any): number {
  return details['itemID'].toNumber();
}

export async function toItemDetails(
  details: any,
  questUseMap: Map<number, boolean>,
  isHealth: boolean,
  isMorale: boolean,
  statRestoreAmount: number = 0
): Promise<IItemDetails> {
  const itemType = details['itemType'].toNumber();

  return {
    balance: 0,
    id: details['itemID'].toNumber(),
    price: details['price'],
    maxSupply: details['maxSupply'],
    name: details['name'],
    description: details['description'],
    imageUrl: details['imageUrl'].replace('https://farmlandgame.net/assets/', './assets/'),
    animationUrl: details['animationUrl'].replace('https://farmlandgame.net/assets/', './assets/'),
    paymentContract: details['paymentContract'],
    mintingActive: details['mintingActive'],
    rarity: details['rarity'].toNumber(),
    itemType: itemType,
    questUseMap: questUseMap,
    isHealth: isHealth,
    isMorale: isMorale,
    statRestoreAmount: statRestoreAmount,
    craftingDetails: {
      isCraftable: true, //itemType === 0 || itemType === 2,
      isDragging: false,
      isCrafting: false
    }
  };
}
