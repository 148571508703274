import { IContractConstants } from './ModelInterfaces';

export interface IAssetConfig {
  contractAddress: string;
  assetConstants?: IAssetConstants | null;
}

export interface IContractConfig {
  contractAddress: string;
  contractConstants: IContractConstants;
  deployedBlockNumber: number;
}

export interface IContractWithClaimsConfig {
  contractAddress: string;
  contractConstants: IContractConstants;
  deployedBlockNumber: number;
  claimMerkle: any | null;
}

export class AssetConfig implements IAssetConfig {
  contractAddress = '';
  assetConstants = null;
}

export enum AssetType {
  Land = 'Land',
  Crop = 'Crop',
  Item = 'Item',
  SLP = 'SLP',
  Token = 'Token'
}

export interface IAssetConstants extends IContractConstants {
  symbol: string;
  icon: any;
  image: any;
  contractVersion: number;
  contractAbi: any;
  decimals: number;
  displayDecimals: number;
  assetType: AssetType;
  assetTypeCode: number;
  loadPrices: boolean;
}

export class AssetConstants implements IAssetConstants {
  symbol: string = '';
  icon: any = null;
  image: any = null;
  contractVersion: number = 1;
  contractAbi: any = null;
  decimals: number = 18;
  displayDecimals: number = 8;
  assetType: AssetType = AssetType.Crop;
  assetTypeCode: number = 0;
  loadPrices: boolean = true;
}
