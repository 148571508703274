import React, { Component } from 'react';
import { Subscription } from 'rxjs';
import { EthereumService } from '../../services/EthereumService';
import { ISuperdrawService, ILottoService, IGameService } from '../../services/Interfaces';
import { MessagingService } from '../../services/MessagingService';
import { LottoServiceV1 } from '../../services/LottoServiceV1';
import '../Content.scss';
import '../Tables.scss';
import './Saloon.scss';
import Lotto from './Lotto';
import saloon from '../../assets/moonshine-saloon.png';
import Superdraw from './Superdraw';
import { GameType } from '../../models/GameConfig';
import { SuperdrawServiceV1 } from '../../services/SuperdrawServiceV1';
import ChickenFeed from './ChickenFeed';
import { ChickenFeedServiceV1 } from '../../services/ChickenFeedServiceV1';
import Prizes from './Prizes';

interface ISaloonProps {
  messagingService: MessagingService;
  ethereumservice: EthereumService;
}

interface ISaloonState {
  lottoGame: ILottoService | null;
  chickenFeed: ILottoService | null;
  superDraw: ISuperdrawService | null;
  games: IGameService[];
}

class Saloon extends Component<ISaloonProps, ISaloonState> {
  subscription: Subscription;
  walletAddress?: string;
  chainId?: number;

  constructor(props: any) {
    super(props);

    this.state = {
      lottoGame: null,
      chickenFeed: null,
      superDraw: null,
      games: []
    };

    this.subscription = new Subscription();
  }

  componentDidMount() {
    var eventSubscription = this.props.messagingService.events.subscribe(event => {
      this.refresh();
    });

    this.subscription.add(eventSubscription);

    this.refresh();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  refresh() {
    if (
      this.props.ethereumservice.walletData.walletAddress &&
      this.props.ethereumservice.networkManager?.network?.chainId
    ) {
      if (
        this.props.ethereumservice.walletData.walletAddress !== this.walletAddress ||
        this.props.ethereumservice.networkManager!.network!?.chainId !== this.chainId
      ) {
        this.walletAddress = this.props.ethereumservice.walletData.walletAddress;
        this.chainId = this.props.ethereumservice.networkManager!.network!.chainId;
        this.init();
      } else {
        this.update();
      }
    }
  }

  async init() {
    if (
      this.props.ethereumservice.networkManager?.network &&
      this.props.ethereumservice.networkManager?.network?.gameConfigs.length > 0
    ) {
      var gameUpdatePromises = this.props.ethereumservice.networkManager!.network!.gameConfigs.map(async lc => {
        switch (lc.gameType) {
          case GameType.HarvestLotto:
            var lotto = new LottoServiceV1(lc, this.props.ethereumservice, this.props.messagingService);

            await lotto.update();
            return lotto;

          case GameType.ChickenFeed:
            var chickenFeed = new ChickenFeedServiceV1(lc, this.props.ethereumservice, this.props.messagingService);

            await chickenFeed.update();
            return chickenFeed;

          case GameType.SuperDraw:
            var superdraw = new SuperdrawServiceV1(lc, this.props.ethereumservice, this.props.messagingService);

            await superdraw.update();
            return superdraw;
        }
      });

      await Promise.all(gameUpdatePromises).then((gameServices: any) => {
        this.setState({ games: gameServices });

        gameServices.forEach((gameService: IGameService) => {
          switch (gameService.config.gameType) {
            case GameType.HarvestLotto:
              this.setState({ lottoGame: gameService as ILottoService });
              break;
            case GameType.ChickenFeed:
              this.setState({ chickenFeed: gameService as ILottoService });
              break;
            case GameType.SuperDraw:
              this.setState({ superDraw: gameService as ISuperdrawService });
              break;
          }
        });
      });
    }
  }

  update() {
    this.state.lottoGame?.update();

    this.setState({ lottoGame: this.state.lottoGame });
  }

  render(): React.ReactNode {
    return (
      <section id="content" className="saloon is-vcentered">
        <div className="container is-max-widescreen">
          {!this.props.ethereumservice.walletData.isConnected && (
            <span
              className="column meme is-half has-text-centered"
              style={{ cursor: 'pointer' }}
              onClick={() => this.props.ethereumservice.connectWallet()}>
              <h3 className="sub-title py-3">Connect Wallet</h3>
            </span>
          )}

          {this.props.ethereumservice.walletData.isConnected && (
            <React.Fragment>
              {this.props.ethereumservice.networkManager!.network!.gameConfigs.length === 0 && (
                <span className="column meme is-half has-text-centered">
                  <h3 className="sub-title py-3">Nothing going on here, try a different network!</h3>
                </span>
              )}
              {this.props.ethereumservice.networkManager!.network!.gameConfigs.length > 1 && !this.state.superDraw && (
                <span className="column meme is-half has-text-centered">
                  <h3 className="sub-title py-3">Loading Saloon</h3>
                </span>
              )}
              {this.props.ethereumservice.networkManager!.network!.gameConfigs.length > 1 && this.state.superDraw && (
                <React.Fragment>
                  <div className="is-flex moonshine-banner-container">
                    <img className="moonshine-banner-img" alt="" src={saloon}></img>
                  </div>

                  <div className="tile is-ancestor is-vertical mt-3 mx-3">
                    <div className="tile is-horizontal is-12">
                      <div className="tile is-parent">
                        <div className="is-flex is-flex-direction-column is-fullwidth">
                          <article className="card mini-game tile is-child box">
                            {this.state.superDraw && (
                              <Superdraw
                                ethereumService={this.props.ethereumservice}
                                superdraw={this.state.superDraw}></Superdraw>
                            )}
                          </article>
                        </div>
                      </div>
                    </div>
                    <div className="tile is-horizontal is-12">
                      <div className="tile is-vertical is-6">
                        <div className="tile is-parent">
                          <div className="is-flex is-flex-direction-column is-fullwidth">
                            <article className="card mini-game tile is-child box has-background-light">
                              {this.state.lottoGame && (
                                <Lotto
                                  ethereumService={this.props.ethereumservice}
                                  lottoService={this.state.lottoGame}></Lotto>
                              )}
                            </article>
                          </div>
                        </div>
                      </div>
                      <div className="tile is-vertical is-6">
                        <div className="tile is-parent">
                          <div className="is-flex is-flex-direction-column is-fullwidth">
                            <article className="card mini-game tile is-child box has-background-light">
                              {this.state.chickenFeed && (
                                <ChickenFeed
                                  ethereumService={this.props.ethereumservice}
                                  lottoService={this.state.chickenFeed}></ChickenFeed>
                              )}
                            </article>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tile is-horizontal is-12">
                      <div className="tile is-parent">
                        <div className="is-flex is-flex-direction-column is-fullwidth">
                          <article className="card mini-game tile is-child box">
                            {this.state.superDraw && (
                              <Prizes ethereumService={this.props.ethereumservice} games={this.state.games}></Prizes>
                            )}
                          </article>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </section>
    );
  }
}

export default Saloon;
