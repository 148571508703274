import { Component } from 'react';
//@ts-ignore
import { Switch, Route, Redirect } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import { EthereumService } from '../services/EthereumService';
import { MessagingService } from '../services/MessagingService';
import Farm from './Farm/Farm';
import Landing from './Landing';
import Characters from './Characters/Characters';
import Pools from './Pools/Opportunities';
import Saloon from './Saloon/Saloon';
import Barn from './Barn/Barn';
import { Quests } from './Quests/Quests';
import { Bank } from './Bank/Bank';
import { CharacterImagePage } from './Characters/CharacterImagePage';

interface IContentProps {
  messagingService: MessagingService;
  ethereumservice: EthereumService;
}

class Content extends Component<IContentProps> {
  render() {
    return (
      <div id="content-container" className="noselect">
        <Switch>
          <Route exact={true} path="/">
            <Landing messagingService={this.props.messagingService} ethereumService={this.props.ethereumservice} />
          </Route>

          <Route path="/farm">
            <ErrorBoundary>
              <Farm messagingService={this.props.messagingService} ethereumservice={this.props.ethereumservice} />
            </ErrorBoundary>
          </Route>

          <Route path="/bank">
            <ErrorBoundary>
              <Bank messagingService={this.props.messagingService} ethereumService={this.props.ethereumservice} />
            </ErrorBoundary>
          </Route>

          <Route path="/citizens">
            <ErrorBoundary>
              <Characters messagingService={this.props.messagingService} ethereumService={this.props.ethereumservice} />
            </ErrorBoundary>
          </Route>

          <Route
            path="/character-image/:characterId"
            render={(props: any) => (
              <CharacterImagePage {...props} ethereumService={this.props.ethereumservice} />
            )}></Route>

          <Route
            path="/character-image/:characterId"
            render={(props: any) => (
              <CharacterImagePage {...props} ethereumService={this.props.ethereumservice} />
            )}></Route>

          <Route path="/quests">
            <ErrorBoundary>
              <Quests messagingService={this.props.messagingService} ethereumService={this.props.ethereumservice} />
            </ErrorBoundary>
          </Route>

          <Route path="/barn">
            <ErrorBoundary>
              <Barn messagingService={this.props.messagingService} ethereumService={this.props.ethereumservice} />
            </ErrorBoundary>
          </Route>

          <Route path="/pools">
            <ErrorBoundary>
              <Pools messagingService={this.props.messagingService} ethereumService={this.props.ethereumservice} />
            </ErrorBoundary>
          </Route>

          <Route path="/saloon">
            <ErrorBoundary>
              <Saloon messagingService={this.props.messagingService} ethereumservice={this.props.ethereumservice} />
            </ErrorBoundary>
          </Route>

          <Redirect to="/" />
        </Switch>
      </div>
    );
  }
}

export default Content;
