import { Component } from 'react';
import bulmaCollapsible from '@creativebulma/bulma-collapsible';
import './CollapsibleCard.scss';

interface ICollapsibleCardProps {
  id: string;
  title: string;
  children: React.ReactNode;
  bgColor?: string;
  initialCollapsed?: boolean | undefined;
}

interface ICollapsibleCardState {
  collapsed: boolean;
  bgColor: string;
}

export default class CollapsibleCard extends Component<ICollapsibleCardProps, ICollapsibleCardState> {
  collapsible?: any;

  constructor(props: ICollapsibleCardProps) {
    super(props);

    console.log(props.initialCollapsed);

    this.state = {
      collapsed:
        props.initialCollapsed !== undefined
          ? props.initialCollapsed!
          : localStorage.getItem(`collapsible-state-${this.props.id}`) === 'true',
      bgColor: this.props.bgColor ?? '#00000040'
    };

    this.setCollapsedState = this.setCollapsedState.bind(this);
  }

  componentDidMount() {
    this.collapsible = bulmaCollapsible.attach(`.${this.props.id}-is-collapsible`, {
      container: this.refs.collapsibles
    });

    this.collapsible[0].on('after:expand', (e: any) => {
      this.setCollapsedState(false);
    });
    this.collapsible[0].on('after:collapse', (e: any) => {
      this.setCollapsedState(true);
    });
  }

  setCollapsedState(collapsed: boolean) {
    if (this.props.initialCollapsed !== undefined) return;
    localStorage.setItem(`collapsible-state-${this.props.id}`, `${collapsed}`);
    this.setState({ collapsed: collapsed });
  }

  render() {
    return (
      <article
        ref="collapsibles"
        className={`card collapsible-card tile is-child box has-background-light ${
          this.state.collapsed ? 'pb-0' : 'pb-4'
        }`}>
        <div className="info-control card-title my-0" style={{ justifyContent: 'space-between', border: 'none' }}>
          <h2 className="info mb-0" style={{ textTransform: 'uppercase', backgroundColor: this.state.bgColor }}>
            {this.props.title}
          </h2>
          <a href={`#${this.props.id}-content`} data-action="collapse" aria-label="collapse">
            <span className="icon" style={{ color: 'var(--orange2)' }}>
              <i className={`${this.state.collapsed ? 'fa-angle-down' : 'fa-angle-up'} fas`} aria-hidden="true"></i>
            </span>
          </a>
        </div>

        <div
          id={`${this.props.id}-content`}
          className={`${this.props.id}-is-collapsible is-collapsible pt-4 ${this.state.collapsed ? '' : 'is-active'}`}>
          <div style={{ overflowY: 'visible' }}>
            <hr className="mb-2 mt-0" />
            <div className="content">{this.props.children}</div>
          </div>
        </div>
      </article>
    );
  }
}
