import React, { Component } from 'react';
import { Subscription } from 'rxjs';
import { ICharacterWrapper, newSampleCharacterDetails } from '../../models/CharacterDetails';
import { EthereumService } from '../../services/EthereumService';
import { MessagingService } from '../../services/MessagingService';
import { CitizenService } from '../../services/CitizenService';
import { Utils } from '../../services/Utils';
import { BigNumber } from '@ethersproject/bignumber';
import { ICharacterConfig } from '../../models/CharacterConfig';
import { CharacterSet } from './CharacterSet';
import { Character } from './Character';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/scss/navigation';
import '../Tables.scss';
import './Characters.scss';
import './Character.scss';
import './CharacterAnim.scss';
import si1 from '../../assets/avatars/3.png';
import si2 from '../../assets/avatars/4.png';
import si3 from '../../assets/avatars/7.png';
import si4 from '../../assets/avatars/8.png';
import { Mutex } from 'async-mutex';

const gaby = newSampleCharacterDetails(
  'Gaby Schlather',
  si2,
  [
    { id: 0, display_type: '', trait_type: 'Badge 1', badge_type: 'Special', value: 'Founding mother' },
    { id: 1, display_type: '', trait_type: 'Badge 2', badge_type: 'Image', value: 'Red Pill' }
  ],
  0
);
const kevin = newSampleCharacterDetails(
  'Kevin Molz',
  si4,
  [{ id: 0, display_type: '', trait_type: 'Badge 1', badge_type: 'Image', value: 'Laser eyes' }],
  1
);
const mitch = newSampleCharacterDetails(
  'Mitch Drummey',
  si1,
  [{ id: 0, display_type: '', trait_type: 'Badge 1', badge_type: 'Stat', value: 'Lightning' }],
  2
);
const judy = newSampleCharacterDetails('Judy Yopp', si3, [], 3);

interface ICharactersProps {
  messagingService: MessagingService;
  ethereumService: EthereumService;
}

interface ICharactersState {
  isConnected: boolean;
  isLoaded: boolean;
  investAsset?: string;
  characters: ICharacterWrapper[];
  showDetail: boolean;
  amountToMint: number;
  refreshedAtBlock: number;
}

class Characters extends Component<ICharactersProps, ICharactersState> {
  subscription: Subscription;
  walletAddress?: string;
  chainId?: number;
  characterConfig?: ICharacterConfig | null;
  citizenService?: CitizenService;
  characterLaunchTimer?: NodeJS.Timer;
  mutex = new Mutex();

  constructor(props: any) {
    super(props);

    this.state = {
      isConnected: true,
      isLoaded: false,
      investAsset: '',
      characters: [],
      amountToMint: 1,
      showDetail: false,
      refreshedAtBlock: 0
    };

    this.subscription = new Subscription();

    this.handleAmountToMint = this.handleAmountToMint.bind(this);
    this.handleAmountToMintChange = this.handleAmountToMintChange.bind(this);
    this.mintCharacter = this.mintCharacter.bind(this);
    this.getMintCost = this.getMintCost.bind(this);
    this.setInView = this.setInView.bind(this);

    SwiperCore.use([Navigation]);
  }

  componentDidMount() {
    var eventSubscription = this.props.messagingService.events.subscribe((event: any) => {
      this.refresh();
    });
    this.subscription.add(eventSubscription);
    this.refresh();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  handleAmountToMint(e: any) {
    var amount = Math.min(20, Math.max(1, e.target.value));
    this.setState({ amountToMint: amount });
  }

  handleAmountToMintChange(changeBy: number) {
    this.setState({ amountToMint: this.state.amountToMint + changeBy });
  }

  mintCharacter() {
    this.citizenService?.mint(this.state.amountToMint);
  }

  setInView(character: ICharacterWrapper, inView: boolean) {
    this.citizenService?.setInView(character, inView);
    if (inView && character.details === null) {
      this.update();
    }
  }

  async refresh(): Promise<any> {
    await this.mutex.runExclusive(async () => {
      await this.refreshImpl();
    });
  }

  private async refreshImpl(): Promise<any> {
    if (
      this.props.ethereumService.walletData.walletAddress &&
      this.props.ethereumService.networkManager?.network?.chainId &&
      this.props.ethereumService.walletData.blockNumber > this.state.refreshedAtBlock
    ) {
      if (
        this.props.ethereumService.walletData.walletAddress !== this.walletAddress ||
        this.props.ethereumService.networkManager!.network?.chainId !== this.chainId
      ) {
        this.walletAddress = this.props.ethereumService.walletData.walletAddress;
        this.chainId = this.props.ethereumService.networkManager!.network!.chainId;
        await this.init();
      } else {
        await this.update();
      }
    }
  }

  async init() {
    if (
      this.props.ethereumService.networkManager?.network &&
      this.props.ethereumService.networkManager?.network?.assets.size > 0
    ) {
      this.characterConfig = this.props.ethereumService.networkManager!.network!.characterConfig;

      if (this.characterConfig == null) {
        return;
      }

      this.citizenService = new CitizenService(
        this.characterConfig,
        this.props.messagingService,
        this.props.ethereumService
      );

      await this.update();
    }
  }

  async update() {
    await this.citizenService!.update();

    this.setState({
      isConnected: this.props.ethereumService.walletData.isConnected,
      characters: this.citizenService!.ownedCharacters,
      isLoaded: true,
      refreshedAtBlock: this.props.ethereumService.walletData.blockNumber
    });
  }

  getMintCost(): string {
    return Utils.formatUnits(this.citizenService!.mintPrice?.mul(BigNumber.from(this.state.amountToMint)));
  }

  render() {
    var characterCount = this.state.characters.length;
    return (
      <section id="content" className="is-vcentered">
        <div className="is-max-fullhd1">
          {!this.state.isConnected && (
            <span
              className="column meme is-half has-text-centered mt-4"
              style={{ cursor: 'pointer' }}
              onClick={() => this.props.ethereumService.connectWallet()}>
              <h3 className="sub-title py-3">Connect Wallet</h3>
            </span>
          )}
          {this.state.isConnected && !this.state.isLoaded && (
            <span className="column meme is-half has-text-centered mt-4">
              <h3 className="sub-title py-3">Loading...</h3>
            </span>
          )}
          {this.state.isConnected && this.state.isLoaded && (
            <div>
              <div className="characters">
                {characterCount > 0 && this.renderAssets()}
                {characterCount <= 0 && this.renderSampleAssets()}
              </div>
              <section className="my-6">
                <div className="container is-flex is-flex-direction-column">
                  <div className="columns is-centered">
                    <span className="column meme is-bordered is-half has-text-centered">
                      {characterCount > 0 && <h3 className="sub-title is-upper pt-3">Mint more Citizens</h3>}
                      {characterCount <= 0 && <h3 className="sub-title is-upper pt-3">Mint your own Citizen</h3>}
                      {!this.props.ethereumService.networkManager?.network?.hasCollectibles && (
                        <h3 className="sub-title m-4">
                          <span>Only on Arbitrum</span>
                        </h3>
                      )}
                      {this.props.ethereumService.networkManager?.network?.hasCollectibles && (
                        <div className="is-flex my-5">
                          <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center character-mint buy-buttons">
                            <ul className="action-control">
                              <li className="action-form">
                                <div className="field has-addons">
                                  <div className="control">
                                    <button
                                      className="button minus-button is-action"
                                      disabled={this.state.amountToMint <= 1}
                                      onClick={() => this.handleAmountToMintChange(-1)}>
                                      <span className="icon">
                                        <i className="fas fa-minus"></i>
                                      </span>
                                    </button>
                                  </div>
                                  <div className="control">
                                    <input
                                      className="input is-action"
                                      type="text"
                                      disabled={true}
                                      value={this.state.amountToMint}
                                    />
                                  </div>
                                  <div className="control">
                                    <button
                                      className="button plus-button is-action"
                                      disabled={this.state.amountToMint >= 20}
                                      onClick={() => this.handleAmountToMintChange(+1)}>
                                      <span className="icon">
                                        <i className="fas fa-plus"></i>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <button
                                  className="button boost-button is-action is-fullwidth"
                                  onClick={this.mintCharacter}>
                                  Mint Citizens
                                </button>
                              </li>
                            </ul>

                            <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center mt-5">
                              <p>
                                <span className="mr-1">Cost {this.getMintCost()}</span>
                                ETH (+ gas)
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              </section>
            </div>
          )}
        </div>
      </section>
    );
  }

  renderAssets() {
    return (
      <React.Fragment>
        <CharacterSet
          key={this.characterConfig!.citizenConfig!.contractAddress}
          setInView={this.setInView}
          characterService={this.citizenService!}></CharacterSet>
      </React.Fragment>
    );
  }

  renderSampleAssets() {
    return (
      <React.Fragment>
        <Swiper navigation spaceBetween={10} slidesPerView="auto" centerInsufficientSlides>
          <SwiperSlide>
            <Character
              character={gaby}
              actionsLv1={[
                { name: 'Sample', isApproved: true, onClick: () => {}, onHoverMessage: null, enabled: false }
              ]}
              actionsLv2={[]}
              actionsLv3={[]}
              onCardClick={() => {}}
              isLoading={false}
              isSample={true}></Character>
          </SwiperSlide>
          <SwiperSlide>
            <Character
              character={kevin}
              actionsLv1={[
                { name: 'Sample', isApproved: true, onClick: () => {}, onHoverMessage: null, enabled: false }
              ]}
              actionsLv2={[]}
              actionsLv3={[]}
              onCardClick={() => {}}
              isLoading={false}
              isSample={true}></Character>
          </SwiperSlide>
          <SwiperSlide>
            <Character
              character={mitch}
              actionsLv1={[
                { name: 'Sample', isApproved: true, onClick: () => {}, onHoverMessage: null, enabled: false }
              ]}
              actionsLv2={[]}
              actionsLv3={[]}
              onCardClick={() => {}}
              isLoading={false}
              isSample={true}></Character>
          </SwiperSlide>
          <SwiperSlide>
            <Character
              character={judy}
              actionsLv1={[
                { name: 'Sample', isApproved: true, onClick: () => {}, onHoverMessage: null, enabled: false }
              ]}
              actionsLv2={[]}
              actionsLv3={[]}
              onCardClick={() => {}}
              isLoading={false}
              isSample={true}></Character>
          </SwiperSlide>
        </Swiper>
      </React.Fragment>
    );
  }
}

export default Characters;
