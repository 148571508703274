import { Component } from 'react';
import farmer from '../assets/farmer.png';

interface IErrorProps {
  children: React.ReactNode;
}

interface IErrorState {
  hasError: boolean;
}

class ErrorBoundary extends Component<IErrorProps, IErrorState> {
  constructor(props: IErrorProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <section id="farm" className="is-vcentered hero is-fullheight">
          <div className="hero-body mt-6 container">
            <div className="columns is-multiline">
              <div className="column has-text-centered is-full">
                <h1 className="title has-text-light">Something went wrong</h1>
                <p className="subtitle has-text-light">
                  An unexpected error has occurred. Please&nbsp;
                  <a
                    className="has-action-text"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://discord.com/invite/QVgWRA346s">
                    contact us.
                  </a>
                </p>
              </div>
              <div className="column has-text-centered is-full">
                <img alt="Unhappy Farmer" src={farmer} width="60px" />
              </div>
            </div>
          </div>
        </section>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
