export interface IContractsData {
  isAuthorized: boolean;
  isPending: boolean;

  farmBuiltBlockNumber: any;
  lastHarvestBlockNumber: any;
  maxGrowthCycle: any;
  maxGrowthCycleWithFarmer: any;
  cropBalance: any;
  cropToHarvest: any;
  farmMaturityBoost: any;
  farmCompostBoost: any;
  maxMaturityBoost: any;
  maxCompostBoost: any;
  totalBoost: any;
  landInFarm: any;
  wLandInFarm: any;
  wLand: any;
  cropComposted: any;
  landBalance: any;
  burnRequiredTo10x: any;
  maxTo10x: any;
  ownsFarmer: any;
  ownsEquipment: any;
  numberofCollectibles: any;

  globalRatio: any;
  globalNumberOfFarms: any;
  globalLandInFarm: any;
  globalComposted: any;

  globalAverageLandInFarms: any;
  globalAverageComposted: any;

  endOfGrowingCycle: any;
  growingCycleCountDown: any;
  growingCycleCountDownSecs: any;

  maximumMaturityCollectibleBoost: string;
}

export class ContractsData implements IContractsData {
  isAuthorized = false;
  isPending = false;

  farmBuiltBlockNumber = 0;
  lastHarvestBlockNumber = 0;
  maxGrowthCycle = 0;
  maxGrowthCycleWithFarmer = 0;

  cropBalance: any = '0';
  cropToHarvest: any = '0';
  farmMaturityBoost: any = '0';
  farmCompostBoost: any = '0';
  maxMaturityBoost: any = '0';
  maxCompostBoost: any = '0';
  totalBoost: any = '0';
  landInFarm: any = '0';
  wLandInFarm: any = '0';
  wLand: any = '0';
  cropComposted: any = '0';
  landBalance: any = '0';
  burnRequiredTo10x: any = 0;
  maxTo10x: any = '0';
  ownsFarmer: any = false;
  ownsEquipment: any = false;
  numberofCollectibles: any = '0';

  globalRatio: any = '0';
  globalNumberOfFarms: any = '0';
  globalLandInFarm: any = '0';
  globalComposted: any = '0';

  globalAverageLandInFarms: any = '0';
  globalAverageComposted: any = '0';

  growingCycleCountDown: any = 0;
  growingCycleCountDownSecs = 0;
  endOfGrowingCycle: any = 0;

  maximumMaturityCollectibleBoost: string = '';
}
