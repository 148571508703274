import { EthereumService } from '../../services/EthereumService';
import { MercenaryService } from '../../services/MercenaryService';
import { IQuestService } from '../../services/QuestService';
import { ItemService } from '../../services/ItemService';
import { IItemDetails } from '../../models/ItemDetails';
import { Utils } from '../../services/Utils';
import { ITime } from '../../services/Interfaces';
import { BigNumber } from 'ethers';
import { Mutex } from 'async-mutex';
import {
  ICharacterStat,
  ICharactersActivity as ICharacterActivity,
  ICharacterWrapper,
  ICharacterDetails,
  newLoadingCharacter
} from '../../models/CharacterDetails';

export interface IExplorerItems {
  received: number;
  blockNumber: number;
  item: IItemDetails;
}

export enum ExplorerStateEnum {
  None,
  New,
  Questing,
  Completed,
  Aborted,
  Idle
}

export class ExplorerState {
  characterActivity: ICharacterActivity | null = null;
  explorerState: ExplorerStateEnum = ExplorerStateEnum.None;
  recent: IExplorerItems[] = [];
  landRecent: number = 0;
  history: IExplorerItems[] = [];
  landHistory: number = 0;
  lastQuestName: string = '';
  activeItems: IItemDetails[] = [];
  questCountdownSecs: number = 0;
  questCountdownTime: ITime = { days: '  ', hours: '  ', mins: '  ', secs: '  ', isComplete: false };
  health: ICharacterStat | null = {} as ICharacterStat;
  morale: ICharacterStat | null = {} as ICharacterStat;
  mutex = new Mutex();

  loadingCharacter: ICharacterDetails;

  public refreshedAtBlock: number = 0;

  constructor(
    public characterWrapper: ICharacterWrapper,
    private questService: IQuestService,
    private mercenaryService: MercenaryService,
    private itemService: ItemService,
    private ethereumService: EthereumService
  ) {
    this.loadingCharacter = newLoadingCharacter(characterWrapper!.id);
  }

  public get character() {
    return this.characterWrapper.details ?? this.loadingCharacter;
  }

  public get isCharacterLoaded() {
    return this.characterWrapper.details;
  }

  public get isCharacterUpdated() {
    return this.isCharacterLoaded && this.ethereumService.walletData.blockNumber === this.refreshedAtBlock;
  }

  async updateState(): Promise<any> {
    await this.mutex.runExclusive(async () => {
      await this.updateItemsReceivedState();
      await this.updateQuestState();
      await this.updateCharacterState();
      this.refreshedAtBlock = this.ethereumService.walletData.blockNumber;
    });
  }

  async updateItemsReceivedState(): Promise<any> {
    var items = this.questService ? await this.questService!.getCharactersItemsReceived(this.characterWrapper.id) : [];

    if (items.length === 0) return {};

    var maxBlockNumber = items.reduce((max, item) => {
      return item.blockNumber > max.blockNumber ? item : max;
    }).blockNumber;

    var recentItemsMap = items
      .filter(item => item.blockNumber === maxBlockNumber)
      .reduce((acc, curr) => {
        var item = this.itemService!.itemMap.get(BigNumber.from(curr.itemID).toNumber());
        if (item) {
          if (acc.has(item!.id)) {
            var currAmount = acc.get(item!.id)!.received;
            acc.set(item!.id, {
              item: item!,
              blockNumber: curr!.blockNumber,
              received: BigNumber.from(curr.itemAmount).toNumber() + currAmount
            });
          } else {
            acc.set(item!.id, {
              item: item!,
              blockNumber: curr!.blockNumber,
              received: BigNumber.from(curr.itemAmount).toNumber()
            });
          }
        }
        return acc;
      }, new Map<number, IExplorerItems>());
    this.recent = [...recentItemsMap.values()];

    this.landRecent = items
      .filter(item => item.blockNumber === maxBlockNumber)
      .reduce((acc, curr) => {
        acc = acc + +Utils.formatUnits(curr.landAmount);
        return acc;
      }, 0);

    let historyMap = items.reduce((acc, curr) => {
      var item = this.itemService!.itemMap.get(BigNumber.from(curr.itemID).toNumber());
      if (item) {
        if (acc.has(item!.id)) {
          var currAmount = acc.get(item!.id)!.received;
          acc.set(item!.id, {
            item: item!,
            blockNumber: curr!.blockNumber,
            received: BigNumber.from(curr.itemAmount).toNumber() + currAmount
          });
        } else {
          acc.set(item!.id, {
            item: item!,
            blockNumber: curr!.blockNumber,
            received: BigNumber.from(curr.itemAmount).toNumber()
          });
        }
      }
      return acc;
    }, new Map<number, IExplorerItems>());
    this.history = [...historyMap.values()];

    this.landHistory = items.reduce((acc, curr) => {
      acc = acc + +Utils.formatUnits(curr.landAmount);
      return acc;
    }, 0);
  }

  async updateCharacterState(): Promise<void> {
    if (this.characterWrapper.details === null) return;

    [this.health, this.morale] = await Promise.all([
      this.questService.getCharactersHealth(this.characterWrapper.id),
      this.mercenaryService!.getCharactersMorale(this.characterWrapper.id, this.characterWrapper.details!.morale)
    ]);
  }

  async updateQuestState(): Promise<any> {
    this.explorerState = ExplorerStateEnum.None;

    if (this.questService) {
      this.characterActivity = await this.questService?.getQuestState(this.characterWrapper.id);
    }

    this.activeItems = await this.itemService?.getActiveItems(this.characterWrapper.id);

    const networkBlockTime = this.ethereumService!.networkManager!.network!.blocktime;
    this.questCountdownSecs = 0;

    if (this.characterActivity) {
      this.questCountdownSecs =
        networkBlockTime * (this.characterActivity.endBlock - this.ethereumService!.walletData.stdBlockNumber);

      if (this.characterActivity.activityDuration <= 0) {
        this.explorerState = ExplorerStateEnum.New;
      } else if (!this.characterActivity.isActive) {
        this.explorerState =
          this.characterActivity!.completedBlock < this.characterActivity!.endBlock
            ? ExplorerStateEnum.Aborted
            : ExplorerStateEnum.Idle;
      } else if (this.questCountdownSecs > 0) {
        this.explorerState = ExplorerStateEnum.Questing;
      } else if (this.characterActivity.isActive) {
        this.explorerState = ExplorerStateEnum.Completed;
      }
    }

    var quests = this.questService?.getAvailableQuests();
    var lastQuest = this.questService!.getLastQuest(this.characterWrapper!.id!);
    var quest = quests?.find(q => q.index === lastQuest);

    this.lastQuestName = quest?.name ?? 'Quest';
    this.updateTimeRemaining();
  }

  updateTimeRemaining(): void {
    const secondsRemaining = this.questCountdownSecs < 0 ? 0 : Math.trunc(this.questCountdownSecs);

    this.questCountdownTime = {
      days: secondsRemaining < 0 ? '00' : ('0' + Math.floor(secondsRemaining / (60 * 60 * 24))).slice(-2),
      hours: secondsRemaining < 0 ? '00' : ('0' + Math.floor((secondsRemaining / (60 * 60)) % 24)).slice(-2),
      mins: secondsRemaining < 0 ? '00' : ('0' + Math.floor((secondsRemaining / 60) % 60)).slice(-2),
      secs: secondsRemaining < 0 ? '00' : ('0' + (secondsRemaining % 60)).slice(-2),
      isComplete: secondsRemaining < 0
    };
  }
}
