import { ethers } from 'ethers';
import { ILiquidityConfig } from '../models/LiquidityConfig';
import { EthereumService } from './EthereumService';
import { MessagingService } from './MessagingService';
import { Utils } from './Utils';
import { ILiquidityAssetService } from './Interfaces';

export class LpLandAssetService implements ILiquidityAssetService {
  walletAddress?: string;

  poolContract?: ethers.Contract | any;
  stakingContract?: ethers.Contract | any;

  lptBalance: string | 0 = 0;
  approvedLptBalance: string | 0 = 0;
  unapprovedLptBalance: string | 0 = 0;
  liquidityBalance: string | 0 = 0;

  hasReward = false;
  currentReward: string | 0 = 0;
  upcomingReward: string | 0 = 0;
  isInstantReward = false;

  totalLiquidity: string = '0';
  apy: number | 0 = 0;

  actionDisplayName = 'Wrap';
  undoActionDisplayName = 'Unwrap';

  constructor(
    public config: ILiquidityConfig,
    private ethereumService: EthereumService,
    private messagingService: MessagingService
  ) {
    this.walletAddress = this.ethereumService!.walletData.walletAddress;
    this.poolContract = new ethers.Contract(
      this.config.poolContractAddress,
      this.config.poolContractAbi,
      ethereumService.signer
    );
    this.stakingContract = new ethers.Contract(
      this.config.liquidityContractAddress,
      this.config.liquidityContractAbi,
      ethereumService.signer
    );
  }

  update(): Promise<ILiquidityAssetService> {
    return Promise.all([
      this.poolContract.balanceOf(this.walletAddress),
      this.poolContract.allowance(this.walletAddress, this.config.liquidityContractAddress),
      this.stakingContract.balanceOf(this.walletAddress)
    ]).then(response => {
      this.lptBalance = Utils.formatUnits(response[0], 18);
      this.approvedLptBalance = Utils.formatUnits(response[1], 18);
      this.unapprovedLptBalance = `${+this.lptBalance - +this.approvedLptBalance}`;
      this.liquidityBalance = Utils.formatUnits(response[2], 18);

      // TODO: error checking

      // TODO: determine total liquidity
      var totalLiquidity = 0;
      this.totalLiquidity = totalLiquidity.toLocaleString();

      // TODO: determine ROI
      this.apy = 0;

      return this;
    });
  }

  // - approve(staking pool contract address, BPT Amount)
  approve() {
    var amount = Utils.parseUnits('1000000000');

    // TODO: events
    this.poolContract.approve(this.config.liquidityContractAddress, amount).then(
      (approveResponse: any) =>
        this.messagingService.events.next({
          message: `Approval of ${this.config.lpToken} is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error approving ${this.config.lpToken} : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  // - stake(BPT Amount) >> stake BPT
  add(amount: any) {
    var sanitizedAmount = Utils.sanitizeParseUnits(amount);

    // TODO: events
    this.stakingContract.wrap(sanitizedAmount).then(
      (stakeResponse: any) =>
        this.messagingService.events.next({
          message: `Staking of ${this.config.lpToken} is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error staking ${this.config.lpToken} : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  // - withdraw(BPT Amount) >> stop staking return BPT to wallet
  remove(amount: any) {
    var sanitizedAmount = Utils.sanitizeParseUnits(amount);

    // TODO: events
    this.stakingContract.unwrap(sanitizedAmount).then(
      (withdrawResponse: any) =>
        this.messagingService.events.next({
          message: `Withdrawal of ${this.config.lpToken} is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error withdrawing ${this.config.lpToken} : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  claim() {}
  exit() {}
}
