/* eslint-disable prettier/prettier */
import { BigNumber, ethers } from 'ethers';
import {
  CharacterStat,
  ICharacterDetails,
  ICharacterStat,
  ICharacterWrapper,
  loadCitizenMercenaryDetails,
  loadMercenaryDetails
} from '../models/CharacterDetails';
import { EthereumService } from './EthereumService';
import { MessagingService } from './MessagingService';
import { Utils } from './Utils';
import { ICharacterService } from './Interfaces';
import { ICharacterConfig } from '../models/CharacterConfig';
import { IAssetConfig } from '../models/AssetConfig';
import { OwnedTokenCacheService } from './OwnedTokenCacheService';
import { Mutex } from 'async-mutex';
import { IItemDetails } from '../models/ItemDetails';

export class MercenaryService implements ICharacterService {
  mutex = new Mutex();
  walletAddress?: string;
  mercenaryContract?: ethers.Contract;
  citizenContract?: ethers.Contract;
  mintMercenaryContract?: ethers.Contract;
  statManagerContract?: ethers.Contract;
  count: number = 0;
  gasPrice?: string | number;
  tokenUri?: string;
  mintPrice?: BigNumber;
  updateTraitsPrice?: BigNumber;
  ownedCharacters: ICharacterWrapper[] = [];
  config: IAssetConfig;
  claimIndex?: BigNumber | null;
  offChainClaimIndex?: BigNumber | null;

  actionText: string = 'Mint';
  actionUrl: string = '/quests';

  ownedMercenariesEventCache: OwnedTokenCacheService;

  constructor(
    public characterConfig: ICharacterConfig,
    private messagingService: MessagingService,
    private ethereumService: EthereumService
  ) {
    this.walletAddress = this.ethereumService!.walletData.walletAddress;

    this.config = this.characterConfig.mercenaryConfig;

    this.mercenaryContract = new ethers.Contract(
      this.characterConfig.mercenaryConfig.contractAddress,
      this.characterConfig.mercenaryConfig.contractConstants.contractAbi,
      ethereumService.signer
    );

    this.citizenContract = new ethers.Contract(
      this.characterConfig.citizenConfig.contractAddress,
      this.characterConfig.citizenConfig.contractConstants.contractAbi,
      ethereumService.signer
    );

    this.mintMercenaryContract = new ethers.Contract(
      this.characterConfig.mintMercenaryConfig.contractAddress,
      this.characterConfig.mintMercenaryConfig.contractConstants.contractAbi,
      this.ethereumService.signer!
    );

    this.statManagerContract = new ethers.Contract(
      this.characterConfig.statManagerConfig.contractAddress,
      this.characterConfig.statManagerConfig.contractConstants.contractAbi,
      this.ethereumService.signer!
    );

    this.ownedMercenariesEventCache = new OwnedTokenCacheService(
      this.mercenaryContract,
      this.mercenaryContract!.filters.Transfer(null, this.walletAddress),
      this.mercenaryContract!.filters.Transfer(this.walletAddress, null),
      this.characterConfig.mercenaryConfig.deployedBlockNumber,
      ethereumService
    );

    this.loadStatics();
    this.setupEventHandlers();
  }

  async loadStatics(): Promise<any> {
    this.mintPrice = await this.mintMercenaryContract!.mercenaryPrice();
    this.updateTraitsPrice = await this.mintMercenaryContract!.traitPrice();
  }

  async update(): Promise<any> {
    await this.mutex.runExclusive(async () => {
      await this.loadOwnedWrappers();
      await this.loadCharacterDetails();
      await this.getClaimIndex();
      await this.getOffChainClaimIndex();
    });
  }

  setInView(characterStatics: ICharacterWrapper, inView: boolean) {
    if (this.ownedCharacters.find(c => c.id === characterStatics.id)) {
      characterStatics.inView = inView;
      if (inView) this.update();
    }
  }

  private async loadOwnedWrappers() {
    await this.ownedMercenariesEventCache.update();
    var ownedTokens = this.ownedMercenariesEventCache.ownedTokens;
    var newOwnedTokens = ownedTokens.filter(x => !this.ownedCharacters.some(y => y.id === x.toNumber()));

    var newCharacterStaticPromises = newOwnedTokens.map((tokenId: BigNumber) => {
      return this.loadStaticDetails(BigNumber.from(tokenId));
    });

    var characterStatics = await Promise.all(newCharacterStaticPromises);

    const unique = (value: ICharacterWrapper, index: number, self: Array<ICharacterWrapper>) => {
      return self.findIndex(x => x.id === value.id) === index;
    };

    this.ownedCharacters = [...this.ownedCharacters, ...characterStatics]
      .filter(unique)
      .sort((a, b) => (a.id < b.id ? -1 : 1));
  }

  private async loadStaticDetails(id: BigNumber): Promise<ICharacterWrapper> {
    const uri = await this.mercenaryContract!.tokenURI(id.toNumber());
    return { index: -1, id: id.toNumber(), uri, inView: false, details: null, detailsRefreshedAtBlock: 0 };
  }

  private async loadCharacterDetails(): Promise<any> {
    var characterDetailsPromises = this.ownedCharacters.map(async characterWrapper => {
      if (
        characterWrapper.inView &&
        characterWrapper.detailsRefreshedAtBlock < this.ethereumService.walletData.blockNumber
      ) {
        characterWrapper.details = await this.loadDetails(characterWrapper);
        characterWrapper.detailsRefreshedAtBlock = this.ethereumService.walletData.blockNumber;
      }
      return characterWrapper;
    });

    var characterWrappers = await Promise.all(characterDetailsPromises);

    const unique = (value: ICharacterWrapper, index: number, self: Array<ICharacterWrapper>) => {
      return self.findIndex(x => x.id === value.id) === index;
    };

    this.ownedCharacters = characterWrappers.filter(unique).sort((a, b) => (a.id < b.id ? -1 : 1));
  }

  private async loadDetails(characterStatics: ICharacterWrapper): Promise<ICharacterDetails> {
    var result = await Promise.all([
      this.mercenaryContract!.getStats(characterStatics.id),
      this.getStatBoostsAvailable(characterStatics.id),
      this.mercenaryContract!.getWrappedTokenDetails(characterStatics.id)
    ]);
    const stats = result[0];
    const statBoostsAvailable = result[1];
    const wrappedTokenDetails = result[2];
    const wrappedTokenId = wrappedTokenDetails.status ? wrappedTokenDetails.wrappedTokenID : null;

    if (
      characterStatics.uri.includes('https://farmlandgame.net/assets/NFTS/characters') ||
      characterStatics.uri.includes('https://farmland-game.github.io/assets/NFTS/characters')
    ) {
      return await loadCitizenMercenaryDetails(
        0,
        BigNumber.from(characterStatics.id),
        characterStatics.uri,
        stats,
        statBoostsAvailable
      );
    } else {
      return await loadMercenaryDetails(
        BigNumber.from(characterStatics.id),
        wrappedTokenId,
        characterStatics.uri!,
        stats,
        statBoostsAvailable
      );
    }
  }

  hasClaim(): boolean {
    return this.offChainClaimIndex != null || this.claimIndex != null;
  }

  async getCharactersHealth(characterId: number): Promise<ICharacterStat> {
    var max = await this.mercenaryContract!.getMaxHealth(characterId);
    return {
      name: 'Health',
      bottomColor: '#e42e52',
      topColor: 'green',
      max: BigNumber.from(max).toNumber(),
      remaining: BigNumber.from(max).toNumber()
    };
  }

  async getCharactersMorale(characterId: number, remaining: number): Promise<ICharacterStat> {
    var max = await this.mercenaryContract!.getMaxMorale(characterId);

    return {
      name: 'Morale',
      bottomColor: 'orange',
      topColor: 'blue',
      max: BigNumber.from(max).toNumber(),
      remaining: remaining
    };
  }

  async mint(traits: string[]) {
    let overrides = {
      value: this.mintPrice!.mul(BigNumber.from(1))
    };

    try {
      await this.mintMercenaryContract!.mint(traits, overrides);
      this.messagingService.events.next({
        message: `Minting a Mercenary is pending`,
        pending: true
      });
    } catch (error) {
      this.messagingService.errors.next({
        message: `Error minting Mercenary : ${Utils.getContractErrorMessage(error)}`,
        error: error
      });
    }
  }

  async boostStat(character: ICharacterDetails, amounts: number[], boostingStats: CharacterStat[]) {
    try {
      await this.statManagerContract!.boostStat(
        character.id,
        amounts,
        boostingStats.map(x => x - 1)
      ); // stat index is 0 based
      this.messagingService.events.next({
        message: `Mercenary stat boost is pending`,
        pending: true
      });
    } catch (error) {
      this.messagingService.errors.next({
        message: `Error boosting mercenary stats: ${Utils.getContractErrorMessage(error)}`,
        error: error
      });
    }
  }

  async restoreHealth(characterDetails: ICharacterDetails, item: IItemDetails, amount: number): Promise<any> {
    try {
      const gasEstimated = await this.statManagerContract!.estimateGas.restoreHealth(
        characterDetails.id,
        item.id,
        amount
      );
      await this.statManagerContract!.restoreHealth(characterDetails.id, item.id, amount, {
        gasLimit: Math.ceil(gasEstimated.toNumber() * 1.5)
      });
      this.messagingService.events.next({
        message: `Mercenary health restore is pending`,
        pending: true
      });
    } catch (error) {
      this.messagingService.errors.next({
        message: `Error restoring mercenary health: ${Utils.getContractErrorMessage(error)}`,
        error: error
      });
    }
  }

  async restoreMorale(characterDetails: ICharacterDetails, item: IItemDetails, amount: number): Promise<any> {
    try {
      const gasEstimated = await this.statManagerContract!.estimateGas.restoreMorale(
        characterDetails.id,
        item.id,
        amount
      );
      await this.statManagerContract!.restoreMorale(characterDetails.id, item.id, amount, {
        gasLimit: Math.ceil(gasEstimated.toNumber() * 1.5)
      });
      this.messagingService.events.next({
        message: `Mercenary morale restore is pending`,
        pending: true
      });
    } catch (error) {
      this.messagingService.errors.next({
        message: `Error restoring mercenary morale: ${Utils.getContractErrorMessage(error)}`,
        error: error
      });
    }
  }

  async updateAppearance(character: ICharacterDetails, traits: string[]) {
    let overrides = {
      value: this.updateTraitsPrice!.mul(BigNumber.from(1))
    };

    try {
      await this.mintMercenaryContract!.updateTraits(character.id, traits, overrides);
      this.messagingService.events.next({
        message: `Appearance update of a Mercenary is pending`,
        pending: true
      });
    } catch (error) {
      this.messagingService.errors.next({
        message: `Error updating the appearance of a Mercenary : ${Utils.getContractErrorMessage(error)}`,
        error: error
      });
    }
  }

  async getStatBoostsAvailable(tokenId: number): Promise<number> {
    var statsAvailable = await this.mercenaryContract!.getStatBoostAvailable(tokenId);
    return BigNumber.from(statsAvailable).toNumber();
  }

  unwrap(characterId: number) {
    return this.mercenaryContract!.unwrap(characterId).then(
      () =>
        this.messagingService.events.next({
          message: `Retiring a Character is pending`,
          pending: true
        }),
      (error: any) =>
        this.messagingService.errors.next({
          message: `Error retiring Character : ${Utils.getContractErrorMessage(error)}`,
          error: error
        })
    );
  }

  private async getClaimIndex(): Promise<any> {
    var allowList = await this.mintMercenaryContract!.getAllowlistIndex(this.walletAddress!);
    this.claimIndex = allowList.unclaimed ? allowList.index : null;
  }

  private async getOffChainClaimIndex() {
    var merkle =
      this.characterConfig.mintMercenaryConfig?.claimMerkle?.claims[this.ethereumService.walletData.walletAddress];

    if (!merkle) {
      return BigNumber.from(0);
    }

    const claimed = await this.mintMercenaryContract!.isClaimed(merkle.index);

    this.offChainClaimIndex = claimed ? null : merkle.index;
  }

  async claim(traits: string[]): Promise<any> {
    if (this.claimIndex) {
      return this.mintMercenaryContract!.claim(this.claimIndex, traits).then(
        () =>
          this.messagingService.events.next({
            message: `Claim of Mercenary is pending`,
            pending: true
          }),
        (error: any) =>
          this.messagingService.errors.next({
            message: `Error claiming Mercenary : ${Utils.getContractErrorMessage(error)}`,
            error: error
          })
      );
    } else if (this.offChainClaimIndex) {
      var merkle =
        this.characterConfig.mintMercenaryConfig!.claimMerkle.claims[this.ethereumService.walletData.walletAddress];

      if (!merkle) {
        this.messagingService.errors.next({
          message: `Error: address not found in merkle root`,
          error: 'Address not found in merkle root'
        });
        return Promise.reject('Error: address not found in merkle root');
      }

      try {
        const gasEstimated = await this.mintMercenaryContract!.estimateGas.claimAirDrop(
          merkle.index,
          merkle.proof,
          traits
        );

        await this.mintMercenaryContract!.claimAirDrop(merkle.index, merkle.proof, traits, {
          gasLimit: Math.ceil(gasEstimated.toNumber() * 1.5)
        });

        this.messagingService.events.next({
          message: `Claim of Mercenary is pending`,
          pending: true
        });
      } catch (error) {
        this.messagingService.errors.next({
          message: `Error claiming Mercenary : ${Utils.getContractErrorMessage(error)}`,
          error: error
        });
      }
    }
  }

  removeEventHandlers() {
    this.mercenaryContract?.removeAllListeners();
    this.statManagerContract?.removeAllListeners();
  }

  setupEventHandlers() {
    this.removeEventHandlers();

    var update = (message: string) => {
      this.messagingService.events.next({ message: message });
      this.update();
    };

    this.mercenaryContract!.on(
      {
        address: this.ethereumService.walletData.walletAddress,
        topics: [ethers.utils.id('ApprovalForAll(address,address,bool)')]
      },
      (owner, operator, approved) => {
        if (owner === this.ethereumService.walletData.walletAddress) {
          if (approved) {
            update(`Mercenaries have been approved`);
          } else {
            this.messagingService.errors.next({
              message: `Mercenaries were not approved`,
              error: `Mercenaries were not approved`
            });
          }
        }
      }
    );

    this.mercenaryContract!.on(
      {
        address: this.ethereumService.walletData.walletAddress,
        topics: [ethers.utils.id('Transfer(address,address,uint256)')]
      },
      (from, to, tokenId) => {
        if (to === this.ethereumService.walletData.walletAddress) {
          update(`Mercenary available`);
        }
      }
    );

    this.mercenaryContract!.on(
      {
        address: this.ethereumService.walletData.walletAddress,
        topics: [ethers.utils.id('StatIncreased(address,uint256,uint256,uint256)')]
      },
      (account, tokenID, amount, statIndex) => {
        var merc = this.ownedCharacters.find(x => x.id === BigNumber.from(tokenID).toNumber());
        var stat = CharacterStat[BigNumber.from(statIndex).toNumber() + 1];
        if (merc != null) {
          var name = merc?.details != null ? merc?.details.name : 'Mercenary';
          update(`${name} ${stat != null ? stat : 'stat'} restored by ${amount}`);
        }
      }
    );

    this.statManagerContract!.on(
      {
        topics: [ethers.utils.id('StatBoosted(address,uint256,uint256,uint256)')]
      },
      (account, tokenID, amount, statIndex) => {
        var stat = CharacterStat[BigNumber.from(statIndex).toNumber() + 1];

        if (account === this.ethereumService.walletData.walletAddress) {
          var merc = this.ownedCharacters.find(x => x.id === BigNumber.from(tokenID).toNumber());
          var name = merc?.details != null ? merc?.details.name : 'Mercenary';
          update(`${name} ${stat != null ? stat : 'stat'} boosted by ${amount}`);
        }
      }
    );
  }
}
