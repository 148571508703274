import { BigNumber } from 'ethers';
import { Utils } from '../services/Utils';

export interface IPackDetails {
  id: number;
  name: string;
  description: string;
  itemType: BigNumber;
  price: number;
  paymentAddress: string;
  packMerkleRoot: string;
  maxSupply: BigNumber;
  active: boolean;
  totalSupply: BigNumber;
}

export async function toPackDetails(id: number, details: Array<any>): Promise<IPackDetails> {
  var description = 'Contains random items useful on quests';
  switch (id) {
    case 1:
      description = 'Contains random consumables useful on quests';
      break;
    case 2:
      description = 'Contains random items useful on quests and possibly a suprise';
  }

  return {
    id: id,
    name: details[0],
    description: description,
    itemType: details[1],
    price: +Utils.formatUnits(details[2], 18),
    paymentAddress: details[3],
    packMerkleRoot: details[4],
    maxSupply: details[5],
    active: details[6],
    totalSupply: details[7]
  };
}
